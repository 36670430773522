import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import useResponsive from "src/hooks/useResponsive";

const TimelineTileSkeleton = () => {
  const theme = useTheme();
  const isDesktop = useResponsive("up", "sm");
  return (
    <TimelineItem>
      {isDesktop && (
        <TimelineOppositeContent
          sx={{
            flex: 0,
            minWidth: 100,
          }}
          color="text.secondary"
        >
          <Skeleton variant="text" width={70} />
        </TimelineOppositeContent>
      )}
      <TimelineSeparator>
        <TimelineDot
          sx={{
            height: 20,
            width: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "grey.300",
          }}
        ></TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Box
          sx={{
            border: "1px solid" + theme.palette.grey[300],
            borderRadius: 1,
            bgcolor: "background.paper",
            width: "300px",
          }}
        >
          {!isDesktop && (
            <Box
              sx={{
                flex: 0,
                minWidth: 100,
              }}
              color="text.secondary"
            >
              <Skeleton variant="text" width={70} />
            </Box>
          )}
          <Box
            sx={{
              bgcolor: "background.neutral",
              py: 0.5,
              px: 1,
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Skeleton
              variant="text"
              width={90}
              sx={{
                bgcolor: theme.palette.grey[300],
              }}
            />
            <Skeleton
              variant="text"
              width={50}
              sx={{
                bgcolor: theme.palette.grey[300],
              }}
            />
          </Box>
          <Box>
            {[Math.random() * 4, Math.random() * 5].map((key, i) => {
              return (
                <Box
                  key={`skeleton-box-${key}-${i}`}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 1,
                    py: 0.5,
                  }}
                >
                  <Skeleton variant="text" width={10 * [8, 4, 7, 9][i]} />
                  <Skeleton variant="text" width={[4, 9, 7, 5][i] * 10} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};
export default TimelineTileSkeleton;
