import PostProcessedTable from "./../table/postProcessing/postProcessedTable";

const PostProcessing = ({ postProcessing, title, handleItemOpen }) => {
  return (
    <>
      {Object.entries(postProcessing)?.map(([k, item]: any[]) => {
        return (
          <PostProcessedTable
            {...{
              k,
              handleItemOpen,
              item,
              title,
            }}
          />
        );
      })}
    </>
  );
};

export default PostProcessing;
