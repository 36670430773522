import { Box, DialogActions, DialogContent } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { Button } from "@mui/material";
import { Typography } from "@mui/material";
import { Dialog } from "@mui/material";
import AlertIllustration from "src/assets/illustrations/infoalertIllustrations";
import CloseButton from "src/components/CloseButton";
import useLocales from "src/hooks/useLocales";

const DeleteDialog = (props: {
  open: any;
  handleClose: any;
  name: string;
  deleteRow: any;
  enableDeleteTable: boolean;
}) => {
  const { open, handleClose, name, enableDeleteTable } = props;
  const { translate } = useLocales();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <CloseButton handleClose={handleClose} />
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <AlertIllustration />
        </Box>
        <Typography
          variant="subtitle2"
          align="center"
          sx={{ fontSize: "13px", color: "text.greyLight" }}
        >
          {translate("Are you sure you want to delete this column?")}
        </Typography>
        {enableDeleteTable && (
          <Typography
            variant="subtitle2"
            align="center"
            sx={{ fontSize: "12px", color: "text.greyLight" }}
          >
            {translate(
              "Deleting this column will remove all the rows in this table."
            )}
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          pt: 1,
        }}
      >
        <Button size="small" variant="outlined" onClick={handleClose}>
          {translate("Cancel")}
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            props.deleteRow(name);
            handleClose();
          }}
          autoFocus
        >
          {translate("Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
