import { useState } from "react";

export type TFilterState = {
  status?: string;
  workflowId?: string;
  documentName?: string;
  documentStatus?: string;
  documentClass?: string;
  fromDate?: string;
  toDate?: string;
  page: number;
  record: number;
  approvalStatus?: string;
};

const useApprovalFilter = ({ record, page }) => {
  const [filterState, setFilterState] = useState<TFilterState>({
    status: "",
    workflowId: "",
    documentName: "",
    documentStatus: "",
    documentClass: "",
    fromDate: "",
    toDate: "",
    page: page ?? 1,
    record: record ?? 10,
    approvalStatus: "0",
  });

  return {
    filterState,
    setFilterState,
  };
};
export default useApprovalFilter;
