import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import useLocales from "src/hooks/useLocales";
import { useTheme } from "@mui/material/styles";
import Multiply from "src/assets/icon/multiply/Multiply";
import { useState } from "react";
import { useSnackbar } from "notistack";
const AlertRemoveTag = ({ open, onClose, onOk }) => {
  const { translate } = useLocales();
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {translate("Remove tag")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            fontSize: "12px",
          }}
        >
          {translate("Are you sure you want to remove this tag?")}
        </DialogContentText>

        <Alert
          severity="info"
          variant="outlined"
          sx={{
            mt: 2,
            fontSize: "11px",
            fontWeight: "normal",
            fontStyle: "italic",
          }}
        >
          <p>
            {translate(
              "Note: Removing tag from this document will remove it from other documents as well."
            )}
          </p>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
          size="small"
        >
          {translate("No")}
        </Button>
        <LoadingButton
          onClick={() => {
            onOk();
          }}
          variant="contained"
          size="small"
        >
          {translate("Yes")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default AlertRemoveTag;

export const TagLabel = ({
  tagName,
  setTagName,
  removeTagName,
  setRemoveTagName,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  return (
    <>
      <AlertRemoveTag
        open={open}
        onClose={() => setOpen(false)}
        onOk={() => {
          // setLoading(true);
          // DeleteAsync(`${API_GATEWAY}/api/extractionservice/selfTraining/tag`, {
          //   tagName: tagName,
          // })
          //   .then((res) => {
          //     if (res.status === 200) {
          //       enqueueSnackbar(translate("Tag removed successfully"), {
          //         variant: "success",
          //       });
          //       setTagName("");
          //       setOpen(false);
          //       setLoading(false);
          //     }
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //     enqueueSnackbar(translate("Failed to remove tag"), {
          //       variant: "error",
          //     });
          //     setLoading(false);
          //   })
          //   .finally(() => setLoading(false));
          setRemoveTagName(tagName);
          setOpen(false);
          setTagName("");
        }}
      />
      <Chip
        variant="outlined"
        color="primary"
        size="small"
        label={tagName}
        sx={{
          borderRadius: "4px",
          height: "20px",
          fontSize: "10px",
          bgcolor: "secondary.light",
          border: `1px solid ${theme?.palette?.secondary?.dark}`,
          pointerEvents: "auto",
        }}
        deleteIcon={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 15,
              height: 15,
            }}
          >
            <Multiply
              height={10}
              width={10}
              customColor={theme?.palette?.action.active}
            />
          </Box>
        }
        onDelete={() => {
          setOpen(true);
        }}
      />
    </>
  );
};
