import { Autocomplete } from "@mui/material";
import ChevronDown from "src/assets/icon/chevron/ChevronDown";
import { BootstrapFormInput } from "../form/TextField";
import { BootstrapFormInput as DenseInput } from "src/components/textField";
import { useTheme } from "@mui/material";
type MyDenseAutocompleteProps = {
  ref?: any;
  id?: string;
  options: any[];
  disabled?: boolean;
  placeholder?: string;
  denser?: boolean;
  [key: string]: any;
};
const MyDenseAutocomplete = ({
  ref,
  id,
  options,
  disabled,
  placeholder,
  error,
  denser = false,
  ...rest
}: MyDenseAutocompleteProps) => {
  const theme = useTheme();
  const sxProps = {
    "& .MuiInputBase-input": {
      ...(error && {
        backgroundColor: theme.palette.error.light,
        borderColor: theme.palette.error.dark,
        color: theme.palette.error.dark,
        "&::placeholder": {
          opacity: 1,
          color: theme.palette.error.dark,
        },
        "&:focus": {
          borderColor: theme.palette.error.dark,
          boxShadow: `0 0 0 0.01rem ${theme.palette.error.dark}`,
        },
      }),
    },
  };
  return (
    <Autocomplete
      ref={ref}
      // disablePortal
      size="small"
      id={id}
      clearOnBlur={true}
      options={options}
      disabled={disabled}
      openText="Open"
      popupIcon={<ChevronDown height={20} width={30} />}
      renderInput={({ ...params }) => {
        const { InputLabelProps, InputProps, inputProps, ...rest } = params;
        if (denser) {
          return (
            <DenseInput
              endAdornment={params.InputProps?.endAdornment}
              ref={InputProps?.ref}
              placeholder={placeholder}
              multiline
              {...params}
              sx={{
                ...sxProps,
              }}
            />
          );
        }
        return (
          <BootstrapFormInput
            endAdornment={params.InputProps?.endAdornment}
            ref={InputProps?.ref}
            placeholder={placeholder}
            {...params}
            sx={{
              ...sxProps,
            }}
          />
        );
      }}
      {...rest}
    />
  );
};
export default MyDenseAutocomplete;
