import { store } from "src/redux/store";
import { Mixpanel } from "./Mixpanel";

const MixpanelUser = ({ ...props }) => {
  const { sub, email } = store.getState()?.credential?.userTokenDecode;
  const {
    eventName,
    eventSource,
    user,
    userEmail,
    userProfile,
    status,
    impersonateMode,
    signInMethod,
    signUpMethod,
  } = props;

  const ORGANIZATION_ID = localStorage.getItem("company_id");

  if (eventSource == "Authentication") {
    if (user == "Login") {
      const loginProps = {
        "First Name": userProfile?.firstName,
        "Last Name": userProfile?.lastName,
        Email: userProfile?.email,
        Role: userProfile?.roleInOrganization,
        "Declared docs/m": userProfile?.organizationDocumentsPerMonth,
        "Current Plan": userProfile?.organizationPlan,
        "Company Name": userProfile?.organizationName,
        "Company Country": userProfile?.organizationCountry,
        Sector: userProfile?.organizationSector,
        "Sales Motion": userProfile.isPartner
          ? "partner"
          : userProfile.isOutbound
          ? "outbound"
          : "self-serve",
        "User Role":
          userProfile?.isOrganizationAdmin === true ? "admin" : "user",
        company_id: ORGANIZATION_ID,
        Language: "Spanish",
        $impersonate_mode: impersonateMode === true ? "Yes" : "No",
      };
      Mixpanel.register(loginProps);
      Mixpanel.identify(userEmail);
      var auth_props = {
        $id: sub,
        $status: status,
        $email: email,
        company_id: ORGANIZATION_ID,
        $signIn_method: signInMethod,
      };
      Mixpanel.track(eventName, auth_props);
    } else {
      const invitedToTeam = localStorage.getItem("invitedToTeam");
      const userProps = {
        "First Name": userProfile?.firstName,
        "Last Name": userProfile?.lastName,
        Email: userProfile?.email,
        Role: userProfile?.roleInOrganization,
        "Declared docs/m": userProfile?.documentsPerMonth,
        "Current Plan": userProfile?.subscribedPlan,
        "Company Name": userProfile?.organizationName,
        "Company Country": userProfile?.organizationCountry,
        Sector: userProfile?.sectorInOrganization,
        "Sales Motion": userProfile.isPartner
          ? "partner"
          : userProfile.isOutbound
          ? "outbound"
          : "self-serve",
        "User Role": userProfile?.invitedByUser === "" ? "admin" : "user",
        company_id: ORGANIZATION_ID,
        Language: "Spanish",
        "Organization Contact Number": userProfile?.organizationContactNumber,
        "Registration Process": userProfile?.registrationStep,
        "Invited To A Team": invitedToTeam !== null ? invitedToTeam : "No",
        "SignUp Method": signUpMethod,
      };
      console.log("userProps", userProps, userProfile);
      Mixpanel.register(userProps);

      const companyProps = {
        "Current Plan": userProfile?.subscribedPlan,
        "Company Name": userProfile?.organizationName,
        "Company Country": userProfile?.organizationCountry,
        "Declared docs/m": userProfile?.documentsPerMonth,
        Sector: userProfile?.sectorInOrganization,
        "Sales Motion": userProfile.isPartner
          ? "partner"
          : userProfile.isOutbound
          ? "outbound"
          : "self-serve",
      };

      let auth_props = {
        // $email: userEmail,
        $status: status,
        // company_id: ORGANIZATION_ID,
        $signUp_Method: signUpMethod,
        $invited_to_a_team: invitedToTeam !== null ? invitedToTeam : "No",
      };
      if (userProfile?.registrationStep === "complete") {
        Mixpanel.identify(userEmail, true, () => {
          Mixpanel.track(eventName, auth_props);
          Mixpanel.people.set(userProps);
          setTimeout(() => {
            Mixpanel.group.set_property(ORGANIZATION_ID, companyProps);
          }, 10000);
        });
      } else {
        Mixpanel.identify(userEmail);
        Mixpanel.people.set(userProps);
      }
    }
  } else if (eventSource === "Profile Update") {
    const auth_props = {
      $id: sub,
      $status: status,
      $email: email,
      company_id: ORGANIZATION_ID,
    };

    const userProps = {
      "First Name": userProfile.firstName,
      "Last Name": userProfile.lastName,
      Role: userProfile.roleInOrganization,
      "Declared docs/m": userProfile.documentsPerMonth,
      // "Current Plan": userProfile.subscribedPlan,
      "Company Name": userProfile.organizationName,
      "Company Country": userProfile.organizationCountry,
      Sector: userProfile.sectorInOrganization,
    };

    Mixpanel.identify(userEmail, false);
    Mixpanel.register(userProps);
    Mixpanel.track(eventSource, auth_props);
    Mixpanel.people.set(userProps);

    const companyProps = {
      "Company Name": userProfile.organizationName,
      "Company Country": userProfile.organizationCountry,
      "Declared docs/m": userProfile.documentsPerMonth,
      Sector: userProfile.sectorInOrganization,
      company_id: ORGANIZATION_ID,
    };
    Mixpanel.group.set_property(ORGANIZATION_ID, companyProps);
  } else if (eventName === "Password Changed") {
    Mixpanel.identify(email, false);
    const auth_props = {
      $id: sub,
      $email: email,
      company_id: ORGANIZATION_ID,
    };
    Mixpanel.track(eventName, auth_props);
  } else if (eventName === "New user invited") {
    Mixpanel.identify(email, false);
    const auth_props = {
      $id: sub,
      $email: email,
      company_id: ORGANIZATION_ID,
    };
    Mixpanel.track(eventName, auth_props);
  }
  return;
};

export { MixpanelUser };
