import "./switch.css";
import { FormControlLabel, Switch, SwitchProps } from "@mui/material";
import MySwitch from "../switch";
import React from "react";

const MySwitchField = ({
  label,
  ...props
}: { label?: string | React.ReactNode } & SwitchProps) => {
  return (
    <FormControlLabel
      control={<MySwitch defaultChecked {...props} />}
      label={label}
      sx={{
        alignItems: "flex-start",
        marginLeft: "0px",
      }}
      labelPlacement="top"
    />
  );
};

export default MySwitchField;
