import { useState } from "react";

const useHistoryDrawer = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleDrawerOpen = (row, id) => {
    setOpenDrawer(true);
    setSelectedRow(row);
    setSelectedRowId(id);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
    setSelectedRow(null);
    setSelectedRowId(null);
  };

  return {
    openDrawer,
    selectedRow,
    selectedRowId,
    handleDrawerOpen,
    handleDrawerClose,
  };
};

export default useHistoryDrawer;
