import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
          borderRadius: "4px",
          textTransform: "none",
        },
        sizeLarge: {
          height: 48,
          fontSize: "15px",
          padding: "10px 50px",
        },
        sizeMedium: {
          fontSize: "14px",
          padding: "7px 40px",
        },
        sizeSmall: {
          fontSize: "10px",
          padding: "5px 20px",
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[200],

          boxShadow: theme.customShadows.z8,
          "&:hover": {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          backgroundColor: theme.palette.primary.light,
          borderRadius: "4px",
          fontSize: "16px",
        },
        containedSecondary: {
          bgcolor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
          },
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
        },
        containedError: {
          boxShadow: theme.customShadows.error,
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[100]}`,
          color: theme.palette.text.secondary,
          "&:hover": {
            backgroundColor: theme.palette.action.selected,
          },
        },
        outlined: {
          border: `1px solid ${theme.palette.text.secondary}`,
          color: theme.palette.text.secondary,
          borderRadius: "4px",
        },
        text: {
          color: theme.palette.text.secondary,

          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        textInherit: {
          color: theme.palette.text.secondary,
          "&:hover": {
            backgroundColor: theme.palette.action.selected,
          },
        },
      },
    },
  };
}
