import { useEffect } from "react";
import { Box, Chip, Drawer, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
import useResponsive from "../../../hooks/useResponsive";
import cssStyles from "../../../utils/cssStyles";
import { NAVBAR } from "../../../config";
import Logo from "../../../components/Logo";
import { NavSectionVertical } from "../../../components/nav-section";
import Scrollbar from "../../../components/Scrollbar";
import menuBar from "src/layouts/dashboard/navbar/NavConfig";
import CollapseButton from "./CollapseButton";
import NavbarAccount from "./NavbarAccount";
const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar,
}: Props) {
  const theme = useTheme();
  const isDesktop = useResponsive("up", "lg");
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse } =
    useCollapseDrawer();
  // const version = localStorage.getItem("version");

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
          // overflowX: "hidden",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          // overflow: "hidden",
          // width: 90,
          justifyContent: "space-between",
        }}
      >
        <Stack
          spacing={0.5}
          sx={{
            pt: 1,
            pb: 2,
            px: 2.5,
            flexShrink: 0,
            ...(isCollapse && { alignItems: "center" }),
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Logo isCollapse={isCollapse} />
            {/* {!isCollapse && (
              <Chip
                label={version}
                color="secondary"
                variant="outlined"
                size="small"
                sx={{ m: 1, fontSize: "10px" }}
              />
            )} */}
          </Box>
          {isDesktop && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
          <NavSectionVertical
            navConfig={menuBar().navConfig}
            isCollapse={isCollapse}
          />
        </Stack>
        <Box sx={{ p: 2 }}>
          <NavbarAccount isCollapse={isCollapse} />
        </Box>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              // borderRightStyle: "dashed",
              bgcolor: "background.neutral",
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
