import React, { useEffect } from "react";

//mixpanel
import { Mixpanel } from "./Mixpanel.js";

// redux
import { store } from '../../src/redux/store';

const DocumentFinished = ({ ...props }) => {

  const { email } = store.getState()?.credential?.userTokenDecode;

  const { eventName, Id, status, nameOfDocuments, typeOfDocuments, sourceOfDocuments, organizationId } = props;

  Mixpanel.identify(email);
  if (status) {
    Mixpanel.track(eventName, {
      $status: 'Successfully Reviewed',
      $user_email: email,
      $documentId: Id,
      $document_name: nameOfDocuments,
      $document_type: typeOfDocuments,
      $document_source: sourceOfDocuments,
      company_id: organizationId
    });
  }
  else {
    Mixpanel.track(eventName, {
      $status: 'Unsuccessful Review',
      $user_email: email,
      $documentId: Id
    });
  }



  // return <div>mixpanelComponent</div>;
  return;
};

export { DocumentFinished };