import { BorderBottom } from "@mui/icons-material";
import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Tooltip(theme: Theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "white",
          boxShadow: "0px 6px 12px -3px #424a530a, 0px 6px 18px 0px #424a531f", // "0px 2px 5px 0px rgba(0,0,0,0.12)",
          border: `1px solid #d0d7de`,
          color: theme.palette.text.primary,
          borderRadius: "4px",
        },
        arrow: {
          color: theme.palette.background.paper,
        },
      },
    },
  };
}
