import { DatePicker } from "@mui/lab";
import { FormHelperText, SxProps, Theme } from "@mui/material";
import { TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import MyDatePicker from "../pickers/DatePicker";

interface RHFDatePickerProps {
  name: string;
  label?: string;
  rules?: any;
  sx?: SxProps<Theme>;
}

const RHFDatePicker = (props: RHFDatePickerProps) => {
  const { name, label, rules, sx, ...other } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <MyDatePicker
              value={field.value}
              onChange={(date, event) => {
                field.onChange(date);
              }}
              label={label}
              error={!!error}
              sx={sx}
            />
            {error && (
              <FormHelperText error sx={{ textAlign: "left" }}>
                {error.message}
              </FormHelperText>
            )}
          </>
        );
      }}
    />
  );
};

export default RHFDatePicker;
