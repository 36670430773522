import { AutocompleteProps, FormHelperText } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { NewAutoCompleteField } from "../form/AutocompleteField";

export interface Option {
  value: any;
  label: string;
}

export interface FieldsExpectedValues {
  field: string;
  expectedValues: {
    description: string;
    value: string;
  }[];
}

interface RHFAutocompleteProps {
  name: string;
  dense?: boolean;
  label?: string;
  disabled?: boolean;
  options: Option[];
  sx?: any;
}

const RHFAutocomplete = (
  props: RHFAutocompleteProps &
    Omit<
      AutocompleteProps<any, boolean, boolean, boolean, any>,
      "onChange" | "renderInput"
    >
) => {
  const { name, options, dense = false, label, disabled, ...other } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ref } = field;
        return (
          <>
            <NewAutoCompleteField
              label={label}
              value={
                value
                  ? options.find((option) => {
                      return value === option.value;
                    }) ?? null
                  : null
              }
              onChange={(event: any, newValue) => {
                onChange(newValue ? newValue.value : null);
              }}
              options={options}
              error={error?.message}
              disabled={disabled}
              {...other}
            />
            {error && (
              <FormHelperText error sx={{ textAlign: "left" }}>
                {error.message}
              </FormHelperText>
            )}
          </>
        );
      }}
    />
  );
};

export default RHFAutocomplete;
