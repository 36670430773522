import { Box, Typography } from "@mui/material";
import { Skeleton } from "@mui/material";
import { Grid } from "@mui/material";

const BreadCrumbSkeleton = ({ links = [] }: { links?: string[] }) => {
  const allLinks = ["Dashboard", ...links];
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        {allLinks.map((link, index) => (
          <Box key={index}>
            <Skeleton variant="text" height={20}>
              <Typography
                variant="body2"
                sx={{
                  color: "#000",
                  fontWeight: 600,
                }}
              >
                {link}
              </Typography>
            </Skeleton>
            <Skeleton variant="circular" width={7} height={7} />
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default BreadCrumbSkeleton;
