import React, { useState } from "react";
import { Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import ChevronDown from "src/assets/icon/chevron/ChevronDown";
import { useTheme } from "@mui/material";
import useLocales from "src/hooks/useLocales";
import { DeleteAsync, GetAsync } from "src/common/httpRequests";
import { API_GATEWAY } from "src/constants/settings";
import { applicationTypeMap } from "src/pages/Documents/Finance/table/table";
import { useSnackbar } from "notistack";
import DeleteIcon from "src/assets/icon/delete";
import { OpenIcon } from "src/assets/icon/Open";
import { ListItemText } from "@mui/material";
import DeleteDialog from "./DeleteDialog";
import { DocumentIcon } from "src/assets/icon/Document";
import JsonIcon from "src/assets/icon/json";
import { ReactComponent as HistoryIcon } from "src/assets/icon/history.svg";
const FileDownload = require("js-file-download");

const DocumentOptionsButton = ({
  writePermissions,
  fileName,
  id,
  url,
  disablePeriod,
  navigation,
  setOpenAccountingPeriod,
  documentIsDeleted,
  //   exportTemplateExtension,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const exportCSV = async (id: string) => {
    await GetAsync(
      `${API_GATEWAY}/api/documentservice/DocumentDownload/${id}/csv`,
      true
    )
      .then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          let extension =
            applicationTypeMap[response.headers?.["content-type"]] ?? "csv";
          let file: Blob | string;
          if (extension == "zip") {
            const url = new Blob([response.data], { type: "application/zip" });
            FileDownload(url, `${fileName}.zip`);
            return;
          }
          if (extension == "json") {
            file = `data:text/json;charset=utf-8,${encodeURIComponent(
              response.data.text()
            )}`;
          } else {
            let blob = new Blob([response.data], {
              type: response.headers?.["content-type"] ?? "application/csv",
            });
            file = window.URL.createObjectURL(blob);
          }
          const link = document.createElement("a");
          link.setAttribute("href", file);
          link.setAttribute("download", `${fileName}.${extension ?? "csv"}`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          enqueueSnackbar(response?.data?.Detail, { variant: "error" });
        }
      })
      .catch((err) => {
        console.log("Error", err);
        enqueueSnackbar(translate(`something_went_wrong`), {
          variant: "error",
        });
      });
  };
  const exportJSON = async (id: string) => {
    await GetAsync(
      `${API_GATEWAY}/api/documentservice/DocumentDownload/${id}/json`
    )
      .then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
            JSON.stringify(response.data)
          )}`;
          const link = document.createElement("a");
          link.setAttribute("href", jsonString);
          link.setAttribute("download", `${fileName}.json`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          enqueueSnackbar(response?.data?.Detail, { variant: "error" });
        }
      })
      .catch((err) => {
        console.log("Error", err);
        enqueueSnackbar(translate(`something_went_wrong`), {
          variant: "error",
        });
      });
  };

  const GetBlobUrlFrom = async (url: string) => {
    window.open(url);
  };
  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = (row) => {
    setOpenDialog(true);
  };

  const [deleteLoader, setDeleteLoader] = useState(false);
  const handleDeleteRow = async (id) => {
    setDeleteLoader(true);
    const response = await DeleteAsync(
      `${API_GATEWAY}/api/documentservice/document`,
      {
        documentIds: [id],
      }
    );
    try {
      if (response.status === 200) {
        enqueueSnackbar(translate(`deleted_document`));
        setDeleteLoader(false);
        setOpenDialog(false);
        navigation();
      }
    } catch (err) {
      enqueueSnackbar(translate(`something_went_wrong`), {
        variant: "error",
      });
      setDeleteLoader(false);
      setOpenDialog(false);
    }
  };
  return (
    <>
      <DeleteDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        handleDeleteRow={async (id) => {
          handleDeleteRow(id);
        }}
        item={id}
        loader={deleteLoader}
      />

      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="fileupload"
        variant="contained"
        endIcon={<ChevronDown customColor={theme.palette.background.paper} />}
        size="medium"
      >
        {translate(`Action`)}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            GetBlobUrlFrom(url);
            handleClose();
          }}
        >
          <ListItemIcon
            sx={{
              mr: 0,
            }}
          >
            <OpenIcon />
          </ListItemIcon>
          <ListItemText primary={translate("View document")} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            GetAsync(
              `${API_GATEWAY}/api/documentservice/DocumentDownload/${id}/downloadfile`,
              true,
              undefined,
              {
                responseType: "blob",
              }
            ).then((response) => {
              if (response.status === 200) {
                console.log("response", response);
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                console.log("url", url);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${fileName}`);
                document.body.appendChild(link);
                link.click();
              } else {
                enqueueSnackbar(response?.data?.Detail, {
                  variant: "error",
                });
              }
            });
            handleClose();
          }}
        >
          <ListItemIcon
            sx={{
              mr: 0,
            }}
          >
            <DocumentIcon />
          </ListItemIcon>
          <ListItemText primary={translate("Download document")} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            exportJSON(id);
            handleClose();
          }}
        >
          <ListItemIcon
            sx={{
              mr: 0,
            }}
          >
            <JsonIcon />
          </ListItemIcon>
          <ListItemText primary={translate("Download JSON")} />
        </MenuItem>
        <MenuItem
          disabled={!writePermissions || documentIsDeleted}
          onClick={() => {
            handleOpenDialog(id);
            handleClose();
            //   onDeleteSingleRow();
          }}
        >
          <ListItemIcon
            sx={{
              mr: 0,
            }}
          >
            <DeleteIcon
              customColor={theme.palette.action.active}
              disableDimension
            />
          </ListItemIcon>
          <ListItemText primary={translate("delete")} />
        </MenuItem>
        <MenuItem
          disabled={disablePeriod}
          onClick={() => {
            setOpenAccountingPeriod(true);
            handleClose();
          }}
        >
          <ListItemIcon
            sx={{
              mr: 0,
            }}
          >
            <HistoryIcon stroke={theme.palette.action.active} />
          </ListItemIcon>
          <ListItemText primary={translate("View period")} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default DocumentOptionsButton;
