import { SvgIconProps } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

const SearchMinusIcon = ({
  color = "secondary",
  width = 24,
  height = 24,
  sx,
}: SvgIconProps) => {
  const theme = useTheme();
  return (
    <Box sx={sx}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.48334 11.0331H14.58M20.0643 11.0323C20.0643 14.259 18.3428 17.2409 15.5482 18.8545C12.7536 20.468 9.31056 20.4679 6.51608 18.8545C3.72159 17.241 2 14.2593 2 11.0323C2 7.80522 3.7215 4.82364 6.51608 3.21011C9.31066 1.59658 12.7537 1.59668 15.5482 3.21011C18.3427 4.82354 20.0643 7.80522 20.0643 11.0323ZM18.1055 16.6445C17.6751 17.1865 17.1842 17.6771 16.6426 18.1074L19.804 21.2691C20.208 21.6731 20.8629 21.6731 21.2669 21.2691C21.6709 20.8652 21.6709 20.2102 21.2669 19.8063L18.1055 16.6445Z"
          stroke={theme.palette[color].light}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

SearchMinusIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  sx: PropTypes.object,
};

SearchMinusIcon.defaultProps = {
  color: "secondary",
  width: 24,
  height: 24,
};

export default SearchMinusIcon;
