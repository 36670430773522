import { Box, useTheme } from "@mui/material";
import { SvgIconProps } from "@mui/material";
import { type } from "os";

type Props = SvgIconProps & { customColor?: string };

export const RightArrowIcon = ({
  customColor,
  color = "action",
  height = 24,
  width = 24,
}: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: height,
        width: width,
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.9288 12H3M11.9649 3L21 12L11.9649 21"
          stroke={customColor ?? theme.palette?.[color]?.main}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};
