import axios from "axios";
import { store } from "src/redux/store";
import jwt_decode from "jwt-decode";
import { API_GATEWAY } from "src/constants/settings";
import { LoginCred, LogoutCred } from "src/redux/slices/credentials";
import { logoutOrganization } from "src/redux/slices/organization";
import { shutDownIntercomm } from "src/intercom";
import { resetMixpanel } from "src/mixpanel/Mixpanel";
import { resetLocalStorage } from "./resetLocalStorage";

const logout = () => {
  store.dispatch(LogoutCred());
  store.dispatch(logoutOrganization());
  shutDownIntercomm();
  resetMixpanel();
  resetLocalStorage();
  window.location.reload();
};

let refreshingFunc: Promise<any> | undefined = undefined;

export async function PostAsync(
  baseUrl: string,
  body?: any,
  shouldUseToken = true
) {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (shouldUseToken) {
    if (!refreshingFunc) refreshingFunc = ValidateAccessToken();
    let accessToken = await refreshingFunc;
    headers["Authorization"] = `Bearer ${accessToken}`;
    refreshingFunc = undefined;
  }
  return await axios.post(baseUrl, body, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      if (error.response.status === 401) {
        logout();
      } else if (error.response.status === 403) {
        error.response.data = {
          Title: "Forbidden Request",
          Status: error.response.status,
          Detail: "permission_not_granted",
        };
      }
      return error.response;
    }
  );
}

export async function PostFileAsync(baseUrl, body, shouldUseToken = true) {
  let headers = {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  };

  if (shouldUseToken) {
    let accessToken = await ValidateAccessToken();
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return await axios.post(baseUrl, body, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        logout();
      }
      return error.response;
    }
  );
}

export async function PutFileAsync(baseUrl, body, shouldUseToken = true) {
  let headers = {
    "Content-Type": "multipart/form-data; boundary: ",
    Accept: "application/json",
  };

  if (shouldUseToken) {
    if (!refreshingFunc) refreshingFunc = ValidateAccessToken();
    let accessToken = await refreshingFunc;
    headers["Authorization"] = `Bearer ${accessToken}`;
    refreshingFunc = undefined;
  }

  return await axios.put(baseUrl, body, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        logout();
      }
      return error.response;
    }
  );
}

export async function GetAsync(
  baseUrl: string,
  shouldUseToken = true,
  cancelToken?: any,
  options?: any
) {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (shouldUseToken) {
    if (!refreshingFunc) refreshingFunc = ValidateAccessToken();
    let accessToken = await refreshingFunc;
    headers["Authorization"] = `Bearer ${accessToken}`;
    refreshingFunc = undefined;
  }

  return await axios
    .get(baseUrl, { headers, cancelToken: cancelToken, ...options })
    .then(
      (res) => {
        return res;
      },
      (error) => {
        console.log(error.message, error);
        if (error?.response && error?.response?.status === 401) {
          logout();
        }
        if (error.message == "Network Error") {
          throw new Error("Network Error");
        }
        return error.response;
      }
    );
}

export async function DeleteAsync(
  baseUrl: string,
  body?: any,
  shouldUseToken = true
) {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (shouldUseToken) {
    if (!refreshingFunc) refreshingFunc = ValidateAccessToken();
    let accessToken = await refreshingFunc;
    headers["Authorization"] = `Bearer ${accessToken}`;
    refreshingFunc = undefined;
  }

  return await axios.delete(baseUrl, { data: body, headers: headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        logout();
      }
      return error.response;
    }
  );
}

export async function PostWithFormDataAsyncRes(
  baseUrl,
  body,
  shouldUseToken = true
) {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (shouldUseToken) {
    if (!refreshingFunc) refreshingFunc = ValidateAccessToken();
    let accessToken = await refreshingFunc;
    headers["Authorization"] = `Bearer ${accessToken}`;
    refreshingFunc = undefined;
  }

  return await axios.post(baseUrl, body, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        logout();
      }
    }
  );
}

export async function PutAsync(baseUrl, body, shouldUseToken = true) {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (shouldUseToken) {
    if (!refreshingFunc) refreshingFunc = ValidateAccessToken();
    let accessToken = await refreshingFunc;
    headers["Authorization"] = `Bearer ${accessToken}`;
    refreshingFunc = undefined;
  }

  return await axios.put(baseUrl, body, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      if (error.response.status === 401) {
        logout();
      } else if (error.response.status === 403) {
        error.response.data = {
          Title: "Forbidden Request",
          Status: error.response.status,
          Detail: "permission_not_granted",
        };
      }
      return error.response;
    }
  );
}

export async function PatchAsync(
  baseUrl: string,
  body?: any,
  shouldUseToken = true
) {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (shouldUseToken) {
    if (!refreshingFunc) refreshingFunc = ValidateAccessToken();
    let accessToken = await refreshingFunc;
    headers["Authorization"] = `Bearer ${accessToken}`;
    refreshingFunc = undefined;
  }

  return await axios.patch(baseUrl, body, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      if (error.response.status === 401) {
        logout();
      } else if (error.response.status === 403) {
        error.response.data = {
          Title: "Forbidden Request",
          Status: error.response.status,
          Detail: "permission_not_granted",
        };
      }
      return error.response;
    }
  );
}

export async function PatchAsyncNew(baseUrl, body, shouldUseToken = true) {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (shouldUseToken) {
    if (!refreshingFunc) refreshingFunc = ValidateAccessToken();
    let accessToken = await refreshingFunc;
    headers["Authorization"] = `Bearer ${accessToken}`;
    refreshingFunc = undefined;
  }

  return await axios.patch(baseUrl, body, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      if (error.response.status === 401) {
        logout();
      } else if (error.response.status === 403) {
        error.response.data = {
          Title: "Forbidden Request",
          Status: error.response.status,
          Detail: "permission_not_granted",
        };
      }
      return error.response;
    }
  );
}

export async function PostAsyncRegister(baseUrl, body, token) {
  return await axios.post(baseUrl, body).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
}

export async function PatchAsyncRegister(baseUrl, body) {
  return await axios.patch(baseUrl, body).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
}

export async function ValidateAccessToken() {
  const accessToken = store.getState()?.credential?.userCred.accessToken;
  // return accessToken if not expired
  if (isTokenValid()) {
    return accessToken;
  }
  // get refresh token
  let refreshToken = store.getState()?.credential?.userCred.refreshToken;
  let email = store.getState()?.credential?.userCred.email;

  if (refreshToken !== undefined) {
    const response = await GetAsync(
      `${API_GATEWAY}/api/userservice/Account/refreshToken/${refreshToken}`,
      false
    );

    try {
      if (response && response?.status === 200) {
        store.dispatch(LoginCred(response.data, email));
        return response?.data?.accessToken;
      } else {
        logout();
      }
    } catch (err) {
      logout();
    }
  }
}

export function isTokenValid() {
  const accessToken = store.getState()?.credential?.userCred.accessToken;
  if (typeof accessToken === "undefined") {
    return false;
  } else {
    let decodedToken: {
      exp: number;
    } = jwt_decode(accessToken);
    let currentDate = new Date();
    if (decodedToken.exp * 1000 - 1000 * 60 * 1 < currentDate.getTime()) {
      // DeleteCookie("accessToken");
      return false;
    }
    return true;
  }
}
