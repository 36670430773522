import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";

// ----------------------------------------------------------------------

type BumperState = {
  financeBumperDialog: boolean;
  reviewBumperDialog: boolean;
  integerationBumperDialog: boolean;
  progressBarPer: number;
  steps: number;
};

const initialState: BumperState = {
  financeBumperDialog: true,
  reviewBumperDialog: true,
  integerationBumperDialog: true,
  progressBarPer: 0,
  steps: 0,
};

const slice = createSlice({
  name: "BumperDialog",
  initialState,
  reducers: {
    financeBumperDialogAction(state, action) {
      state.financeBumperDialog = action.payload;
    },
    reviewBumperDialogAction(state, action) {
      state.reviewBumperDialog = action.payload;
    },
    integerationBumperDialogAction(state, action) {
      state.integerationBumperDialog = action.payload;
    },
    progressBarPerAction(state, action) {
      state.progressBarPer = action.payload;
    },
    stepAction(state, action) {
      state.steps = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  financeBumperDialogAction,
  reviewBumperDialogAction,
  integerationBumperDialogAction,
  progressBarPerAction,
  stepAction,
} = slice.actions;

export function financeBumperDialog(toggle: Boolean) {
  return async () => {
    dispatch(financeBumperDialogAction(toggle));
  };
}

export function reviewBumperDialog(toggle: Boolean) {
  return async () => {
    dispatch(reviewBumperDialogAction(toggle));
  };
}

export function integerationBumperDialog(toggle: Boolean) {
  return async () => {
    dispatch(integerationBumperDialogAction(toggle));
  };
}

export function ProgressBarPer(percentage: Number) {
  return async () => {
    dispatch(progressBarPerAction(percentage));
  };
}
export function CompletedStep(step: Number) {
  return async () => {
    dispatch(stepAction(step));
  };
}
