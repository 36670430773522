import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";

// ----------------------------------------------------------------------

type CredState = {
  isLoading: boolean;
  error: Error | string | null;
  modalState: boolean; //setState for modal open and close
  tooltipToggleFinance: any; //setState for Box open and close(individual)
  tooltipToggleReview: any; //setState for Box open and close(individual)
  tooltipToggleIntegeration: any; //setState for Box open and close(individual)
  checkTooltip: boolean; //setstate once user complete tooltip
  generalModalState: boolean;
};

const initialState: CredState = {
  isLoading: false,
  error: null,
  modalState: false,
  tooltipToggleFinance: {
    financeBox1: false,
    financeBox2: false,
    financeBox3: false,
  },
  tooltipToggleReview: {
    reviewBox1: false,
    reviewBox2: false,
  },
  tooltipToggleIntegeration: {
    integerationBox1: false,
  },
  checkTooltip: true,
  generalModalState: false,
};

const slice = createSlice({
  name: "modalToggle",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    modalAction(state, action) {
      state.modalState = action.payload;
    },
    tooltipToggleFinanceAction(state, action) {
      state.tooltipToggleFinance = action.payload;
    },
    tooltipToggleReviewAction(state, action) {
      state.tooltipToggleReview = action.payload;
    },
    checkTooltipAction(state, action) {
      state.checkTooltip = action.payload;
    },
    intialTooltipTrueAction(state, action) {
      state.tooltipToggleFinance = {
        financeBox1: true,
        financeBox2: true,
        financeBox3: true,
      };
      state.tooltipToggleReview = {
        reviewBox1: true,
        reviewBox2: true,
      };
      state.tooltipToggleIntegeration = {
        integerationBox1: true,
      };
    },
    handleCloseAction(state, action) {
      state.tooltipToggleFinance = initialState.tooltipToggleFinance;
      state.tooltipToggleReview = initialState.tooltipToggleReview;
      state.tooltipToggleIntegeration = initialState.tooltipToggleIntegeration;
      state.modalState = initialState.modalState;
    },
    generalModalAction(state, action) {
      state.generalModalState = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  modalAction,
  tooltipToggleFinanceAction,
  checkTooltipAction,
  intialTooltipTrueAction,
  handleCloseAction,
  tooltipToggleReviewAction,
  generalModalAction,
} = slice.actions;

// handle Modal
export function ModalToggle(modalToggle: Boolean) {
  return async () => {
    dispatch(modalAction(modalToggle));
  };
}
// handle card to toggle on Finance Page
export function TooltipToggle(tooltipToggle: object) {
  return async () => {
    dispatch(tooltipToggleFinanceAction(tooltipToggle));
  };
}

// handle card to toggle on Review Page
export function TooltipToggleReview(tooltipToggle: object) {
  return async () => {
    dispatch(tooltipToggleReviewAction(tooltipToggle));
  };
}

export function CloseTooltip(closeTooltip: Boolean) {
  return async () => {
    dispatch(handleCloseAction(closeTooltip));
  };
}

export function IntialTooltipTrue(intialTooltipTrue: Boolean) {
  return async () => {
    dispatch(intialTooltipTrueAction(intialTooltipTrue));
  };
}

export function CheckTooltipToggle(checkTooltipToggle: Boolean) {
  return async () => {
    dispatch(checkTooltipAction(checkTooltipToggle));
  };
}

export function ModalToggleGeneral(modalToggle: Boolean) {
  return async () => {
    dispatch(generalModalAction(modalToggle));
  };
}
