import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Menu(theme: Theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          "&.Mui-selected": {
            backgroundColor: theme.palette.background.paper,
            "&:hover": {
              backgroundColor: theme.palette.action.hover,
            },
          },
          color: theme.palette.text.greyLight,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: theme.spacing(1),
        },
      },
    },
  };
}
