import { Box, Dialog, DialogActions, Tooltip, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import { TableHead } from "@mui/material";
import { useState } from "react";
import useLocales from "src/hooks/useLocales";
import { DialogTitle } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material";
import TableRowsIcon from "@mui/icons-material/TableRows";
import DeleteIcon from "src/assets/icon/delete";
import ChevronLeftIcon from "src/assets/icon/chevron/left";
import ChevronRightIcon from "src/assets/icon/chevron/right";
import { Divider } from "@mui/material";
import { BootstrapFormInput } from "src/components/form/TextField";

const TableHeader = ({
  getTranslatedFields,
  handleItemOpenGeneral,
  k,
  handleItemOpen,
  fieldsLabelMap,
  handleDelete,
  nextPage,
  prevPage,
  page,
  setPage,
  total,
}) => {
  const { translate } = useLocales();
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const palette = theme.palette;
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleDeleteDialog = () => {
    setOpenDialog(true);
  };
  const iconColor = palette?.primary?.light;

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translate("Delete this table?")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate("Are you sure you want to delete this table?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" size="small" onClick={handleCloseDialog}>
            {translate("Cancel")}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              handleDelete(fieldsLabelMap[k]);
              handleCloseDialog();
            }}
            autoFocus
          >
            {translate("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <TableHead
        sx={{
          background: theme.palette.secondary.light,
          color: theme.palette.text.secondary,
          px: 1,
          border: `2px solid ${theme.palette.secondary.light}`,
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          fontWeight: 600,
        }}
      >
        {getTranslatedFields(k)}
        {k !== "general" ? (
          <span>
            {total > 0 && (
              <>
                <BootstrapFormInput
                  size="small"
                  type="number"
                  value={page + 1}
                  sx={{
                    "& .MuiInputBase-input": {
                      maxWidth: "30px",
                      minWidth: "5px",
                      height: "10px",
                      display: "inline",
                      padding: "3px 6px 3px 6px",
                      fontSize: "12px",
                      border: `1px solid ${palette?.primary?.light}`,
                      color: palette?.primary?.light,
                      "&:focus": {
                        borderColor: palette?.primary?.light,
                      },
                    },
                  }}
                  onChange={(e) => {
                    let value = parseInt(e.target.value);
                    if (value > total) {
                      value = total;
                      setPage(value - 1);
                    } else if (value < 1 || isNaN(value)) {
                      value = 1;
                      setPage(0);
                    } else {
                      setPage(value - 1);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                />

                <Typography
                  variant="subtitle2"
                  sx={{
                    marginRight: 0.5,
                    marginLeft: 0.5,
                    display: "inline",
                    color: palette?.primary?.light,
                    fontWeight: "600",
                    cursor: "pointer",
                    "&:hover": { color: "black" },
                  }}
                >
                  {translate("of ")} {total}
                </Typography>
                <Tooltip title={translate("previous_page")}>
                  <span>
                    <IconButton
                      size="small"
                      sx={{
                        marginRight: 1,
                        marginLeft: 1,
                        color: palette?.grey["400"],
                        cursor: "pointer",
                        "&:hover": { color: "black" },
                      }}
                      disabled={page === 0}
                      onClick={() => {
                        prevPage();
                      }}
                    >
                      <ChevronLeftIcon
                        height={16}
                        width={16}
                        color={theme.palette.primary.light}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={translate("next_page")}>
                  <span>
                    <IconButton
                      disabled={page + 1 >= total}
                      size="small"
                      sx={{
                        color: iconColor,
                        cursor: "pointer",
                        "&:hover": { color: "black" },
                      }}
                      onClick={() => {
                        nextPage();
                      }}
                    >
                      <ChevronRightIcon
                        height={16}
                        width={16}
                        color={theme.palette.primary.light}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
                <Divider
                  orientation="vertical"
                  sx={{
                    height: "20px",
                    width: "20px",
                    color: iconColor,
                    transform: "rotate(270deg)",
                    display: "inline",
                  }}
                />
              </>
            )}
            <Tooltip title={translate("View table")}>
              <span>
                <IconButton
                  id={"tableName"}
                  color="primary"
                  aria-label="see-item-table"
                  component="label"
                  size="small"
                  disabled={k === "general"}
                  onClick={() => {
                    console.log("fieldsLabelMap[k]", fieldsLabelMap);
                    handleItemOpen(fieldsLabelMap[k]);
                  }}
                >
                  <TableRowsIcon
                    sx={{
                      fontSize: 16,
                      color:
                        k === "general"
                          ? theme.palette.secondary.light
                          : theme.palette.text.secondary,
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={translate("Delete table")}>
              <span>
                <IconButton
                  id={"tableName"}
                  color="primary"
                  size="small"
                  aria-label="see-item-table"
                  component="label"
                  disabled={k === "general"}
                  onClick={handleDeleteDialog}
                >
                  <DeleteIcon
                    customColor={
                      k === "general"
                        ? theme.palette.secondary.light
                        : theme.palette.text.secondary
                    }
                    height={16}
                    width={16}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </span>
        ) : (
          <span>
            <Tooltip title={translate("View table")}>
              <span>
                <IconButton
                  id={"tableName"}
                  color="primary"
                  aria-label="see-item-table"
                  component="label"
                  size="small"
                  onClick={() => {
                    console.log("fieldsLabelMap[k]", fieldsLabelMap);
                    handleItemOpenGeneral(fieldsLabelMap[k]);
                  }}
                >
                  <TableRowsIcon
                    sx={{
                      fontSize: 16,
                      color: theme.palette.text.secondary,
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </span>
        )}
      </TableHead>
    </>
  );
};

export default TableHeader;
