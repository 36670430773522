import {
  Stack,
  Typography,
  Box,
  Grid,
  Button,
  Dialog,
  DialogContent,
  Alert,
  useTheme,
} from "@mui/material";
import { AlertTitle, LoadingButton } from "@mui/lab";
import useLocales from "src/hooks/useLocales";
import DeleteIcon from "./../../../assets/icon/delete";
import { IconButton } from "@mui/material";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import { DocumentsEntity } from "src/@types/processedDocuments";
import { useEffect, useState } from "react";

interface DeleteDocumentModalProps {
  isShowModal: boolean;
  showModalMethod: VoidFunction;
  selected: string[];
  deleteDocument: () => Promise<void>;
  tableData: DocumentsEntity[];
  deleteLoader: boolean;
}

export default function DeleteDocumentModal({
  isShowModal,
  showModalMethod,
  selected,
  deleteDocument,
  tableData,
  deleteLoader,
}: DeleteDocumentModalProps) {
  const { translate } = useLocales();
  const theme = useTheme();
  // const [deletedDocs, setDeletedDocs] = useState<string[]>([]);
  // useEffect(() => {
  //   const filteredDocuments = tableData?.filter((document) => {
  //     if (selected?.includes(document.id)) {
  //       return document;
  //     }
  //   });
  //   if (filteredDocuments.length) {
  //     const toDeleteDocs = filteredDocuments
  //       .filter((filteredDoc) => filteredDoc.isIntegrated)
  //       .map((item) => item.fileName);
  //     setDeletedDocs(toDeleteDocs);
  //   }
  // }, []);

  return (
    <Dialog
      open={isShowModal}
      onClose={showModalMethod}
      fullWidth
      maxWidth={"sm"}
      scroll="body"
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            m: 1,
          }}
        >
          <Typography id="modal-modal-title" variant="h5" sx={{}}>
            {selected?.length === 1
              ? `${translate("delete")} ${translate("document")}?`
              : `${translate("delete")} ${selected?.length} ${translate(
                  "documents"
                )}?`}
          </Typography>
          <IconButton sx={{ height: 30, width: 30 }} onClick={showModalMethod}>
            <CloseModalButton />
          </IconButton>
        </Box>
        {/* {deletedDocs?.length > 0 ? (
          <Stack>
            <Alert
              severity="warning"
              sx={{
                border: `1px solid ${theme.palette.warning.dark}`,
                color: theme.palette.warning.dark,
                backgroundColor: theme.palette.warning.light,
              }}
            >
              <AlertTitle>{translate("information")}</AlertTitle>
              <Typography variant="body1" textAlign="left">
                {translate(`to_delete_documents`, {
                  documents: deletedDocs.join(", "),
                })}
              </Typography>
            </Alert>
          </Stack>
        ) : ( */}
        <Stack spacing={2}>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", pt: 1 }}
            align="center"
          >
            {selected?.length === 1
              ? translate("delete_document_approval")
              : selected?.length === tableData?.length
              ? translate(`delete_all_documents`)
              : translate("delete_documents_approval")}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.primary", fontWeight: "bold" }}
            align="center"
          >
            {translate("delete_documents_warning")}
          </Typography>
        </Stack>
        {/* )} */}
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid
            item
            md={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              onClick={() => showModalMethod()}
              size="medium"
              variant="outlined"
            >
              {translate("cancel_button")}
            </Button>

            <LoadingButton
              type="submit"
              variant="contained"
              loading={deleteLoader}
              onClick={() => deleteDocument()}
              loadingPosition="start"
              sx={{ ml: 1 }}
              size="medium"
              // disabled={deletedDocs?.length > 0}
              startIcon={
                <DeleteIcon customColor="white" height={20} width={20} />
              }
            >
              {translate("delete")}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
