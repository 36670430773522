import React from "react";
import { SvgIconProps } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

const ExcelIcon = ({
  color = "secondary",
  width = 24,
  height = 24,
  customColor = "#657482",
  sx,
  disableDimension = false,
}: SvgIconProps & {
  customColor?: string;
  disableDimension?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...(disableDimension
          ? {}
          : {
              height: height,
              width: width,
            }),
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.85858 2.87732L15.4293 1.0815C15.7027 1.04245 15.9559 1.2324 15.995 1.50577C15.9983 1.52919 16 1.55282 16 1.57648V22.4235C16 22.6996 15.7761 22.9235 15.5 22.9235C15.4763 22.9235 15.4527 22.9218 15.4293 22.9184L2.85858 21.1226C2.36593 21.0522 2 20.6303 2 20.1327V3.86727C2 3.36962 2.36593 2.9477 2.85858 2.87732ZM4 4.73457V19.2654L14 20.694V3.30599L4 4.73457ZM17 19H20V4.99997H17V2.99997H21C21.5523 2.99997 22 3.44769 22 3.99997V20C22 20.5523 21.5523 21 21 21H17V19ZM10.2 12L13 16H10.6L9 13.7143L7.39999 16H5L7.8 12L5 7.99997H7.39999L9 10.2857L10.6 7.99997H13L10.2 12Z"
          stroke={customColor ?? theme.palette[color]?.main}
          strokeWidth="0"
          fill="currentColor"
        />
      </svg>
    </Box>
  );
};

ExcelIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  sx: PropTypes.object,
};

ExcelIcon.defaultProps = {
  color: "secondary",
  width: 24,
  height: 24,
};

export default ExcelIcon;
