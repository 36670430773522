export const suppliers = {
  suppliers: "Proveedores",
  supplier: "Proveedor",
  supplierName: "Nombre del proveedor",
  supplierEmail: "Correo electrónico del proveedor",
  supplierRegistrationNumber: "Número de registro del proveedor",
  supplierAccountInfo: "Información de la cuenta del proveedor",
  supplierAccountHolderName: "Nombre del titular de la cuenta del proveedor",
  supplierIBAN: "IBAN del proveedor",
  supplierURL: "URL del proveedor",
  onboarding_status: "Estado de incorporación",
  tax_id: `ID de impuestos`,
  power_of_attorney: `Poder legal`,
  acceptance_of_terms_and_conditions: `Aceptación de Términos y Condiciones`,
  legal_representative_info: `Información del representante legal`,
  certificates: `Certificados`,
  bank_account_ownership: `Titularidad de la cuenta bancaria`,
  compliance_with_social_security: `Cumplimiento de la seguridad social`,
  compliance_with_tax_administration: `Cumplimiento con la Administración Tributaria`,
  last_month_payment_installment_of_civil_liability_insurance_policy: `Cuota de pago del último mes de la póliza de seguro de responsabilidad civil`,
  goods_and_services: `Bienes y servicios`,
  other_conditions: `Otras condiciones`,
};
