// routes
import { useState, useEffect } from "react";
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
import "bootstrap/dist/css/bootstrap.min.css";

// components
import ThemeSettings from "./components/settings";
import { ChartStyle } from "./components/chart";
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import NotistackProvider from "./components/NotistackProvider";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import "./App.css";
// ----------------------------------------------------------------------
import withClearCache from "./ClearCache";
import { INTERCOM_APP_ID, INTERCOM_AP_BASE } from "./constants/settings";
import { GlobalStyles } from "@mui/material";
import { globalStyles } from "./pages/formvalidation/Component/styles";

const ClearCacheComponent = withClearCache(MainApp);

export default function App() {
  return <ClearCacheComponent />;
}

declare global {
  interface Window {
    Intercom: any;
    intercomSettings: any;
  }
}
function MainApp() {
  useEffect(() => {
    window.intercomSettings = {
      alignment: "left",
    };
    window.Intercom("boot", {
      api_base: INTERCOM_AP_BASE,
      app_id: INTERCOM_APP_ID,
    });
  }, []);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <GlobalStyles styles={globalStyles} />
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
