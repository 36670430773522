import { Popover } from "@mui/material";
import { Box, Typography } from "@mui/material";
import useLocales from "src/hooks/useLocales";

interface Props {
  anchorEl: HTMLElement | null;
  handlePopoverClose: () => void;
  title: string;
}

const NavItemPopover = ({ anchorEl, handlePopoverClose, title }: Props) => {
  const { translate } = useLocales();

  return (
    <Popover
      id="mouse-over-popover"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: 50,
      }}
      sx={{
        pointerEvents: "none",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
      PaperProps={{
        sx: {
          bgcolor: "background.paper",
          borderRadius: "4px",
          p: 1,
          px: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        },
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          color: "text.greyLight",
          fontSize: "12px",
        }}
        variant="subtitle2"
      >
        {translate(title)}
      </Typography>
    </Popover>
  );
};
export default NavItemPopover;
