import { Box } from '@mui/material';

const SettingsIcon = ({ height = 24, width = 24, color = '#657482' }) => {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.9903 13.1809V10.6003H19.7993C19.5954 9.43773 19.1438 8.27357 18.4922 7.34419L20.0415 5.79487L18.0908 3.8442L16.5415 5.39352C15.6122 4.74223 14.4432 4.28546 13.2806 4.08162V1.89062H10.6999V4.08162C9.53734 4.28551 8.37828 4.74223 7.44896 5.39384L5.89964 3.84452L3.94897 5.79519L5.49829 7.34451C4.84668 8.27354 4.38507 9.43773 4.18123 10.6003H1.99023V13.1809H4.18123C4.38512 14.3435 4.84668 15.5077 5.49829 16.4367L3.94897 17.9861L5.89964 19.9367L7.44896 18.3874C8.37831 19.039 9.53734 19.4955 10.6999 19.6996V21.8906H13.2806V19.6996C14.4431 19.4957 15.6121 19.039 16.5415 18.3874L18.0908 19.9367L20.0415 17.9861L18.4922 16.4367C19.1438 15.5074 19.5954 14.3435 19.7993 13.1809H21.9903Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5059 11.8918C16.5059 14.386 14.484 16.4079 11.9898 16.4079C9.49555 16.4079 7.47365 14.386 7.47365 11.8918C7.47365 9.39757 9.49555 7.37567 11.9898 7.37567C14.484 7.37567 16.5059 9.39757 16.5059 11.8918Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default SettingsIcon;
