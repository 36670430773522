import React from "react";
import "./CustomLoader.css"; // Make sure to import your CSS file
import { Box } from "@mui/material";
type CustomLoaderProps = {
  isLoading?: boolean;
  sx?: any;
};
const CustomLoader: React.FC<CustomLoaderProps> = ({
  isLoading = true,
  sx,
}) => {
  return <Box sx={sx} className={isLoading ? "custom-loader" : ""}></Box>;
};

export default CustomLoader;
