import { Dialog, DialogContent } from "@mui/material";
import useLocales from "src/hooks/useLocales";
import { IconButton } from "@mui/material";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import { Box } from "@mui/material";
import { useState } from "react";
import useResponsive from "src/hooks/useResponsive";
import Form from "src/pages/formvalidation/form";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { PatchAsync } from "src/common/httpRequests";
import { API_GATEWAY } from "src/constants/settings";
import { useSnackbar } from "notistack";
import ReviewContextProvider from "src/pages/formvalidation/context/ReviewContext";
import usePermissions from "src/hooks/usePermissions";

interface ReviewModalProps {
  open: boolean;
  handleClose: () => void;
  fileId: string;
  approvalNotRequired: boolean;
  renderDocument: VoidFunction;
  documentId: string;
  workflowId: string;
  id: string;
}
const ReviewModal = (props: ReviewModalProps) => {
  const {
    open,
    handleClose,
    fileId,
    approvalNotRequired,
    renderDocument,
    documentId,
    workflowId,
    id,
  } = props;
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleApprove = async () => {
    PatchAsync(
      `${API_GATEWAY}/api/workflowservice/workflowapprovals/approved`,
      {
        id: id,
        workflowId: workflowId,
        documentId: documentId,
      }
    )
      .then((res: any) => {
        if (res?.status >= 200 && res?.status <= 299) {
          console.log("res-->", res);
          enqueueSnackbar(translate(`successfully_approved`), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(`${res?.data?.Detail}`, { variant: "error" });
        }
      })
      .catch((err: any) => {
        enqueueSnackbar(`${err?.data?.Detail}`, { variant: "error" });
        console.log("Error-->", err);
      })
      .finally(() => {
        setIsSubmitting(false);
        renderDocument();
        handleClose();
      });
  };
  const { permissionHandle } = usePermissions();
  const writeWorkflowApproval = permissionHandle(["WorkflowApproval_Write"]);
  const readWorkflowApproval = permissionHandle([
    "WorkflowApproval_Read",
    "WorkflowApproval_Write",
  ]);
  const ActionButtons = ({ isSubmitting }: { isSubmitting?: boolean }) => {
    return (
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="outlined"
          type="button"
          size="medium"
          disabled={isSubmitting || !writeWorkflowApproval}
          sx={{
            marginRight: "10px",
          }}
          onClick={() => {
            setIsSubmitting(true);
            PatchAsync(
              `${API_GATEWAY}/api/workflowservice/workflowapprovals/rejected`,
              {
                id: id,
              }
            )
              .then((res: any) => {
                if (res?.status >= 200 && res?.status <= 299) {
                  enqueueSnackbar(translate(`successfully_rejected`), {
                    variant: "success",
                  });
                  renderDocument();
                } else {
                  enqueueSnackbar(`${res?.data?.Detail}`, { variant: "error" });
                }
              })
              .catch((err: any) => {
                enqueueSnackbar(`${err?.data?.Detail}`, { variant: "error" });
                console.log("Error-->", err);
              })
              .finally(() => {
                setIsSubmitting(false);
                handleClose();
              });
          }}
        >
          {translate("Reject")}
        </Button>
        <LoadingButton
          type="submit"
          size="medium"
          disabled={isSubmitting || !writeWorkflowApproval}
          variant="contained"
          loading={isSubmitting}
        >
          {translate("Approve")}
        </LoadingButton>
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"xl"}
      scroll="body"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          pt: 2,
          pr: 3.5,
        }}
      >
        <IconButton sx={{ width: 10, height: 8 }} onClick={handleClose}>
          <CloseModalButton />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          pt: 0,
        }}
      >
        <ReviewContextProvider>
          <Form
            approvalNotRequired={approvalNotRequired}
            fileId={fileId}
            connectedERP={"4"}
            isModal
            ActionButtons={ActionButtons}
            handleApprove={handleApprove}
            modalButtonLoading={isSubmitting}
            setModalButtonLoading={setIsSubmitting}
            readPermissions={readWorkflowApproval}
            writePermissions={writeWorkflowApproval}
            handleClose={handleClose}
          />
        </ReviewContextProvider>
      </DialogContent>
    </Dialog>
  );
};
export default ReviewModal;
