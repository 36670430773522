import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
// ----------------------------------------------------------------------
type States = {
    isEmailHidden: boolean;
};

const initialState: States = {
    isEmailHidden: false,
};

const slice = createSlice({
    name: 'States',
    initialState,
    reducers: {
        emailHiddenAction(state, action) {
            state.isEmailHidden = action.payload;
        }
    },
});


// Reducer
export default slice.reducer;

// Actions
export const { emailHiddenAction } = slice.actions;

export function EmailHidden(toggleState: boolean) {
    return async () => {
        dispatch(emailHiddenAction(toggleState));
    };
}