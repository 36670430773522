import { SelectChangeEvent } from "@mui/material";
import { useState } from "react";

const usePagination = ({ totalItems }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const onChangePage = (event: any, page: number) => {
    setPage(page);
  };
  const onChangeRowsPerPage = (event: SelectChangeEvent<any>, value: any) => {
    setRowsPerPage(event.target.value as number);
  };

  const nextPage = () => {
    if (page + 1 >= totalItems) {
      return;
    }
    setPage(page + 1);
  };

  const prevPage = () => {
    if (page - 1 < 0) {
      return;
    }
    setPage(page - 1);
  };
  return {
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    nextPage,
    prevPage,
  };
};
export default usePagination;
