import { Box, Chip, Menu, Typography } from "@mui/material";
import { NewTextFormField } from "../form/TextField";
import { ReactComponent as SearchIcon } from "src/assets/icon/search.svg";
import ChevronRightIcon from "src/assets/icon/chevron/right";
import Multiply from "src/assets/icon/multiply/Multiply";
import CustomLoader from "../loading/loadingContainer";
import useLocales from "src/hooks/useLocales";
import { useTheme } from "@mui/material";
import React from "react";
type FilterMenuProps = {
  inputRef: any;
  handleOpenMenu: any;
  handleCloseMenu: any;
  open: boolean;
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  filters: any;
  handleOnDelete: (key: string) => void;
  rows?: number;
  loader?: boolean;
  children: React.ReactNode;
};

const FilterMenu = ({
  inputRef,
  handleOpenMenu,
  handleCloseMenu,
  open,
  anchorEl,
  filters,
  handleOnDelete,
  loader,
  children,
}: FilterMenuProps) => {
  const theme = useTheme();
  const { translate } = useLocales();
  return (
    <>
      <NewTextFormField
        inputRef={inputRef}
        onClick={(event) => {
          handleOpenMenu(event);
        }}
        multiline
        minRows={1}
        maxRows={6}
        spellCheck={false}
        value={
          Object.entries(filters)
            ?.map(
              ([k, item]: any) =>
                `${translate(item?.label)} ${translate("is")} ${translate(
                  item?.displayValue
                )}`
            )
            .join(", ") + translate("Search ...")
        }
        sx={{
          borderRadius: "4px",
          fontSize: "13px",
          color: "transparent",
          lineHeight: "24px",
          width: "100%",
        }}
        startAdornment={
          <Box>
            <SearchIcon
              stroke={theme?.palette?.secondary?.main}
              style={{
                position: "absolute",
                left: "6px",
                top: "calc(50% - 12px)",
                bottom: "calc(50% - 12px)",
                color: theme?.palette?.secondary?.main,
                height: "20px",
                width: "20px",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                left: "30px",
                right: "30px",
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                top: `10px`,
                bottom: `10px`,
                pointerEvents: "none",
                color: "#657482",
                zIndex: 10,
                overflow: "hidden",
              }}
            >
              {Object.entries(filters)?.map(([k, item]: any, index): any => {
                return (
                  <Chip
                    key={index}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          flexDirection: "row",
                        }}
                      >
                        {translate(item?.label)}
                        <ChevronRightIcon height={10} width={10} />{" "}
                        {translate(item?.displayValue)}
                      </Box>
                    }
                    size="small"
                    onDelete={() => handleOnDelete(k)}
                    deleteIcon={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 15,
                          height: 15,
                        }}
                      >
                        <Multiply
                          height={10}
                          width={10}
                          customColor={theme?.palette?.action.active}
                        />
                      </Box>
                    }
                    sx={{
                      borderRadius: "4px",
                      height: "20px",
                      fontSize: "10px",
                      bgcolor: "secondary.light",
                      border: `1px solid ${theme?.palette?.secondary?.main}`,
                      pointerEvents: "auto",
                    }}
                  />
                );
              })}
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  fontSize: "12px",
                  lineHeight: "16px",
                  fontWeight: 400,
                }}
              >
                {translate(`Search ...`)}
              </Typography>
            </Box>
          </Box>
        }
        endAdornment={
          <CustomLoader
            sx={{
              position: "absolute",
              right: "6px",
              top: "calc(50% - 12px)",
              color: theme?.palette?.secondary?.main,
              height: "20px",
              width: "20px",
            }}
            isLoading={loader}
          />
        }
      />
      <Menu
        open={open}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        anchorPosition={{ top: 200, left: 400 }}
        PaperProps={{
          sx: {
            width: inputRef.current?.getBoundingClientRect()?.width,
            marginTop: "8px",
            p: 2,
          },
        }}
        sx={{}}
      >
        {children}
      </Menu>
    </>
  );
};

export default FilterMenu;
