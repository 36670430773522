import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import NewTextField from "src/components/form/TextField";
import useLocales from "src/hooks/useLocales";
import { ReviewContext } from "../../context/ReviewContext";
import { ExpectedValues } from "../../types/reviewContext";
import MenuAutocomplete from "src/components/denseForm/MenuAutocomplete";

export default function LowConfidence({
  title,
  item,
  getFieldValue,
  setFieldValue,
}) {
  const { translate } = useLocales();
  let value = getFieldValue(item.key);
  const [inputValue, setInputValue] = useState(value?.value || "");
  const getTranslatedFields = (title) => {
    let result = translate(`review_screen.review_fields.${title}`);
    if (result.indexOf("review_screen") === -1) {
      return result;
    }
    return title;
  };
  useEffect(() => {
    setInputValue(value?.value || "");
    setSelectOption({
      label:
        expectedValuesOption?.find((item) => item.value == value.value)
          ?.description ?? "",
      value: value?.value,
    });
  }, [value]);
  const reviewContext = useContext(ReviewContext);
  const { reviewResponse, expectedValues } = reviewContext;
  const { dispatch } = reviewResponse;
  const { documentFieldsExpectedValues } = expectedValues;
  let expectedValuesOption =
    documentFieldsExpectedValues?.find((item_) => item_.field === item.name)
      ?.expectedValues ??
    item.expectedValues?.map((item_) => {
      return {
        value: item_,
        description: item_,
      };
    }) ??
    [];
  const [selectOption, setSelectOption] = useState({
    label:
      expectedValuesOption?.find((item) => item.value == value.value)
        ?.description ?? "",
    value: value.value,
  });
  return (
    <>
      <Box className="mt-2 me-2">
        {expectedValuesOption?.length > 0 ? (
          <MenuAutocomplete
            multiline={false}
            value={selectOption}
            label={getTranslatedFields(title)}
            getOptionLabel={(option: any) => option?.label ?? ""}
            onChange={(event, newValue) => {
              if (newValue != null && typeof newValue !== "string") {
                setInputValue(newValue.value);
                setFieldValue(`${item.key}`, {
                  ...value,
                  value: newValue.value,
                  confidence: 0.9,
                });
                setSelectOption({
                  label: newValue.label,
                  value: newValue.value,
                });
                dispatch({
                  type: "ADD_FIELD",
                  payload: [
                    {
                      name: item.name,
                      value: newValue.value,
                      pageNumber: item.pageNumber,
                      confidence: 0.9,
                      isNew: false,
                      isRemoved: false,
                      itemsFields: null,
                      boundingPolygon: item.boundingPolygon,
                    },
                  ],
                });
              } else if (newValue === null) {
                setInputValue("");
                setSelectOption({
                  label: "",
                  value: "",
                });
              }
            }}
            error={!inputValue ? translate("This is a required field") : ""}
            options={
              expectedValuesOption?.map((item: ExpectedValues) => {
                return {
                  label: translate(item.description),
                  value: item.value,
                };
              }) ?? []
            }
          />
        ) : (
          <NewTextField
            label={getTranslatedFields(title)}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onBlur={() => {
              setFieldValue(`${item.key}`, {
                ...value,
                value: inputValue,
                confidence: 0.9,
              });
              if (inputValue !== value?.value) {
                dispatch({
                  type: "ADD_FIELD",
                  payload: [
                    {
                      name: item.name,
                      value: inputValue,
                      pageNumber: item.pageNumber,
                      confidence: 0.9,
                      isNew: false,
                      isRemoved: false,
                      itemsFields: null,
                      boundingPolygon: item.boundingPolygon,
                    },
                  ],
                });
              }
            }}
            value={inputValue}
            error={!inputValue ? translate("This is a required field") : ""}
            helperText={
              !inputValue ? translate("This is a required field") : ""
            }
          />
        )}
      </Box>
    </>
  );
}
