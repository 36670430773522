import { MenuItem, Select } from "@mui/material";
import { useTheme } from "@mui/material";
import { useField } from "formik";
import { NewTextFormField } from "./TextField";
import ChevronDown from "src/assets/icon/chevron/ChevronDown";

export const MySelectFormField = ({
  placeholder,
  onChange,
  sx,
  onBlur,
  inputProps,
  label,
  options,
  required,
  error,
  multiple = false,
  disabled = false,
  ...props
}: any) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const theme = useTheme();

  return (
    <>
      <Select
        id="customized-textbox"
        error={error ? error : !!errorText}
        {...field}
        inputProps={inputProps}
        displayEmpty
        IconComponent={() => {
          return (
            <ChevronDown
              height={20}
              width={20}
              customColor={
                error || !!errorText
                  ? theme.palette.error.dark
                  : theme.palette.grey[600]
              }
              sx={{
                position: "absolute",
                right: "6px",
                top: "calc(50% - 10px)",
                pointerEvents: "none",
                color: "#657482",
              }}
            />
          );
        }}
        labelId={props.name}
        input={<NewTextFormField placeholder={placeholder} label={label} />}
        sx={{
          "& .MuiInputBase-input": {
            backgroundColor: "background.paper",
            padding: "7px 26px 7px 10px",
            height: "32px",
            display: "flex",
            alignItems: "center",
            ...((error || !!errorText) && {
              backgroundColor: theme.palette.error.light,
              borderColor: theme.palette.error.dark,
            }),
          },
        }}
        multiple={multiple}
        onChange={(e) => {
          field.onChange(e);
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={onBlur}
        disabled={disabled}
        label={label}
        placeholder={placeholder}
      >
        <MenuItem
          sx={{
            fontSize: 12,
          }}
          value=""
          disabled
        >
          {placeholder}
        </MenuItem>
        {options
          .filter((o) => !o.disabled)
          .map((o) => {
            return (
              <MenuItem
                sx={{
                  fontSize: 12,
                }}
                key={o.value}
                value={o.value}
              >
                {o.label}
              </MenuItem>
            );
          })}
      </Select>
      {(!!error || errorText) && (
        <span
          style={{
            color: "#CE0D09",
            fontSize: "12px",
            marginTop: "3px",
          }}
          className="form-label"
        >
          {error || errorText}
        </span>
      )}
    </>
  );
};

export const MySelect = ({
  placeholder,
  onChange,
  sx,
  onBlur,
  inputProps,
  label,
  options,
  required,
  error,
  multiple = false,
  disabled = false,
  ...props
}: any) => {
  const theme = useTheme();

  return (
    <>
      <Select
        error={!!error}
        inputProps={inputProps}
        displayEmpty
        IconComponent={() => {
          return (
            <ChevronDown
              height={20}
              width={20}
              customColor={
                error ? theme.palette.error.dark : theme.palette.grey[600]
              }
              sx={{
                position: "absolute",
                right: "6px",
                top: "calc(50% - 10px)",
                pointerEvents: "none",
                color: "#657482",
              }}
            />
          );
        }}
        labelId={props.name}
        input={<NewTextFormField placeholder={placeholder} label={label} />}
        sx={{
          "& .MuiInputBase-input": {
            backgroundColor: "background.paper",
            padding: "7px 26px 7px 10px",
            height: "32px",
            display: "flex",
            alignItems: "center",
            ...(error && {
              backgroundColor: theme.palette.error.light,
              borderColor: theme.palette.error.dark,
            }),
          },
        }}
        multiple={multiple}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        {...props}
      >
        {placeholder ? <MenuItem value="">{placeholder}</MenuItem> : null}
        {options
          .filter((o) => !o.disabled)
          .map((o) => {
            return (
              <MenuItem
                sx={{
                  fontSize: 12,
                }}
                key={o.value}
                value={o.value}
              >
                {o.label}
              </MenuItem>
            );
          })}
      </Select>
      {!!error && (
        <span
          style={{
            color: "#CE0D09",
            fontSize: "12px",
            marginTop: "3px",
          }}
          className="form-label"
        >
          {error}
        </span>
      )}
    </>
  );
};
