import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import mailReducer from "./slices/mail";
import chatReducer from "./slices/chat";
import calendarReducer from "./slices/calendar";
import credential from "./slices/credentials";
import organization from "./slices/organization";
import modalToggle from "./slices/modal";
import cartReducer from "./slices/cart";
import reviewScreenReducer from "./slices/reviewScreen";
import stepperReducer from "./slices/stepper";
import statesReducer from "./slices/states";
import apiDataReducer from "./slices/apiData";
import bumperStates from "./slices/bumper";
import configuration from "./slices/configuration";
import externalIdentities from "./slices/externalIdentities";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const credentialPersistConfig = {
  key: "user",
  storage,
  keyPrefix: "redux-",
  whitelist: ["userCred", "userTokenDecode"],
};

const organizationPersistConfig = {
  key: "organization",
  storage,
  keyPrefix: "redux-",
  whitelist: ["org"],
};

const cartPersistConfig = {
  key: "cart",
  storage,
  keyPrefix: "redux-",
  whitelist: ["cartState"],
};

const modalPersistConfig = {
  key: "modal",
  storage,
  keyPrefix: "redux-",
  whitelist: [
    "modalState",
    "checkTooltip",
    "tooltipToggleFinance",
    "tooltipToggleReview",
  ],
};

const statesReducerConfig = {
  key: "States",
  storage,
  keyPrefix: "redux-",
  whitelist: ["isEmailHidden"],
};

const apiDataReducerConfig = {
  key: "ApiData",
  storage,
  keyPrefix: "redux-",
  whitelist: ["dashboard"],
};

const bumperStatesReducerConfig = {
  key: "BumperDialog",
  storage,
  keyPrefix: "redux-",
  whitelist: ["steps", "progressBarPer"],
};

const configurationConfig = {
  key: "Configuration",
  storage,
  keyPrefix: "redux-",
  whitelist: ["configurationDetail"],
};

const externalIdentitiesConfig = {
  key: "externalIdentities",
  storage,
  keyPrefix: "redux-",
  whitelist: ["externalIdentities"],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  reviewScreen: reviewScreenReducer,
  modalToggle: persistReducer(modalPersistConfig, modalToggle),
  credential: persistReducer(credentialPersistConfig, credential),
  organization: persistReducer(organizationPersistConfig, organization),
  cart: persistReducer(cartPersistConfig, cartReducer),
  stepper: stepperReducer,
  states: persistReducer(statesReducerConfig, statesReducer),
  apiData: persistReducer(apiDataReducerConfig, apiDataReducer),
  bumper: persistReducer(bumperStatesReducerConfig, bumperStates),
  configuration: persistReducer(configurationConfig, configuration),
  externalIdentities: persistReducer(
    externalIdentitiesConfig,
    externalIdentities
  ),
});

export { rootPersistConfig, rootReducer };
