import { IconButton, Tooltip } from "@mui/material";
import { MenuItem } from "@mui/material";
import CopyIcon from "src/assets/icon/copy/CopyIcon";
import { Box } from "@mui/material";

const MyMenuItem = ({
  option,
  displayKey,
  itemDisplayOpts,
  t,
  optionTooltipProps,
  showValueKey = false,
  valueDisplayKey = "value",
  ...props
}) => {
  let {
    showTooltipOnOption,
    tooltipDisplayKey = "value",
    tooltipPlacement = "top",
    copytoClipboard = false,
  } = optionTooltipProps ?? {};
  return (
    <MenuItem
      {...props}
      key={props.id}
      sx={{
        ...props?.sx,
        py: 0.5,
        px: 1,
        m: 0,
      }}
    >
      {itemDisplayOpts?.showImage && itemDisplayOpts.imgKey && (
        <span key={option.id} className="c-avatar">
          <img
            src={
              option[itemDisplayOpts.imgKey] &&
              option[itemDisplayOpts.imgKey].path
                ? option[itemDisplayOpts.imgKey].path
                : "avatars/8.jpg"
            }
            className="c-avatar-img"
          />
        </span>
      )}
      <Box
        style={{
          marginLeft: 10,
          fontSize: "12px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {itemDisplayOpts?.showColor && (
          <div
            style={{
              height: "22px",
              width: "22px",
              background:
                option[itemDisplayOpts.colorKey ?? "strokeColor"] ??
                "whitesmoke",
              borderRadius: "50%",
              display: "inline-block",
              marginRight: "0.2rem",
            }}
          ></div>
        )}
        {showTooltipOnOption ? (
          <Tooltip
            placement={tooltipPlacement}
            title={
              <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                {t?.(option[tooltipDisplayKey]) ?? option[tooltipDisplayKey]}
                {copytoClipboard && (
                  <IconButton
                    size="small"
                    sx={{
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(option[tooltipDisplayKey]);
                    }}
                  >
                    <CopyIcon height="14px" width="14px" />
                  </IconButton>
                )}
              </Box>
            }
          >
            <span>{t?.(option[displayKey]) ?? option[displayKey]}</span>
          </Tooltip>
        ) : (
          t?.(option[displayKey]) ?? option[displayKey]
        )}
        {showValueKey && (
          <Box
            sx={{
              color: "text.secondary",
              fontSize: "9px",
              fontStyle: "italic",
              fontWeight: "300",
            }}
          >
            {option?.[valueDisplayKey]}
          </Box>
        )}
      </Box>
    </MenuItem>
  );
};

export default MyMenuItem;
