import { SvgIconProps } from "@mui/material";
import React, { memo } from "react";

const IllustrationUploadEmail = ({
  color = "secondary",
  height = 140,
  width = 160,
}: SvgIconProps) => {
  return (
    <div>
      <svg
        width="160"
        height="160"
        viewBox="0 0 160 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M129.147 57.4401L101.476 44.0113L61.9468 24.8274L45.656 16.9141C41.5843 14.9174 37.0581 14.032 32.535 14.3475C28.012 14.663 23.6522 16.1682 19.8966 18.7108C16.1409 21.2534 13.1225 24.7435 11.1463 28.8281C9.17013 32.9127 8.30632 37.4472 8.64217 41.9731L12.116 90.6522L15.3502 134.296C15.7123 138.818 17.2495 143.167 19.8092 146.911C22.369 150.655 25.8622 153.664 29.9424 155.639C34.0227 157.614 38.548 158.488 43.0694 158.172C47.5909 157.856 51.9513 156.363 55.718 153.839L98.6013 125.663C99.5772 124.846 100.498 123.965 101.356 123.025L132.381 102.163C136.136 99.6103 139.154 96.1143 141.132 92.0255C143.11 87.9366 143.979 83.3988 143.652 78.8675C143.324 74.3363 141.811 69.971 139.265 66.21C136.719 62.4489 133.23 59.4244 129.147 57.4401Z"
          fill="#EEF5FD"
        />
        <path
          d="M78.8888 93.0995L25.7142 62.793V127.394H132.857V62.793L78.8888 93.0995Z"
          fill="white"
        />
        <path
          d="M132.857 127.393H25.7142V63.2426L79.2857 32.8555L132.857 63.2426V127.393Z"
          fill="white"
          stroke="#0B427A"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.7142 63.2426L79.2857 93.6298L132.857 63.2426L79.2857 32.8555L25.7142 63.2426Z"
          stroke="#0B427A"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.7142 127.392H132.857L79.2857 93.6289L25.7142 127.392Z"
          stroke="#0B427A"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M79.2855 41.2963L125.656 67.328L132.857 63.2426L79.2857 32.8555L25.7142 63.2426L32.9154 67.328L79.2855 41.2963Z"
          stroke="#0B427A"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.214 41.582C46.214 39.649 47.781 38.082 49.714 38.082H107.89C109.823 38.082 111.39 39.649 111.39 41.582V75.8948L78.8022 94.5167L46.214 75.8948V41.582Z"
          fill="#E4E6EB"
          stroke="#0B427A"
        />
        <path
          d="M64.5714 50.1875H92.5714"
          stroke="#0B427A"
          strokeLinecap="round"
        />
        <path
          d="M64.5714 56.1875H92.5714"
          stroke="#0B427A"
          strokeLinecap="round"
        />
        <path
          d="M64.5714 62.1875H92.5714"
          stroke="#0B427A"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default memo(IllustrationUploadEmail);
