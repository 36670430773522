import { Box, SvgIconProps } from "@mui/material";
import { useTheme } from "@mui/material";

const Active = ({
  height = 24,
  width = 24,
  color = "primary",
}: SvgIconProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height,
        width,
        display: "flex",
        alignItems: "center",
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 10.7693L8.80645 19.2307L23 4"
          stroke={theme.palette?.[color]?.dark}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default Active;
