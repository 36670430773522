import React from "react";
import { SvgIconProps } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

const TXTIcon = ({
  color = "secondary",
  width = 24,
  height = 24,
  customColor,
  sx,
  disableDimension = false,
}: SvgIconProps & {
  customColor?: string;
  disableDimension?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...(disableDimension
          ? {}
          : {
              height: height,
              width: width,
            }),
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="none"
          stroke={customColor ?? theme.palette[color]?.main}
          strokeWidth="2"
          d="M4.99787498,8.99999999 L4.99787498,0.999999992 L19.4999998,0.999999992 L22.9999998,4.50000005 L23,23 L4,23 M18,1 L18,6 L23,6 M2,12 L7,12 M4.5,12 L4.5,19 M16,12 L21,12 M18.5,12 L18.5,19 M14.5,11.5 L8.5,18.5 M8.5,11.5 L14.5,18.5"
        />
      </svg>
    </Box>
  );
};

TXTIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  sx: PropTypes.object,
};

TXTIcon.defaultProps = {
  color: "secondary",
  width: 24,
  height: 24,
};

export default TXTIcon;
