// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Switch, FormControlLabel, FormControlLabelProps } from "@mui/material";
import MySwitch from "../switch";

// ----------------------------------------------------------------------

type IProps = Omit<FormControlLabelProps, "control">;

interface Props extends IProps {
  name: string;
  label: string | React.ReactNode;
}

export default function RHFSwitch({ name, label, disabled, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      label={
        typeof label === "string" ? (
          <b style={{ color: "#657482" }}>{label}</b>
        ) : (
          label
        )
      }
      labelPlacement="top"
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <MySwitch {...field} checked={field.value} disabled={disabled} />
          )}
        />
      }
      {...other}
      sx={{
        alignItems: "flex-start",
        marginLeft: "0px",
      }}
    />
  );
}
