
export const COLOR_LIST = [
    { name: "Pink", value: "#FFC0CB" },
    { name: "SkyBlue", value: "#87CEEB" },
    { name: "HotPink", value: "#FF69B4" },
    { name: "MediumPurple", value: "#9370DB" },
    { name: "MistyRose", value: "#FFE4E1" },
    { name: "DarkGray", value: "#A9A9A9" },
    { name: "Thistle", value: "#D8BFD8" },
    { name: "Cyan", value: "#00FFFF" },
    { name: "LightSeaGreen", value: "#20B2AA" },
    { name: "YellowGreen", value: "#9ACD32" },
    { name: "PeachPuff", value: "#FFDAB9" },
    { name: "Aquamarine", value: "#7FFFD4" }
  ];
 