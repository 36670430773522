import React from "react";
import { Box } from "@mui/material";

const TickIcon = ({ height = 24, width = 24, color = "#657482" }) => {
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5166 11.0002L9.38757 15.1938L16.4843 7.45182"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 21C5.4771 21 1 16.5229 1 11C1 5.4771 5.4771 1 11 1C16.5229 1 21 5.4771 21 11C21 16.5229 16.5229 21 11 21Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default TickIcon;
