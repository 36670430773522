import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Button, Chip, DialogTitle, Typography } from "@mui/material";
import { Divider } from "@mui/material";
import { useTheme } from "@mui/material";
import { Dialog, DialogContent } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { PutAsync } from "src/common/httpRequests";
import { API_GATEWAY } from "src/constants/settings";
import useLocales from "src/hooks/useLocales";

type AccoutingPeriodDialogProps = {
  open: boolean;
  handleClose: () => void;
  accountingPeriod: {
    closeDate: string;
    isClosed: boolean;
    name: string;
    openDate: string;
    periodId: string;
  };
  writePermissions: boolean;
  documentIsDeleted: boolean;
};
const AccoutingPeriodDialog = ({
  open,
  handleClose,
  accountingPeriod,
  writePermissions,
  documentIsDeleted,
}: AccoutingPeriodDialogProps) => {
  const { translate } = useLocales();
  const theme = useTheme();
  let periodName = accountingPeriod.name?.split("-");
  let year = periodName?.[0] ?? "";
  let month = periodName?.[1] ?? "";
  const { enqueueSnackbar } = useSnackbar();
  const onClosePeriod = async () => {
    PutAsync(
      `${API_GATEWAY}/api/documentservice/AccountPeriod/close/${accountingPeriod?.periodId}`,
      {}
    )
      .then((res) => {
        if (res.status !== 200) {
          enqueueSnackbar(translate("Failed to close period"), {
            variant: "error",
          });
          handleClose();
          handleCloseConfirmDialog();
          return;
        }
        enqueueSnackbar(translate("Period closed successfully"), {
          variant: "success",
        });
        handleClose();
        handleCloseConfirmDialog();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>{translate("Close accounting period")}</DialogTitle>
        <DialogContent>
          <Typography fontSize={12}>
            {translate(
              "Are you sure you want to close the accounting period? Once the period is closed, all documents for that period will be non-editable."
            )}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button
              size="small"
              onClick={handleCloseConfirmDialog}
              variant="outlined"
              color="primary"
            >
              {translate("No")}
            </Button>
            <LoadingButton
              size="small"
              onClick={onClosePeriod}
              variant="contained"
              color="primary"
              // loading={loading}
            >
              {translate("Yes")}
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        {/* Dialog Content */}
        <DialogContent dividers>
          {/* Period Name */}
          <Typography variant="h5" component="h3">
            {translate("Accounting period")}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle1" color="text.greyLight">
              {year}-{translate(month.toLowerCase())}
            </Typography>
            <>
              <Divider
                orientation="vertical"
                sx={{
                  height: "20px",
                  width: "20px",
                  color: "action.active",
                  transform: "rotate(270deg)",
                }}
              />
              <Chip
                variant="outlined"
                size="small"
                label={
                  accountingPeriod.isClosed
                    ? translate("Closed")
                    : translate("Open")
                }
                sx={{
                  borderRadius: "4px",
                  height: "20px",
                  fontSize: "10px",
                  bgcolor: accountingPeriod.isClosed
                    ? "error.light"
                    : "success.light",
                  color: accountingPeriod.isClosed
                    ? "error.dark"
                    : "success.dark",
                  border: `1px solid ${
                    accountingPeriod.isClosed
                      ? theme.palette.error.dark
                      : theme.palette.success.dark
                  }`,
                  pointerEvents: "auto",
                }}
              />
            </>
          </Box>

          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            {translate("This period is used for accounting purposes.")}
          </Typography>

          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
              [`& .${timelineItemClasses.root}`]: {
                minHeight: "45px",
                padding: 0,
              },
            }}
          >
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    p: 0.25,
                    m: 0.125,
                  }}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  p: "0px 8px",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="subtitle2">
                    {translate("Open date")}
                  </Typography>
                  <Typography variant="body2">
                    {new Date(accountingPeriod.openDate).toLocaleDateString()}
                  </Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    p: 0.25,
                    m: 0.125,
                  }}
                />
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  p: "0px 8px",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="subtitle2">
                    {translate("Close date")}
                  </Typography>
                  <Typography variant="body2">
                    {new Date(accountingPeriod.closeDate).toLocaleDateString()}
                  </Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button
              size="small"
              onClick={handleClose}
              variant="outlined"
              color="primary"
            >
              {translate("Close")}
            </Button>
            <LoadingButton
              size="small"
              disabled={
                !writePermissions ||
                accountingPeriod.isClosed ||
                documentIsDeleted
              }
              onClick={handleOpenConfirmDialog}
              variant="contained"
              color="primary"
              // loading={loading}
            >
              {translate("Close period")}
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AccoutingPeriodDialog;
