import axios from "axios";
import { API_GATEWAY } from "../constants/settings";
import { SetCookie } from "./cookie";
import { store } from "src/redux/store";

export default class CommonService {
  static async TriggerExtractionOfAttachments(email, token) {
    fetch(`${API_GATEWAY}/api/saasfunctionapp/GmailAttachments/${token}`)
      .then((response) => {
        console.log(
          "executed extract_email_attachments_on_request: ",
          response.ok
        );
      })
      .catch((error) =>
        console.log("error extract_email_attachments_on_request: ", error)
      );

    fetch(`${API_GATEWAY}/api/saasfunctionapp/OneDrive/Documents/${token}`)
      .then((response) => {
        console.log(
          "executed extract_oneDrive_attachments_on_request: ",
          response.ok
        );
      })
      .catch((error) =>
        console.log("error extract_oneDrive_attachments_on_request: ", error)
      );

    fetch(`${API_GATEWAY}/api/saasfunctionapp/Extract/GoogleDrive/${token}`)
      .then((response) => {
        console.log(
          "executed extract_oneDrive_attachments_on_request: ",
          response.ok
        );
      })
      .catch((error) =>
        console.log("error extract_oneDrive_attachments_on_request: ", error)
      );

    return true;
  }

  getBlobContainerSize() {
    return axios({
      method: "GET",
      url: `${API_GATEWAY}/api/webapi/mailboxProfile/getBlobContainerSize`,
      headers: {
        Authorization: `Bearer ${
          store.getState()?.credential?.userCred.accessToken
        }`,
      },
    }).then(async (res) => {
      SetCookie("blobContainerSize", res.data);
    });
  }
}
