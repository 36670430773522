import { Popover } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { Xwrapper } from "react-xarrows";
import useLocales from "src/hooks/useLocales";
import { Condition } from "../type";

interface Props {
  top?: number;
  left?: number;
  conditions: Condition[];
  anchorEl: HTMLElement | null;
  handlePopoverClose: () => void;
}

const ViewWorkflow = ({ conditions, anchorEl, handlePopoverClose }: Props) => {
  const { translate } = useLocales();

  return (
    <Popover
      id="mouse-over-popover"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        pointerEvents: "none",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Box
        sx={{
          display: "flex",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          p: 1,
        }}
      >
        {conditions?.map((condition: Condition, i) => (
          <Box
            id={`comp${i + 1}`}
            sx={{
              bgcolor: "background.paper",
              borderRadius: 0.5,
              p: "1px",
              px: 0.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: "text.greyLight",
                fontSize: "12px",
              }}
              variant="subtitle2"
            >
              {translate("If the variable")}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
              }}
              variant="body2"
            >
              {translate(condition.parameter)}
            </Typography>
            <Arrow />
            <Typography
              sx={{
                fontWeight: "bold",
                color: "text.greyLight",
                fontSize: "12px",
              }}
              variant="subtitle2"
            >
              {translate("Is")}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
              }}
              variant="body2"
            >
              {translate(condition.operation)}
            </Typography>
            <Arrow />
            <Typography
              sx={{
                fontWeight: "bold",
                color: "text.greyLight",
                fontSize: "12px",
              }}
              variant="subtitle2"
            >
              {translate("To the input value")}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
              }}
              variant="body2"
            >
              {translate(condition.formattedValue)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};
export default ViewWorkflow;

export const Arrow = () => (
  <svg
    width="27"
    height="6"
    viewBox="0 0 27 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM27 3L22 0.11325L22 5.88675L27 3ZM3 3.5L22.5 3.5L22.5 2.5L3 2.5L3 3.5Z"
      fill="#B7C1CA"
    />
  </svg>
);
