// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField, TextFieldProps } from "@mui/material";
import NewTextField, { NewTextFormField } from "../form/TextField";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  dense?: boolean;
  rules?: any;
};

type Props = IProps & TextFieldProps;

export default function RHFTextField({
  name,
  size,
  label,
  dense,
  rules,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        if (dense) {
          return (
            <NewTextFormField
              size={size}
              {...field}
              fullWidth
              endAdornment={other?.InputProps?.endAdornment}
              error={!!error}
              helperText={error?.message}
              {...other}
              label={label}
            />
          );
        }
        return (
          <NewTextField
            size={size}
            {...field}
            fullWidth
            endAdornment={other?.InputProps?.endAdornment}
            error={!!error}
            helperText={error?.message}
            {...other}
            label={label}
          />
        );
      }}
    />
  );
}
