import { Box, Divider, List, ListItem, ListItemText } from "@mui/material";
import { TableContainer, TableHead, Table, Skeleton } from "@mui/material";
import { Grid, useTheme, TableRow, TableCell, TableBody } from "@mui/material";
import React from "react";

const ReviewPageSkeleton = () => {
  function generate(element: React.ReactElement) {
    return [0, 1].map((value) =>
      React.cloneElement(element, {
        key: value + Math.random(),
      })
    );
  }
  const theme = useTheme();
  return (
    <Grid container sx={{ px: 2 }} spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Skeleton variant="text" width={60} height={15} />
        <Skeleton variant="circular" width={7} height={7} />
        <Skeleton variant="text" width={70} height={15} />
        <Skeleton variant="circular" width={7} height={7} />
        <Skeleton variant="text" width={50} height={15} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" width={240} height={60} />
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Skeleton
          variant="rectangular"
          width={140}
          height={40}
          sx={{ mr: 2 }}
        />
        <Skeleton variant="rectangular" width={140} height={40} />
      </Grid>
      <Grid item xs={6} sx={{ display: "flex" }}>
        <Skeleton variant="text" width={60} height={40} />
        <Skeleton variant="circular" width={40} height={40} sx={{ ml: 2 }} />
        <Skeleton variant="circular" width={40} height={40} sx={{ ml: 1 }} />
        <Divider orientation="vertical" />
        <Skeleton variant="circular" width={40} height={40} sx={{ ml: 2 }} />
        <Skeleton variant="circular" width={40} height={40} sx={{ ml: 1 }} />
        <Divider orientation="vertical" />
        <Skeleton variant="circular" width={40} height={40} sx={{ ml: 2 }} />
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Skeleton variant="rectangular" width={130} height={30} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={7}>
        <Skeleton variant="text" width={240} height={60} />
        <Box sx={{ display: "flex" }}>
          <Skeleton
            variant="rectangular"
            width={160}
            height={60}
            sx={{ mr: 2 }}
          />
          <Skeleton variant="rectangular" width={160} height={60} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 1 }}>
          <Skeleton variant="text" width={60} height={40} />
          <Skeleton variant="circular" width={40} height={40} sx={{ ml: 2 }} />
          <Skeleton variant="circular" width={40} height={40} sx={{ ml: 1 }} />
          <Divider orientation="vertical" />
          <Skeleton variant="circular" width={40} height={40} sx={{ ml: 2 }} />
          <Skeleton variant="circular" width={40} height={40} sx={{ ml: 1 }} />
          <Divider orientation="vertical" />
          <Skeleton variant="circular" width={40} height={40} sx={{ ml: 2 }} />
        </Box>
        <Skeleton
          variant="rectangular"
          width={170}
          height={40}
          sx={{ mt: 1 }}
        />
        <Skeleton variant="text" width={120} height={30} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="text" width={150} height={50} />
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Skeleton variant="text" width={120} height={60} />
          <Skeleton variant="text" width={160} height={60} sx={{ ml: 2 }} />
        </Box>
        <Skeleton variant="text" height={20} />
        <List>
          {generate(
            <ListItem>
              <ListItemText>
                <Skeleton width={100} height={50} />
              </ListItemText>
            </ListItem>
          )}
        </List>
      </Grid>
      <Grid item xs={5}>
        <Skeleton variant="text" width={120} height={30} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="text" width={150} height={50} />
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Skeleton variant="text" width={120} height={60} />
          <Skeleton variant="text" width={160} height={60} sx={{ ml: 2 }} />
        </Box>
        <Skeleton variant="text" height={20} />
        {[1, 2].map(() => (
          <TableContainer
            key={Math.random()}
            sx={{
              borderRadius: "8px",
              border: `3px solid ${theme.palette.background.neutral}`,
              mt: 2,
            }}
          >
            <Table
              aria-label="collapsible table"
              sx={{
                px: 2,
              }}
            >
              <TableHead
                sx={{
                  background: theme.palette.background.neutral,
                  color: theme.palette.text.secondary,
                  py: 2,
                  border: `2px solid ${theme.palette.secondary.light}`,
                }}
              >
                <Skeleton
                  variant="text"
                  width={100}
                  height={20}
                  component={"tr"}
                />
              </TableHead>
              <TableBody style={{ maxHeight: "35vh" }}>
                {/* <div
                  className="element"
                  style={{
                    overflow: "hidden",
                    maxHeight: "30vh",
                  }}
                > */}
                {[1, 2, 3]?.map((item_: any, idx: number) => (
                  <TableRow
                    key={idx + Math.random()}
                    sx={{ display: "block", border: "none", width: "100%" }}
                  >
                    <TableCell
                      sx={{
                        width: "4%",
                      }}
                    >
                      <Skeleton variant="circular" width={20} height={20} />
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "40%",
                      }}
                    >
                      <Skeleton variant="text" width={100} height={20} />
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "50%",
                      }}
                    >
                      <Skeleton variant="text" width={100} height={20} />
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "6%",
                      }}
                    >
                      <Skeleton variant="circular" width={15} height={15} />
                    </TableCell>
                  </TableRow>
                ))}
                {/* </div> */}
              </TableBody>
            </Table>
          </TableContainer>
        ))}
      </Grid>
    </Grid>
  );
};

export default ReviewPageSkeleton;
