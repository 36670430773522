import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Popover(theme: Theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.dropdown,
          borderRadius: Number(theme.shape.borderRadius) * 1.2,
          backgroundColor: theme.palette.background.neutral,
        },
      },
    },
  };
}
