import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

type CredState = {
    isScreen: Boolean;
    isLoading: boolean;
    error: Error | string | null;
};

const initialState: CredState = {
    isLoading: false,
    error: null,
    isScreen: false,
};

const slice = createSlice({
    name: 'review',
    initialState,
    reducers: {
        setScreenAction(state, action) {
            state.isScreen = action.payload;
        }
    },
});


// Reducer
export default slice.reducer;

// Actions
export const { setScreenAction } = slice.actions;

export function setScreen(SetScreen: boolean) {
    return async () => {
        dispatch(setScreenAction(SetScreen));
    };
}


