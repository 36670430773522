// @mui
import { TableRow, TableCell } from "@mui/material";
//
import EmptyContent from "../EmptyContent";

import useLocales from "../../hooks/useLocales";

// ----------------------------------------------------------------------

type Props = {
  isNotFound: boolean;
  title?: string | React.ReactNode;
  image?: React.ReactNode;
  description?: string | React.ReactNode;
  size?: "small" | "medium" | "large";
  border?: boolean;
};

export default function TableNoData({
  isNotFound,
  title,
  image,
  description,
  size,
  border = true,
}: Props) {
  const { translate } = useLocales();

  return (
    <TableRow
      sx={{
        ...(!border
          ? {
              border: "none",
              "&:last-child td": { border: "none" },
            }
          : {}),
      }}
    >
      {isNotFound ? (
        <TableCell colSpan={16}>
          <EmptyContent
            image={image}
            description={description}
            size={size}
            title={title ? title : translate("finance_screen.no_data")}
            sx={{
              "& span.MuiBox-root": { height: 160 },
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}
