import { global } from "./global";
import { mappings } from "./mappings";
import { suppliers } from "./suppliers";

const en = {
  ...global,
  suppliers: {
    ...suppliers,
  },
  mappings: {
    ...mappings,
  },
};

export default en;
