import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import MenuPopover from "src/components/MenuPopover";
import useLocales from "src/hooks/useLocales";
import { ReactComponent as InsertRowIcon } from "src/assets/icon/table/insertRow.svg";
import { NewTextFormField } from "src/components/textField";

const NewRowPopoverButton = ({ newRow, disabled, total }) => {
  const { translate } = useLocales();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [rowNumber, setRowNumber] = useState<number | null>(total + 1);
  const theme = useTheme();
  const handlePopoverClose = (e) => {
    console.log("handlePopoverClose");
    e.stopPropagation();
    setAnchorEl(null);
  };
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Tooltip title={translate("Add Row")} arrow>
        <span>
          <IconButton
            size="small"
            disabled={disabled}
            sx={{
              marginLeft: 1,
              borderRadius: "8px",
              height: "27px",
              width: "27px",
              color: "grey.400",
              bgcolor: "background.paper",
              border: "1px solid " + theme.palette.grey[400],
              cursor: "pointer",
              "&:hover": { color: "black" },
            }}
            onClick={(e) => handlePopoverOpen(e)}
          >
            <InsertRowIcon
              height={18}
              width={18}
              color={
                disabled ? theme.palette.grey[400] : theme.palette.primary.main
              }
            />
          </IconButton>
        </span>
      </Tooltip>
      <MenuPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 200,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.5,
          },
          bgcolor: "background.paper",
          p: 1,
          boxShadow: "0px 6px 12px -3px #424a530a, 0px 6px 18px 0px #424a531f", // "0px 2px 5px 0px rgba(0,0,0,0.12)",
          border: `1px solid #d0d7de`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle2">
            {translate("Insert new row")}
          </Typography>
        </Box>
        <Divider sx={{ color: "divider" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <NewTextFormField
              // label={translate("Enter row number")}
              type={"number"}
              name="name"
              placeholder={translate("Enter row number")}
              sx={{
                width: "100%",
                mt: 1,
              }}
              value={rowNumber}
              onBlur={(e) => {
                let value = parseInt((rowNumber ?? "")?.toString()); // Parse and validate on blur

                if (isNaN(value) || value < 1) {
                  value = 1;
                } else if (value > total) {
                  value = total;
                }

                setRowNumber(value);
              }}
              onChange={(e) => {
                let value = e.target.value; // Don't parse here yet to allow typing

                // Update the state with the raw value to allow user input freely
                setRowNumber(value);
              }}
            />
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: "action.active",
              fontSize: "10px",
              mt: 0.5,
              px: 0.25,
            }}
          >
            {translate(`Optional: Insert at row (1 to )`, { total: total + 1 })}
          </Typography>
          <Button
            variant="contained"
            size="small"
            sx={{ mt: 1, width: "100%", bgcolor: "secondary.main" }}
            onClick={(e) => {
              newRow(rowNumber);
              setRowNumber(total + 2);
              handlePopoverClose(e);
            }}
          >
            {translate("Insert")}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
};

export default NewRowPopoverButton;
