import { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/material";
import { IconButton } from "@mui/material";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import useLocales from "src/hooks/useLocales";
import CopyIcon from "src/assets/icon/copy/CopyIcon";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "src/routes/paths";
import { GetAsync } from "src/common/httpRequests";
import { API_GATEWAY, MOBILE_APP_DOWNLOAD_URL } from "src/constants/settings";
import Loader from "src/components/loading/loader";
import IconAndroid from "src/assets/icon/Andriod";

interface ModalProps {
  isShowModal: boolean;
  showModalMethod: VoidFunction;
  modalTitle: string;
  modalIllustration: any;
  modalContentText: string;
  dostEmail?: string;
}

export default function QRcodeUploadDialog(props: ModalProps) {
  const {
    isShowModal,
    showModalMethod,
    modalTitle,
    modalIllustration,
    modalContentText,
    dostEmail,
  } = props;
  const theme = useTheme();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("body");
  const [maxWidth] = useState<DialogProps["maxWidth"]>("sm");
  const [text, setText] = useState<string>("copy");
  const [icon, setIcon] = useState(<CopyIcon />);
  const descriptionElementRef = useRef<HTMLElement>(null);
  const [qrcode, setQrcode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isShowModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      setScroll("body");
    }
  }, [isShowModal]);
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const getQrcode = async () => {
    setLoading(true);
    GetAsync(`${API_GATEWAY}/api/documentservice/Document/GenerateQR`)
      .then((res) => {
        if (res.data.base64QR)
          setQrcode("data:image/png;base64," + res.data.base64QR);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isShowModal && qrcode === "") getQrcode();
  }, [isShowModal]);

  const handleTooltipOpen = (dostEmail) => {
    setText(`Copied`);
    setIcon(
      <TaskAltIcon sx={{ height: "30px", width: "24px" }} color="success" />
    );

    navigator?.clipboard?.writeText(dostEmail);

    setInterval(() => {
      setText(`copy`);
      setIcon(<CopyIcon />);
    }, 10000);
  };

  return (
    <div style={{ borderRadius: "90px" }}>
      <Dialog
        open={isShowModal}
        onClose={showModalMethod}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title responsive-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={maxWidth}
      >
        <DialogTitle
          id="scroll-dialog-title responsive-dialog-title"
          sx={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            pb: 2,
          }}
        >
          <Box>{translate("Document Upload via QR")}</Box>
          <IconButton sx={{ width: 10, height: 8 }} onClick={showModalMethod}>
            <CloseModalButton />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          sx={{ textAlign: "center" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              bgcolor: "background.neutral",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            {qrcode !== "" ? (
              <Box
                sx={{
                  borderRadius: 1,
                  border: "1px solid #E4E6EB",
                }}
              >
                <img
                  src={qrcode}
                  alt="qrcode"
                  style={{
                    width: "250px",
                    height: "250px",
                    borderRadius: "10px",
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "250px",
                  width: "250px",
                }}
              >
                <Loader />
              </Box>
            )}
          </Box>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <Typography variant="body2">
                {translate(
                  "Scan the QR code to instantly upload your documents with the Dost App"
                )}
              </Typography>
            </Box>
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              marginTop: 2,
            }}
          >
            <Button
              onClick={() => window.open(MOBILE_APP_DOWNLOAD_URL, "_blank")}
              variant="outlined"
              size="small"
              startIcon={<IconAndroid color="primary" />}
              sx={{
                textAlign: "left",
              }}
            >
              <Typography variant="body1">
                {translate(`Download App (Android)`)}
              </Typography>
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
