// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { SelectProps } from "@mui/material";
import { Select } from "@mui/material";
import NewTextField, { NewTextFormField } from "../form/TextField";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  children: any;
  InputLabelProps?: any;
  SelectProps?: any;
  FormHelperTextProps?: any;
  helperText?: any;
  label?: any;
  dense?: boolean;
};

type Props = IProps & SelectProps;

export default function RHFSelect({
  name,
  children,
  onChange,
  label,
  dense = false,
  ...other
}: Props) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        let { onChange, ...field_ } = field;
        return (
          <Select
            id="customized-textbox"
            {...field_}
            displayEmpty
            input={
              dense ? (
                <NewTextFormField
                  label={label}
                  placeholder={label}
                  error={!!error}
                  helperText={error?.message}
                  onChange={(e: any) => field.onChange(e)}
                />
              ) : (
                <NewTextField
                  label={label}
                  placeholder={label}
                  error={!!error}
                  helperText={error?.message}
                  onChange={(e: any) => field.onChange(e)}
                />
              )
            }
            {...other}
          >
            {children}
          </Select>
        );
      }}
    />
  );
}
