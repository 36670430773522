import { useEffect, useState } from "react";
import { COLOR_LIST } from "./LabelColors";
import { FieldProps } from "../types/types";
import { ItemTable } from "../types/review";

export const useLabels = (fields: Array<any>) => {
  const [labelMap, setLabelMap] = useState<any>(null);

  useEffect(() => {
    if (fields && labelMap == null) {
      const labelMap = {};
      fields?.forEach((field: FieldProps) => {
        if (field?.itemsFields?.length > 0) {
          field.itemsFields?.forEach((item: { item: FieldProps[] }) => {
            item?.item?.forEach((i: FieldProps) => {
              labelMap[i.name] =
                COLOR_LIST[Math.floor(Math.random() * 12)].value;
            });
          });
        } else {
          labelMap[field.name] =
            COLOR_LIST[Math.floor(Math.random() * 12)].value;
        }
      });
      setLabelMap(labelMap);
    }
  }, [fields]);

  return labelMap;
};

interface getLabel {
  [key: string]: string;
}

export const getLabeColor = (fields: Array<any>): getLabel => {
  const labelMap = {};
  fields?.forEach((field: FieldProps) => {
    if (field?.itemsFields?.length > 0) {
      field.itemsFields?.forEach((item: { item: FieldProps[] }) => {
        item?.item?.forEach((i: FieldProps) => {
          labelMap[i.name] = COLOR_LIST[Math.floor(Math.random() * 12)].value;
          if (i.name.includes("%")) {
            let name = i.name.replace(" %", "Percentage");
            labelMap[name] = labelMap[i.name];
          }
          if (i.name.includes("/")) {
            let name = i.name.replace("/", "");
            labelMap[name] = labelMap[i.name];
          }
        });
      });
    } else if (
      field?.tableDefaultProperties &&
      field?.tableDefaultProperties?.length > 0
    ) {
      field.tableDefaultProperties?.forEach((item: string) => {
        labelMap[item] = COLOR_LIST[Math.floor(Math.random() * 12)].value;
      });
    } else {
      labelMap[field.name] = COLOR_LIST[Math.floor(Math.random() * 12)].value;
    }
  });
  return labelMap;
};

export const applyColorToLabel = (labelMap: any, fields: Array<any>) => {
  fields?.forEach((field: FieldProps, index: number) => {
    if (field?.itemsFields?.length > 0) {
      field.itemsFields?.forEach((item, idx: number) => {
        item?.item?.forEach((i: any, ind: number) => {
          i.fillColor = "light-gray";
          i.strokeColor = labelMap[i.name];
          i.preFillColor = labelMap[i.name] + "60";
          i.key = `fields.${index}.itemsFields.${idx}.item.${ind}`;
          if (i?.isFieldReviewRequired && i?.value != "" && i?.value != null) {
            i.confidence = 0.9;
          }
        });
      });
    } else {
      field.fillColor = "light-gray";
      field.strokeColor = labelMap[field.name];
      field.preFillColor = labelMap[field.name] + "60";
      field.key = `fields.${index}`;
      if (
        field?.isFieldReviewRequired &&
        field?.value != "" &&
        field?.value != null
      ) {
        field.confidence = 0.9;
      }
    }
  });
  return fields;
};

export const regenerateKeys = (fields: Array<FieldProps>) => {
  fields?.forEach((field: FieldProps, index: number) => {
    if (field?.itemsFields?.length >= 0) {
      field.itemsFields?.forEach((item, idx: number) => {
        item?.item?.forEach((i: any, ind: number) => {
          i.key = `fields.${index}.itemsFields.${idx}.item.${ind}`;
        });
      });
    }
    field.key = `fields.${index}`;
  });
  return fields;
};
export const regenerateFieldKeys = (
  fields: FieldProps,
  fieldIndex?: number
) => {
  fields.key = `fields.${fieldIndex}`;
  fields.itemsFields?.forEach((item, idx: number) => {
    item?.item?.forEach((i: any, ind: number) => {
      i.key = `fields.${fieldIndex}.itemsFields.${idx}.item.${ind}`;
    });
  });
  return fields;
};

export const generateLabels = (fields: Array<any>) => {
  let labelMap = {};
  if (fields) {
    const labelMap = {};
    fields?.forEach((field: FieldProps) => {
      if (field?.itemsFields?.length > 0) {
        field.itemsFields?.forEach((item: { item: FieldProps[] }) => {
          item?.item?.forEach((i: FieldProps) => {
            labelMap[i.name] = COLOR_LIST[Math.floor(Math.random() * 12)].value;
          });
        });
      } else {
        labelMap[field.name] = COLOR_LIST[Math.floor(Math.random() * 12)].value;
      }
    });
  }
  return labelMap;
};
