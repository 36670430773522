import { Box, Typography } from "@mui/material";
import { Popover } from "@mui/material";
const ApproversPopover = ({
  open,
  anchorEl,
  handlePopoverClose,
  approvers,
}: any) => {
  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: "none",
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Box sx={{ p: 1, display: "flex", flexDirection: "column", gap: 1 }}>
        {approvers?.map((approver: any) => (
          <Box
            sx={{
              bgcolor: "background.paper",
              borderRadius: 0.5,
              p: "1px",
              px: 0.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "text.greyLight",
                fontSize: "12px",
              }}
              variant="subtitle2"
            >
              {approver}
            </Typography>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};

export default ApproversPopover;
