export const suppliers = {
  suppliers: "Suppliers",
  supplier: "Supplier",
  supplierName: "Supplier Name",
  supplierEmail: "Supplier Email",
  supplierRegistrationNumber: "Supplier Registration Number",
  supplierAccountInfo: "Supplier Account Info",
  supplierAccountHolderName: "Supplier Account Holder Name",
  supplierIBAN: "Supplier IBAN",
  supplierURL: "Supplier URL",
  onboarding_status: "Onboarding status",
  tax_id: `Tax ID`,
  power_of_attorney: `Power of Attorney`,
  acceptance_of_terms_and_conditions: `Acceptance of Terms and Conditions`,
  legal_representative_info: `Legal representative information`,
  certificates: `Certificates`,
  bank_account_ownership: `Bank account ownership`,
  compliance_with_social_security: `Compliance with social security`,
  compliance_with_tax_administration: `Compliance with tax administration`,
  last_month_payment_installment_of_civil_liability_insurance_policy: `Last month payment installment of civil liability insurance policy`,
  goods_and_services: `Goods and services`,
  other_conditions: `Other conditions`,
};
