import { styled } from "@mui/material/styles";
export const RootStyle1 = styled("div")(({ theme }) => ({
  zIndex: 999,
  right: 0,
  display: "flex",
  cursor: "pointer",
  position: "fixed",
  alignItems: "center",
  top: theme.spacing(11.5),
  height: theme.spacing(5),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.15),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.grey[500_12],
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  transition: theme.transitions.create("opacity"),
  "&:hover": { opacity: 0.82 },
  textDecoration: "none",
  border: "0.2px solid #e7e7e7",
}));

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const globalStyles = {
  h1: { color: "grey" },
  "*::-webkit-scrollbar": {
    width: "0.8rem",
    height: "0.8rem",
    padding: "10px",
  },
  "*::-webkit-scrollbar-track": { backgroundColor: "#f1f1f1" },
  "*::-webkit-scrollbar-thumb": {
    backgroundColor: "#27446052",
    padding: "1px",
    width: "0.2rem",
    border: "5px solid #f1f1f1",
    cursor: "pointer",
  },
  "::-webkit-scrollbar-thumb": { borderRadius: "10px", width: "0.2rem" },
  "::-webkit-scrollbar-track": { borderRadius: "10px" },
};

export const dialogboxStyles = {
  zIndex: "10000",
  borderRadius: "30px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.40)",
  border: "1px solid #DEDEDE",
};
