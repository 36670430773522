import { store } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";

const useDefaultQuery = () => {
  const {
    org: { isTestingUser, enableDefaultProdEnvFilterForNonTestUser },
  } = store.getState()?.organization;
  const path =
    PATH_DASHBOARD.general.documents.root +
    (!isTestingUser && enableDefaultProdEnvFilterForNonTestUser
      ? "?fields.Environment=Production"
      : "");
  const getDefaultFilter = () => {
    if (!isTestingUser && enableDefaultProdEnvFilterForNonTestUser)
      return [
        {
          fieldName: "Environment",
          value: "Production",
        },
      ];
    return [];
  };

  const comparePaths = (path1: string | undefined, path2: string) => {
    if (!path1 || !path2) return false;
    const url1 = new URL(window.location.origin + path1);
    const url2 = new URL(window.location.origin + path2);
    let result = url1.pathname === url2.pathname;
    return result;
  };

  return { path, getDefaultFilter, comparePaths };
};
export default useDefaultQuery;
