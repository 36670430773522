import constants from "./production.json";
// API Gateway
export const API_GATEWAY = constants.API_GATEWAY;
export const CONSUMERAPIURL = constants.CONSUMERAPIURL;
// Toggles
export const ENABLE_COUNTRY_CODE = constants.ENABLE_COUNTRY_CODE;
export const EXTERNAL_IDENTITY_LOGIN = constants.EXTERNAL_IDENTITY_LOGIN;
export const TOGGLE_HUBSPOT = constants.TOGGLE_HUBSPOT;
export const ENABLE_REGISTER_PAGE_V2 = constants.ENABLE_REGISTER_PAGE_V2;
export const ENABLE_USER_ACCOUNT_STATUS = constants.ENABLE_USER_ACCOUNT_STATUS;
export const ENABLE_VIEW_PROFILE_PAGE_V2 =
  constants.ENABLE_VIEW_PROFILE_PAGE_V2;
export const ENABLE_INVITE_USER_V2 = constants.ENABLE_INVITE_USER_V2;
export const ENABLE_WEBHOOK = constants.ENABLE_WEBHOOK;
export const ENABLE_SEGMENT = constants.ENABLE_SEGMENT;
export const ENABLE_ORGANIZATION_SETTINGS =
  constants.ENABLE_ORGANIZATION_SETTINGS;
export const DISABLE_ONBOARDING_COMPONENT =
  constants.DISABLE_ONBOARDING_COMPONENT;
export const ENABLE_ORGANIZATION_CONFIGURATION =
  constants.ENABLE_ORGANIZATION_CONFIGURATION;
// Constants
export const TERMS_OF_SERVICE = constants.TERMS_OF_SERVICE;
export const PRIVACY_POLICY = constants.PRIVACY_POLICY;
export const INTERCOM_APP_ID = constants.INTERCOM_APP_ID;
export const INTERCOM_AP_BASE = constants.INTERCOM_AP_BASE;
export const ALLOWED_DOCUMENT_TYPES = constants.ALLOWED_DOCUMENT_TYPES;
export const HUBSPOT = constants.HUBSPOT;
export const ROLE_ENUM = constants.ROLE_ENUM;
export const STRIPE_PRICE_ID_PLANS = constants.STRIPE_PRICE_ID_PLANS;
export const STRIPE_PRICE_ID_ADDONS = constants.STRIPE_PRICE_ID_ADDONS;
export const MIXPANEL = constants.MIXPANEL;
export const SEGMENT = constants.SEGMENT;
export const STRIPE = constants.STRIPE;
export const FOLDERTYPE = constants.FOLDERTYPE;
export const ONE_DRIVE_CRED = constants.ONE_DRIVE_CRED;
export const ALLOWEDMIMETYPES = constants.ALLOWEDMIMETYPES;
export const EMAILFREQUENCY = constants.EMAILFREQUENCY;
export const G_DRIVE = constants.GOOGLE_CRED;
export const AGENT_URL = constants.AGENT_URL;
export const IS_AGENT = constants.IS_AGENT;
export const MOBILE_APP_DOWNLOAD_URL = constants.MOBILE_APP_DOWNLOAD_URL;
