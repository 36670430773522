import { Box, Typography, Button } from "@mui/material";
import WorkflowIllustrations from "src/assets/illustrations/WorkflowIllustrations";
import useLocales from "src/hooks/useLocales";

const EmptyWorkflows = ({ handleOpenAction, isfilterEmpty, disabled }) => {
  const { translate } = useLocales();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ mt: 2 }}>
          <WorkflowIllustrations />
        </Box>
        <Typography variant="body1" sx={{ mt: 2 }}>
          {isfilterEmpty
            ? translate(`No workflows found for the selected filters`)
            : translate(`You don't have any workflows yet`)}
        </Typography>

        {!isfilterEmpty && (
          <Button
            disabled={disabled}
            onClick={() => handleOpenAction("add")}
            variant="contained"
            size="medium"
            sx={{
              "&:hover": {
                color: "white",
              },
              mt: 2,
            }}
          >
            {translate("Add Workflow")}
          </Button>
        )}
      </Box>
    </>
  );
};

export default EmptyWorkflows;
