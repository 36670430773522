import { createContext, ReactNode, useEffect, useReducer } from "react";
// utils
import axios from "../utils/axios";
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from "../@types/auth";
import { API_GATEWAY } from "../constants/settings";
import { store } from "../redux/store";
import { LoginCred, LogoutCred, tokenCred } from "src/redux/slices/credentials";
import jwt_decode from "jwt-decode";
import {
  loginOrganization,
  logoutOrganization,
} from "src/redux/slices/organization";
import { resetLocalStorage } from "src/common/resetLocalStorage";

enum Types {
  Initial = "INITIALIZE",
  Login = "LOGIN",
  LoginWithExternalProvider = "LOGIN_WITH_EXTERNAL_PROVIDER",
  LoginImpersonate = "LOGIN_IMPERSONATE",
  LoginAfterRegister = "LOGIN_AFTER_REGISTER",
  Logout = "LOGOUT",
  Register = "REGISTER",
  RegisterContinue = "REGISTERCONTINUE",
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.LoginImpersonate]: {
    username: AuthUser;
  };
  [Types.LoginWithExternalProvider]: {
    user: AuthUser;
  };
  [Types.LoginAfterRegister]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
  [Types.RegisterContinue]: {
    user: AuthUser;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "LOGIN_IMPERSONATE":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.username,
      };
    case "LOGIN_WITH_EXTERNAL_PROVIDER":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case "REGISTERCONTINUE":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case "REGISTER":
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload.user,
      };
    case "LOGIN_AFTER_REGISTER":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  // const navigate = useNavigate();
  useEffect(() => {
    const initialize = async () => {
      const accessToken = store.getState()?.credential?.userCred.accessToken;
      try {
        if (accessToken) {
          // setSession(accessToken, refreshToken);
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: null,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const Login = async (username: string, password: string) => {
    const response = await axios.post(
      `${API_GATEWAY}/api/userservice/Account/login`,
      {
        username,
        password,
      }
    );
    const { accessToken, user } = response.data.session;
    let decodedToken = jwt_decode(accessToken);
    localStorage.setItem("company_id", response.data.organizationId);
    localStorage.setItem("profile", JSON.stringify(response.data.profile));
    localStorage.setItem(
      "discrepancyDetection",
      response?.data?.enableDiscrepancyDetection
    );
    localStorage.setItem("version", response.data?.version);
    store.dispatch(LoginCred(response.data.session, username));
    store.dispatch(tokenCred(decodedToken));
    store.dispatch(loginOrganization(response.data?.profile));

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const LoginImpersonate = async (data) => {
    const { organizationId, profile, session, username, version } = data;

    let decodedToken = jwt_decode(session?.accessToken);
    localStorage.setItem("company_id", organizationId);
    localStorage.setItem("profile", JSON.stringify(profile));
    localStorage.setItem(
      "discrepancyDetection",
      data?.enableDiscrepancyDetection
    );
    localStorage.setItem("version", version);
    store.dispatch(LoginCred(session, profile?.email));
    store.dispatch(tokenCred(decodedToken));
    store.dispatch(loginOrganization(profile));

    dispatch({
      type: Types.LoginImpersonate,
      payload: {
        username,
      },
    });
  };

  //Login with External Provider
  const LoginWithExternalProvider = async (response) => {
    const {
      session,
      username,
      organizationId,
      profile,
      enableDiscrepancyDetection,
    } = response.data;
    let user = session.firstName;
    let accessToken = session.accessToken;

    let decodedToken = jwt_decode(accessToken);
    localStorage.setItem("company_id", organizationId);
    localStorage.setItem("profile", JSON.stringify(profile));
    localStorage.setItem("discrepancyDetection", enableDiscrepancyDetection);
    localStorage.setItem("version", response.data?.version);
    store.dispatch(LoginCred(response.data.session, username));
    store.dispatch(loginOrganization(response.data?.profile));
    store.dispatch(tokenCred(decodedToken));

    dispatch({
      type: Types.LoginWithExternalProvider,
      payload: {
        user,
      },
    });
  };

  // Login After Register
  const LoginAfterRegister = async (username: string, password: string) => {
    const response = await axios.post(
      `${API_GATEWAY}/api/userservice/Account/login`,
      {
        username,
        password,
      }
    );
    let LoginResponse = response.data;
    const { session, profile, organizationId, version } = response.data;
    let user = session.user;
    let decodedToken = jwt_decode(session.accessToken);

    localStorage.setItem("company_id", organizationId);
    localStorage.setItem("profile", JSON.stringify(profile));
    localStorage.setItem("version", response.data?.version);
    localStorage.setItem(
      "discrepancyDetection",
      response?.data?.enableDiscrepancyDetection
    );
    await store.dispatch(LoginCred(session, username));
    await store.dispatch(tokenCred(decodedToken));
    await store.dispatch(loginOrganization(profile));
    dispatch({
      type: Types.LoginAfterRegister,
      payload: {
        user,
      },
    });
    return LoginResponse;
  };

  // Pre-Register
  const register = async (SignupEmail: string) => {
    const response = await axios.post(
      `${API_GATEWAY}/api/usermanagement/account/newsignupemailrequest`,
      {
        SignupEmail,
      }
    );
    const { user } = response.data;

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const registerContinue = async (body) => {
    const response = await axios.post(
      `${API_GATEWAY}/api/webapi/v1/UserManagement`,
      body
    );

    const { user, accessToken } = response.data.data;

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    resetLocalStorage();
    store.dispatch(LogoutCred());
    store.dispatch(logoutOrganization());
    // setSession(null, null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        Login,
        LoginWithExternalProvider,
        LoginAfterRegister,
        LoginImpersonate,
        logout,
        register,
        registerContinue,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
