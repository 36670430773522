import { Box, SvgIconProps } from "@mui/material";
import { useTheme } from "@mui/material";

const Multiply = ({
  height = 24,
  width = 24,
  color = "error",
  customColor,
}: SvgIconProps & {
  customColor?: string;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: width,
        height: height,
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 4L20 20M4 20L20 4"
          stroke={customColor ?? theme.palette?.[color]?.dark}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default Multiply;
