import { useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { SvgIconProps } from "@mui/material";

const EyeCloseIcon = ({
  color = "primary",
  height = 20,
  width = 20,
  sx = {},
  customColor,
}: SvgIconProps & {
  customColor?: string;
}) => {
  const theme = useTheme();
  return (
    <Box sx={sx}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5098 4L4 19.9375M13.334 9.5C13.334 10.0523 12.8863 10.5 12.334 10.5C11.7817 10.5 11.334 10.0523 11.334 9.5C11.334 8.94772 11.7817 8.5 12.334 8.5C12.8863 8.5 13.334 8.94772 13.334 9.5ZM21.9896 11.8307L19.1447 14.6639C15.1987 18.5938 8.8013 18.5938 4.85525 14.6639L2.01038 11.8307C1.99654 11.8168 1.99654 11.7945 2.01038 11.7807L4.85525 8.94744C8.8013 5.01752 15.1987 5.01752 19.1447 8.94744L21.9896 11.7807C22.0035 11.7945 22.0035 11.8171 21.9896 11.8307ZM12.9716 6.03216C12.8802 6.02468 12.7891 6.0172 12.6977 6.01265C12.5362 6.00486 12.3742 5.99971 12.2124 6.00001C12.1825 6.00001 12.1526 6.00175 12.1228 6.00205C11.9923 6.00349 11.8621 6.0078 11.7317 6.01438C11.6377 6.01953 11.544 6.02648 11.4503 6.0345C11.4046 6.03821 11.3592 6.04228 11.3135 6.04659C10.1034 6.16874 8.91454 6.53601 7.82489 7.14522C7.48008 7.81992 7.2832 8.58351 7.2832 9.39382C7.2838 12.1221 9.4893 14.3333 12.2101 14.3333C14.9308 14.3333 17.1364 12.122 17.1364 9.39411C17.1364 8.57193 16.9329 7.79866 16.5783 7.11677C15.5001 6.52307 14.3262 6.16502 13.1319 6.04576C13.0785 6.04061 13.025 6.03648 12.9716 6.03216Z"
          stroke={customColor ?? "#657482"}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default EyeCloseIcon;
