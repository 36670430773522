import { useState } from "react";

export type TFilterState = {
  name: string;
  approver: string | null;
  allowReviewDocument: boolean | string | null;
  page: number;
  record: number;
};

const useWorkflowFilter = ({ record, page }) => {
  const [filterState, setFilterState] = useState<TFilterState>({
    name: "",
    approver: "",
    allowReviewDocument: "",
    page: page ?? 1,
    record: record ?? 10,
  });

  return {
    filterState,
    setFilterState,
  };
};
export default useWorkflowFilter;
