import React from "react";
import { SvgIconProps } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

const XMLIcon = ({
  color = "secondary",
  width = 24,
  height = 24,
  customColor,
  sx,
  disableDimension = false,
}: SvgIconProps & {
  customColor?: string;
  disableDimension?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...(disableDimension
          ? {}
          : {
              height: height,
              width: width,
            }),
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 3v4a1 1 0 0 0 1 1h4"
          stroke={customColor ?? theme.palette[color]?.main}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4"
          stroke={customColor ?? theme.palette[color]?.main}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 15l4 6"
          stroke={customColor ?? theme.palette[color]?.main}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 21l4 -6"
          stroke={customColor ?? theme.palette[color]?.main}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 15v6h3"
          stroke={customColor ?? theme.palette[color]?.main}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 21v-6l2.5 3l2.5 -3v6"
          stroke={customColor ?? theme.palette[color]?.main}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

XMLIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  sx: PropTypes.object,
};

XMLIcon.defaultProps = {
  color: "secondary",
  width: 24,
  height: 24,
};

export default XMLIcon;
