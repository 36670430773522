import React from "react";
import { SvgIconProps } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

const DeleteIcon = ({
  color = "secondary",
  width = 24,
  height = 24,
  customColor,
  sx,
  disableDimension = false,
}: SvgIconProps & {
  customColor?: string;
  disableDimension?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...(disableDimension
          ? {}
          : {
              height: height,
              width: width,
            }),
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 4.58146H21.0643M9 8.12891L9.96773 18.4514M14.9345 8.12891L13.9668 18.4514M15.4087 22.0006H8.403C7.2785 22.0006 6.3369 21.1483 6.22493 20.0294L4.93462 4.58146H19.128L17.5868 20.0294C17.4748 21.1484 16.5333 22.0006 15.4087 22.0006ZM15.9033 4.58062H8.16144C8.16144 3.1555 9.31695 2 10.7421 2H13.3227C14.7478 2 15.9033 3.1555 15.9033 4.58062Z"
          stroke={customColor ?? theme.palette[color]?.main}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

DeleteIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  sx: PropTypes.object,
};

DeleteIcon.defaultProps = {
  color: "secondary",
  width: 24,
  height: 24,
};

export default DeleteIcon;
