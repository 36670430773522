import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';

type ConfigurationState = {
  externalIdentities: any;
};

const initialState: ConfigurationState = {
  externalIdentities: {}
};

const slice = createSlice({
  name: 'ExternalIdentities',
  initialState,
  reducers: {
    externalIdentitiesAction(state, action) {
      state.externalIdentities = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { externalIdentitiesAction } = slice.actions;

export function setExternalIdentities(data: any) {
  return async () => {
    dispatch(externalIdentitiesAction(data));
  };
}