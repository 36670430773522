import { Box } from "@mui/material";

const FileIcon = ({
  height = 24,
  width = 24,
  color = "#657482",
  sx = {},
  disableDimention = false,
}) => {
  return (
    <Box
      sx={{
        ...sx,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...(disableDimention
          ? {}
          : {
              height: height,
              width: width,
            }),
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.7258 21.1801H4.38862C3.95084 21.1801 3.58122 20.8434 3.52442 20.3932L2.00344 8.27393C1.96924 7.97497 2.19437 7.71236 2.4847 7.71236H21.5151C21.8058 7.71236 22.0313 7.97568 21.9964 8.27497L20.5909 20.3845C20.538 20.8383 20.1668 21.1801 19.7258 21.1801Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M20.6503 7.7137L20.7103 6.4171C20.7103 5.45954 19.9667 4.68346 19.0493 4.68346H10.6828C10.0993 4.68346 9.55861 4.36396 9.25861 3.84173C8.95827 3.31952 8.41761 3 7.83441 3H4.95214C4.03439 3 3.29084 3.77608 3.29084 4.73363V7.7137H20.6503Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </svg>
    </Box>
  );
};

export default FileIcon;
