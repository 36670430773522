import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MenuItem, Typography, alpha, useTheme } from "@mui/material";
import useLocales from "src/hooks/useLocales";
import { useRef, useState } from "react";
import EditDataDescription from "./editDataDescription";
import { DataDescriptionProps } from "../../types/types";
import EditIcon from "src/assets/icon/edit";
import DeleteIcon from "./../../../../assets/icon/delete";
import { TableMoreMenu } from "src/components/table";
interface RefHandler {
  handleOpen: () => void;
}

const DataDescription = (props: DataDescriptionProps) => {
  const {
    title,
    bgColor,
    description,
    isHighlighted,
    isEditable = true,
    item,
    handleMouseScroll,
    removeArrowAxis,
    setFieldValue,
    handleDeleteDialog,
    getFieldValue,
  } = props;

  const [edit, setEdit] = useState(false);
  const { translate } = useLocales();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setEdit(true);
    handleClose();
  };

  const getTranslatedFields = (title) => {
    let result = translate(`review_screen.review_fields.${title}`);
    if (result.indexOf("review_screen") == -1) {
      return result;
    }
    return title;
  };

  const optionDialogRef = useRef<RefHandler>(null);
  return (
    <>
      <TableRow
        id={item.key}
        key={item.key}
        onClick={
          isEditable && anchorEl === null
            ? (e) => {
                handleMouseScroll(item);
              }
            : undefined
        }
        onMouseLeave={
          isEditable && anchorEl === null
            ? () => {
                removeArrowAxis();
              }
            : undefined
        }
        sx={{
          display: " block",
          my: 0.5,
          ...(item?.isFieldReviewRequired
            ? {
                backgroundColor: theme.palette?.error.light,
              }
            : {
                bgcolor:
                  isHighlighted && !edit && bgColor
                    ? alpha(bgColor, 0.3)
                    : "none",
              }),
          cursor: "pointer",
          border: "none",
          "&:hover": {
            backgroundColor: item?.isFieldReviewRequired
              ? alpha(theme.palette?.error.dark, 0.1)
              : !(isHighlighted || edit)
              ? "background.neutral"
              : "none",
          },
          borderRadius: "8px",
        }}
      >
        {edit ? (
          <EditDataDescription
            setFieldValue={setFieldValue}
            name={item.key}
            setEdit={setEdit}
            bgColor={bgColor}
            title={title}
            item={item}
            getFieldValue={getFieldValue}
            optionRef={optionDialogRef}
          />
        ) : (
          <>
            <TableCell sx={{ width: "3%", p: 0.5 }}>
              <div
                style={{
                  height: "15px",
                  width: "15px",
                  background: bgColor,
                  borderRadius: "50%",
                  marginRight: "0.2rem",
                }}
              ></div>
            </TableCell>
            <TableCell
              sx={{ width: "40%", paddingLeft: 0.5, textAlign: "start" }}
            >
              <Typography
                style={{
                  fontSize: "11px",
                  wordBreak: " break-word" as "break-word",
                }}
              >
                {getTranslatedFields(title)}
              </Typography>
            </TableCell>
            <TableCell sx={{ width: "55%", px: 0.5 }}>
              <Typography
                style={{
                  wordBreak: " break-word" as "break-word",
                  fontSize: "11px",
                }}
              >
                {description}
              </Typography>
            </TableCell>
            {isEditable && (
              <TableCell sx={{ width: "5%", pr: 1 }}>
                <>
                  <TableMoreMenu
                    open={anchorEl}
                    onOpen={handleClick}
                    onClose={handleClose}
                    width={150}
                    actions={
                      <>
                        <MenuItem
                          onClick={() => {
                            removeArrowAxis();
                            handleEdit();
                            setTimeout(() => {
                              optionDialogRef?.current?.handleOpen();
                            }, 50);
                            handleClose();
                          }}
                        >
                          <EditIcon
                            height={20}
                            width={20}
                            sx={{
                              height: "auto",
                              width: "auto",
                            }}
                            customColor={theme.palette?.action.active}
                          />
                          {translate(`edit`)}
                        </MenuItem>
                        <MenuItem
                          disabled={item.key.split(".")?.length > 2}
                          onClick={() => {
                            removeArrowAxis();
                            handleDeleteDialog(item);
                            handleClose();
                          }}
                        >
                          <DeleteIcon
                            height={14}
                            width={14}
                            color="primary"
                            disableDimension
                          />
                          {translate("delete")}
                        </MenuItem>
                      </>
                    }
                  />
                </>
              </TableCell>
            )}
          </>
        )}
      </TableRow>
    </>
  );
};
export default DataDescription;
