import { Theme } from "@mui/material/styles";
import ChevronDown from "src/assets/icon/chevron/ChevronDown";

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: () => {
          return (
            <ChevronDown
              height={24}
              width={24}
              customColor={"#657482"}
              sx={{
                position: "absolute",
                right: "6px",
                top: "calc(50% - 12px)",
                pointerEvents: "none",
                color: "#657482",
              }}
            />
          );
        },
      },
    },
  };
}
