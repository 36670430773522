import { styled } from "@mui/material/styles";
import { List, Box, ListSubheader } from "@mui/material";
import { NavSectionProps } from "../type";
import { NavListRoot } from "./NavList";
import { Divider } from "@mui/material";

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(0),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((group, index) => (
        <List key={index} disablePadding>
          <Divider variant="middle" className="mb-1" />
          {group.items.map((list, i) => {
            if (list.role) {
              return (
                <NavListRoot
                  key={list.title + "_" + list.path + "_" + i}
                  list={list}
                  isCollapse={isCollapse}
                />
              );
            }
          })}
        </List>
      ))}
    </Box>
  );
}
