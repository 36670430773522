import createAvatar from "../utils/createAvatar";
import Avatar, { Props as AvatarProps } from "./Avatar";

export default function MyAvatar({
  user,
  ...other
}: AvatarProps & { user?: any }) {
  return (
    <Avatar
      src={user?.photoURL}
      alt={user?.firstName}
      customColor={
        user?.photoURL ? "default" : createAvatar(user?.firstName).customColor
      }
      {...other}
    >
      {createAvatar(user?.firstName).name}
    </Avatar>
  );
}
