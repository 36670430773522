import { useTheme } from "@mui/material";
import { SvgIconProps } from "@mui/material";
import React from "react";

export const RefreshIcon = ({
  color = "primary",
  height = 14,
  width = 14,
  customColor,
}: SvgIconProps & {
  customColor?: string;
}) => {
  const theme = useTheme();
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.9109 13.3021C21.6749 15.1017 20.9535 16.803 19.824 18.2238C18.6946 19.6445 17.1997 20.731 15.4997 21.3667C13.7997 22.0024 11.9587 22.1633 10.1742 21.8323C8.38967 21.5012 6.72893 20.6906 5.37008 19.4874C4.01123 18.2842 3.00553 16.7339 2.46085 15.0026C1.91616 13.2712 1.85304 11.4243 2.27824 9.65986C2.70344 7.8954 3.60094 6.27997 4.87446 4.98682C6.14799 3.69367 7.74951 2.77158 9.50726 2.31944C14.38 1.06971 19.4239 3.57792 21.286 8.25193M21.9957 2.00488V8.25355H15.7471"
          stroke={customColor ?? theme.palette?.[color]?.light}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
