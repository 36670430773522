import { SvgIconProps } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

const EditIcon = ({
  color = "secondary",
  width = 24,
  height = 24,
  customColor,
  disableDimension = false,
  sx,
}: SvgIconProps & {
  customColor?: string;
  disableDimension?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...(disableDimension
          ? {}
          : {
              height: height,
              width: width,
            }),
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.2394 22H15.7346M21.2332 6.42249L7.4952 20.1605L2 21.9922L3.83173 16.497L17.5697 2.75902C18.5818 1.74699 20.2212 1.74699 21.2332 2.75902C22.2452 3.77106 22.2452 5.41046 21.2332 6.42249ZM4.28743 16.0388L16.1936 4.13263L19.857 7.79606L7.95086 19.7022L4.28743 16.0388Z"
          stroke={customColor ?? theme.palette[color].main}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

EditIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  sx: PropTypes.object,
};

EditIcon.defaultProps = {
  color: "secondary",
  width: 24,
  height: 24,
};

export default EditIcon;
