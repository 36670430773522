import { SvgIconProps } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

const SearchPlusIcon = ({
  color = "secondary",
  width = 24,
  height = 24,
  sx,
}: SvgIconProps) => {
  const theme = useTheme();
  return (
    <Box sx={sx}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.48334 11.0331H14.58M11.0315 14.5817V7.48497M20.0643 11.0323C20.0643 14.259 18.3428 17.2409 15.5482 18.8545C12.7536 20.468 9.31056 20.4679 6.51608 18.8545C3.72159 17.241 2 14.2593 2 11.0323C2 7.80522 3.7215 4.82364 6.51608 3.21011C9.31066 1.59658 12.7537 1.59668 15.5482 3.21011C18.3427 4.82354 20.0643 7.80522 20.0643 11.0323ZM18.1063 16.645C17.676 17.187 17.185 17.6776 16.6434 18.1079L19.8048 21.2696C20.2088 21.6736 20.8638 21.6736 21.2677 21.2696C21.6717 20.8657 21.6717 20.2107 21.2678 19.8068L18.1063 16.645Z"
          stroke={theme.palette[color].light}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

SearchPlusIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  sx: PropTypes.object,
};

SearchPlusIcon.defaultProps = {
  color: "secondary",
  width: 24,
  height: 24,
};

export default SearchPlusIcon;
