import { CalendarIcon } from "src/assets/icon/calendar";
import { BootstrapFormInput, BootstrapInput } from "../form/TextField";
import { DatePicker } from "@mui/lab";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { useTheme } from "@mui/material";

type DatePickerProps = {
  label?: string;
  value?: any;
  onChange(date: any, keyboardInputValue?: string | undefined): void;
  error?: boolean | undefined;
  disabled?: boolean;
};
const MyDatePickerField = ({
  label,
  value,
  onChange,
  disabled,
  error,
}: DatePickerProps) => {
  const theme = useTheme();
  return (
    <>
      <FormControl fullWidth placeholder="select">
        {label && (
          <InputLabel
            htmlFor={"country-select-demo"}
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              marginBottom: 1,
              transform: "translate(0, 1.5px) scale(0.75)",
              color: theme.palette.action.active,
            }}
          >
            {label}
          </InputLabel>
        )}
        <DatePicker
          label={label}
          value={value}
          disabled={disabled}
          onChange={onChange}
          InputAdornmentProps={{
            sx: {
              position: "absolute",
              right: 0,
              padding: "9px",
            },
          }}
          components={{ OpenPickerIcon: CalendarIcon }}
          renderInput={(params_) => {
            let { margin, onKeyDown, onKeyUp, InputProps, ...params } = params_;
            return (
              <BootstrapInput
                endAdornment={InputProps?.endAdornment}
                {...params}
                ref={params.inputRef}
                placeholder="dkfncj"
                fullWidth
                error={error}
              />
            );
          }}
        />
      </FormControl>
    </>
  );
};
export const MyDatePickerFormField = ({
  label,
  value,
  onChange,
  disabled,
  error,
}: DatePickerProps) => {
  const theme = useTheme();
  return (
    <>
      <FormControl fullWidth placeholder="select">
        {label && (
          <InputLabel
            htmlFor={"country-select-demo"}
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              marginBottom: 1,
              transform: "translate(0, 1.5px) scale(0.75)",
              color: theme.palette.action.active,
            }}
          >
            {label}
          </InputLabel>
        )}
        <DatePicker
          label={label}
          value={value}
          disabled={disabled}
          onChange={onChange}
          InputAdornmentProps={{
            sx: {
              position: "absolute",
              right: 0,
              padding: "9px",
            },
          }}
          components={{ OpenPickerIcon: CalendarIcon }}
          renderInput={(params_) => {
            let { margin, onKeyDown, onKeyUp, InputProps, ...params } = params_;
            return (
              <BootstrapFormInput
                endAdornment={InputProps?.endAdornment}
                {...params}
                ref={params.inputRef}
                placeholder="dkfncj"
                fullWidth
                error={error}
              />
            );
          }}
        />
      </FormControl>
    </>
  );
};

export default MyDatePickerField;
