import React from "react";

const UsersIcon = ({ height = 24, width = 24, color = "#657482" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 22L2 18.381C2 16.1718 3.79086 14.381 6 14.381H13.2381C15.4472 14.381 17.2381 16.1718 17.2381 18.381V22M21.9993 22V17.2381C21.9993 15.6601 20.7202 14.381 19.1422 14.381M15.3327 2C17.9626 2 20.0946 4.13198 20.0946 6.76191C20.0946 8.89412 18.6932 10.699 16.7613 11.3058M14.381 6.7619C14.381 9.39183 12.249 11.5238 9.61905 11.5238C6.98912 11.5238 4.85714 9.39183 4.85714 6.7619C4.85714 4.13198 6.98912 2 9.61905 2C12.249 2 14.381 4.13198 14.381 6.7619Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UsersIcon;
