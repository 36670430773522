import { CalendarIcon } from "src/assets/icon/calendar";
import { BootstrapInput } from "../form/TextField";
import { DatePicker } from "@mui/lab";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { useTheme } from "@mui/material";
import { IconButton } from "@mui/material";
import ChevronLeftIcon from "src/assets/icon/chevron/left";
import ChevronRightIcon from "src/assets/icon/chevron/right";
import { Box } from "@mui/material";

type DatePickerProps = {
  label?: string;
  value?: any;
  onChange(date: any, keyboardInputValue?: string | undefined): void;
  error?: boolean | undefined;
  disabled?: boolean;
  sx?: any;
};
const MyDatePicker = ({
  label,
  value,
  onChange,
  disabled,
  error,
  sx,
}: DatePickerProps) => {
  const theme = useTheme();
  const { palette } = theme;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          alignItems: "flex-start",
          p: 0,
          gap: "4px",
        }}
      >
        {label && (
          <InputLabel
            htmlFor={"country-select-demo"}
            sx={{
              fontSize: "12px",
              fontWeight: 700,
              // transform: "translate(0, 1.5px) scale(0.75)",
              color: theme.palette.action.active,
            }}
          >
            {label}
          </InputLabel>
        )}
        <DatePicker
          label={label}
          value={value}
          disabled={disabled}
          onChange={onChange}
          InputAdornmentProps={{
            sx: {
              position: "absolute",
              right: 0,
              padding: "9px",
            },
          }}
          components={{
            OpenPickerIcon: CalendarIcon,
            LeftArrowButton: ({ ...props }) => (
              <IconButton
                size="small"
                sx={{
                  height: "30px",
                  width: "30px",
                  color: palette?.grey["400"],
                  cursor: "pointer",
                  "&:hover": { color: "black" },
                  "&:disabled": {
                    color: palette?.grey["400"],
                    bgcolor: palette?.grey["300"],
                  },
                }}
                {...props}
              >
                <ChevronLeftIcon height={20} width={20} />
              </IconButton>
            ),
            RightArrowButton: ({ ...props }) => (
              <IconButton
                size="small"
                sx={{
                  height: "30px",
                  width: "30px",
                  color: palette?.grey["400"],
                  cursor: "pointer",
                  "&:hover": { color: "black" },
                  "&:disabled": {
                    color: palette?.grey["400"],
                    bgcolor: palette?.grey["300"],
                  },
                }}
                {...props}
              >
                <ChevronRightIcon height={20} width={20} />
              </IconButton>
            ),
          }}
          renderInput={(params_) => {
            let {
              margin,
              onKeyDown,
              onKeyUp,
              InputProps,
              placeholder,
              ...params
            } = params_;
            return (
              <BootstrapInput
                endAdornment={InputProps?.endAdornment}
                {...params}
                ref={params.inputRef}
                placeholder={placeholder}
                fullWidth
                error={error}
                sx={{
                  "& .MuiInputBase-input": {
                    ...(error && {
                      backgroundColor: theme.palette.error.light,
                      borderColor: theme.palette.error.dark,
                      color: theme.palette.error.dark,
                      "&::placeholder": {
                        opacity: 1,
                        color: theme.palette.error.dark,
                      },
                      "&:focus": {
                        borderColor: theme.palette.error.dark,
                        boxShadow: `0 0 0 0.01rem ${theme.palette.error.dark}`,
                      },
                      // ...sx,
                    }),
                    // ...(startAdornment && {
                    //   padding: "5px 26px 5px 32px",
                    // }),
                    // ...sx,
                  },
                }}
                {...params}
              />
            );
          }}
        />
      </Box>
    </>
  );
};

export default MyDatePicker;
