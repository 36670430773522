export const resetLocalStorage = () => {
  localStorage.removeItem("customLogo");
  localStorage.removeItem("company_id");
  localStorage.removeItem("invitedToTeam");
  localStorage.removeItem("externalProviderId");
  localStorage.removeItem("signUpMethod");
  localStorage.removeItem("profile");
  localStorage.removeItem("discrepancyDetection");
  localStorage.removeItem("version");
};
