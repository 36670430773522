import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

// ----------------------------------------------------------------------
type CartState = { 
    selectedProduct: any;
    selectedAddOns: any,
    concatProdAddons: any,
    isSupport: boolean,
    isCheckout: boolean,
    selectedProductID: any[];
    userId: string;
}
type CredState = {
    error: Error | string | null;
    isLoading: boolean;
    cartState: CartState[];
};
const initialCartState: CartState = {
    selectedProduct: {},
    selectedAddOns: [],
    concatProdAddons: [],
    isSupport: false,
    isCheckout: false,
    selectedProductID: [],
    userId: ""
}
const initialState: CredState = {
    isLoading: false,
    error: null,
    cartState: []
};

const slice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        cartActionPlans(state, action) {
            let [userId,product ] = action.payload
            let cartState = state.cartState.find((item) => item.userId === userId) || {...initialCartState, userId}
            cartState.selectedAddOns = []
            cartState.selectedProduct = product
            cartState.concatProdAddons = [product]
            cartState.selectedProductID = [{ Id: product.productId, Name: product.subscription , _id:product.id }]
            let filteredList = state.cartState.filter((item) => item.userId !== userId)
            state.cartState = [...filteredList, cartState]
        },
 
        cartActionAddons(state, action) {
            let [userId, product ] = action.payload
            let cartState = state.cartState.find((item) => item.userId === userId) || {...initialCartState, userId}
            const isEmptyCart = cartState.selectedAddOns.length === 0;

            if (isEmptyCart) {
                cartState.selectedAddOns = [action.payload]
                cartState.concatProdAddons = [...cartState.concatProdAddons, product]
                cartState.selectedProductID = [...cartState.selectedProductID, { Id: product.productId, Name: product.subscription ,_id: product.id }]
            } else {
                const check = cartState.selectedAddOns.find(pr => pr.id === product.id)
                if (!check) {
                    cartState.selectedAddOns = [...cartState.selectedAddOns, product]
                    cartState.concatProdAddons = [...cartState.concatProdAddons, product]
                    cartState.selectedProductID = [...cartState.selectedProductID, { Id: product.productId, Name: product.subscription ,_id:product.id }]
                }
            }
            let filteredList = state.cartState.filter((item) => item.userId !== userId)
            state.cartState = [...filteredList, cartState]
        },

        deleteIndCartAction(state, action) {
            let [userId, id ] = action.payload
            let cartState = state.cartState.find((item) => item.userId === userId) || {...initialCartState, userId}
            const updateConcatProdAddons = cartState.concatProdAddons.filter((item) => item.id !== id);
            cartState.concatProdAddons = updateConcatProdAddons;

            const updateSelectedAddOns = cartState.selectedAddOns.filter((item) => item.id !== id);
            cartState.selectedAddOns = updateSelectedAddOns;

            const updateSelectedProductID = cartState.selectedProductID.filter((item) => item._id !== id);
            cartState.selectedProductID = updateSelectedProductID
            let filteredList = state.cartState.filter((item) => item.userId !== userId)
            state.cartState = [...filteredList, cartState]
        },

        supportAction(state, action) {
            let [userId, bool ] = action.payload
            let cartState = state.cartState.find((item) => item.userId === userId) || {...initialCartState, userId}
            cartState.isSupport = bool
            let filteredList = state.cartState.filter((item) => item.userId !== userId)
            state.cartState = [...filteredList, cartState]
        },

        clearCartAction(state, action) {
            let [userId] = action.payload
            let cartState = state.cartState.find((item) => item.userId === userId) || {...initialCartState, userId}
            cartState.selectedProduct = {}
            cartState.selectedAddOns = []
            cartState.concatProdAddons = []
            cartState.selectedProductID = []
            let filteredList = state.cartState.filter((item) => item.userId !== userId)
            state.cartState = [...filteredList, cartState]
        }
        ,
        checkoutAction(state, action) {
            let [userId, bool] = action.payload
            let cartState = state.cartState.find((item) => item.userId === userId) || {...initialCartState, userId}
            cartState.isCheckout = bool
            let filteredList = state.cartState.filter((item) => item.userId !== userId)
            state.cartState = [...filteredList, cartState]
        }
    },
});


// Reducer
export default slice.reducer;

// Actions
export const { cartActionPlans, cartActionAddons, clearCartAction, deleteIndCartAction, supportAction, checkoutAction } = slice.actions;

export function AddPlan(product: any, userId: string) {
    return async () => {
        dispatch(cartActionPlans([userId,product]));
    };
}

export function AddAddOns(product: any, userId: string) {
    return async () => {
        dispatch(cartActionAddons([userId,product]));
    };
}
export function deleteIndCart(ProductId: number, userId: string) {
    return async () => {
        if (ProductId < 1.9) {
            dispatch(clearCartAction([userId]))
        } else {
            dispatch(deleteIndCartAction([userId,ProductId]));
        }
    };
}
export function clearCart(userId: string) {
    return async () => {
        dispatch(clearCartAction([userId]));
    };
}
export function suportToggle(bool: boolean, userId: string) {
    return async () => {
        dispatch(supportAction([userId,bool]));
    };
}
export function checkoutToggle(bool: boolean, userId: string) {
    return async () => {
        dispatch(checkoutAction([userId,bool]));
    };
}

