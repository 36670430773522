import useLocales from "src/hooks/useLocales";
import { Container, Button, Box, Typography } from "@mui/material";
import CustomCardSkeleton from "./CustomCard";
import { Skeleton } from "@mui/material";
import { Breadcrumb } from "react-bootstrap";
import BreadCrumbSkeleton from "src/components/skeleton/BreadCrumbSkeleton";

const WorkflowsSkeleton = ({ writePermission }) => {
  const { translate } = useLocales();
  const skeleton = ["", "", "", "", "", "", ""];

  return (
    <>
      <Container maxWidth={"lg"}>
        <BreadCrumbSkeleton links={[translate("Dashboard")]} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
            flexWrap: "wrap",
            direction: "row",
          }}
        >
          <Skeleton>
            <Typography variant="h3" sx={{ mb: 2 }}>
              {translate("Workflow settings")}
            </Typography>
          </Skeleton>
          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <Skeleton>
              <Button variant="outlined" color="primary" onClick={() => {}}>
                {translate("View all documents")}
              </Button>
            </Skeleton>
            <Skeleton>
              <Button variant="contained" color="primary" onClick={() => {}}>
                {translate("New workflow")}
              </Button>
            </Skeleton>
          </Box>
        </Box>
        {writePermission && (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexWrap: "wrap",
              pb: 2,
              direction: "row",
            }}
          >
            {skeleton?.map((item, i) => (
              <CustomCardSkeleton key={"skeleton" + i} />
            ))}
          </Box>
        )}
      </Container>
    </>
  );
};

export default WorkflowsSkeleton;
