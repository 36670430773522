import { INTERCOM_APP_ID, INTERCOM_AP_BASE } from "./constants/settings"


export const bootIntercomm = (name: string, email: string) => {
    window.Intercom('boot', {
       api_base: INTERCOM_AP_BASE,
       app_id: INTERCOM_APP_ID,
       name: name, 
       email: email,
       created_at: Date.now(),
    });
}

export const shutDownIntercomm = () => {
    window.Intercom('shutdown');
}