import { useSelector } from "react-redux";

const usePermissions = () => {
  let permissions = useSelector(
    (state: any) => state.credential.userTokenDecode.Permissions
  );
  let permissionsArr: any[] = [];
  try {
    if (permissions) {
      permissionsArr = permissions.split(",");
    } else {
    }
  } catch (error) {
    console.log("error", error);
  }
  const permissionHandle = (permission: string[]) => {
    let checkPermissions = permission.some((r) => permissionsArr.includes(r));
    return checkPermissions;
  };

  return {
    permissionHandle,
  };
};
export default usePermissions;
