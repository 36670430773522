import jwt_decode from "jwt-decode";
export function GetCookie(name) {
  name = `${name}=`;
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}

export function SetCookie(name, value, expiry) {
  let t = new Date();
  if (name === "expiresIn" && (expiry != undefined || expiry != ""))
    document.cookie = `expiresIn=${new Date(
      t.setSeconds(t.getSeconds() + expiry)
    ).toUTCString()}`;
  else {
    document.cookie = `${name}=${value};path=/`;
  }
}

export function SetGeneralCookie(name, value, expiry) {
  let expires = expiry === undefined ? '' : `expires=${new Date(expiry).toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

export function isTokenValid() {
  let accessToken = GetCookie("accessToken");
  if (typeof accessToken === "undefined") {
    return false;
  } else {
    let decodedToken = jwt_decode(accessToken);
    let currentDate = new Date();
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      // DeleteCookie("accessToken");
      return false;
    }
    return true;
  }
}

export function DeleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
