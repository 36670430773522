import { useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { SvgIconProps } from "@mui/material";
import React from "react";

export const LinkIcon = ({
  color = "action",
  height = 24,
  width = 24,
}: SvgIconProps) => {
  const theme = useTheme();
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.25063 15.7514L15.7514 8.25063M10.7509 4.50064L11.3297 3.83057C12.5021 2.65836 14.0921 1.99988 15.75 2C17.4078 2.00012 18.9978 2.65882 20.17 3.83119C21.3422 5.00357 22.0007 6.59359 22.0005 8.25146C22.0004 9.90933 21.3417 11.4993 20.1693 12.6715L19.5018 13.2515M13.2513 19.5018L12.755 20.1693C11.569 21.3422 9.96822 22 8.30019 22C6.63216 22 5.03142 21.3422 3.84536 20.1693C3.26075 19.5913 2.79663 18.903 2.47986 18.1443C2.1631 17.3856 2 16.5717 2 15.7495C2 14.9274 2.1631 14.1134 2.47986 13.3547C2.79663 12.596 3.26075 11.9078 3.84536 11.3297L4.50043 10.7509"
          stroke={theme.palette?.[color]?.active}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};
