import { useTheme } from "@mui/material";
import { Box } from "@mui/material";

type MyHelperTextProps = {
  helperText?: string;
  sx?: any;
};
const MyHelperText = ({ helperText, sx = {} }: MyHelperTextProps) => {
  const theme = useTheme();
  return (
    <>
      {helperText && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            fontFamily: ["Inter", "Public Sans"].join(","),
            fontSize: "10px",
            fontWeight: 400,
            mt: 0.25,
            color: theme.palette.action.active,
            fontStyle: "italic",
            py: 0.5,
            ...sx,
          }}
        >
          {helperText}
        </Box>
      )}
    </>
  );
};

export default MyHelperText;
