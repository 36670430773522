import { global } from "./global";
import { mappings } from "./mappings";
import { suppliers } from "./suppliers";

const es = {
  ...global,
  suppliers: { ...suppliers },
  mappings: { ...mappings },
};

export default es;
