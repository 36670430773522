import {
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
  Dialog,
  Button,
} from "@mui/material";
import useLocales from "src/hooks/useLocales";

const DeleteDialog = ({
  openDialog,
  handleCloseDialog,
  handleDeleteRow,
  item,
}) => {
  const { translate } = useLocales();
  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {translate("Delete this field?")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2" sx={{ fontSize: "13px" }}>
          {translate("Are you sure you want to delete this field?")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" gap={1}>
          <Button
            size="small"
            variant="outlined"
            onClick={handleCloseDialog}
            sx={{
              p: 0,
            }}
          >
            No
          </Button>
          <Button
            size="small"
            onClick={() => {
              handleDeleteRow(item.key);
              handleCloseDialog();
            }}
            autoFocus
            variant="contained"
          >
            {translate("Yes")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
