import { Check } from "@mui/icons-material";
import { Box, IconButton, Popover } from "@mui/material";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import CopyIcon from "src/assets/icon/copy/CopyIcon";
import useLocales from "src/hooks/useLocales";
import { Checkmark } from "react-checkmark";

interface Props {
  anchorEl: HTMLElement | null;
  handlePopoverClose: () => void;
  title: string;
  clickToCopy?: boolean;
  copied?: boolean;
}

const TimelinePopover = ({
  anchorEl,
  handlePopoverClose,
  title,
  clickToCopy,
  copied,
}: Props) => {
  const { translate } = useLocales();

  useEffect(() => {
    if (Boolean(anchorEl)) {
      document.body.onwheel = handlePopoverClose;
      document.body.addEventListener("touchstart", handlePopoverClose, false);
    }
    return () => {
      document.body.onwheel = null;
      document.body.removeEventListener(
        "touchstart",
        handlePopoverClose,
        false
      );
    };
  }, [Boolean(anchorEl)]);

  return (
    <Popover
      id="mouse-over-popover"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      disableScrollLock
      marginThreshold={32}
      container={anchorEl?.parentElement}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        pointerEvents: "none",
      }}
      onClose={handlePopoverClose}
      PaperProps={{
        sx: {
          bgcolor: "background.paper",
          borderRadius: "4px",
          p: 0.5,
          px: 1,
          display: "flex",
          alignItems: "center",
          overflowWrap: "break-word",
          // inlineSize: "450px",
        },
      }}
    >
      <>
        {/* <Typography
          sx={{
            fontWeight: "bold",
            color: "text.greyLight",
            fontSize: "10px",
          }}
          variant="subtitle2"
         
        >
          {translate(title)}
        </Typography> */}
        {clickToCopy && !copied ? (
          <>
            <Typography
              sx={{
                fontWeight: "bold",
                color: "text.greyLight",
                fontSize: "10px",
              }}
              variant="subtitle2"
            >
              {translate("Click to copy")}
            </Typography>
            <Box
              sx={{
                p: 0,
                ml: 1,
                cursor: "pointer",
                "&:hover": {
                  bgcolor: "background.paper",
                },
              }}
            >
              <CopyIcon height={"14px"} width={"14px"} />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              flexDirection: "row",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: "text.greyLight",
                fontSize: "10px",
              }}
              variant="subtitle2"
            >
              {translate("Copied !")}
            </Typography>
            <Checkmark size="small" />
          </Box>
        )}
      </>
    </Popover>
  );
};
export default TimelinePopover;
