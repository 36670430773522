import { useTheme } from "@mui/material";
import { InputLabel } from "@mui/material";
type InputLabelProps = {
  sx?: any;
  disabled?: boolean;
  id?: string;
  children?: React.ReactNode;
};
const MyInputLabel = ({ sx, disabled, children, id }: InputLabelProps) => {
  const theme = useTheme();
  return (
    <InputLabel
      htmlFor={id}
      sx={{
        fontSize: "12px",
        fontWeight: 700,
        fontStyle: "normal",
        fontFamily: ["Inter", "Public Sans"].join(","),
        flexGrow: 1,
        flex: "none",
        alignItems: "center",
        alignContent: "baseline",
        color: disabled
          ? theme.palette.action.disabled
          : theme.palette.action.active,
        ...sx,
      }}
    >
      {children}
    </InputLabel>
  );
};

export default MyInputLabel;
