// @mui
import { styled } from "@mui/material/styles";
import { Typography, Box, BoxProps } from "@mui/material";
//
import Image from "./Image";
import React from "react";
import { th } from "date-fns/locale";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(8, 2),
}));

// ----------------------------------------------------------------------

interface Props extends Omit<BoxProps, "title"> {
  title: string | React.ReactNode;
  img?: string;
  description?: string | React.ReactNode;
  image?: React.ReactNode;
  size?: "small" | "medium" | "large";
}

export default function EmptyContent({
  title,
  description,
  img,
  image,
  size = "medium",
  ...other
}: Props) {
  return (
    <RootStyle
      {...other}
      sx={{
        ...(size === "small"
          ? {
              padding: (theme) => theme.spacing(2, 2),
            }
          : {}),
      }}
    >
      {image ? image : <EmptyDataSvg />}
      <Typography
        variant={size === "small" ? "h6" : size === "medium" ? "h5" : "h4"}
        gutterBottom
      >
        {title}
      </Typography>

      {description && (
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {description}
        </Typography>
      )}
    </RootStyle>
  );
}

export const EmptyDataSvg = ({ width = "112", height = "112" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M93.4026 36.2084L74.0333 26.8083L46.3628 13.3796L34.9592 7.84024C32.109 6.44256 28.9407 5.82282 25.7745 6.04366C22.6084 6.2645 19.5566 7.3181 16.9276 9.09795C14.2987 10.8778 12.1857 13.3208 10.8024 16.1801C9.4191 19.0393 8.81443 22.2134 9.04953 25.3815L11.4812 59.457L13.7451 90.0073C13.9986 93.1729 15.0747 96.2171 16.8665 98.8379C18.6583 101.459 21.1036 103.565 23.9597 104.948C26.8159 106.33 29.9836 106.942 33.1486 106.721C36.3136 106.5 39.3659 105.454 42.0026 103.688L72.0209 83.9644C72.704 83.3925 73.3483 82.7756 73.9494 82.118L95.6666 67.5142C98.295 65.7276 100.408 63.2804 101.792 60.4182C103.177 57.556 103.786 54.3795 103.556 51.2077C103.327 48.0358 102.268 44.9801 100.486 42.3474C98.7035 39.7147 96.2607 37.5975 93.4026 36.2084Z"
      fill="#EEF5FD"
    />
    <rect
      x="17.5"
      y="28.9883"
      width="79"
      height="50.8919"
      fill="white"
      stroke="#0B427A"
      strokeDasharray="3 3"
    />
    <path
      d="M78.1782 22H35.8193C33.187 22 31.0527 24.1363 31.0527 26.7711V54.0905C31.0527 56.7252 33.187 58.8615 35.8193 58.8615H80.1263C80.1263 58.8615 80.1263 57.6757 82.9037 54.6886C82.9279 54.4926 82.9446 54.2933 82.9446 54.0905V26.7711C82.9446 24.1363 80.8105 22 78.1782 22Z"
      fill="#E4E6EB"
      stroke="#0B427A"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.5234 32.4688H48.5586"
      stroke="#0B427A"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.5234 39.9023H71.4377"
      stroke="#0B427A"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.5234 47.4258H71.4377"
      stroke="#0B427A"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="68.9902"
      y="52.3672"
      width="20.6216"
      height="20.6216"
      rx="10.3108"
      fill="#EEF5FD"
    />
    <rect
      x="68.9902"
      y="52.3672"
      width="20.6216"
      height="20.6216"
      rx="10.3108"
      stroke="#0B427A"
    />
    <path
      d="M79.3008 57.9219V67.4356"
      stroke="#0B427A"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84.0587 62.6797H74.5449"
      stroke="#0B427A"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
