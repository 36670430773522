import { PopperProps } from "@mui/material";
import { useContext, useRef, useState } from "react";
import { Events, animateScroll as scroll, scroller } from "react-scroll";
import { ReviewContext } from "../context/ReviewContext";
import { TReviewContextType } from "../types/reviewContext";

const useScroll = ({ currentTab, tableRefs }) => {
  const [zoom, setZoom] = useState(1);
  const prismaZoomRef = useRef<any>(null);
  const [xarrowColor, setXarrowColor] = useState("");
  const [title, setTitle] = useState<string | null>(null);
  const [boundingBox, setBoundingBox] = useState("");
  const [anchorEl, setAnchorEl] = useState<PopperProps["anchorEl"]>(null);
  const { axis: axixContext } = useContext<TReviewContextType>(ReviewContext);
  const { axis, setAxis } = axixContext;

  const scaleCoords = (coords) => {
    let zoom = prismaZoomRef?.current?.state?.zoom;
    let scaleCoords = coords?.map((coord) => coord * zoom);
    return scaleCoords;
  };
  const checkCoords = (coords) => {
    if (!coords) return false;
    // check if all are zero
    let allZero = true;
    coords?.forEach((coord) => {
      if (coord !== 0) allZero = false;
    });
    return !allZero;
  };
  const setArrowAxis = (item) => {
    if (zoom > 1) return;
    var startTime = performance.now();
    let element = document.getElementById("map-area-" + item?.key);
    let rect = element?.getBoundingClientRect();
    let coords = element!.getAttribute("coords")?.split(",");
    if (!checkCoords(coords)) return;
    let scaleCoords_ = scaleCoords(coords);
    setTitle(item.key);
    let axis_ = {
      xAxis: (rect?.x ?? 0) + parseInt(scaleCoords_[0]) + window.scrollX,
      yAxis:
        (rect?.y ?? 0) +
        parseInt(((scaleCoords_[1] + scaleCoords_[3]) / 2).toString()) +
        window.scrollY,
      xAxisDialog: (rect?.x ?? 0) + parseInt(scaleCoords_[0]) + window.scrollX,
      yAxisDialog: (rect?.y ?? 0) + parseInt(scaleCoords_[1]) + window.scrollY,
    };
    setAxis({ ...axis_ });
    setXarrowColor(item.strokeColor);
    setBoundingBox(item?.key || item);
    var endTime = performance.now();

    console.log(`Call to doSomething took ${endTime - startTime} milliseconds`);
  };
  const scrollToWithContainer = (tableName: string, title_: string) => {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register("end", () => {
        resolve(0);
        Events.scrollEvent.remove("end");
      });
      scroller.scrollTo(tableName, {
        duration: 0,
        delay: 0,
        containerId: "scrollToScreen",
        offset: -30,
        spyThrottle: 200,
        smooth: "easeInQuad",
      });
    });
    goToContainer.then(() =>
      scroller.scrollTo(`${title_}`, {
        duration: 0,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: `${tableName}`,
        offset: -10,
        spyThrottle: 200,
      })
    );
  };

  const scrollToImageContainer = (positionY, positionX, item) => {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register("end", () => {
        resolve(0);
        Events.scrollEvent.remove("end");
      });
      scroll.scrollTo(positionY, {
        containerId: "img-mapper-page",
        duration: 0,
        delay: 0,
        smooth: "easeInOutQuart",
        ignoreCancelEvents: true,
        // offset: -600,
      });
    });
    goToContainer.then(() => {
      if (positionX) {
        let goToHorizontalContainer = new Promise((resolve, reject) => {
          Events.scrollEvent.register("end", () => {
            resolve(0);
            Events.scrollEvent.remove("end");
          });
          scroll.scrollTo(positionX, {
            containerId: "img-mapper-page",
            // offset: -1500,
            horizontal: true,
            duration: 0,
            delay: 0,
            smooth: "easeInOutQuart",
            isDynamic: true,
            ignoreCancelEvents: true,
            // to: to,
          });
        });
        goToHorizontalContainer.then(() => {
          setArrowAxis(item);
        });
      } else {
        setArrowAxis(item);
      }
    });
  };

  const onMouseEnter = (area) => {
    if (area?.key) {
      setTitle(area?.key);
      if (currentTab === "0") {
        if (area?.key?.split(".").length === 6) {
          new Promise((resolve, reject) => {
            setTimeout(() => {
              tableRefs.current?.[area.category]?.current?.goToPage(
                area?.key?.split(".")?.[3] ?? 1
              );
              resolve(0);
            }, 300);
          }).then(() => {
            scrollToWithContainer(area?.category, area?.key);
          });
        } else {
          scrollToWithContainer(area?.category, area?.key);
        }
      }
    }
  };

  const onMouseLeave = (area) => {
    if (area.key && boundingBox) {
      setAxis((axis) => ({ ...axis, xAxis: null, yAxis: null }));
      setXarrowColor("");
      setTitle("");
      setBoundingBox("");
    }
  };

  const handleMouseScroll = (item) => {
    if (zoom > 1) return;
    let element = document.getElementById("map-area-" + item?.key);
    if (!element) return;
    let coords = element.getAttribute("coords")?.split(",");
    let scaleCoords_ = scaleCoords(coords);
    if (!checkCoords(scaleCoords_)) return;
    scrollToImageContainer(
      (scaleCoords_[1] + scaleCoords_[3]) / 2 + window.scrollY,
      scaleCoords_[0] + window.scrollX,
      item
    );
  };
  const removeArrowAxis = () => {
    setAxis((axis) => ({ ...axis, xAxis: null, yAxis: null }));
    setXarrowColor("");
    setTitle("");
    setBoundingBox("");
  };

  return {
    scrollToWithContainer,
    scrollToImageContainer,
    setArrowAxis,
    setXarrowColor,
    axis,
    setAxis,
    boundingBox,
    xarrowColor,
    title,
    setZoom,
    zoom,
    prismaZoomRef,
    onMouseEnter,
    onMouseLeave,
    handleMouseScroll,
    removeArrowAxis,
    setBoundingBox,
    anchorEl,
    setAnchorEl,
  };
};
export default useScroll;
