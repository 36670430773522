import { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useLocales from "../../../hooks/useLocales";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material/styles";
import { textStyle } from "../../../theme/style";
import { GetAsync } from "src/common/httpRequests";
import { API_GATEWAY } from "src/constants/settings";
import { googleIcon, outlookIcon } from "src/assets/index";
import { useDispatch, useSelector } from "src/redux/store";
import { setExternalIdentities } from "src/redux/slices/externalIdentities";

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  afterSubmit?: string;
};

export default function PreRegForm() {
  const [blockedURL, setBlockedURL] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { externalIdentities } = useSelector(
    (state) => state.externalIdentities
  );
  const [externalLoginIdentities, setExternalLoginIdentities] = useState<any>(
    []
  );
  const { enqueueSnackbar } = useSnackbar();
  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate(`authentication_screen.email_invalid`))
      .required(translate(`authentication_screen.email_required`))
      .test(
        "email",
        translate(`authentication_screen.use_professional_account`),
        function (this, e): boolean {
          const email = this.parent.email;
          let isBlockedDomain = true;
          for (let i = 0; i < blockedURL.length; i++) {
            if (email.includes(`@${blockedURL[i]}`)) {
              isBlockedDomain = false;
            }
          }
          return isBlockedDomain;
        }
      ),
  });
  useEffect(() => {
    let isMounted: boolean = true;
    const fetchExternalIdentity = async () => {
      const response = await GetAsync(
        `${API_GATEWAY}/api/userservice/ExternalIdentity`
      );
      try {
        dispatch(setExternalIdentities(response.data.externalIdentities));
        if (isMounted) {
          setExternalLoginIdentities(response.data.externalIdentities);
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    if (externalIdentities?.length > 0) {
      setExternalLoginIdentities(externalIdentities);
    } else {
      fetchExternalIdentity();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const defaultValues = {
    email: "",
  };

  const theme = useTheme();
  const { mode } = theme?.palette;

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    // defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  useEffect(() => {
    const fetchDomains = async () => {
      const response = await GetAsync(
        `${API_GATEWAY}/api/userservice/Account/blockedDomains`
      );
      try {
        setBlockedURL(response.data.domains);
      } catch (e) {
        enqueueSnackbar(e.message, { variant: "error" });
      }
    };

    fetchDomains();
  }, []);

  const onSubmit = async (data: FormValuesProps) => {
    // const response = await GetAsync(`${API_GATEWAY}/api/userservice​/Account​/username​/{${data.email}}​/exist`)
    let { email } = data;
    email = email.toLowerCase();

    const response = await GetAsync(
      `${API_GATEWAY}/api/userservice/Account/username/${email}/exist`
    );
    localStorage.setItem("signUpMethod", "email");
    try {
      if (response?.data?.isExist === false) {
        navigate(`/auth/new-register?email=${email}`);
      } else {
        enqueueSnackbar(translate(`authentication_screen.alreadyExistError`), {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(`${error.message}`, { variant: "error" });
    }
    //   enqueueSnackbar(`Email has been send`);
    // navigate(`/auth/new-register?email=${data.email}`)
    // } catch (error) {
    //   enqueueSnackbar(`${error.message}`, { variant: 'error' });
    //   // console.error(error);
    //   // reset();
    //   if (isMountedRef.current) {
    //     setError('afterSubmit', { ...error, message: error.message });
    //   }
    // }
  };

  const loginRedirect = (url: string, id: string, key: string) => {
    localStorage.setItem("externalProviderId", id);
    localStorage.setItem("signUpMethod", key);
    window.location.replace(`${url}`);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <h1 style={{ color: "#274460", paddingBottom: "2%" }}></h1>
        <h5 style={{ color: "#274460", paddingBottom: "3%" }}></h5>
      </Box>
      <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            {translate("authentication_screen.get_started_title")}
          </Typography>
          <Typography variant="h6">
            {translate("authentication_screen.get_started_text")}
          </Typography>
        </Box>
      </Stack>
      <Stack spacing={3}>
        <RHFTextField
          name="email"
          label={translate("authentication_screen.email_address")}
          className={mode === "dark" ? "formInput" : ""}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {translate("authentication_screen.pre_reg_btn")}
        </LoadingButton>

        <div>
          <Divider sx={{ marginY: 3 }}>
            {translate(`authentication_screen.or_signup_with`)}
          </Divider>
          <div>
            {externalLoginIdentities.map((item, index) => {
              return (
                <LoadingButton
                  key={index}
                  className="external-login"
                  sx={{ marginBottom: "20px" }}
                  onClick={() => loginRedirect(item.url, item.id, item.key)}
                  size="large"
                  fullWidth
                  variant="contained"
                  loading={isSubmitting}
                >
                  {item.key == "google" ? (
                    <img
                      src={googleIcon}
                      style={{ marginRight: "10px", width: "6%" }}
                    />
                  ) : item.key == "outlook" ? (
                    <img
                      src={outlookIcon}
                      style={{ marginRight: "10px", width: "6%" }}
                    />
                  ) : (
                    <img />
                  )}
                  <span style={{ textAlign: "center", fontSize: "0.9375rem" }}>
                    {translate(`authentication_screen.signup_with_${item.key}`)}
                  </span>
                  <span></span>
                </LoadingButton>
              );
            })}
          </div>{" "}
        </div>
      </Stack>
    </FormProvider>
  );
}
