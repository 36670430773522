import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";

type OrganizationState = {
  org: {
    plan: string;
    documentsPerMonth: string;
    addOns?: string | Array<string>;
    enableDiscrepancyDetection: boolean;
    enableNewDocumentConfiguration: boolean;
    enableNewInputMapping: boolean;
    enablePayments: boolean;
    enableCatalogManagement: boolean;
    enableAsyncReviewPage: boolean;
    enableDocumentCreateThroughForm: boolean;
    enableAnalytics: boolean;
    enablePaymentFlow: boolean;
    enableSelfTraining: boolean;
    enableDefaultProdEnvFilterForNonTestUser: boolean;
    enableAsyncMappingUpload: boolean;
    isTestingUser: boolean;
    isImpersonatedUser: boolean;
  };
};

const initialState: OrganizationState = {
  org: {
    plan: "",
    documentsPerMonth: "",
    addOns: "",
    enableDiscrepancyDetection: false,
    enableNewDocumentConfiguration: false,
    enableNewInputMapping: false,
    enablePayments: false,
    enableDocumentCreateThroughForm: false,
    enableCatalogManagement: false,
    enableAsyncReviewPage: false,
    enableAnalytics: false,
    enablePaymentFlow: false,
    enableSelfTraining: false,
    enableDefaultProdEnvFilterForNonTestUser: false,
    enableAsyncMappingUpload: false,
    isTestingUser: false,
    isImpersonatedUser: false,
  },
};

const slice = createSlice({
  name: "Organization",
  initialState,
  reducers: {
    loginOrganizationAction(state, action) {
      let {
        organizationPlan,
        organizationDocumentsPerMonth,
        organizationAddOns,
        enableDiscrepancyDetection,
        enableNewInputMapping,
        enableNewDocumentConfiguration,
        enableAsyncReviewPage,
        enablePayments,
        enableDocumentCreateThroughForm,
        enableCatalogManagement,
        enableAsyncMappingUpload,
        enableAnalytics,
        enablePaymentFlow,
        enableSelfTraining,
        enableDefaultProdEnvFilterForNonTestUser,
        isImpersonatedUser,
      } = action.payload;
      state.org = {
        plan: organizationPlan,
        documentsPerMonth: organizationDocumentsPerMonth,
        addOns: organizationAddOns ?? "",
        enableDiscrepancyDetection,
        enableNewDocumentConfiguration,
        enableAsyncReviewPage,
        enableNewInputMapping,
        enablePayments,
        enableDocumentCreateThroughForm,
        enableCatalogManagement,
        enableAsyncMappingUpload,
        enableAnalytics,
        enablePaymentFlow,
        enableSelfTraining,
        enableDefaultProdEnvFilterForNonTestUser,
        isTestingUser: false,
        isImpersonatedUser: isImpersonatedUser,
      };
    },
    logoutOrganizationAction(state) {
      state.org = {
        plan: "",
        documentsPerMonth: "",
        addOns: "",
        enableDiscrepancyDetection: false,
        enableNewDocumentConfiguration: false,
        enableNewInputMapping: false,
        enablePayments: false,
        enableDocumentCreateThroughForm: false,
        enableCatalogManagement: false,
        enableAsyncMappingUpload: false,
        enableAsyncReviewPage: false,
        enableAnalytics: false,
        enablePaymentFlow: false,
        enableSelfTraining: false,
        enableDefaultProdEnvFilterForNonTestUser: false,
        isTestingUser: false,
        isImpersonatedUser: false,
      };
    },
    planUpdateAfterCheckout(state, action) {
      state.org = { ...state.org, plan: action.payload };
    },
  },
});

export default slice.reducer;

export const {
  loginOrganizationAction,
  logoutOrganizationAction,
  planUpdateAfterCheckout,
} = slice.actions;

export const loginOrganization = (state: any) => {
  return async () => {
    dispatch(loginOrganizationAction(state));
  };
};

export const logoutOrganization = () => {
  return async () => {
    dispatch(logoutOrganizationAction());
  };
};

export const planUpdate = (state: any) => {
  return async () => {
    dispatch(planUpdateAfterCheckout(state));
  };
};
