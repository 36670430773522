import { SvgIconProps } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

const CrossIcon = ({
  customColor = "#657482",
  width = 24,
  height = 24,
  sx,
  disableDimension = false,
}: SvgIconProps & {
  customColor?: string;
  disableDimension?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...(disableDimension
          ? {}
          : {
              height: height,
              width: width,
            }),
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.26432 8.45182L15.3611 15.5486M8.26432 15.5486L15.3611 8.45182M11.8125 22C6.2896 22 1.8125 17.5229 1.8125 12C1.8125 6.4771 6.2896 2 11.8125 2C17.3354 2 21.8125 6.4771 21.8125 12C21.8125 17.5229 17.3354 22 11.8125 22Z"
          stroke={customColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

CrossIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  sx: PropTypes.object,
};

CrossIcon.defaultProps = {
  color: "secondary",
  width: 24,
  height: 24,
};

export default CrossIcon;
