
export function createLabelsBoundingBoxes( l: any, index: number, height: number, width: number, ocrHeight: number, ocrWidth: number, labels: any ) {
    let bp =l.boundingPolygon
    const joinBoundingBoxes = [
      bp?.lowerLeft?.x ?? 0,
      bp?.lowerLeft?.y ?? 0,
      bp?.lowerRight?.x ?? 0,
      bp?.lowerRight?.y ?? 0,
      bp?.upperLeft?.x ?? 0,
      bp?.upperLeft?.y ?? 0,
      bp?.upperRight?.x  ?? 0,
      bp?.upperRight?.y ?? 0,
    ];
    const adjustedBounding = joinBoundingBoxes.map((element, index) =>
    index % 2 ? (element * height) / ocrHeight : (element * width) / ocrWidth
    );
    l.coords = adjustedBounding
    const box = {
      id: "LABEL-" + String(index),
      title: l.value?.replace("\n", " "),
      shape: "poly",
      name: l.name, 
      coords: adjustedBounding,
      strokeColor: l.strokeColor,
      preFillColor: l.preFillColor,
      fillColor: l.fillColor,
      key:"",
      boundingPolygon: l.boundingPolygon,
      category: l.name,
      pageNumber: l.pageNumber,
    };
    return box;
}

export function createOcrBoundingBoxes( line: any, index: number, height: number, width: number, ocrHeight: number, ocrWidth: number ) {
    let bp =line.boundingPolygon
    const joinBoundingBoxes = [
      bp.lowerLeft?.x ?? 0,
      bp.lowerLeft?.y ?? 0,
      bp.lowerRight?.x ?? 0,
      bp.lowerRight?.y ?? 0,
      bp.upperLeft?.x ?? 0,
      bp.upperLeft?.y ?? 0,
      bp.upperRight?.x  ?? 0,
      bp.upperRight?.y ?? 0,
    ];
    const adjustedBounding = joinBoundingBoxes.map((element, index) =>
    index % 2 ? (element * height) / ocrHeight : (element * width) / ocrWidth
    );
    const box = {
      id: "OCR-" + String(index),
      title: line.content,
      shape: "poly",
      name: "un_labelled", 
      coords: adjustedBounding,
      strokeColor: "",//  "#B6B6B680",
      preFillColor: "",//  "#F7F4A420",
      fillColor: "",// "#F7F4A420",
      key:"",
      boundingPolygon: line.boundingPolygon,
      category: "un_labelled",
      pageNumber: line.pageNumber,
    };
    return box;
}
