import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Link, Container, Typography, Tooltip } from "@mui/material";
import { PATH_AUTH } from "../../routes/paths";
import Page from "../../components/Page";
import Logo from "../../components/Logo";
import { RegisterForm } from "../../sections/auth/register";
import { PreRegForm } from "src/sections/auth/pre-register";
import useResponsive from "../../hooks/useResponsive";
import { useTheme } from "@mui/material/styles";
import { textStyle } from "../../theme/style";

//hooks
import useLocales from "../../hooks/useLocales";
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from "src/constants/settings";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));
const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));
export default function PreRegister() {
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");

  const theme = useTheme();
  const { mode } = theme?.palette;
  const { translate } = useLocales();

  return (
    <Page title="Register">
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {smUp && (
            <Typography variant="body2" align="center" sx={{ mt: { md: 0 } }}>
              {translate("authentication_screen.pre_reg_header_text")} {""}
              <Link
                variant="subtitle2"
                component={RouterLink}
                to={PATH_AUTH.login}
                sx={{ color: textStyle.palette.color[mode] }}
              >
                {translate("authentication_screen.login_txt")}
              </Link>
            </Typography>
          )}
        </HeaderStyle>
        <Container>
          <ContentStyle>
            <PreRegForm />

            <Typography variant="body1" align="center" sx={{ mt: 3 }}>
              {translate("authentication_screen.pre_reg_text")}{" "}
              <Link
                target="_blank"
                underline="always"
                variant="subtitle1"
                href={TERMS_OF_SERVICE}
                sx={{ color: textStyle.palette.color[mode] }}
              >
                {translate("authentication_screen.terms_text")}
              </Link>{" "}
              {translate("authentication_screen.pre_reg_text_one")}{" "}
              <Link
                target="_blank"
                underline="always"
                variant="subtitle1"
                href={PRIVACY_POLICY}
                sx={{ color: textStyle.palette.color[mode] }}
              >
                {translate("authentication_screen.privacy_policy")}
              </Link>
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
