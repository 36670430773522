import { GetAsync, PostAsync } from "./httpRequests";
import { store } from "src/redux/store";
import { API_GATEWAY } from "src/constants/settings";

const FileDownload = require("js-file-download");

export default {
  DownloadFiles(fileId, fileType, zipName) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${store.getState()?.credential?.userCred.accessToken}`
    );
    let fileIdsArr = [];
    fileId.forEach((element) => fileIdsArr.push(element));

    var raw = JSON.stringify(fileIdsArr);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${API_GATEWAY}/api/webapi/FilesDownload/DownloadFiles?folderName=2&fileType=${fileType}`,
      requestOptions
    )
      .then((response) =>
        response.blob().then((fileData) => FileDownload(fileData, zipName))
      )
      .catch((error) => console.log("error", error));
  },

  async DownloadJSONFiles(fileId, zipName) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${store.getState()?.credential?.userCred.accessToken}`
    );
    let obj = {};
    let ids = [];
    fileId.forEach((element) => ids.push(element));
    obj.documentIds = ids;
    var raw = JSON.stringify(obj);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      `${API_GATEWAY}/api/documentservice/DocumentDownload/downloadjsonfiles`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          response.blob().then((fileData) => FileDownload(fileData, zipName));
        } else {
          return response.json();
        }
      })
      .catch((error) => console.log("error", error));

    return response;
  },

  DownloadCSVFiles(fileId, zipName) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${store.getState()?.credential?.userCred.accessToken}`
    );

    let obj = {};
    let ids = [];
    fileId.forEach((element) => ids.push(element));
    obj.documentIds = ids;
    var raw = JSON.stringify(obj);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${API_GATEWAY}/api/documentservice/DocumentDownload/downloadcsvfiles`,
      requestOptions
    ).then((response) =>
      response.blob().then((fileData) => FileDownload(fileData, zipName))
    );
  },

  DownloadInvoices(fileId, zipName) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${store.getState()?.credential?.userCred.accessToken}`
    );

    let obj = {};
    let ids = [];
    fileId.forEach((element) => ids.push(element));
    obj.documentIds = ids;

    var raw = JSON.stringify(obj);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${API_GATEWAY}/api/documentservice/DocumentDownload/downloadinvoices`,
      requestOptions
    )
      .then((response) =>
        response.blob().then((fileData) => FileDownload(fileData, zipName))
      )
      .catch((error) => console.log("error", error));
  },
  //
  async ExportCsv(fileIds, fileType, zipName) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${store.getState()?.credential?.userCred.accessToken}`
    );
    let obj = {};
    let ids = [];
    fileIds.forEach((element) => ids.push(element));

    obj.documentIds = ids;
    var raw = JSON.stringify(obj);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${API_GATEWAY}/api/documentservice/DocumentDownload/exportcsv`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response;
        } else return response.json();
      })
      .catch((error) => console.log("error", error));
    return response;
  },

  DeleteFiles(fileIds, fileType) {
    let fileIdsArr = [];
    fileIds.forEach((element) => fileIdsArr.push({ fileId: element }));
    return PostAsync(
      `${API_GATEWAY}/api/webapi/FilesDelete/DeleteFiles?folderName=${fileType}`,
      fileIdsArr,
      true
    );
  },

  async GetFileListWithStatus(
    valueStatus,
    fromDate,
    toDate,
    page = 1,
    pageSize
  ) {
    const response = await GetAsync(
      `${API_GATEWAY}/api/webapi/FilesUpload/GetFileListWithStatus?valueStatus=${valueStatus}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&pageSize=${pageSize}`,
      true
    );
    if (response) {
      return response;
    }
  },

  // Export Documents Via Template
  async DownloadViaTemplate(obj, zipName) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${store.getState()?.credential?.userCred.accessToken}`
    );

    var raw = JSON.stringify(obj);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${API_GATEWAY}/api/documentservice/documentDownload/downloadCsvFiles`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          response.blob().then((fileData) => FileDownload(fileData, zipName));
        } else {
          return response.json();
        }
        // if (response.status === ) {
        //   response.blob().then((fileData) => FileDownload(fileData, zipName));
        // } else {
        //   // Custom message for failed HTTP codes
        //   if (response.status === 404) throw new Error("404, Not found");
        //   if (response.status === 400)
        //     throw new Error("Please download original document.");
        //   if (response.status === 500)
        //     throw new Error("500, internal server error");
        //   // For any other server error
        //   throw new Error(response.status);
        // }
      })
      .catch((error) => {
        console.log("error", error);
        return error;
      });
    return response;
  },

  // async UpdateExtractionValue(invoiceId, keyForExtractedValue, newExtractionValue) {
  // 	const response = await PutAsync(
  // 		`${API_GATEWAY}/api/webapi/ExtractedFiles/Update/Field/ValueAndStatus?invoiceId=${invoiceId}&jsonKey=${keyForExtractedValue}&jsonValue=${newExtractionValue}`, true);
  // 	if (response) {
  // 		return response;
  // 	}
  // },

  // async TryCreatingInSap(invoiceId,erpEnum) {
  // 	const response = await PostAsyncWIthResponseHandler(
  // 		`${API_GATEWAY}/api/webapi/ERPsIntegration/CreateInvoice?invoiceId=${invoiceId}&erp=${erpEnum}`, true);
  // 	if (response) {
  // 		return response;
  // 	}
  // },

  // async updateExtractionStatus(invoiceId, keyForExtractedValue) {
  // 	const response = await PutAsync(
  // 		`${API_GATEWAY}/api/webapi/ExtractedFiles/Update/Field/Status?invoiceId=${invoiceId}&jsonKey=${keyForExtractedValue}`, true);
  // 	if (response) {
  // 		return response;
  // 	}
  // },

  async GetFilesCountDayWise(valueStatus, fromDate, toDate) {
    const response = await GetAsync(
      `${API_GATEWAY}/api/webapi/FilesUpload/GetFilesCountDayWise?valueStatus=${valueStatus}&fromDate=${fromDate}&toDate=${toDate}`
    );
    if (response) {
      return response;
    }
  },

  async GetErpFormattedJson(invoiceId) {
    const response = await GetAsync(
      `${API_GATEWAY}/api/webapi/ERPsIntegration/ErpFormattedJson/${invoiceId}/2`
    );
    return response;
  },

  async GetFileErrorStats(valueStatus, fromDate, toDate) {
    const response = await GetAsync(
      `${API_GATEWAY}/api/webapi/FilesUpload/GetFileErrorStats?valueStatus=${valueStatus}&fromDate=${fromDate}&toDate=${toDate}`
    );
    if (response) {
      return response;
    }
  },
  async GetAllUserCustomModels() {
    const response = await GetAsync(
      `${API_GATEWAY}/api/webapi/UserCustomModel/GetAllUserCustomModels`
    );
    if (response) {
      return response;
    }
  },
  async GetCustomModelsList() {
    const response = await GetAsync(
      `${API_GATEWAY}/api/webapi/UserCustomModel/GetCustomModelsList`
    );
    if (response) {
      return response;
    }
  },

  async UpdateFallback(selectedValue) {
    const response = await GetAsync(
      `${API_GATEWAY}/api/webapi/UserCustomModel/UpdateUserFallback?userId=${selectedValue.user}&customModelId=${selectedValue.primaryModel}&fallbackModelId_1=${selectedValue.fallbackModel1}&fallbackModelId_2=${selectedValue.fallbackModel2}&fallbackModelId_3=${selectedValue.fallbackModel3}&fallbackModelId_4=${selectedValue.fallbackModel4}`
    );
    if (response) {
      return response;
    }
  },

  // async GetBlobFile(fileId, folder) {
  // 	const response = await GetDownloadAsync(`${API_GATEWAY}/api/webapi/FilesDownload/GetBlobFile?fileName=${fileId}&folder=${folder}`, true);
  // 	if (response) {
  // 		return response;
  // 	}
  // }
};
