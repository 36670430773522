import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import useLocales from "src/hooks/useLocales";
import { PATH_DASHBOARD } from "src/routes/paths";

export const BreadCrumbs = () => {
  const { translate } = useLocales();
  return (
    <HeaderBreadcrumbs
      sx={{
        px: 2,
      }}
      links={[
        { name: translate("Dashboard"), href: PATH_DASHBOARD.root },
        {
          name: translate("Documents"),
          href: PATH_DASHBOARD.general.documents.root,
        },
        {
          name: translate("review"),
          href: PATH_DASHBOARD.general.documents.general.review,
        },
      ]}
    />
  );
};
