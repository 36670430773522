import { useState, useEffect, useRef } from "react";
import { useNavigate, createSearchParams, Navigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Box, Tab, Tabs, Table, Grid } from "@mui/material";
import { Button, TableBody, Container, TableContainer } from "@mui/material";
import { Typography, Menu, IconButton, Tooltip } from "@mui/material";
import useSettings from "src/hooks/useSettings";
import useTable from "src/hooks/useTable";
import useInterval from "src/hooks/useInterval";
import Page from "src/components/Page";
import Scrollbar from "src/components/Scrollbar";
import { TableMoreMenu, TableNoData } from "src/components/table";
import { TableSelectedActions } from "src/components/table";
import FinanceTableRow from "./TableRow";
import fileManagerService from "src/common/uploadsInfo";
import { API_GATEWAY } from "src/constants/settings";
import { DeleteAsync, PostAsync } from "src/common/httpRequests";
import servives from "src/common/fileManagerService";
import { useSnackbar } from "notistack";
import UploadDocumentModal from "./../UploadDocumentModal";
import useLocales from "src/hooks/useLocales";
import { useSelector, store } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import DeleteDocumentModal from "./../DeleteDocumentModal";
import { RefreshIcon } from "src/assets/icon/refresh";
import { SelectChangeEvent } from "@mui/material/Select";
import ChevronDown from "src/assets/icon/chevron/ChevronDown";
import MenuItem from "@mui/material/MenuItem";
import { MailIcon } from "src/assets/icon/Mail";
import { UploadIcon } from "src/assets/icon/Upload";
import { LinkIcon } from "src/assets/icon/Link";
import EmailUploadModal from "./../EmailUploadModal";
import IllustrationUploadEmail from "src/assets/illustration_upload_email";
import TableHeadCustom from "./tableHeadCustom";
import { useForm } from "react-hook-form";
import { DocumentAlertBox } from "src/pages/Notification/NotificationAlertBox";
import { DocumentsEntity } from "src/@types/processedDocuments";
import { ExportDocumentTemplatesEntity } from "src/@types/processedDocuments";
import axios from "axios";
import SearchFilters from "./../Filters";
import MyTablePagination from "src/components/pagination/TablePagination";
import useFilter, { TFilterState } from "./../hooks/useFilter";
import usePageQuery from "./../hooks/usePageQuery";
import DeleteIcon from "src/assets/icon/delete";
import { ReactComponent as QRcode } from "src/assets/icon/qrcode.svg";
import { DownloadIcon } from "src/assets/icon/download";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import useDynamicTranslate from "src/hooks/useDynamicTranslation";
import JsonIcon from "src/assets/icon/json";
import { LoadingButton } from "@mui/lab";
import HistoryDrawer from "./../history/HistoryDrawer";
import useHistoryDrawer from "./../history/useHistory";
import { DocumentIcon } from "src/assets/icon/Document";
import ExportDocumentModal from "./../ExportDocumentModal";
import DiscrepancyDrawer from "./../DiscrepancyDrawer";
import useDiscrepancyDrawer from "./../DiscrepancyDrawer/useDiscrepancyDrawer";
import usePermissions from "src/hooks/usePermissions";
import NoAccessNavigate from "src/components/NoAccessNavigate";
import { Message } from "@mui/icons-material";
import MessageDrawer from "./../messages/MessageDrawer";
import useMessageDrawer from "./../messages/useMessageDrawer";
import EuroIcon from "src/assets/icon/EuroIcon";
import QRcodeUploadDialog from "../qrcode/QrcodeDialog";

let source = axios.CancelToken.source();

export default function InvoiceList() {
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const { permissionHandle } = usePermissions();
  const writePayment = permissionHandle(["Payments_Write"]);
  const [searchParams] = useSearchParams();
  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: "createDate",
    defaultCurrentPage: parseInt(searchParams.get("page") ?? "1") ?? 1,
    defaultRowsPerPage: parseInt(searchParams.get("rowsPerPage") ?? "") ?? 25,
  });
  const [documentFilterFields, setDocumentFilterFields] = useState<string[]>(
    []
  );
  const [documentFieldOperators, setDocumentFieldOperators] = useState<
    { enum: string; label: string }[]
  >([]);
  const [documentFieldToggle, setDocumentFieldToggle] = useState(false);
  const { filterState, setFilterState } = useFilter();
  const { setPageQuery, pageQuery } = usePageQuery({
    filters: filterState,
    rowsPerPage,
    page,
    documentFilterFields,
  });
  const { handleSubmit } = useForm();
  const [tableData, setTableData] = useState<DocumentsEntity[]>([]);
  const [loader, setLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [noOfItems, setNoOfItems] = useState(1);
  const [tabs, setTabs] = useState<string[]>(["all"]);
  const [exportTemplateExtension, setExportTemplateExtension] =
    useState<string>("csv");
  const { dynamicTranslation } = useDynamicTranslate();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const [dostEmail, setDostEmail] = useState("");
  const [isUploadDocumentModalVisible, setIsUploadDocumentModalVisible] =
    useState<boolean>(false);
  const [isDeleteDocumentModalVisible, setIsDeleteDocumentModalVisible] =
    useState<boolean>(false);
  const SKELETON: string[] = ["", "", "", "", "", "", ""];
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectAnchorEl, setSelectAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const open = Boolean(anchorEl);
  const [documentStatus, setDocumentStatus] = useState<string>("");
  const [documentCount, setDocumentCount] = useState<number>(0);
  const [exportDocumentTemplates, setExportDocumentTemplates] = useState<
    ExportDocumentTemplatesEntity[]
  >([]);
  const [
    isExportDocumentViaTemplateModalVisible,
    setIsExportDocumentViaTemplateModalVisible,
  ] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    org: { enableDocumentCreateThroughForm, enablePaymentFlow },
  } = store.getState()?.organization;

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setSelectAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSelectAnchorEl(null);
  };

  const { userTokenDecode }: any = useSelector((state) => state.credential);
  let count = userTokenDecode.login_count;
  let _fileManagerService = new fileManagerService();
  var formattedDate = new Date();
  var date = formattedDate.getDate();
  var month = formattedDate.getMonth();
  month += 1; // JavaScript months are 0-11
  var year = formattedDate.getFullYear();

  const {
    openDrawer,
    selectedRow,
    selectedRowId,
    handleDrawerOpen,
    handleDrawerClose,
  } = useHistoryDrawer();

  const {
    handleDrawerOpen: handleDrawerOpenMessage,
    ...restMessageDrawerProps
  } = useMessageDrawer();

  const handleDeleteRow = async () => {
    setDeleteLoader(true);
    let fileIdsArr: any = [];
    selected.forEach((element) => fileIdsArr.push(element));
    const res = await DeleteAsync(
      `${API_GATEWAY}/api/documentservice/document/deleteBulkV2`,
      {
        documentIds: fileIdsArr,
      }
    );
    try {
      if (res.status === 200) {
        const docNames: string[] = [];

        res?.data?.deleteDocuments?.map(
          (item: {
            fileName: string;
            hasDeleted: boolean;
            message: string;
          }) => {
            if (!item.hasDeleted) {
              enqueueSnackbar(
                `${translate(
                  "finance_screen.document_delete_unsuccessful"
                )}: ${translate(`finance_screen.${item.message}`)}`,
                { variant: "error" }
              );
            } else {
              docNames.push(item.fileName);
            }
          }
        );
        if (docNames.length != 0) {
          enqueueSnackbar(
            `${docNames.length}
             ${translate("finance_screen.document_deleted_successfully")}`,
            { variant: "success" }
          );
        }
        setDeleteLoader(false);
        setIsDeleteDocumentModalVisible(false);
      } else {
        enqueueSnackbar(translate(res?.data?.Detail), { variant: "error" });
        setDeleteLoader(false);
        setIsDeleteDocumentModalVisible(false);
      }
    } catch (err) {
      enqueueSnackbar(translate(`something_went_wrong`), {
        variant: "error",
      });
      setDeleteLoader(false);
      setIsDeleteDocumentModalVisible(false);
    }
    fetchData(page, rowsPerPage, filterState);
  };

  const handleExportCSVs = async () => {
    const res: any = await servives.ExportCsv(
      selected,
      2,
      "csvFiles_" + date + "_" + month + "_" + year + ".zip"
    );
    try {
      if (res && res.status === 200) {
        const blob = new Blob([await res.blob()], {
          type: res.headers?.get("Content-Type") ?? "application/csv",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        let extension =
          applicationTypeMap[res.headers.get("Content-Type")] ?? "csv";
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          (extension ?? "csv") +
            "Export_" +
            date +
            "_" +
            month +
            "_" +
            year +
            "." +
            (extension ?? "csv")
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        enqueueSnackbar(res.Detail, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleDownloadJSON = async () => {
    const res = await servives.DownloadJSONFiles(
      selected,
      "Document_JSON" + date + "_" + month + "_" + year + ".zip"
    );
    if (res && res.status !== 200) {
      enqueueSnackbar(res?.Detail, { variant: "error" });
    }
  };

  const handleDownloadInvoices = async (type: string) => {
    if (selected.length > 0) {
      servives.DownloadInvoices(
        selected,
        type + date + "_" + month + "_" + year + ".zip"
      );
    } else {
      enqueueSnackbar("Selection is required", { variant: "error" });
    }
  };

  const onViewDiscrepancy = (docId: string) => {
    navigate({
      pathname: PATH_DASHBOARD.general.discrepancy,
      search: createSearchParams({
        docId: docId,
      }).toString(),
    });
  };

  const dataFiltered = applySortFilter({
    tableData: tableData ?? [],
  });

  const isNotFound = !dataFiltered?.length && !loader;

  const TABLE_HEAD = [
    {
      id: "invoiceNumber",
      label: translate(`document_name`),
      align: "left",
    },
    {
      id: "createDate",
      label: translate(`processed_on`),
      align: "left",
    },
    {
      id: "dueDates",
      label: translate(`finance_screen.status`),
      align: count > 1 ? "left" : "center",
    },

    ...(enablePaymentFlow
      ? [
          {
            id: "paymentStatus",
            label: translate(`payment`),
            align: "left",
          },
        ]
      : []),
    // { ...{
    //   id: "paymentStatus",
    //   label: translate(`payment_status`),
    //   align: "left",
    // })},

    {
      id: "Erp",
      label: translate(`finance_screen.output_data`),
      align: "left",
    },
    { id: "action", label: translate("action"), align: "left" },
  ];

  const optionsStatus = [
    { enum: "", value: `${translate(`finance_screen.all`)}` },
    { enum: "1", value: `${translate(`status.processed`)}` },
    { enum: "4", value: `${translate(`status.need_review`)}` },
    { enum: "5", value: `${translate(`status.reviewed`)}` },
    { enum: "6", value: `${translate(`status.duplicate`)}` },
    { enum: "2", value: `${translate(`status.failed`)}` },
  ];

  const optionsApprovalStatus = [
    { enum: "", value: `${translate(`finance_screen.all`)}` },
    { enum: "99999", value: `${translate(`approval not required`)}` },
    { enum: "0", value: `${translate(`Approval required`)}` },
    { enum: "1", value: `${translate(`approved`)}` },
    { enum: "2", value: `${translate(`rejected`)}` },
  ];

  const optionsIntegrationStatus = [
    { enum: "", value: `${translate(`All`)}` },
    { enum: "true", value: `${translate(`Integrated`)}` },
    { enum: "false", value: `${translate(`Not Integrated`)}` },
  ];

  const fetchData = async (
    page: number,
    rowsPerPage: number,
    filterState: TFilterState
  ) => {
    let {
      invoiceStatus,
      approvalStatus,
      isERPIntegrated,
      includeDeleted,
      fromDate,
      toDate,
      documentClass,
      documentName,
      fields,
      paymentStatus,
    } = filterState;
    await _fileManagerService
      .listProcessedDocuments(
        page,
        rowsPerPage,
        invoiceStatus,
        approvalStatus,
        isERPIntegrated,
        includeDeleted,
        documentName,
        documentClass.toLowerCase() === "all" ? "" : documentClass,
        fromDate ? fromDate : "",
        toDate ? toDate : "",
        Object.entries(fields ?? {})?.map(([key, value]) => {
          return { fieldName: key, value: value };
        }),
        source.token,
        paymentStatus
      )
      .then((supplierInvoicesData: any) => {
        if (supplierInvoicesData.status === 200) {
          let inProcessDocumentCount = 0;
          if (supplierInvoicesData?.data?.documents?.length > 0) {
            let ext =
              supplierInvoicesData?.data?.documents?.[0]
                ?.exportTemplateExtension;
            setExportTemplateExtension(ext);
          }
          supplierInvoicesData?.data?.documents?.forEach((element: any) => {
            if (element.documentStatus === 0) {
              inProcessDocumentCount = inProcessDocumentCount + 1;
            }
          });
          if (supplierInvoicesData?.data?.documentFields) {
            setDocumentFilterFields(supplierInvoicesData?.data?.documentFields);
          }

          if (supplierInvoicesData?.data?.documentFieldOperators) {
            const docFieldOperators: { enum: string; label: string }[] =
              supplierInvoicesData?.data?.documentFieldOperators?.map(
                (operator: string) => ({
                  enum: operator,
                  label: operator,
                })
              );
            setDocumentFieldOperators(docFieldOperators);
          }
          if (supplierInvoicesData?.data?.enableDocFieldSearch) {
            setDocumentFieldToggle(
              supplierInvoicesData?.data?.enableDocFieldSearch
            );
          }
          if (dostEmail === "") {
            setDostEmail(supplierInvoicesData?.data?.mailboxAddress);
          }
          if (inProcessDocumentCount > 0) {
            setDocumentStatus("0");
            setDocumentCount(inProcessDocumentCount);
          } else if (
            documentCount > 0 &&
            documentStatus == "0" &&
            inProcessDocumentCount == 0
          ) {
            setDocumentStatus("1");
            setDocumentCount(0);
          } else if (documentStatus !== "") {
            setDocumentStatus("");
          }
          setExportDocumentTemplates(
            supplierInvoicesData?.data?.exportDocumentTemplates
          );
          setTableData(supplierInvoicesData?.data?.documents);
          setNoOfItems(supplierInvoicesData?.data?.filteredRecordsCount);
          if (tabs.length === 1) {
            setTabs([...tabs, ...supplierInvoicesData?.data?.documentClasses]);
          }
        } else {
          navigate(PATH_DASHBOARD.general.app);
          enqueueSnackbar("Something went wrong", { variant: "error" });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.message === "Network Error") {
          enqueueSnackbar(translate("Network Error"), { variant: "error" });
        }
      });
    setSelected([]);
  };

  // Export Document
  const onExportDocument = () => {
    setIsExportDocumentViaTemplateModalVisible(
      !isExportDocumentViaTemplateModalVisible
    );
  };
  // Export Document

  // Make Payment
  const onMakePayment = () => {
    navigate(`${PATH_DASHBOARD.general.documents.general.createPayment}`, {
      state: { documentIds: selected },
    });
  };

  useEffect(() => {
    const fetchAPIs = async () => {
      setLoader(true);
      await fetchData(page, rowsPerPage, filterState);
      setLoader(false);
    };
    source.cancel("cancel request");
    source = axios.CancelToken.source();
    fetchAPIs();
  }, [rowsPerPage]);

  const countRef = useRef(page);
  countRef.current = page;
  const countRefs = useRef(rowsPerPage);

  countRefs.current = rowsPerPage;

  const renderDocument = async () => {
    let rowsPerPage = countRefs.current;
    let {
      invoiceStatus,
      approvalStatus,
      isERPIntegrated,
      includeDeleted,
      fromDate,
      toDate,
      documentClass,
      documentName,
      fields,
      paymentStatus,
    } = filterState;

    _fileManagerService
      .listProcessedDocuments(
        page,
        rowsPerPage,
        invoiceStatus,
        approvalStatus,
        isERPIntegrated,
        includeDeleted,
        documentName,
        documentClass?.toLowerCase() === "all" ? "" : documentClass,
        fromDate ? fromDate : "",
        toDate ? toDate : "",
        Object.entries(fields ?? {})?.map(([key, value]) => {
          return { fieldName: key, value: value };
        }),
        source.token,
        paymentStatus
      )
      .then((supplierInvoicesData: any) => {
        let inProcessDocumentCount = 0;
        supplierInvoicesData?.data?.documents?.forEach((element: any) => {
          if (element.documentStatus === 0) {
            inProcessDocumentCount = inProcessDocumentCount + 1;
          }
        });
        if (inProcessDocumentCount > 0) {
          setDocumentStatus("0");
          setDocumentCount(inProcessDocumentCount);
        } else if (
          documentCount > 0 &&
          documentStatus == "0" &&
          inProcessDocumentCount == 0
        ) {
          setDocumentStatus("1");
          setDocumentCount(0);
        } else if (documentStatus != "") {
          setDocumentStatus("");
        }
        if (supplierInvoicesData?.data?.enableDocFieldSearch) {
          setDocumentFieldToggle(
            supplierInvoicesData?.data?.enableDocFieldSearch
          );
        }
        if (supplierInvoicesData?.data?.documentFields) {
          setDocumentFilterFields(supplierInvoicesData?.data?.documentFields);
        }
        if (supplierInvoicesData?.data?.documentFieldOperators) {
          const docFieldOperators: { enum: string; label: string }[] =
            supplierInvoicesData?.data?.documentFieldOperators?.map(
              (operator: string) => ({
                enum: operator,
                label: translate(operator),
              })
            );
          setDocumentFieldOperators(docFieldOperators);
        }
        setExportDocumentTemplates(
          supplierInvoicesData?.data?.exportDocumentTemplates
        );
        setTableData(supplierInvoicesData?.data?.documents);
        setNoOfItems(supplierInvoicesData?.data?.filteredRecordsCount);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error, error.message);
        if (error.message === "Network Error") {
          enqueueSnackbar(translate("Network Error"), { variant: "error" });
        }
      });
  };

  useEffect(() => {
    pageQuery();
  }, [filterState]);

  useInterval(
    () => {
      if (page > 0) {
        renderDocument();
      }
    },
    10000,
    [page, rowsPerPage]
  );

  const searchApi = async (filterState_) => {
    setLoader(true);
    source.cancel("cancel request");
    source = axios.CancelToken.source();
    setPage(1);
    setPageQuery("page", "1");
    await fetchData(1, rowsPerPage, filterState_ ?? filterState);
    setLoader(false);
  };

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isQRCodeModalVisible, setIsQRCodeModalVisible] =
    useState<boolean>(false);
  const [isExtractNowBtnDisable, setIsExtractNowBtnDisable] = useState(false);

  const handleExtractDocuments = async () => {
    setIsExtractNowBtnDisable(true);
    PostAsync(`${API_GATEWAY}/api/documentservice/Document/pending/pull`)
      .then((res) => {
        if (res?.status === 200)
          enqueueSnackbar(translate(`extracting_documents`));
        else enqueueSnackbar(translate(`something_went_wrong`));
      })
      .catch((err) => {
        console.log("Error---> ", err);
        enqueueSnackbar(translate(`something_went_wrong`));
      });
    setIsExtractNowBtnDisable(false);
  };

  const {
    handleDrawerOpen: handleDrawerOpenDiscrepancy,
    ...restDiscrepancyDrawerProps
  } = useDiscrepancyDrawer();
  if (!permissionHandle(["Documents_Read", "Documents_Write"])) {
    return <NoAccessNavigate />;
  }

  const handleDeleteSingleDocument = (docId: string) => {
    setSelected([docId]);
    setIsDeleteDocumentModalVisible(true);
  };

  return (
    <Page title="Processed Documents">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HistoryDrawer
          {...{ openDrawer, selectedRow, selectedRowId, handleDrawerClose }}
        />
        <MessageDrawer {...restMessageDrawerProps} />
        <DiscrepancyDrawer {...restDiscrepancyDrawerProps} />
        <HeaderBreadcrumbs
          links={[
            { name: translate(`Dashboard`), href: PATH_DASHBOARD.root },
            {
              name: translate("Documents"),
            },
          ]}
        />
        <Grid container>
          <Grid item md={12} xs={12}>
            <DocumentAlertBox
              count={documentCount}
              status={documentStatus}
              setStatus={setDocumentStatus}
            />
            {/* {isExportDocumentModalVisible && (
              <ExportDocumentModal
                isShowModal={isExportDocumentModalVisible}
                showModalMethod={onExportDocument}
                document
              />
            )} */}
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography
              variant="h3"
              sx={{
                color: "primary.main",
                fontWeight: "bold",
                fontSize: "1.5rem",
                mt: 1,
              }}
            >
              {translate("Documents")}
            </Typography>
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <LoadingButton
              loading={isExtractNowBtnDisable}
              onClick={handleExtractDocuments}
              variant="outlined"
              sx={{ m: 1, p: "16px, 24px, 16px, 24px" }}
              startIcon={<RefreshIcon />}
            >
              {translate("finance_screen.extract_now")}
            </LoadingButton>

            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className="fileupload"
              variant="contained"
              disabled={!permissionHandle(["Documents_Write"])}
              endIcon={
                <ChevronDown
                  customColor={
                    !permissionHandle(["Documents_Write"])
                      ? theme.palette.action.disabled
                      : theme.palette.action.active
                  }
                />
              }
              sx={{ my: 1, p: "16px, 24px, 16px, 24px" }}
              size="medium"
            >
              {translate(`upload_documents`)}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  setIsModalVisible(true);
                }}
              >
                <MailIcon width={20} height={20} />
                <Typography
                  variant="subtitle1"
                  color={theme.palette.action.active}
                  sx={{ marginLeft: 0.5 }}
                >
                  {translate(`upload_by_email`)}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setIsUploadDocumentModalVisible(true);
                }}
              >
                <UploadIcon width={20} height={20} />
                <Typography
                  variant="subtitle1"
                  color={theme.palette.action.active}
                  sx={{ marginLeft: 0.5 }}
                >
                  {translate(`upload_from_device`)}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setIsQRCodeModalVisible(true);
                }}
              >
                <QRcode
                  width={20}
                  height={20}
                  stroke={theme.palette.action.active}
                  // fill={theme.palette.action.active}
                />
                <Typography
                  variant="subtitle1"
                  color={theme.palette.action.active}
                  sx={{ marginLeft: 0.5 }}
                >
                  {translate(`Upload with QR code`)}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate("/dashboard/suppliers");
                }}
              >
                <LinkIcon width={20} height={20} />
                <Typography
                  variant="subtitle1"
                  color={theme.palette.action.active}
                  sx={{ marginLeft: 0.5 }}
                >
                  {translate(`upload_by_supplier`)}
                </Typography>
              </MenuItem>
              {enableDocumentCreateThroughForm && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate(
                      PATH_DASHBOARD.general.documents.general.createDocument
                    );
                  }}
                >
                  <DocumentIcon width={20} height={20} />
                  <Typography
                    variant="subtitle1"
                    color={theme.palette.action.active}
                    sx={{ marginLeft: 0.5 }}
                  >
                    {translate(`create_document`)}
                  </Typography>
                </MenuItem>
              )}
            </Menu>
          </Grid>
        </Grid>
        <EmailUploadModal
          isShowModal={isModalVisible}
          showModalMethod={() => {
            setIsModalVisible(!isModalVisible);
          }}
          modalTitle={"upload_by_email"}
          modalIllustration={<IllustrationUploadEmail />}
          modalContentText={"finance_screen.email_text"}
          dostEmail={dostEmail}
        />
        <QRcodeUploadDialog
          isShowModal={isQRCodeModalVisible}
          showModalMethod={() => {
            setIsQRCodeModalVisible(!isQRCodeModalVisible);
          }}
          modalTitle={"Upload by QR code"}
          modalIllustration={<IllustrationUploadEmail />}
          modalContentText={"finance_screen.email_text"}
          dostEmail={dostEmail}
        />
        {isExportDocumentViaTemplateModalVisible && (
          <ExportDocumentModal
            isShowModal={isExportDocumentViaTemplateModalVisible}
            showModalMethod={onExportDocument}
            documentIds={selected}
            exportDocumentTemplates={exportDocumentTemplates}
          />
        )}

        <UploadDocumentModal
          isShowModal={isUploadDocumentModalVisible}
          showModalMethod={() =>
            setIsUploadDocumentModalVisible(!isUploadDocumentModalVisible)
          }
          renderDocument={renderDocument}
        />
        {isDeleteDocumentModalVisible && (
          <DeleteDocumentModal
            isShowModal={isDeleteDocumentModalVisible}
            showModalMethod={() => {
              setIsDeleteDocumentModalVisible(!isDeleteDocumentModalVisible);
              selected.length = 0;
            }}
            deleteDocument={() => handleDeleteRow()}
            selected={selected}
            tableData={tableData}
            deleteLoader={deleteLoader}
          />
        )}
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filterState?.documentClass || "all"}
          onChange={(e, value) => {
            setFilterState({ ...filterState, documentClass: value });
            searchApi({ ...filterState, documentClass: value });
          }}
        >
          {tabs.map((tab) => (
            <Tab disableRipple key={tab} value={tab} label={translate(tab)} />
          ))}
        </Tabs>
        <SearchFilters
          {...{
            handleSubmit,
            filterState,
            setFilterState,
            optionsApprovalStatus,
            optionsIntegrationStatus,
            optionsStatus,
            loader,
            translate,
            searchApi,
            documentFilterFields,
            documentFieldOperators,
            documentFieldToggle,
          }}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: "relative" }}>
            {selected.length > 0 && (
              <TableSelectedActions
                numSelected={selected?.length}
                rowCount={tableData?.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData?.map((row: DocumentsEntity) => row.id)
                  )
                }
                actions={
                  <Box
                    sx={{
                      px: 3,
                      cursor: "pointer",
                    }}
                  >
                    {enablePaymentFlow && writePayment && (
                      <Tooltip
                        title={translate("make_payment")}
                        placement="top"
                      >
                        <IconButton onClick={onMakePayment}>
                          <EuroIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <TableMoreMenu
                      open={selectAnchorEl}
                      onOpen={handleOpenMenu}
                      onClose={handleCloseMenu}
                      label={translate("Export")}
                      actions={
                        <>
                          <MenuItem
                            onClick={() => {
                              handleDownloadInvoices("invoiceFiles_");
                              handleCloseMenu();
                            }}
                          >
                            <DownloadIcon />

                            {translate("Export original(s)")}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleExportCSVs();
                              handleCloseMenu();
                            }}
                          >
                            <DownloadIcon />
                            {`${translate("Export")} ${translate(
                              exportTemplateExtensionMap[
                                exportTemplateExtension.toLowerCase()
                              ] + (selected?.length > 0 ? "(s)" : "")
                            )}`}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleDownloadJSON();
                              handleCloseMenu();
                            }}
                          >
                            <JsonIcon
                              disableDimension
                              customColor={theme.palette.action.active}
                            />
                            {translate(
                              `Export JSON ${selected?.length > 0 ? "(s)" : ""}`
                            )}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              onExportDocument();
                              handleCloseMenu();
                            }}
                          >
                            <DownloadIcon />
                            {translate(`download_via_template`)}
                          </MenuItem>
                        </>
                      }
                      icon={<DownloadIcon height={20} width={20} />}
                    />

                    <Tooltip title={translate("delete")} placement="top">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setIsDeleteDocumentModalVisible(true);
                        }}
                      >
                        <DeleteIcon
                          height={20}
                          width={20}
                          customColor={theme.palette.action.active}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
              />
            )}

            <Table size={"small"}>
              <TableHeadCustom
                order={order}
                loader={loader}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length}
                numSelected={selected?.length}
                // onSort={onSort}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData?.map((row: DocumentsEntity) => row?.id)
                  )
                }
              />

              <TableBody>
                {isNotFound === false ? (
                  (loader ? SKELETON : dataFiltered)?.map(
                    (row: DocumentsEntity, i: number) => {
                      const messageKeys = dynamicTranslation(
                        row?.messageKeys,
                        "messageKey"
                      );
                      return (
                        <FinanceTableRow
                          key={
                            row?.id
                              ? `tablerow-${row?.id}-${i}`
                              : "skeletonRow_" + i
                          }
                          row={row}
                          selectedItem={selected}
                          selected={selected.includes(row?.id)}
                          onSelectRow={() => onSelectRow(row?.id)}
                          onDeleteSingleRow={() =>
                            handleDeleteSingleDocument(row?.id)
                          }
                          loader={loader}
                          pageQuery={pageQuery}
                          onViewDiscrepancy={() => onViewDiscrepancy(row.id)}
                          messageKeys={messageKeys}
                          onViewHistory={(row, rowId) =>
                            handleDrawerOpen(row, rowId)
                          }
                          onViewMessage={(row) =>
                            handleDrawerOpenMessage(row, null)
                          }
                          onViewDiscrepancyDrawer={(row, rowId) =>
                            handleDrawerOpenDiscrepancy(row, rowId)
                          }
                          exportDocumentTemplates={exportDocumentTemplates}
                        />
                      );
                    }
                  )
                ) : (
                  <TableNoData isNotFound={isNotFound} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box
          sx={{
            position: "relative",
          }}
        >
          <MyTablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            count={noOfItems}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={translate("finance_screen.rows_per_page")}
            page={page - 1}
            onPageChange={(event, page) => {
              onChangePage(event, page + 1);
              const fetchAPIs = async () => {
                setLoader(true);
                await fetchData(page + 1, rowsPerPage, filterState);
                setLoader(false);
              };
              source.cancel("cancel request");
              source = axios.CancelToken.source();
              fetchAPIs();
            }}
            onRowsPerPageChange={(
              event: SelectChangeEvent<HTMLInputElement>
            ) => {
              onChangeRowsPerPage(event, true);
            }}
            sx={{
              "& .MuiTablePagination-selectLabel": { m: 0 },
              "& .MuiTablePagination-displayedRows": { m: 0 },
            }}
          />
        </Box>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData }: { tableData: any }) {
  return tableData;
}

export const applicationTypeMap = {
  "application/csv": "csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/xml": "xml",
  "text/plain": "txt",
  "application/json": "json",
  "application/zip": "zip",
};

export const exportTemplateExtensionMap = {
  csv: "csv",
  xlsx: "excel",
  xml: "XML",
  txt: "text",
  json: "JSON",
  zip: "zip",
};
