import { Tooltip, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import { TableHead } from "@mui/material";
import useLocales from "src/hooks/useLocales";
import { useTheme } from "@mui/material";
import TableRowsIcon from "@mui/icons-material/TableRows";
import ChevronLeftIcon from "src/assets/icon/chevron/left";
import ChevronRightIcon from "src/assets/icon/chevron/right";
import { Divider } from "@mui/material";
import { BootstrapFormInput } from "src/components/form/TextField";

const TableHeader = ({
  getTranslatedFields,
  k,
  handleItemOpen,
  nextPage,
  prevPage,
  page,
  setPage,
  total,
  item,
}) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const palette = theme.palette;
  const iconColor = palette?.primary?.light;

  return (
    <>
      <TableHead
        sx={{
          background: theme.palette.secondary.light,
          color: theme.palette.text.secondary,
          px: 1,
          border: `2px solid ${theme.palette.secondary.light}`,
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          fontWeight: 600,
        }}
      >
        {getTranslatedFields(k)}
        {k !== "general" ? (
          <span>
            {total > 0 && (
              <>
                <BootstrapFormInput
                  size="small"
                  type="number"
                  value={page + 1}
                  sx={{
                    "& .MuiInputBase-input": {
                      maxWidth: "30px",
                      minWidth: "5px",
                      height: "10px",
                      display: "inline",
                      padding: "3px 6px 3px 6px",
                      fontSize: "12px",
                      border: `1px solid ${palette?.primary?.light}`,
                      color: palette?.primary?.light,
                      "&:focus": {
                        borderColor: palette?.primary?.light,
                      },
                    },
                  }}
                  onChange={(e) => {
                    let value = parseInt(e.target.value);
                    if (value > total) {
                      value = total;
                      setPage(value - 1);
                    } else if (value < 1 || isNaN(value)) {
                      value = 1;
                      setPage(0);
                    } else {
                      setPage(value - 1);
                    }
                  }}
                />

                <Typography
                  variant="subtitle2"
                  sx={{
                    marginRight: 0.5,
                    marginLeft: 0.5,
                    display: "inline",
                    color: palette?.primary?.light,
                    fontWeight: "600",
                    cursor: "pointer",
                    "&:hover": { color: "black" },
                  }}
                >
                  {translate("of ")} {total}
                </Typography>
                <Tooltip title={translate("previous_page")}>
                  <IconButton
                    size="small"
                    sx={{
                      marginRight: 1,
                      marginLeft: 1,
                      color: palette?.grey["400"],
                      cursor: "pointer",
                      "&:hover": { color: "black" },
                    }}
                    disabled={page == 0}
                    onClick={() => {
                      prevPage();
                    }}
                  >
                    <ChevronLeftIcon
                      height={16}
                      width={16}
                      color={theme.palette.primary.light}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title={translate("next_page")}>
                  <IconButton
                    disabled={page + 1 >= total}
                    size="small"
                    sx={{
                      color: iconColor,
                      cursor: "pointer",
                      "&:hover": { color: "black" },
                    }}
                    onClick={() => {
                      nextPage();
                    }}
                  >
                    <ChevronRightIcon
                      height={16}
                      width={16}
                      color={theme.palette.primary.light}
                    />
                  </IconButton>
                </Tooltip>
                <Divider
                  orientation="vertical"
                  sx={{
                    height: "20px",
                    width: "20px",
                    color: iconColor,
                    transform: "rotate(270deg)",
                    display: "inline",
                  }}
                />
              </>
            )}
            <Tooltip title={translate("View table")}>
              <span>
                <IconButton
                  id={"tableName"}
                  color="primary"
                  aria-label="see-item-table"
                  component="label"
                  size="small"
                  disabled={k == "general"}
                  onClick={() => {
                    handleItemOpen((item ?? [])?.flat(), k);
                  }}
                >
                  <TableRowsIcon
                    sx={{
                      fontSize: 16,
                      color:
                        k == "general"
                          ? theme.palette.secondary.light
                          : theme.palette.text.secondary,
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </span>
        ) : (
          <span
            style={{
              height: "25px",
              width: "25px",
            }}
          ></span>
        )}
      </TableHead>
    </>
  );
};

export default TableHeader;
