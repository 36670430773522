import { DiscrepancyMessagesEntity } from "src/@types/discrepancy";
import useLocales from "./useLocales";
import { MessageKeysEntity } from "src/@types/processedDocuments";

const useDynamicTranslate = (): {
  dynamicTranslation: (
    messages:
      | MessageKeysEntity[]
      | DiscrepancyMessagesEntity[]
      | null
      | undefined,
    keyName: string
  ) => (string | undefined)[] | undefined;
} => {
  const { translate } = useLocales();

  const dynamicTranslation = (
    messages:
      | MessageKeysEntity[]
      | DiscrepancyMessagesEntity[]
      | null
      | undefined,
    keyName: string
  ): (string | undefined)[] | undefined => {
    const message = messages?.map((item, _) => {
      const keyValue = item[keyName];
      if (!keyValue) return undefined;

      if (item?.values) {
        const value = item?.values?.reduce((obj, value, index) => {
          obj[index] = value;
          return obj;
        }, {});
        const result = translate(keyValue, value);
        return result;
      } else {
        return translate(keyValue);
      }
    });
    return message;
  };

  return { dynamicTranslation };
};

export default useDynamicTranslate;
