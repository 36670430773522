import { useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { SvgIconProps } from "@mui/material";
import React from "react";

export const MailIcon = ({
  color = "action",
  height = 24,
  width = 24,
  sx,
}: SvgIconProps) => {
  const theme = useTheme();
  return (
    <Box sx={sx}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 9L12 13.591L21 9M21 18.9996L12 13.8984L3 18.9996M2 18L2 8.61803C2 8.23926 2.214 7.893 2.55279 7.72361L12 3L21.4472 7.72361C21.786 7.893 22 8.23926 22 8.61803V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18Z"
          stroke={theme.palette?.[color]?.active}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};
