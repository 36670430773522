import Timeline from "@mui/lab/Timeline";
import TimelineTile from "./TimelineTile";
import { useEffect, useState } from "react";
import { GetAsync } from "src/common/httpRequests";
import { API_GATEWAY } from "src/constants/settings";
import TimelineTileSkeleton from "./historySkeleton";
import { timelineItemClasses } from "@mui/lab";
import { useSnackbar } from "notistack";
import useLocales from "src/hooks/useLocales";

export default function OppositeContentTimeline({ id }) {
  const [history, setHistory] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const getHistory = async () => {
    GetAsync(`${API_GATEWAY}/api/DocumentService/Document/${id}/history`)
      .then((res) => {
        if (res.status !== 200) throw new Error(res.statusText);
        setHistory(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        enqueueSnackbar(translate(err.message), { variant: "error" });
      });
  };

  useEffect(() => {
    if (id) {
      getHistory();
    } else if (history != null) {
      setHistory(null);
      setLoading(true);
    }
  }, [id]);

  if (loading)
    return (
      <>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {[1, 2, 3].map((item) => {
            return <TimelineTileSkeleton key={`skeleton-${item}`} />;
          })}
        </Timeline>
      </>
    );

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {history?.historyDetail.map((item) => {
        return <TimelineTile item={item} />;
      })}
    </Timeline>
  );
}
