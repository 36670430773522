import { Box, IconButton } from "@mui/material";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";

const CloseButton = ({
  children,
  handleClose,
}: {
  children?: any;
  handleClose: any;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        pt: 3,
        pr: 3.5,
      }}
    >
      {children?.length > 0 ? children : <Box></Box>}
      <IconButton sx={{ width: 10, height: 8 }} onClick={handleClose}>
        <CloseModalButton />
      </IconButton>
    </Box>
  );
};

export default CloseButton;
