import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import DostLogo from "src/assets/logo/newDostLogoWithoutText.png";
import { BoxProps } from "@mui/material";
import newDostLogo from "../assets/logo/newDostLogo.png";

import { AuthContext } from "../contexts/JWTContext";
interface Props extends BoxProps {
  disabledLink?: boolean;
  isCollapse?: boolean;
}

export default function Logo({ disabledLink = false, isCollapse, sx }: Props) {
  const context = useContext(AuthContext);

  const customLogo = localStorage.getItem("customLogo");

  const logo =
    customLogo && context?.isAuthenticated ? (
      <img src={customLogo} style={{ width: "70px" }} />
    ) : isCollapse ? (
      <img src={DostLogo} alt="Dost Logo" height={"40px"} width={"40px"} />
    ) : (
      <img src={newDostLogo} style={{ width: "100px" }} />
    );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
