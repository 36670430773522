import { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/material";
import { IconButton } from "@mui/material";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import useLocales from "src/hooks/useLocales";
import CopyIcon from "src/assets/icon/copy/CopyIcon";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "src/routes/paths";

interface ModalProps {
  isShowModal: boolean;
  showModalMethod: VoidFunction;
  modalTitle: string;
  modalIllustration: any;
  modalContentText: string;
  dostEmail?: string;
}

export default function EmailUploadModal(props: ModalProps) {
  const {
    isShowModal,
    showModalMethod,
    modalTitle,
    modalIllustration,
    modalContentText,
    dostEmail,
  } = props;
  const theme = useTheme();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("body");
  const [maxWidth] = useState<DialogProps["maxWidth"]>("md");
  const [text, setText] = useState<string>("copy");
  const [icon, setIcon] = useState(<CopyIcon />);
  const descriptionElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (isShowModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      setScroll("body");
    }
  }, [isShowModal]);
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleTooltipOpen = (dostEmail) => {
    setText(`Copied`);
    setIcon(
      <TaskAltIcon sx={{ height: "30px", width: "24px" }} color="success" />
    );

    navigator?.clipboard?.writeText(dostEmail);

    setInterval(() => {
      setText(`copy`);
      setIcon(<CopyIcon />);
    }, 10000);
  };

  return (
    <div style={{ borderRadius: "90px" }}>
      <Dialog
        open={isShowModal}
        onClose={showModalMethod}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title responsive-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={maxWidth}
        sx={{ padding: 4, borderRadius: "32px !important" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 3,
            mr: 3,
          }}
        >
          <IconButton sx={{ width: 10, height: 8 }} onClick={showModalMethod}>
            <CloseModalButton />
          </IconButton>
        </Box>
        <DialogTitle
          id="scroll-dialog-title responsive-dialog-title"
          sx={{ paddingLeft: 4 }}
        >
          {translate(modalTitle)}
        </DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          sx={{ textAlign: "center", minWidth: "780px" }}
        >
          {modalIllustration}

          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <Typography variant="body1">
                {translate(modalContentText)}
              </Typography>
            </Box>
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => handleTooltipOpen(dostEmail)}
              variant="outlined"
              size="small"
              sx={{
                textAlign: "left",
              }}
            >
              <Typography variant="body1">
                {translate(text)} {dostEmail}
              </Typography>
            </Button>
            <Button
              onClick={() => {
                navigate({
                  pathname: PATH_DASHBOARD.general.documents.general.mailInbox,
                });
              }}
              variant="contained"
              size="small"
              sx={{
                mt: 4.5,
                pt: 1,
                ml: 1,
                textAlign: "center",
              }}
            >
              <Typography variant="body1">{translate(`inbox`)}</Typography>
            </Button>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={showModalMethod} variant="outlined" size="medium">
            {translate("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
