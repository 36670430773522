import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { resetLocalStorage } from "src/common/resetLocalStorage";
import { shutDownIntercomm } from "src/intercom";
import { resetMixpanel } from "src/mixpanel/Mixpanel";
import Login from "src/pages/auth/Login";
import { LogoutCred } from "src/redux/slices/credentials";
import { logoutOrganization } from "src/redux/slices/organization";
import { store } from "src/redux/store";
import { PATH_AUTH } from "src/routes/paths";

const NoAccessNavigate = ({ to }: { to?: string }) => {
  const accessToken = store.getState()?.credential?.userCred.accessToken;
  console.log("access token", accessToken);
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  useEffect(() => {
    if (!accessToken) {
      resetMixpanel();
      store.dispatch(LogoutCred());
      store.dispatch(logoutOrganization());
      shutDownIntercomm();
      resetLocalStorage();
      window.location.reload();
    }
  }, [accessToken]);

  if (!accessToken) {
    return null;
  }
  return (
    <>
      <Navigate to={to ? to : "/403"} replace />
    </>
  );
};

export default NoAccessNavigate;
