import { TableCell, Drawer, Box, IconButton, Alert } from "@mui/material";
import { useTheme, Typography, TableContainer } from "@mui/material";
import { Table, TableBody, TableRow, TableHead, Tooltip } from "@mui/material";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import useLocales from "src/hooks/useLocales";
import { useEffect, useState } from "react";
import { IDiscrepancy, ItemDiscrepanciesEntity } from "src/@types/discrepancy";
import { GetAsync } from "src/common/httpRequests";
import { API_GATEWAY } from "src/constants/settings";
import useDynamicTranslate from "src/hooks/useDynamicTranslation";
import { useSnackbar } from "notistack";
import { TableNoData } from "src/components/table";
import { PATH_DASHBOARD } from "src/routes/paths";
import EmptyDocumentsIllustration from "src/assets/illustrations/EmptyDocumentsIllustrations";
import EmptyItemsIllustration from "src/assets/illustrations/EmptyItemsIllustrations";
import DiscrepancyDrawerSkeleton from "./DiscrepancyDrawerSkeleton";
import { FileExtension } from "src/components/FileExtension";
import Label from "src/components/Label";

interface IState {
  loading: boolean;
  discrepancyData: IDiscrepancy;
  errorMsg: string;
}

const DiscrepancyDrawer = ({
  openDrawer,
  handleDrawerClose,
  selectedRowId,
}) => {
  const theme = useTheme();
  const { translate } = useLocales();

  const [state, setState] = useState<IState>({
    loading: false,
    discrepancyData: {} as IDiscrepancy,
    errorMsg: "",
  });
  const { dynamicTranslation } = useDynamicTranslate();
  const [generalDiscrepancyMessages, setGeneralDiscrepancyMessages] = useState<
    (string | undefined)[] | undefined | null
  >([]);
  const { enqueueSnackbar } = useSnackbar();
  const { loading, discrepancyData } = state;
  useEffect(() => {
    const fetchDiscrepancy = async () => {
      setState({ ...state, loading: true });
      GetAsync(
        `${API_GATEWAY}/api/discrepancydetectionservice/documentdiscrepancy/${selectedRowId}`
      )
        .then((res) => {
          if (res?.status >= 200 && res?.status <= 299) {
            const result = dynamicTranslation(
              res?.data?.discrepancyDetection.generalDiscrepancy
                .discrepancyMessages,
              "key"
            );
            setGeneralDiscrepancyMessages(result);
            setState({
              ...state,
              loading: false,
              discrepancyData: res?.data,
            });
          } else {
            setState({
              ...state,
              loading: false,
              errorMsg: res?.data?.Detail,
            });
            enqueueSnackbar(translate(res?.data?.Detail), {
              variant: "error",
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          setState({ ...state, loading: false, errorMsg: err.message });
          enqueueSnackbar(translate(err?.Detail), { variant: "error" });
        });
    };
    if (selectedRowId) fetchDiscrepancy();
  }, [selectedRowId]);

  const { document, matchingDocuments } = discrepancyData;
  const review = async (fileId: string) => {
    window.open(
      `${PATH_DASHBOARD.general.documents.general.review}?fileId=${fileId}`,
      "_blank"
    );
  };

  const handleReviewPage = (id: string) => {
    const fileId = id;
    review(fileId);
  };
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const handleShowTooltip = ({ currentTarget }: React.MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };

  const hideTooltip = () => setTooltipEnabled(false);

  return (
    <>
      <Drawer
        open={openDrawer}
        variant="persistent"
        anchor="right"
        PaperProps={{
          sx: {
            width: {
              xs: "100%",
              sm: 500,
              md: 700,
              lg: 700,
              xl: 700,
            },
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: "16px",
            bgcolor: "background.paper",
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.standard,
              }),
            boxShadow: (theme) => theme.customShadows.z24,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography variant="h6">
              {translate("Document Discrepancy")}
            </Typography>
          </Box>
          <IconButton sx={{ width: 10, height: 8 }} onClick={handleDrawerClose}>
            <CloseModalButton />
          </IconButton>
        </Box>
        {loading ? (
          <DiscrepancyDrawerSkeleton />
        ) : (
          <Box
            sx={{
              overflow: "auto",
              height: "calc(100vh - 64px)",
            }}
          >
            <Box
              sx={{
                padding: 2,
              }}
            >
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  {translate(`Document detail`)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 2,
                  border: "1px solid " + theme.palette.grey[300],
                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <DataRow
                      label="document_name"
                      value={discrepancyData?.document?.name}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <DataRow
                      label="document_id"
                      chip
                      value={discrepancyData?.document?.id}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Tooltip title={document?.name} placement="top">
                  <Typography
                    variant="subtitle1"
                    noWrap
                    sx={{ my: 1, textOverflow: "ellipsis", width: 455 }}
                  >
                    {translate(`Related documents`)}
                  </Typography>
                </Tooltip>
                {matchingDocuments?.length == 0 && (
                  <Label>{translate("No documents Found")}</Label>
                )}
              </Box>
              <TableContainer
                sx={{
                  maxHeight: "500px",
                }}
              >
                <Table size="small" sx={{}}>
                  {Array.isArray(matchingDocuments) &&
                  matchingDocuments?.length ? (
                    <TableHead>
                      <TableRow>
                        <TableCell>{translate(`name`)}</TableCell>
                        <TableCell>{translate(`document_number`)}</TableCell>
                        <TableCell>{translate(`document_type`)}</TableCell>
                      </TableRow>
                    </TableHead>
                  ) : (
                    <></>
                  )}
                  <TableBody>
                    {Array.isArray(matchingDocuments) &&
                    matchingDocuments?.length ? (
                      matchingDocuments?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleReviewPage(item.documentId)}
                          >
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <FileExtension
                                ext={item?.documentName?.split(".")?.pop()}
                                height="20px"
                                width={"20px"}
                                style={{
                                  color: theme.palette.text.greyLight,
                                }}
                              />
                              <Tooltip
                                title={item?.documentName}
                                onMouseEnter={handleShowTooltip}
                                onMouseLeave={hideTooltip}
                                disableHoverListener={!tooltipEnabled}
                                placement="top"
                                arrow
                              >
                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  sx={{
                                    textOverflow: "ellipsis",
                                    width: 250,
                                    cursor: "pointer",
                                  }}
                                >
                                  {item?.documentName}
                                </Typography>
                              </Tooltip>
                            </Box>
                          </TableCell>
                          <TableCell>{item?.documentNumber}</TableCell>
                          <TableCell>{translate(item?.documentType)}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <></>
                      // <TableNoData
                      //   isNotFound={!!discrepancyData?.deliveryNotes}
                      //   title={translate(`no documents`)}
                      //   description={translate("No related documents found.")}
                      //   image={<EmptyDocumentsIllustration />}
                      //   size="small"
                      // />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <Typography gutterBottom variant="subtitle1" component="div">
                  {translate(`discrepancies`)}
                </Typography>
              </Box>
              {((
                discrepancyData?.discrepancyDetection?.generalDiscrepancy
                  ?.messages ?? []
              )?.length > 0 ||
                discrepancyData?.discrepancyDetection?.itemDiscrepancies
                  ?.length !== 0) && (
                <Box sx={{ my: 1 }}>
                  <Alert severity="warning" variant="outlined" icon={false}>
                    <ul
                      style={{
                        paddingLeft: "16px",
                      }}
                    >
                      {discrepancyData?.discrepancyDetection?.generalDiscrepancy
                        ?.messages &&
                      (
                        discrepancyData?.discrepancyDetection
                          ?.generalDiscrepancy?.messages ?? []
                      )?.length >= 1
                        ? generalDiscrepancyMessages?.map((item, index) => (
                            <Typography
                              fontSize={12}
                              key={index}
                              sx={{
                                wordBreak: " break-word" as "break-word",
                                py: 0.5,
                              }}
                            >
                              <li>{item}</li>
                            </Typography>
                          ))
                        : ""}
                      {discrepancyData?.discrepancyDetection?.itemDiscrepancies
                        ?.length !== 0 ? (
                        discrepancyData?.discrepancyDetection?.itemDiscrepancies?.map(
                          (item: ItemDiscrepanciesEntity, index) => {
                            const messageKeys = dynamicTranslation(
                              item?.discrepancyMessages,
                              "key"
                            );
                            return (
                              <>
                                <Typography
                                  fontSize={12}
                                  key={index}
                                  sx={{
                                    wordBreak: " break-word" as "break-word",
                                    py: 0.5,
                                  }}
                                >
                                  <li>
                                    <b>
                                      {" "}
                                      {item?.description
                                        ? item?.description
                                        : "-"}
                                      {item?.referenceCode
                                        ? ` / ${item?.referenceCode}`
                                        : ""}
                                      {" : "}
                                    </b>
                                    {messageKeys?.map((item, _) => item)}
                                  </li>
                                </Typography>
                              </>
                            );
                          }
                        )
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Alert>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Drawer>
    </>
  );
};

export default DiscrepancyDrawer;

type DataRowProps = {
  label: string;
  value: string;
  chip?: boolean;
};
export const DataRow = ({ label, value, chip }: DataRowProps) => {
  const { translate } = useLocales();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 1,
      }}
    >
      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            color: (theme) => theme.palette.text.greyLight,
          }}
        >
          {translate(label)}
        </Typography>
      </Box>
      <Box>
        {chip ? (
          <Label
            variant="outlined"
            color="success"
            sx={{
              borderRadius: "4px",
              fontSize: "10px",
              color: "text.secondary",
              bgcolor: "secondary.light",
              border: "none",
              fontStyle: "normal",
              fontStretch: "extra-expanded",
              py: "1px",
              height: "16px",
            }}
          >
            {value || "-"}
          </Label>
        ) : (
          <Typography variant="subtitle1">{value || "-"}</Typography>
        )}
      </Box>
    </Box>
  );
};
