import { Table, TableBody, TableContainer, TableHead } from "@mui/material";
import { TableCell } from "@mui/material";
import { Tooltip } from "@mui/material";
import { useTheme } from "@mui/material";
import { Typography } from "@mui/material";
import { Box, Skeleton, TableRow } from "@mui/material";
import EmptyDocumentsIllustration from "src/assets/illustrations/EmptyDocumentsIllustrations";
import EmptyItemsIllustration from "src/assets/illustrations/EmptyItemsIllustrations";
import { TableNoData } from "src/components/table";
import useLocales from "src/hooks/useLocales";

const DiscrepancyDrawerSkeleton = () => {
  const theme = useTheme();
  const { translate } = useLocales();
  return (
    <>
      <Box
        sx={{
          overflow: "auto",
          height: "calc(100vh - 64px)",
        }}
      >
        <Box
          sx={{
            padding: 2,
          }}
        >
          <Box>
            <Skeleton width={120} height={20} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 2,
              border: "1px solid " + theme.palette.grey[300],
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <DataRow />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <DataRow />
              </Box>
            </Box>
          </Box>
          <Box sx={{ my: 2 }}></Box>
          <Skeleton width={120} height={20} />
          <TableContainer>
            <Table size="small" sx={{}}>
              <TableBody>
                <TableNoData
                  isNotFound
                  title={<Skeleton width={120} height={20} />}
                  description={<Skeleton width={160} height={20} />}
                  image={
                    <Skeleton variant="rectangular" height={30} width={40} />
                  }
                  size="small"
                />
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Skeleton width={120} height={20} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DiscrepancyDrawerSkeleton;

export const DataRow = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 1,
      }}
    >
      <Box>
        <Skeleton width={120} height={20} />
      </Box>
      <Box>
        <Skeleton width={120} height={20} />
      </Box>
    </Box>
  );
};
