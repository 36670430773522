import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

type ApiState = {
    isLoading: boolean;
    error: Error | string | null;
    dashboard: any;

};

const initialState: ApiState = {
    isLoading: false,
    error: null,
    dashboard: {}
};

const slice = createSlice({
    name: 'ApiData',
    initialState,
    reducers: {
        dashboardAction(state, action) {
            state.dashboard = action.payload;
        },
        dashboardApiManupulationAction(state, action) {
            const { key, value } = action.payload;
            state.dashboard.productBumpers[key] = value
        },
    },
});


// Reducer
export default slice.reducer;

// Actions
export const { dashboardAction, dashboardApiManupulationAction } = slice.actions;

export function dashboard(data: any) {
    return async () => {
        dispatch(dashboardAction(data));
    };
}

export function dashboardApiManupulation(data: any) {
    return async () => {
        dispatch(dashboardApiManupulationAction(data));
    };
}