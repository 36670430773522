//form
import { useFormContext, Controller } from 'react-hook-form'
//textfield
import { TextField, TextFieldProps } from '@mui/material';

//Phone Number
import MuiPhoneNumber from 'material-ui-phone-number';


type IProps = {
  name: any;
}

type Props = IProps & TextFieldProps;

export default function RHFPhoneNumber({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiPhoneNumber {...field} {...other}
          onChange={(e) => {
            field.onChange({
              target: {
                value: e
              }
            })
          }}
          variant='outlined'
          error={!!error}
          helperText={error?.message}
          fullWidth
          defaultCountry="es"
        />
      )}
    />
  );
}