import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Card, Stack, Link, Container, Typography } from "@mui/material";
import { PATH_AUTH } from "../../routes/paths";
import useResponsive from "../../hooks/useResponsive";
import useLocales from "../../hooks/useLocales";
import Page from "../../components/Page";
import { LoginForm } from "../../sections/auth/login";
import { useTheme } from "@mui/material/styles";
import { textStyle } from "../../theme/style";
import svgImage from "src/assets/logo/LoginBackground.png";

// ----------------------------------------------------------------------

export const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

export const SectionStyle = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "50%",
  display: "flex",
  justifyContent: "center",
  backgroundImage: `url(${svgImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "99%",
}));

const ContentStyle = styled(Card)(({ theme }) => ({
  maxWidth: "100%",
  margin: "80px auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(4, 3, 0, 3),
  borderRadius: "32px",
  backgroundColor: theme.palette.greyShade.lighter,
}));

export default function Login() {
  const { translate } = useLocales();
  const smUp = useResponsive("up", "sm");
  const theme = useTheme();
  const { mode } = theme?.palette;
  const mdUp = useResponsive("up", "md");

  return (
    <Page title="Login">
      <RootStyle>
        {mdUp && (
          <SectionStyle>
            <Box sx={{ width: "80%", marginTop: 25 }}>
              <Typography
                variant="body1"
                fontWeight={700}
                fontSize={40}
                color={theme.palette.text.secondary}
              >
                {translate(`login_tag_line`)}
              </Typography>

              <Typography variant="body1" sx={{ mt: 3 }}>
                {translate("authentication_screen.signin_header_text")} {""}
                <Link
                  variant="subtitle2"
                  component={RouterLink}
                  to={PATH_AUTH.newRegister}
                  sx={{
                    color: theme.palette.secondary.dark,
                    fontWeight: "bold",
                  }}
                >
                  {translate("authentication_screen.get_started")}
                </Link>
              </Typography>
            </Box>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {translate("authentication_screen.signin_title")}
                </Typography>
                <Typography variant="h6" sx={{ marginTop: "16px" }}>
                  {translate("authentication_screen.signin_text")}
                </Typography>
              </Box>
            </Stack>

            <LoginForm />

            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                {translate("authentication_screen.forgot_password")}
                <Link
                  // variant="subtitle2"
                  component={RouterLink}
                  to={PATH_AUTH.preRegister}
                  sx={{ color: textStyle.palette.color[mode] }}
                >
                  {translate("authentication_screen.get_started")}
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
