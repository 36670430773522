import { IconButton, Tooltip, useTheme } from "@mui/material";
import { Box, Typography } from "@mui/material";
import ChevronLeftIcon from "src/assets/icon/chevron/left";
import ChevronRightIcon from "src/assets/icon/chevron/right";
import useLocales from "src/hooks/useLocales";

type PaginationProps = {
  page: number;
  record: number;
  count: number;
  onChangePage: (event: any, newPage: number) => void;
};

const Pagination = ({ page, record, count, onChangePage }: PaginationProps) => {
  const theme = useTheme();
  const { palette } = theme;
  const iconColor = palette?.grey["600"];
  const { translate } = useLocales();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "& svg": { m: 1.5 },
      }}
    >
      <Typography variant="subtitle2">
        {page * record + 1}-
        {page * record + record > count ? count : page * record + record}{" "}
        {translate("of_")}&nbsp;
        {count}
      </Typography>
      <Tooltip title={translate("previous_page")}>
        <span>
          <IconButton
            size="small"
            disabled={page === 0}
            sx={{
              marginRight: 1,
              marginLeft: 1,
              height: "30px",
              width: "30px",
              color: palette?.grey["400"],
              bgcolor: palette?.grey["300"],
              cursor: "pointer",
              "&:hover": { color: "black" },
              "&:disabled": {
                color: palette?.grey["400"],
                bgcolor: palette?.grey["300"],
              },
            }}
            onClick={(e) => onChangePage(e, page - 1)}
          >
            <ChevronLeftIcon
              height={18}
              width={18}
              color={
                page === 0
                  ? palette?.action.disabled
                  : theme.palette.primary.light
              }
            />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={translate("next_page")}>
        <span>
          <IconButton
            size="small"
            disabled={page * record + record >= count}
            sx={{
              height: "30px",
              width: "30px",
              color: iconColor,
              bgcolor: palette?.grey["300"],
              cursor: "pointer",
              "&:hover": { color: "black" },
              "&:disabled": {
                color: palette?.grey["400"],
                bgcolor: palette?.grey["300"],
              },
            }}
            onClick={(e) => onChangePage(e, page + 1)}
          >
            <ChevronRightIcon
              height={18}
              width={18}
              color={
                page * record + record >= count
                  ? palette?.action.disabled
                  : theme.palette.primary.light
              }
            />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default Pagination;
