import {
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { NewTextFormField } from "src/components/form/TextField";
import { ReactComponent as SearchIcon } from "src/assets/icon/search.svg";
import useLocales from "src/hooks/useLocales";
import { useForm } from "react-hook-form";
import { MailIcon } from "src/assets/icon/Mail";
import FilterMenu from "src/components/filter/FilterMenu";

const SearchFilters = ({
  loader,
  searchApi,
  filterState,
  setFilterState,
  count,
  approvers,
  optionsAllowReviewStatus,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { translate } = useLocales();
  const { handleSubmit } = useForm();
  const [filters, setFilters] = useState<any>({});
  const [initialValues, setInitialValues] = useState<any>(filterState);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let rows = Math.floor(
    ((inputRef.current as HTMLTextAreaElement)?.scrollHeight - 5) / 24
  );

  const handleChange = (name: string, value: any) => {
    setInitialValues({
      ...initialValues,
      [name]: value,
    });
  };

  const filterSettersMap = {
    name: (value = "") => {
      filters.name = {
        name: "name",
        value: value,
        displayValue: value,
        defaultValue: null,
        label: `Workflow name`,
      };
    },
    approver: (value = "") => {
      filters.approver = {
        name: "approver",
        value: value,
        displayValue: value,
        defaultValue: null,
        label: `Approver`,
      };
    },
    allowReviewDocument: (value = "") => {
      filters.allowReviewDocument = {
        name: "allowReviewDocument",
        value: value,
        displayValue:
          value.toString() == "true" ? translate(`Yes`) : translate(`No`),
        type: "select",
        defaultValue: "",
        label: `Review allowed`,
      };
    },
  };

  const handleFilters = (initialValues: any) => {
    Object.entries(initialValues).forEach(([key, value]) => {
      if (value != "" && value != undefined) {
        filterSettersMap?.[key]?.(value);
      } else {
        delete filters[key];
      }
    });
    setFilters(filters);
  };

  const handleOnDelete = (k: string) => {
    delete filters[k];
    setFilters(filters);
    setFilterState({
      ...initialValues,
      [k]: undefined,
    });
    setInitialValues({
      ...initialValues,
      [k]: undefined,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        py: 1,
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "70%",
            lg: "70%",
            xl: "50%",
          },
          py: 1,
        }}
      >
        <FilterMenu
          filters={filters}
          inputRef={inputRef}
          anchorEl={anchorEl}
          open={open}
          handleOpenMenu={handleOpenMenu}
          handleCloseMenu={handleCloseMenu}
          handleOnDelete={handleOnDelete}
          rows={rows}
          loader={loader}
        >
          <form
            onSubmit={handleSubmit(() => {
              setFilterState(initialValues);
              handleFilters(initialValues);
              searchApi(initialValues);
              handleCloseMenu();
            })}
          >
            <Grid
              container
              spacing={2}
              direction={{ xs: "column", sm: "column", md: "row" }}
              sx={{
                py: 1,
                display: "flex",
                flexWrap: {
                  xs: "wrap",
                  sm: "wrap",
                  md: "nowrap",
                },
              }}
            >
              <Grid item xs={12} sm={6}>
                <NewTextFormField
                  id="outlined-controlled"
                  label={translate(`Workflow name`)}
                  value={initialValues?.name}
                  disabled={loader}
                  onChange={(event: React.FocusEvent<HTMLInputElement>) => {
                    handleChange("name", event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  value={initialValues?.approver}
                  disabled={loader}
                  fullWidth
                  input={
                    <NewTextFormField
                      label={translate(`Approver`)}
                      startAdornment={
                        <MailIcon
                          height={22}
                          width={22}
                          sx={{
                            position: "absolute",
                            left: "6px",
                            top: "calc(50% - 12px)",
                            pointerEvents: "none",
                            color: "#657482",
                            zIndex: 10,
                          }}
                        />
                      }
                    />
                  }
                  onChange={(event: SelectChangeEvent) => {
                    handleChange("approver", event.target.value);
                  }}
                >
                  {approvers?.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              direction={{ xs: "column", sm: "row" }}
              sx={{ py: 1, display: "flex", alignItems: "flex-end" }}
            >
              <Grid item xs={12} sm={6}>
                <Select
                  value={initialValues?.allowReviewDocument}
                  disabled={loader}
                  fullWidth
                  displayEmpty
                  input={
                    <NewTextFormField
                      label={translate(`Allow review document`)}
                    />
                  }
                  onChange={(event: SelectChangeEvent) => {
                    handleChange("allowReviewDocument", event.target.value);
                  }}
                >
                  {optionsAllowReviewStatus?.map((item) => {
                    return (
                      <MenuItem key={item} value={item.enum}>
                        {item.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                size="small"
                startIcon={<SearchIcon height={20} width={20} stroke="white" />}
                type="submit"
                sx={{ width: "fit-content" }}
              >
                {translate(`finance_screen.search`)}
              </Button>
            </Box>
          </form>
        </FilterMenu>
      </Box>
    </Box>
  );
};

export default SearchFilters;
