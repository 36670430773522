import { useTheme } from "@mui/material";
import { SvgIconProps } from "@mui/material";

export const DownloadIcon = ({
  color = "action",
  height = 24,
  width = 24,
}: SvgIconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9996 15L11.9996 3M15.9996 12.0002L11.9996 16.0318L7.9996 12.0002M17 5.00192H18C20.2091 5.00192 22 6.79278 22 9.00192V17.0019C22 19.2111 20.2091 21.0019 18 21.0019H6C3.79086 21.0019 2 19.2111 2 17.0019L2 9.00192C2 6.79278 3.79086 5.00192 6 5.00192H7"
        stroke={theme.palette?.[color]?.active}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
