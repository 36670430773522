// @mui
import { Breakpoint } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------

type Query = "up" | "down" | "between" | "only";
type Key = Breakpoint | number;
type Start = Breakpoint | number;
type End = Breakpoint | number;

export default function useResponsive(
  query: Query,
  key?: Key,
  start?: Start,
  end?: End
) {
  const theme = useTheme();

  const breakPointMap = {
    up: theme.breakpoints.up(key as Key),
    down: theme.breakpoints.down(key as Key),
    between: theme.breakpoints.between(start as Start, end as End),
    only: theme.breakpoints.only(key as Breakpoint),
  };
  const media = useMediaQuery(breakPointMap[query]);
  return media;
}
