import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';

type ConfigurationState = {
  configurationDetail: any;
};

const initialState: ConfigurationState = {
  configurationDetail: {}
};

const slice = createSlice({
  name: 'Configuration',
  initialState,
  reducers: {
    configurationDetailAction(state, action) {
      state.configurationDetail = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions 
export const { configurationDetailAction } = slice.actions;

export function setConfiguration(data: any) {
  return async () => {
    dispatch(configurationDetailAction(data));
  };
}