import { Box, SvgIconProps } from "@mui/material";

type Props = {
  customColor?: string;
  height?: number;
  width?: number;
  color?: string;
  disableDimension?: boolean;
} & SvgIconProps;
const JsonIcon = ({
  height = 20,
  width = 20,
  color = "primary",
  customColor = "#657482",
  disableDimension = false,
}: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        ...(!disableDimension && {
          height: height,
          width: width,
        }),
      }}
    >
      <svg
        width="41"
        height="40"
        viewBox="0 0 41 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4001 21.2499C14.1536 21.2466 13.9136 21.1705 13.7102 21.0312C13.5069 20.8918 13.3492 20.6955 13.2572 20.4668C13.1651 20.2381 13.1427 19.9873 13.1928 19.746C13.2429 19.5046 13.3633 19.2834 13.5387 19.1103C13.7142 18.9371 13.9369 18.8197 14.1789 18.7728C14.421 18.7259 14.6714 18.7516 14.8989 18.8467C15.1263 18.9418 15.3206 19.102 15.4572 19.3072C15.5938 19.5124 15.6667 19.7534 15.6667 19.9999C15.6624 20.3329 15.527 20.6508 15.2899 20.8848C15.0528 21.1188 14.7332 21.2499 14.4001 21.2499Z"
          fill={customColor ?? color}
        />
        <path
          d="M20.8 21.25C21.4904 21.25 22.05 20.6904 22.05 20C22.05 19.3096 21.4904 18.75 20.8 18.75C20.1097 18.75 19.55 19.3096 19.55 20C19.55 20.6904 20.1097 21.25 20.8 21.25Z"
          fill={customColor ?? color}
        />
        <path
          d="M27.4667 21.2832C27.2155 21.2865 26.969 21.2151 26.7585 21.0779C26.548 20.9407 26.3831 20.7441 26.2846 20.513C26.1862 20.2818 26.1587 20.0266 26.2057 19.7798C26.2527 19.533 26.372 19.3058 26.5484 19.1269C26.7249 18.9481 26.9506 18.8258 27.1967 18.7756C27.4429 18.7253 27.6984 18.7494 27.9308 18.8448C28.1632 18.9402 28.3621 19.1025 28.502 19.3111C28.6419 19.5198 28.7167 19.7653 28.7167 20.0166C28.7167 20.3496 28.5856 20.6693 28.3516 20.9064C28.1176 21.1435 27.7997 21.2788 27.4667 21.2832Z"
          fill={customColor ?? color}
        />
        <path
          d="M3.54996 21.25C3.2723 21.2516 3.002 21.1608 2.7817 20.9918C2.56139 20.8228 2.40363 20.5852 2.33329 20.3166C2.25128 19.9963 2.29776 19.6566 2.46281 19.3701C2.62786 19.0836 2.89839 18.873 3.21663 18.7833C3.21663 18.7833 7.31663 17.6666 7.31663 15V7.49995C7.30292 6.93152 7.40478 6.36623 7.61603 5.83834C7.82727 5.31044 8.1435 4.83094 8.54556 4.42888C8.94761 4.02683 9.42712 3.71059 9.95501 3.49935C10.4829 3.2881 11.0482 3.18625 11.6166 3.19995C11.9481 3.19995 12.2661 3.33165 12.5005 3.56607C12.7349 3.80049 12.8666 4.11843 12.8666 4.44995C12.8666 4.78147 12.7349 5.09941 12.5005 5.33383C12.2661 5.56825 11.9481 5.69995 11.6166 5.69995C11.3736 5.67937 11.129 5.71237 10.9002 5.79663C10.6713 5.88089 10.4637 6.01436 10.2921 6.1876C10.1204 6.36085 9.98885 6.56966 9.9067 6.7993C9.82456 7.02893 9.79381 7.2738 9.81663 7.51662V15C9.81663 19.6166 4.09996 21.1666 3.84996 21.2333L3.54996 21.25Z"
          fill={customColor ?? color}
        />
        <path
          d="M11.6167 36.8C11.5119 36.8174 11.4049 36.8174 11.3 36.8C11.05 36.8 5.33338 35.1333 5.33338 30.5667V23.05C5.3533 22.81 5.32097 22.5686 5.23863 22.3423C5.15629 22.116 5.02589 21.9102 4.8564 21.7391C4.68692 21.5681 4.48238 21.4358 4.25686 21.3513C4.03135 21.2669 3.79021 21.2323 3.55005 21.25C3.21853 21.25 2.90059 21.1183 2.66617 20.8839C2.43174 20.6495 2.30005 20.3315 2.30005 20C2.30005 19.6685 2.43174 19.3505 2.66617 19.1161C2.90059 18.8817 3.21853 18.75 3.55005 18.75C4.11566 18.7387 4.67773 18.8417 5.20253 19.0529C5.72733 19.2642 6.20405 19.5793 6.60407 19.9793C7.0041 20.3793 7.31919 20.856 7.53043 21.3808C7.74168 21.9056 7.84472 22.4677 7.83338 23.0333V30.5333C7.83338 33.2 11.8834 34.3333 11.9167 34.35C12.0775 34.3867 12.2292 34.4558 12.3625 34.5529C12.4958 34.6501 12.6079 34.7734 12.6921 34.9152C12.7763 35.0571 12.8308 35.2146 12.8522 35.3781C12.8736 35.5417 12.8615 35.7079 12.8167 35.8667C12.7497 36.1339 12.5951 36.371 12.3776 36.5402C12.1601 36.7094 11.8922 36.8008 11.6167 36.8Z"
          fill={customColor ?? color}
        />
        <path
          d="M38.0667 21.2499H37.7501C37.5001 21.2499 31.7834 19.5833 31.7834 15.0166V7.49994C31.8036 7.25845 31.771 7.01545 31.6877 6.78788C31.6043 6.56031 31.4724 6.35364 31.3011 6.18228C31.1297 6.01092 30.923 5.87899 30.6955 5.79569C30.4679 5.71238 30.2249 5.67971 29.9834 5.69994C29.6519 5.69994 29.3339 5.56825 29.0995 5.33383C28.8651 5.09941 28.7334 4.78146 28.7334 4.44994C28.7334 4.11842 28.8651 3.80048 29.0995 3.56606C29.3339 3.33164 29.6519 3.19994 29.9834 3.19994C30.5504 3.18632 31.1143 3.28768 31.641 3.4979C32.1678 3.70813 32.6465 4.02286 33.0483 4.42312C33.4501 4.82338 33.7667 5.30088 33.979 5.82682C34.1913 6.35275 34.2948 6.91623 34.2834 7.48328V14.9999C34.2834 17.6666 38.3334 18.7999 38.3667 18.7999C38.688 18.8861 38.9626 19.0953 39.131 19.3822C39.2994 19.6691 39.3481 20.0107 39.2667 20.3333C39.1966 20.5974 39.0406 20.8308 38.8234 20.9967C38.6062 21.1627 38.34 21.2517 38.0667 21.2499Z"
          fill={customColor ?? color}
        />
        <path
          d="M29.9835 36.8C29.6853 36.7924 29.3997 36.6784 29.1782 36.4786C28.9567 36.2788 28.8139 36.0064 28.7757 35.7106C28.7375 35.4148 28.8063 35.1151 28.9697 34.8656C29.1331 34.616 29.3803 34.4332 29.6668 34.35C29.6668 34.35 33.7668 33.2333 33.7668 30.5333V23.05C33.7532 22.483 33.8545 21.9191 34.0647 21.3924C34.275 20.8656 34.5897 20.3869 34.99 19.9851C35.3902 19.5833 35.8677 19.2667 36.3937 19.0544C36.9196 18.8421 37.4831 18.7386 38.0501 18.75C38.3816 18.75 38.6996 18.8817 38.934 19.1161C39.1684 19.3505 39.3001 19.6685 39.3001 20C39.3001 20.3315 39.1684 20.6495 38.934 20.8839C38.6996 21.1183 38.3816 21.25 38.0501 21.25C37.8084 21.232 37.5657 21.2669 37.3389 21.3523C37.1121 21.4377 36.9067 21.5716 36.7369 21.7445C36.5671 21.9175 36.4371 22.1253 36.3559 22.3537C36.2747 22.582 36.2443 22.8254 36.2668 23.0667V30.5333C36.2668 35.15 30.5501 36.7 30.3001 36.7667C30.1971 36.7952 30.0901 36.8065 29.9835 36.8Z"
          fill={customColor ?? color}
        />
      </svg>
    </Box>
  );
};

export default JsonIcon;
