import { Mixpanel } from "./Mixpanel.js";

export default function MixpanelUnsuccess({ ...props }) {
  const { eventName, eventSource, Useremail, status } = props;
  if (eventSource == "Authentication") {
    let auth_props = { $email: Useremail, $status: status };
    Mixpanel.identify(Useremail);
    Mixpanel.track(eventName, auth_props);
  }
  return;
}

export { MixpanelUnsuccess };
