import {
  TableRow,
  TableCell,
  TableHead,
  Typography,
  Checkbox,
  SxProps,
} from "@mui/material";
import useLocales from "src/hooks/useLocales";
import FileIcon from "src/assets/icon/file";
import { Theme } from "@mui/material";

type Props = {
  order?: "asc" | "desc";
  orderBy?: string;
  headLabel: any[];
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: string) => void;
  onSelectAllRows?: (checked: boolean) => void;
  sx?: SxProps<Theme>;
  loader?: boolean;
};

export default function TableHeadCustom({
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSort,
  onSelectAllRows,
  loader,
  sx,
}: Props) {
  const { translate } = useLocales();
  const TABLE_HEAD = [
    {
      id: "invoiceNumber",
      label: translate(`Document Name`),
      align: "left",
      icon: (
        <FileIcon
          sx={{
            mr: "7px",
          }}
        />
      ),
    },
    {
      id: "workflowName",
      label: translate(`Workflow Name`),
      align: "left",
    },
    {
      id: "date",
      label: translate(`Processed on`),
      align: "left",
    },
    {
      id: "status",
      label: translate(`Status in workflow`),
    },
    {
      id: "review",
      label: translate(`Action`),
      align: "left",
    },
  ];
  return (
    <TableHead>
      <TableRow>
        {onSelectAllRows && (
          <TableCell padding="checkbox">
            <Checkbox
              size="small"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSelectAllRows(event.target.checked)
              }
            />
          </TableCell>
        )}

        {TABLE_HEAD.map((headCell, index) => (
          <TableCell key={headCell.id} align={"left"}>
            <Typography
              variant="subtitle2"
              sx={{
                display: "flex",
                fontWeight: "bold",
                alignItems: "center",
              }}
            >
              {headCell.icon}
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
