export const unifyItemFields = (fields) => {
  let fields_ = structuredClone(fields);
  fields_?.forEach((field) => {
    if (field.itemsFields?.length > 0) {
      let itemsArray: any[] = [];
      let itemsAttribute: any[] = [];
      field.itemsFields?.forEach((item, i) => {
        item?.item?.forEach((l) => {
          if (itemsArray?.[i] === undefined) {
            itemsArray[i] = {
              [l.name]: { ...l },
            };
            itemsAttribute.push(l.name);
          } else {
            let obj = itemsArray[i];
            obj[l.name] = { ...l };
            itemsArray[i] = obj;
            itemsAttribute.push(l.name);
          }
        });
        itemsAttribute = itemsAttribute.filter(function (item, pos) {
          return itemsAttribute.indexOf(item) === pos;
        });
      });
      let itemsArrayMerged: any[] = [];
      itemsArray?.forEach((item_) => {
        let obj = item_;
        itemsAttribute?.forEach((attr) => {
          if (item_[attr] === undefined) {
            obj[attr] = {
              id: null,
              name: attr,
              processedValue: null,
              value: "",
              confidence: 0,
              pageNumber: null,
              isMetaDataValue: false,
              isFieldReviewRequired: false,
              isMandatory: false,
              boundingPolygon: null,
            };
          }
        });
        itemsArrayMerged.push(Object.values(obj));
      });
      field.itemsFields = itemsArrayMerged?.map((item, i) => {
        return { item: item, isRemoved: false, index: i };
      });
    }
  });
  return fields_;
};
