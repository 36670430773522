import * as React from "react";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { API_GATEWAY } from "src/constants/settings";
import { PostFileAsync } from "src/common/httpRequests";
import { UploadMultiFile } from "../../../components/upload";
//SnackBar
import { useSnackbar } from "notistack";
//Locales
import useLocales from "src/hooks/useLocales";
import { ALLOWED_DOCUMENT_TYPES } from "src/constants/settings";

export default function UploadDocumentModal(props) {
  const { isShowModal, showModalMethod, renderDocument } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  const translator = (key: string) => {
    return translate(`finance_screen.${key}`);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [files, setFiles] = useState<any[]>([]);

  const handleDropMultiFile = React.useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles([...files, ...newFiles]);
    },
    [files]
  );

  const handleRemoveFile = (inputFile) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const maxFiles = 10;
    const names = files.map((value) => value.name);

    const duplicates = names.filter(
      (name, index, arr) => arr.indexOf(name) !== index
    );

    for (let i = 0; i < duplicates.length; i++) {
      if (duplicates[i] === files[i].name) {
        setIsLoading(false);
        return enqueueSnackbar(translator("duplicate_files_are_not_allowed"), {
          variant: "error",
        });
      }
    }

    if (files.length > maxFiles) {
      setIsLoading(false);
      return enqueueSnackbar(translator("files_cannot_be_more_than_10"), {
        variant: "error",
      });
    }
    const formData = new FormData();
    for (let file of files) {
      formData.append("documents", file, file.name);
    }
    const res = await PostFileAsync(
      `${API_GATEWAY}/api/documentservice/Document/Upload`,
      formData
    );
    try {
      showModalMethod();
      handleRemoveAllFiles();

      const count: string[] = [];
      const docTypes: string[] = [];
      const docNames: string[] = [];

      if (res?.status === 200) {
        res?.data?.documents?.map(
          (item: {
            fileName: string;
            hasError: boolean;
            id: string;
            message: string;
          }) => {
            if (item.hasError) {
              enqueueSnackbar(
                `${translator("document_upload_unsuccessful")}: ${translator(
                  item.message
                )}`,
                { variant: "error" }
              );
            } else {
              docNames.push(item.fileName);
              let docType = item.fileName.split(".");
              docTypes.push(docType[1].toUpperCase());
              count.push(item.id);
            }
          }
        );

        if (count.length != 0) {
          enqueueSnackbar(
            `${count.length}
             ${translator("document_uploaded_successfully")}`,
            { variant: "success" }
          );
        }
      } else {
        enqueueSnackbar(translator("document_upload_unsuccessful"), {
          variant: "error",
        });
      }
      renderDocument();
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
    setIsLoading(false);
  };
  return (
    <Modal
      open={isShowModal}
      onClose={showModalMethod}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <React.Fragment>
        <UploadMultiFile
          loading={isLoading}
          multiple={true}
          accept={ALLOWED_DOCUMENT_TYPES}
          files={files}
          onDrop={handleDropMultiFile}
          onRemove={handleRemoveFile}
          onRemoveAll={handleRemoveAllFiles}
          onUpload={() => onSubmit()}
          handleCloseModal={() => showModalMethod()}
        />
      </React.Fragment>
    </Modal>
  );
}
