import { Box } from "@mui/material";
import { useTheme } from "@mui/material";

const CloseModalButton = () => {
  const theme = useTheme();

  return (
    <Box>
      <svg
        width="30"
        height="30"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="20" fill="#E4E6EB" />
        <path
          d="M12 12L28 28M12 28L28 12"
          stroke="#657482"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default CloseModalButton;

/*
 */
