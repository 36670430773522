import { Grid } from "@mui/material";

interface XMLDataViewProps {
  values: any;
  currentPage: number;
  invoiceType: string;
  onClick: any;
  next: any;
  previous: any;
  setValues: any;
}

const XMLDataView = (props: XMLDataViewProps) => {
  props?.values.fields.forEach((l: any, index: number) => {
    if (l.pageNumber === null && l.itemsFields == null) {
      l.key = `fields.${index}`;
      l.category = "general";
    }
    if (l.itemsFields?.length > 0) {
      l.itemsFields?.forEach((item, idx) => {
        item?.item?.forEach((i: any, ind: number) => {
          if (i.pageNumber == null) {
            i.key = `fields.${index}.itemsFields.${idx}.item.${ind}`;
            i.category = l.name;
            l.category = l.name;
          }
        });
        l.key = `fields.${index}`;
      });
    }
  });
  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        sx={{
          mt: 4,
          ml: 2,
          cursor: "pointer",
          height: "78vh",
          display: "flex",
          justifyContent: "center",
          top: { md: 30 },
          width: { md: "200px" },
        }}
      ></Grid>
    </Grid>
  );
};

export default XMLDataView;
