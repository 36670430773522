import {
  DefaultNamespace,
  KeyPrefix,
  Namespace,
  useTranslation,
  UseTranslationOptions,
  UseTranslationResponse,
} from "react-i18next";
import useSettings from "./useSettings";
// config
import { allLangs, defaultLang } from "../config";
import { Localization } from "@mui/material/locale";

// ----------------------------------------------------------------------
type Lang = {
  label: string;
  value: string;
  systemValue: Localization;
  icon: string;
  disabled: boolean;
};
export default function useLocales<
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined
>(
  ns?: N | Readonly<N>,
  options?: UseTranslationOptions<TKPrefix>
): {
  onChangeLang: (newlang: string) => void;
  translate: (text: any, options?: any) => string;
  currentLang: Lang;
  allLangs: Lang[];
} {
  const { i18n, t: translate } = useTranslation(ns, options);

  const { onChangeDirectionByLang } = useSettings();

  const langStorage = localStorage.getItem("i18nextLng");

  const currentLang =
    allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
    onChangeDirectionByLang(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text: any, options?: any) => translate(text, options),
    currentLang,
    allLangs,
  };
}
