import { Table, useTheme, TableContainer } from "@mui/material";
import DataDescription from "../../description/dataDescription";
import useLocales from "src/hooks/useLocales";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import TableHeader from "./TableHead";

const PostProcessedTable = forwardRef(
  (
    props: {
      k: string;
      item: any;
      title: string;
      handleItemOpen: any;
    },
    ref
  ) => {
    const { k, item, handleItemOpen, title } = props;
    const theme = useTheme();
    const { translate } = useLocales();
    const tableRef = useRef<HTMLDivElement>(null);
    const getTranslatedFields = (title) => {
      let result = translate(`review_screen.review_fields.${title}`);
      if (result.indexOf("review_screen") == -1) {
        return result;
      }
      return title;
    };
    const [page, setPage] = useState(0);

    const nextPage = () => {
      if (page + 1 >= item?.length) {
        return;
      }
      setPage(page + 1);
    };

    const prevPage = () => {
      if (page - 1 < 0) {
        return;
      }
      setPage(page - 1);
    };
    const goToPage = (page_) => {
      let page = parseInt(page_);
      if (page >= item?.length || page < 0) {
        return;
      }
      setPage(page);
    };

    useImperativeHandle(ref, () => ({
      nextPage,
      prevPage,
      goToPage,
    }));
    return (
      <TableContainer
        sx={{
          borderRadius: "8px",
          border: `3px solid ${theme.palette.secondary.light}`,
          mt: 2,
        }}
      >
        <Table
          aria-label="collapsible table"
          size="small"
          sx={{
            px: 2,
            maxHeight: "35vh",
          }}
        >
          <TableHeader
            {...{
              getTranslatedFields,
              k,
              handleItemOpen,
              nextPage,
              prevPage,
              setPage,
              page,
              item,
              total: item?.length,
            }}
          />
          {k == "general" ? (
            <div id={k} style={{ overflow: "auto", maxHeight: "30vh" }}>
              {item
                ?.sort((a: any, b: any) => {
                  if (
                    getTranslatedFields(a?.key).toLowerCase() >
                    getTranslatedFields(b?.key).toLowerCase()
                  ) {
                    return 1;
                  }
                  if (
                    getTranslatedFields(a?.key).toLowerCase() <
                    getTranslatedFields(b?.key).toLowerCase()
                  ) {
                    return -1;
                  }
                  return 0;
                })
                ?.map((item_) => (
                  <DataDescription
                    key={""}
                    isEditable={false}
                    title={item_.key}
                    bgColor={item_.color}
                    description={item_?.value}
                    isHighlighted={title === item_.key ? true : false}
                    item={item_}
                  />
                ))}
            </div>
          ) : (
            <div
              id={k}
              ref={tableRef}
              style={{ overflow: "auto", maxHeight: "30vh" }}
            >
              {item?.[page]
                ?.sort((a: any, b: any) => {
                  if (
                    getTranslatedFields(a?.key).toLowerCase() >
                    getTranslatedFields(b?.key).toLowerCase()
                  ) {
                    return 1;
                  }
                  if (
                    getTranslatedFields(a?.key).toLowerCase() <
                    getTranslatedFields(b?.key).toLowerCase()
                  ) {
                    return -1;
                  }
                  return 0;
                })
                ?.map((item_) => (
                  <DataDescription
                    key={""}
                    isEditable={false}
                    title={item_.key}
                    bgColor={item_.color}
                    description={item_?.value}
                    isHighlighted={title === item_.key ? true : false}
                    item={item_}
                  />
                ))}
            </div>
          )}
        </Table>
      </TableContainer>
    );
  }
);

export default PostProcessedTable;
