import { useState } from "react";

const useReviewToolbar = () => {
  const [stayMultiHighlighted, setStayMultiHighlighted] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [events, setEvents] = useState<any>([]);
  const [combineAreas, setCombineAreas] = useState<any>(new Map());

  const gatherEvents = (event) => {
    let event_ = {
      pageX: event.pageX,
      pageY: event.pageY,
    };
    events.push(event_);
    setEvents(events);
  };

  const avgEvent = () => {
    let pageX = 0;
    let pageY = 0;
    events.forEach((event) => {
      pageX += event.pageX;
      pageY += event.pageY;
    });
    if (events.length == 0) return { pageX, pageY };
    pageX = pageX / events.length;
    pageY = pageY / events.length;
    return { pageX, pageY };
  };

  const toolbarprops = {
    stayMultiHighlighted,
    editMode,
    events,
    combineAreas,
  };

  const addAreaToCombine = (area: any) => {
    combineAreas[area.id] = area;
    setCombineAreas(combineAreas);
  };

  const generateLine = () => {
    let bp = calculateCoordinates(Object.values(combineAreas));
    let content = getContent(Object.values(combineAreas));
    let line = {
      content: content,
      boundingPolygon: {
        lowerLeft: { x: bp[0], y: bp[1] },
        lowerRight: { x: bp[2], y: bp[3] },
        upperLeft: { x: bp[4], y: bp[5] },
        upperRight: { x: bp[6], y: bp[7] },
      },
    };
    return line;
  };

  const resetState = () => {
    setStayMultiHighlighted(false);
    setEditMode(false);
    setEvents([]);
    setCombineAreas([]);
  };

  const editOptionsProps = {
    setStayMultiHighlighted,
    setEditMode,
    gatherEvents,
    avgEvent,
    setEvents,
    addAreaToCombine,
    setCombineAreas,
    generateLine,
    resetState,
  };

  return { toolbarprops, editOptionsProps };
};

export default useReviewToolbar;

export const calculateCoordinates = (areas: any[]) => {
  let boundingPolygon = [0, 0, 0, 0, 0, 0, 0, 0];
  areas.map((area: any) => {
    let bp = area.boundingPolygon;
    if (bp?.lowerLeft?.x > boundingPolygon[0] || boundingPolygon[0] == 0) {
      boundingPolygon[0] = bp?.lowerLeft?.x;
    }
    if (bp?.lowerLeft?.y > boundingPolygon[1] || boundingPolygon[1] == 0) {
      boundingPolygon[1] = bp?.lowerLeft?.y;
    }
    if (bp?.lowerRight?.x < boundingPolygon[2] || boundingPolygon[2] == 0) {
      boundingPolygon[2] = bp?.lowerRight?.x;
    }
    if (bp?.lowerRight?.y > boundingPolygon[3] || boundingPolygon[3] == 0) {
      boundingPolygon[3] = bp?.lowerRight?.y;
    }
    if (bp?.upperLeft?.x < boundingPolygon[4] || boundingPolygon[4] == 0) {
      boundingPolygon[4] = bp?.upperLeft?.x;
    }
    if (bp?.upperLeft?.y < boundingPolygon[5] || boundingPolygon[5] == 0) {
      boundingPolygon[5] = bp?.upperLeft?.y;
    }
    if (bp?.upperRight?.x > boundingPolygon[6] || boundingPolygon[6] == 0) {
      boundingPolygon[6] = bp?.upperRight?.x;
    }
    if (bp?.upperRight?.y < boundingPolygon[7] || boundingPolygon[7] == 0) {
      boundingPolygon[7] = bp?.upperRight?.y;
    }
  });
  return boundingPolygon;
};

export const getContent = (areas: any[]) => {
  let content = "";
  areas?.map((area: any) => {
    content += area.title + " ";
  });
  return content;
};
