import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// routes
import { PATH_DASHBOARD, PATH_AUTH } from "../routes/paths";
import { AGENT_URL, IS_AGENT } from "src/constants/settings";

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated } = useAuth();
  const IS_AGENT = true;

  //Only For Agent
  const url = window.location.href;
  var fields = url.split("/");

  const loginRoutes = fields.includes("login");
  const registerRoute = fields.includes("new-register");
  //
  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  //Only For Agent
  if (IS_AGENT && !isAuthenticated && fields[2] === AGENT_URL && !loginRoutes) {
    return <Navigate to={PATH_AUTH.login} />;
  }
  //

  // if (!!registerRoute) {
  //   return <Navigate to={PATH_AUTH.login} />
  // }

  return <>{children}</>;
}
