import { useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { SvgIconProps } from "@mui/material";

export const DocumentIcon = ({
  color = "action",
  height = 24,
  width = 24,
  customColor,
}: SvgIconProps & {
  customColor?: string;
}) => {
  const theme = useTheme();
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.51758 8H9.09822M6.51758 11.977H17.4853M6.51758 15.9979H17.4853M22 18V6C22 4.89543 21.1046 4 20 4L4 4C2.89543 4 2 4.89543 2 6L2 18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18Z"
          stroke={customColor ?? theme.palette?.[color]?.active}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};
