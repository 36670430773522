import {
  Box,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  useAutocomplete,
  useTheme,
} from "@mui/material";
import React, { useImperativeHandle, useState } from "react";
import ChevronDown from "src/assets/icon/chevron/ChevronDown";
import Multiply from "src/assets/icon/multiply/Multiply";
import { ReactComponent as SearchIcon } from "src/assets/icon/search.svg";
import useLocales from "src/hooks/useLocales";
import MyErrorHelperText from "../denseForm/ErrorHelperText";
import NewTextField, { BootstrapInput } from "../form/TextField";
import Pagination from "./../pagination/Pagination";

type Option = {
  label: string;
  value: string;
};

export type Dense = "normal" | "dense" | "denser";
export type Variant = "primary" | "secondary";

export const MenuAutocomplete = React.forwardRef(
  (
    {
      InputLabelProps,
      value,
      onChange,
      onBlur,
      disabled,
      sxProps,
      denser,
      options,
      optionTitle,
      dense = "normal",
      variant = "primary",
      multiline = true,
      placeholder,
      ...props
    }: {
      options: Option;
      InputLabelProps?: any;
      label?: string;
      disabled?: boolean;
      value: string;
      dense?: Dense;
      variant?: Variant;
      onChange: (newValue: string) => void;
      placeholder?: string;
      onBlur: () => void;
    } & any,
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const handleOpenMenu = () => {
      setOpen(true);
    };
    const handleCloseMenu = () => {
      setOpen(false);
    };
    const inputRef = React.useRef<HTMLTextAreaElement>(null);
    const theme = useTheme();

    useImperativeHandle(
      ref,
      () => ({
        handleOpen() {
          console.log("handleRef");
          handleOpenMenu();
        },
      }),
      []
    );

    const inputProps = {
      fullWidth: true,
      // ref: ref,
      multiline: multiline,
      value: value?.label ?? "",
      disabled: disabled,
      error: props.error,
      readOnly: true,
      placeholder: placeholder,
      onBlur: onBlur,
      sx: {
        "& .MuiInputBase-input": {
          ...sxProps,
          ...(dense === "normal"
            ? {
                padding: "5px 48px 5px 10px",
                height: "25px",
              }
            : dense === "dense"
            ? {
                padding: "5px 48px 5px 10px",
                height: "20px",
                lineHeight: "16px",
              }
            : {}),
          ...(variant === "secondary"
            ? {
                border: "1px solid #528ACC",
              }
            : {}),
          ...(props.error && {
            backgroundColor: theme.palette.error.light,
            borderColor: theme.palette.error.dark,
            color: theme.palette.error.dark,
            "& svg": {
              color: theme.palette.error.dark,
            },
            "&::placeholder": {
              opacity: 0.8,
              color: theme.palette.error.dark,
            },
            "&:focus": {
              borderColor: theme.palette.error.dark,
              boxShadow: `0 0 0 0.01rem ${theme.palette.error.dark}`,
            },
          }),
        },
        "& .MuiInputBase-root": {
          p: 0,
        },
      },
      endAdornment: (
        <InputAdornment
          position="end"
          sx={{
            zIndex: 10,
            position: "absolute",
            right: "5px",
            cursor: "pointer",
          }}
        >
          <IconButton
            size="small"
            onClick={(e) => {
              onChange(e, null);
            }}
          >
            <Multiply
              height={12}
              width={12}
              customColor={
                props?.error
                  ? theme.palette.error.dark
                  : theme.palette.action.active
              }
            />
          </IconButton>
          <IconButton
            size="small"
            onClick={(e) => {
              handleOpenMenu();
            }}
          >
            <ChevronDown
              height={15}
              width={15}
              customColor={
                props?.error
                  ? theme.palette.error.dark
                  : theme.palette.action.active
              }
            />
          </IconButton>
        </InputAdornment>
      ),
    };
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          p: 0,
          gap: "4px",
        }}
        ref={inputRef}
      >
        {props.label && (
          <InputLabel
            htmlFor="demo-customized-textbox"
            sx={{
              fontSize: "12px",
              fontWeight: 700,
              color: theme.palette.action.active,
              ...InputLabelProps,
              ...(disabled ? { color: theme.palette.action.disabled } : {}),
            }}
          >
            {props.label}
          </InputLabel>
        )}
        <BootstrapInput {...inputProps} />
        <AutocompleteDialog
          {...{
            options,
            onChange,
            open,
            handleCloseMenu,
            optionTitle,
          }}
        />
        <MyErrorHelperText error={!!props.error} helperText={props.error} />
      </Box>
    );
  }
);

export default MenuAutocomplete;

const AutocompleteDialog = ({
  options,
  onChange,
  open,
  handleCloseMenu,
  optionTitle,
}) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [page, setPage] = React.useState(0);

  const onInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: string
  ) => {
    setInputValue(value);
    setPage(0);
  };

  const handleGetOptionLabel = (option: Option) => `${option.label}`;
  const handleFilterOption = (options, state) => {
    if (state.inputValue === "") {
      return options;
    }
    return options.filter(
      (option) =>
        option.label.toLowerCase().includes(state.inputValue.toLowerCase()) ||
        option.value.toLowerCase().includes(state.inputValue.toLowerCase())
    );
  };
  const handleChange = (event, value) => onChange(value);

  const { getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete({
      options: options,
      inputValue: inputValue,
      onInputChange: onInputChange,
      getOptionLabel: handleGetOptionLabel,
      onChange: handleChange,
      filterOptions: handleFilterOption,
    });
  const handleChangePage = (event: any, newPage: number) => {
    console.log("event", event, newPage);
    setPage(newPage);
  };
  const rowsPerPage = 10;

  const getOptions = () =>
    groupedOptions?.length > 0
      ? groupedOptions
      : inputValue == ""
      ? options
      : [];
  return (
    <Dialog
      open={open}
      onClose={handleCloseMenu}
      scroll="body"
      hideBackdrop
      maxWidth={"sm"}
      fullWidth
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          p: 2,
        }}
      >
        <InputLabel
          htmlFor="demo-customized-textbox"
          sx={{
            fontSize: "12px",
            fontWeight: 700,
            color: theme.palette.action.active,
          }}
        >
          {optionTitle}
        </InputLabel>
        <NewTextField
          {...getInputProps?.()}
          placeholder={translate("Type keywords for description or value..")}
          fullWidth
          startAdornment={
            <Box
              sx={{
                position: "absolute",
                left: "6px",
                top: "calc(50% - 12px)",
                pointerEvents: "none",
                color: "#657482",
                zIndex: 10,
              }}
            >
              <SearchIcon
                style={{
                  stroke: theme?.palette?.secondary?.main,
                  height: "20px",
                  width: "20px",
                }}
              />
            </Box>
          }
        />
        <Box
          {...getListboxProps()}
          component={"ul"}
          sx={{
            padding: 0,
            display: "flex",
            gap: "16px",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              maxHeight: "300px",
              overflowY: "auto",
              padding: "8px 0px",
              minHeight: "200px",
            }}
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    ml: 1,
                  }}
                >
                  {translate("Description")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    ml: 2,
                  }}
                >
                  {translate("Value")}
                </Typography>
              </Grid>
            </Grid>
            {getOptions()
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((option: Option, index) => (
                <Grid
                  container
                  component="li"
                  key={option.value}
                  {...getOptionProps({ option, index })}
                  sx={{
                    alignItems: "flex-start",
                    padding: "8px 8px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                  onClick={() => {
                    onChange(null, option);
                    handleCloseMenu();
                  }}
                >
                  <Grid item xs={6}>
                    <Typography sx={{ fontSize: "11px", color: "#657482" }}>
                      {option.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ fontSize: "11px", color: "#657482", ml: 2 }}
                    >
                      {option.value}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            {getOptions()?.length == 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexGrow: 1,
                  p: 3,
                }}
              >
                <Typography variant="subtitle2">
                  {translate("No options")}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <Box
            sx={{
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              page={page}
              count={
                groupedOptions?.length > 0
                  ? groupedOptions?.length
                  : inputValue == ""
                  ? options?.length
                  : 0
              }
              record={rowsPerPage}
              onChangePage={handleChangePage}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
