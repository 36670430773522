import { useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { SvgIconProps } from "@mui/material";
import React from "react";

export const SmallCloseIcon = ({
  color = "primary",
  height = "8px",
  width = "15.86px",
  sx,
}: //   sx = {},
SvgIconProps) => {
  const theme = useTheme();
  return (
    <Box sx={{ ...sx }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.8707 5.87188L10.1288 10.1299M5.8707 10.1299L10.1288 5.87188M8 14C4.68626 14 2 11.3137 2 8C2 4.68626 4.68626 2 8 2C11.3137 2 14 4.68626 14 8C14 11.3137 11.3137 14 8 14Z"
          stroke={theme.palette?.[color]?.light}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};
