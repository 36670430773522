import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
// ----------------------------------------------------------------------
type StepperState = {
    activeStep: number;
    quickBooksCred: {}
};

const initialState: StepperState = {
    activeStep: 0,
    quickBooksCred: {
        ClientId: "",
        ClientSecret: "",
        AuthorizationToken: "",
        CompanyRealmId: "",
        ExpenseAccountName: ""
    }
};

const slice = createSlice({
    name: 'Stepper',
    initialState,
    reducers: {
        stepperAction(state, action) {
            state.activeStep = action.payload;
        },
        quickBooksAction(state, action) {
            state.quickBooksCred = action.payload
        }
    },
});


// Reducer
export default slice.reducer;

// Actions
export const { stepperAction, quickBooksAction } = slice.actions;

export function Stepper(step: number) {
    return async () => {
        dispatch(stepperAction(step));
    };
}
export function QuickBooksCred(obj: any) {
    return async () => {
        dispatch(quickBooksAction(obj));
    };
}