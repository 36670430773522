import { Tooltip } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { TableCell, TableRow } from "@mui/material";
import InfoIcon from "src/assets/icon/info";
import useLocales from "src/hooks/useLocales";

export function Headings({ headers }: { headers: Array<any> }) {
  const { translate } = useLocales();
  const getTranslatedFields = (title) => {
    let result = translate(`review_screen.review_fields.${title}`);
    if (result.indexOf("review_screen") == -1) {
      return result;
    }
    return title;
  };
  const theme = useTheme();
  return (
    <TableRow>
      <TableCell align="left" sx={{ fontWeight: "bold" }}>
        {translate("Line")}
      </TableCell>
      {headers
        ?.sort((a: any, b: any) => {
          if (
            getTranslatedFields(a).toLowerCase() >
            getTranslatedFields(b).toLowerCase()
          ) {
            return 1;
          }
          if (
            getTranslatedFields(a).toLowerCase() <
            getTranslatedFields(b).toLowerCase()
          ) {
            return -1;
          }
          return 0;
        })
        .map((header) => (
          <>
            <TableCell align="left" sx={{ fontWeight: "bold" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                {getTranslatedFields(header)}
                <Tooltip title={`${header}`} placement="top">
                  <Box>
                    <InfoIcon
                      height={16}
                      width={16}
                      customColor={theme.palette.text.primary}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </TableCell>
          </>
        ))}
    </TableRow>
  );
}
