import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Typography, useTheme } from "@mui/material";
import { formatDistanceToNow, formatRelative } from "date-fns";
import { DocumentIcon } from "src/assets/icon/Document";
import TimelinePopover from "./Popover";
import { es } from "date-fns/locale";
import TickIcon from "src/assets/icon/tick";
import CrossIcon from "src/assets/icon/cross";
import Label from "src/components/Label";
import useLocales from "src/hooks/useLocales";
import React, { ElementType, Suspense, lazy, useState } from "react";
import useResponsive from "src/hooks/useResponsive";

const TimelineTile = ({ item }) => {
  const theme = useTheme();
  const { currentLang, translate } = useLocales();
  const { stateName, datetime, isFailed, failureReason, ...rest } = item;
  const [dateAnchorEl, setDateAnchorEl] = useState<HTMLElement | null>(null);
  const handleDatePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    let { currentTarget } = event;
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setDateAnchorEl(event.currentTarget);
    }
  };
  const handleDatePopoverClose = () => {
    setDateAnchorEl(null);
  };

  const [stateNameAnchorEl, setStateNameAnchorEl] =
    useState<HTMLElement | null>(null);

  const handleStateNamePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    let { currentTarget } = event;
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setStateNameAnchorEl(event.currentTarget);
    }
  };
  const handleStateNamePopoverClose = () => {
    setStateNameAnchorEl(null);
  };
  const isDesktop = useResponsive("up", "sm");
  return (
    <TimelineItem>
      {isDesktop && (
        <TimelineOppositeContent
          sx={{
            flex: 0,
            minWidth: 100,
          }}
          color="text.secondary"
        >
          <Typography variant="body2" color="text.secondary">
            {formatRelative(new Date(datetime), new Date(), {
              locale: currentLang.value === "es" ? es : undefined,
            })}
          </Typography>
        </TimelineOppositeContent>
      )}
      <TimelineSeparator>
        <TimelineDot
          variant="outlined"
          sx={{
            height: 22,
            width: 22,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <HistoryIcon height={15} width={15} name={stateName} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        {!isDesktop && (
          <Box
            sx={{
              flex: 0,
              minWidth: 100,
              py: 0.5,
            }}
            color="text.secondary"
          >
            <Typography variant="body2" color="text.secondary">
              {formatRelative(new Date(datetime), new Date(), {
                locale: currentLang.value === "es" ? es : undefined,
              })}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            border: "1px solid #C4CDD5",
            borderRadius: 1,
            bgcolor: "background.paper",
            width: "300px",
          }}
        >
          <Box
            sx={{
              bgcolor: theme.palette.secondary.light,
              py: 0.5,
              px: 1,
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              onMouseEnter={handleStateNamePopoverOpen}
              onMouseLeave={handleStateNamePopoverClose}
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 600,
                maxWidth: 192,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {translate(stateName)}
            </Typography>
            <TimelinePopover
              title={translate(stateName)}
              anchorEl={stateNameAnchorEl}
              handlePopoverClose={handleStateNamePopoverClose}
            />
            <Typography
              variant="body2"
              onMouseEnter={handleDatePopoverOpen}
              onMouseLeave={handleDatePopoverClose}
              sx={{
                fontSize: 10,
                color: theme.palette.text.secondary,
                maxWidth: 70,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {formatDistanceToNow(new Date(datetime), {
                addSuffix: true,
                includeSeconds: true,
                ...(currentLang.value === "es" && { locale: es }),
              })}
            </Typography>
            <TimelinePopover
              title={formatDistanceToNow(new Date(datetime), {
                addSuffix: true,
                includeSeconds: true,
                ...(currentLang.value === "es" && { locale: es }),
              })}
              anchorEl={dateAnchorEl}
              handlePopoverClose={handleDatePopoverClose}
            />
          </Box>
          <Box>
            {Object.entries(rest).map(([key, value]) => {
              if (typeof value == "object" && value !== null) {
                return <></>;
              } else if (typeof value == "object" && Array.isArray(value)) {
                return <></>;
              }
              return <LineFields valueKey={key} value={value} />;
            })}
            {isFailed && (
              <>
                <LineFields valueKey="isFailed" value={isFailed} />
                <LineFields valueKey="failureReason" value={failureReason} />
              </>
            )}
          </Box>
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

export default TimelineTile;

const LineFields = ({ valueKey, value }) => {
  const { translate } = useLocales();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 1,
        py: 0.5,
      }}
    >
      <Typography variant="body2" sx={{ color: "text.greyLight" }}>
        {translate(valueKey)}
      </Typography>
      <FormatValue valueKey={valueKey} value={value} />
    </Box>
  );
};

const FormatValue = ({ valueKey, value }: any) => {
  const [valueAnchorEl, setValueAnchorEl] = useState<HTMLElement | null>(null);

  const handleValuePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    let { currentTarget } = event;
    // if (currentTarget.scrollWidth > currentTarget.clientWidth) {
    setValueAnchorEl(event.currentTarget);
    // }
  };
  const handleValuePopoverClose = () => {
    setValueAnchorEl(null);
  };
  const [copied, setCopied] = useState(false);
  const theme = useTheme();
  if (valueKey.startsWith("is") || valueKey.startsWith("has")) {
    if (value) return <TickIcon height={16} width={16} />;
    return <CrossIcon height={16} width={16} />;
  } else if (value) {
    if (valueKey.endsWith("status") || valueKey.endsWith("Status")) {
      return (
        <Label
          sx={{
            bgcolor: "background.neutral",
            color: "text.greyLight",
            border: `1px solid ${theme.palette.text.disabled}`,
            p: 0.125,
            px: 1,
            height: "0.9rem",
            fontWeight: 500,
            fontSize: "10px",
            my: "auto",
            cursor: "pointer",
          }}
        >
          {value as any}
        </Label>
      );
    } else
      return (
        <>
          <Typography
            variant="body2"
            onMouseEnter={handleValuePopoverOpen}
            onMouseLeave={handleValuePopoverClose}
            sx={{
              alignContent: "right",
              textAlign: "right",
              overflow: "hidden",
              maxWidth: 150,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onClick={() => {
              setCopied(true);
              navigator.clipboard.writeText(value);
              setTimeout(() => {
                setCopied(false);
              }, 2000);
            }}
          >
            {value}
          </Typography>
          <TimelinePopover
            title={value}
            anchorEl={valueAnchorEl}
            handlePopoverClose={handleValuePopoverClose}
            clickToCopy
            copied={copied}
          />
        </>
      );
  } else return <></>;
};
const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  );
};
const GitForkIcon = Loadable(lazy(() => import("src/assets/icon/git-fork")));
const FilterIcon = Loadable(lazy(() => import("src/assets/icon/filter")));

const HistoryIcon = ({ height, width, name }) => {
  if (name?.toLowerCase().indexOf("integrate") !== -1) {
    return <GitForkIcon height={height} width={width} />;
  }
  if (name?.toLowerCase().startsWith("workflow")) {
    return <FilterIcon height={height} width={width} />;
  }
  return <DocumentIcon height={height} width={width} />;
};
