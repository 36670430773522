import React from "react";
import { SvgIconProps } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

const TemplateIcon = ({
  color = "primary",
  width = 24,
  height = 25,
  customColor = "#657482",
  sx,
  disableDimension = false,
}: SvgIconProps & {
  customColor?: string;
  disableDimension?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...(disableDimension
          ? {}
          : {
              height: height,
              width: width,
            }),
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 12.3252H20M14 16.3252H20M14 20.3252H20M4 5.3252C4 5.05998 4.10536 4.80563 4.29289 4.61809C4.48043 4.43055 4.73478 4.3252 5 4.3252H19C19.2652 4.3252 19.5196 4.43055 19.7071 4.61809C19.8946 4.80563 20 5.05998 20 5.3252V7.3252C20 7.59041 19.8946 7.84477 19.7071 8.0323C19.5196 8.21984 19.2652 8.3252 19 8.3252H5C4.73478 8.3252 4.48043 8.21984 4.29289 8.0323C4.10536 7.84477 4 7.59041 4 7.3252V5.3252ZM4 13.3252C4 13.06 4.10536 12.8056 4.29289 12.6181C4.48043 12.4306 4.73478 12.3252 5 12.3252H9C9.26522 12.3252 9.51957 12.4306 9.70711 12.6181C9.89464 12.8056 10 13.06 10 13.3252V19.3252C10 19.5904 9.89464 19.8448 9.70711 20.0323C9.51957 20.2198 9.26522 20.3252 9 20.3252H5C4.73478 20.3252 4.48043 20.2198 4.29289 20.0323C4.10536 19.8448 4 19.5904 4 19.3252V13.3252Z"
          stroke={customColor ?? theme.palette[color]?.main}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

TemplateIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  sx: PropTypes.object,
};

TemplateIcon.defaultProps = {
  color: "primary",
  width: 24,
  height: 25,
};

export default TemplateIcon;
