import {
  DialogActions,
  DialogContent,
  IconButton,
  InputLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { Box, Dialog, DialogTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  DocumentsEntity,
  ExportDocumentTemplatesEntity,
} from "src/@types/processedDocuments";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import { FormProvider, RHFAutocomplete } from "src/components/hook-form";
import * as Yup from "yup";
import useLocales from "src/hooks/useLocales";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { GetAsync } from "src/common/httpRequests";
import { API_GATEWAY } from "src/constants/settings";
import { useSnackbar } from "notistack";
import services from "src/common/fileManagerService";

interface ExportDocumentProps {
  isShowModal: boolean;
  showModalMethod: VoidFunction;
  documentIds: string | string[];
  document?: DocumentsEntity;
  exportDocumentTemplates: ExportDocumentTemplatesEntity[];
}

interface FormValueProps {
  documentIds: string | string[];
  templateId: string;
}

const ExportDocumentModal: React.FC<ExportDocumentProps> = (props) => {
  const {
    isShowModal,
    exportDocumentTemplates,
    documentIds,
    document,
    showModalMethod,
  } = props;
  const { translate } = useLocales();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const exportDocumentSchema = Yup.object().shape({
    documentIds: Yup.lazy((val) =>
      Array.isArray(val) ? Yup.array().of(Yup.string()) : Yup.string()
    ),
    templateId: Yup.string()
      .required(translate(`template_is_required`))
      .typeError(translate(`template_is_required`)),
  });

  const methods = useForm<FormValueProps>({
    resolver: yupResolver(exportDocumentSchema),
    defaultValues: {
      documentIds,
      templateId: "",
    },
  });

  const {
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = methods;

  async function exportDocumentCSV(values: FormValueProps) {
    if (Array.isArray(values.documentIds)) {
      const template = exportDocumentTemplates.find(
        (item, index) => item.templateId == values.templateId
      );

      const res: any = await services.DownloadViaTemplate(
        values,
        `documents_${template?.templateName}.zip`
      );

      if (res && res.status !== 200) {
        enqueueSnackbar(res?.Detail, { variant: "error" });
      }
      showModalMethod();
    } else {
      await GetAsync(
        `${API_GATEWAY}/api/documentservice/documentDownload/${values.documentIds}/csv?templateId=${values.templateId}`
      )
        .then((res) => {
          if (res?.status === 200) {
            const fileType = res?.headers?.["content-type"].split("/").pop();
            let file: Blob | string;
            const fileName = document ? document.fileName.split(".")[0] : "";
            let blob = new Blob([res.data], {
              type: res.headers?.["content-type"],
            });
            file = window.URL.createObjectURL(blob);
            const link = window.document.createElement("a");
            link.setAttribute("href", file);
            link.setAttribute("download", `${fileName}.${fileType}`);
            window.document.body.appendChild(link);
            link.click();
            link.remove();
            showModalMethod();
          } else {
            enqueueSnackbar(res?.data?.Detail, { variant: "error" });
          }
        })
        .catch((err) => {
          console.log("Error", err);
          enqueueSnackbar(translate(`something_went_wrong`), {
            variant: "error",
          });
        });
    }
  }

  return (
    <Dialog
      open={isShowModal}
      onClose={showModalMethod}
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(exportDocumentCSV)}
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" sx={{ textAlign: "left", my: 1 }}>
              {translate(`download_via_template`)}
            </Typography>
            <IconButton
              sx={{
                height: "10px",
                width: "8px",
              }}
              onClick={showModalMethod}
            >
              <CloseModalButton />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <InputLabel
            htmlFor="demo-customized-textbox"
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              transform: "translate(0, 1.5px) scale(0.75)",
              color: theme.palette.action.active,
            }}
          >
            {translate("template")}
          </InputLabel>
          <RHFAutocomplete
            name={`templateId`}
            options={exportDocumentTemplates.map((item, index) => ({
              value: item.templateId,
              label: item.templateName,
            }))}
          />
        </DialogContent>
        <DialogActions
          sx={{ marginTop: 2, display: "flex", justifyContent: "center" }}
        >
          <Button
            type="button"
            size="medium"
            variant="outlined"
            sx={{ marginRight: 1, width: "20%" }}
            onClick={showModalMethod}
          >
            {translate(`cancel`)}
          </Button>
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            size="medium"
            variant="contained"
          >
            {translate(`download`)}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default ExportDocumentModal;
