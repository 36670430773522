import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Container(theme: Theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "8px",
        },
        maxWidthLg: {
          maxWidth: 1140,
          px: 1,
        },
        maxWidthXl: {
          maxWidth: 1140,
          px: 1,
        },
        pl: 1,
        pr: 1,
      },
    },
  };
}
