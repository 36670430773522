import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useReducer, useState } from "react";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import DeleteIcon from "src/assets/icon/delete";
import EditIcon from "src/assets/icon/edit";
import MenuAutocomplete from "src/components/denseForm/MenuAutocomplete";
import MyMenuItem from "src/components/denseForm/MenuItem";
import { TableMoreMenu } from "src/components/table";
import { NewTextFormField } from "src/components/textField";
import useLocales from "src/hooks/useLocales";
import { ReviewContext } from "src/pages/formvalidation/context/ReviewContext";
import { updatePayloadFields } from "src/pages/formvalidation/hooks/useItemsTable";
import { ExpectedValues } from "src/pages/formvalidation/types/reviewContext";
import { ReviewResponse } from "src/pages/formvalidation/types/reviewResponse";
import DeleteDialog from "./../../table/DeleteDialog";

const GeneralTable = ({ open, handleClose, rows, ...props }) => {
  const { translate } = useLocales();
  const [openDialog, setOpenDialog] = useState(false);
  const [item, setItem] = useState<any>(null);
  const [itemIndex, setItemIndex] = useState<number>(0);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleDeleteDialog = (item, i) => {
    setItem(item);
    setItemIndex(i);
    setOpenDialog(true);
  };
  const reviewInitialState: ReviewResponse = {
    fields: [],
  };
  const reviewContext = useContext(ReviewContext);
  const { expectedValues, reviewResponse, initial } = reviewContext;
  const { setInitialState } = initial;
  const { dispatch: reviewDispatch } = reviewResponse;
  const { documentFieldsExpectedValues } = expectedValues;
  const reducer = (state: ReviewResponse, action: any) => {
    switch (action.type) {
      case "ADD_FIELD":
        let fields = updatePayloadFields(action.payload, state.fields);
        return {
          ...state,
          fields: fields,
        };
      case "REMOVE_FIELD":
        let fields_ = updatePayloadFields(action.payload, state.fields);
        return {
          ...state,
          fields: fields_,
        };
      case "REMOVE_TABLE":
        let fields__ = state.fields;
        let delete_name = action.payload.name;
        let index = fields__.findIndex((field_) => field_.name === delete_name);
        if (index !== -1) {
          let fieldToBeDeleted = fields__[index];
          fieldToBeDeleted.isRemoved = true;
          fieldToBeDeleted.itemsFields = [];
          fields__[index] = fieldToBeDeleted;
        } else {
          fields__.push({
            name: delete_name,
            value: null,
            pageNumber: null,
            confidence: null,
            isRemoved: true,
            isNew: false,
            itemsFields: [],
            boundingPolygon: null,
          });
        }
        return {
          ...state,
          fields: fields__,
        };
      case "SET_INITIAL_STATE":
        return {
          ...state,
          fields: action.payload,
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, reviewInitialState);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      maxWidth="lg"
      fullWidth={true}
      scroll="body"
      aria-describedby="alert-dialog-description"
    >
      <Formik
        initialValues={rows}
        onSubmit={(values) => {
          props.setFieldValue("fields", values);
          reviewDispatch({
            type: "ADD_FIELD",
            payload: state.fields,
          });
          setInitialState((initState) => {
            return {
              ...initState,
              fields: values,
            };
          });
          handleClose();
        }}
        enableReinitialize
        // innerRef={ref}
      >
        {({ values, setFieldValue, setValues }) => (
          <Form>
            <DeleteDialog
              {...{
                openDialog,
                handleCloseDialog,
                handleDeleteRow: () => {
                  let fields = values.filter((field, idx) => itemIndex !== idx);
                  setValues(fields);
                  dispatch({
                    type: "REMOVE_TABLE",
                    payload: {
                      name: item.name,
                    },
                  });
                },
                item,
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  pt: 3,
                  pr: 3.5,
                  ml: "auto",
                }}
              >
                <IconButton sx={{ width: 10, height: 8 }} onClick={handleClose}>
                  <CloseModalButton />
                </IconButton>
              </Box>
            </Box>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "bottom",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5">
                  {translate("General Table")}
                </Typography>
              </Box>
              <TableContainer
                sx={{
                  maxHeight: "50vh",
                }}
              >
                <Table
                  // stickyHeader
                  size="small"
                  aria-label="table with items"
                  sx={{
                    px: 2,
                    maxHeight: "65vh",
                  }}
                >
                  <TableHead>
                    <TableRow sx={{}}>
                      <TableCell
                        sx={{
                          width: "3%",
                        }}
                      ></TableCell>
                      <TableCell
                        sx={{
                          width: "27%",
                        }}
                      >
                        {translate("Field")}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "65%",
                        }}
                      >
                        {translate("Value")}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "5%",
                        }}
                      >
                        {translate("Action")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values?.length > 0 &&
                      values?.map((row, index) => {
                        let expectedValuesOption =
                          documentFieldsExpectedValues?.find(
                            (item) => item.field === row.name
                          )?.expectedValues ??
                          row.expectedValues?.map((item) => {
                            return {
                              value: item,
                              description: item,
                            };
                          }) ??
                          [];
                        let displayValue = "";
                        if (
                          Array.isArray(expectedValuesOption) &&
                          expectedValuesOption.length > 0
                        ) {
                          displayValue =
                            expectedValuesOption?.find(
                              (item) => item.value === row.value
                            )?.description ?? "";
                        } else {
                          displayValue = row?.value;
                        }
                        if (!row?.itemsFields)
                          return (
                            <GeneralTableRow
                              key={index}
                              i={index}
                              row={row}
                              dispatch={dispatch}
                              expectedValuesOption={expectedValuesOption}
                              displayValue={displayValue}
                              setFieldvalue={setFieldValue}
                              handleDeleteDialog={handleDeleteDialog}
                            />
                          );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <Button
                    size="medium"
                    onClick={handleClose}
                    variant="outlined"
                  >
                    {translate("Cancel")}
                  </Button>
                  <Button size="medium" type="submit" variant="contained">
                    {translate("Save")}
                  </Button>
                </Box>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
export default GeneralTable;

const GeneralTableRow = ({
  row,
  dispatch,
  expectedValuesOption,
  displayValue,
  setFieldvalue,
  handleDeleteDialog,
  i,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [edit, setEdit] = useState(false);
  const theme = useTheme();
  const { translate } = useLocales();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setEdit(!edit);
    handleAnchorClose();
  };
  const getTranslatedFields = (title) => {
    let result = translate(`review_screen.review_fields.${title}`);
    if (result.indexOf("review_screen") == -1) {
      return result;
    }
    return title;
  };
  return (
    <TableRow
      onDoubleClick={() => {
        setEdit(!edit);
      }}
      sx={{}}
    >
      <TableCell
        sx={{
          width: "3%",
        }}
      >
        <div
          style={{
            height: "15px",
            width: "15px",
            background: row.strokeColor,
            borderRadius: "50%",
            marginRight: "0.2rem",
          }}
        ></div>
      </TableCell>
      <TableCell
        sx={{
          width: "27%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "12px",
            }}
          >
            {getTranslatedFields(row.name)}
          </Typography>
        </Box>
      </TableCell>
      <TableCell
        sx={{
          width: "65%",
        }}
      >
        {edit ? (
          <EditValue
            value={row.value}
            row={row}
            dispatch={dispatch}
            i={i}
            expectedValuesOption={expectedValuesOption}
            setFieldValue={setFieldvalue}
          />
        ) : (
          <>{displayValue}</>
        )}
      </TableCell>
      <TableCell sx={{ width: "5%", pr: 1 }}>
        <>
          <TableMoreMenu
            open={anchorEl}
            onOpen={handleClick}
            onClose={handleAnchorClose}
            width={150}
            actions={
              <>
                <MenuItem
                  onClick={() => {
                    handleEdit();
                    handleAnchorClose();
                  }}
                >
                  <EditIcon
                    height={20}
                    width={20}
                    sx={{
                      height: "auto",
                      width: "auto",
                    }}
                    customColor={theme.palette?.action.active}
                  />
                  {translate(`edit`)}
                </MenuItem>
                <MenuItem
                  disabled={row.key.split(".")?.length > 2}
                  onClick={() => {
                    handleDeleteDialog(row, i);
                    handleAnchorClose();
                  }}
                >
                  <DeleteIcon
                    height={14}
                    width={14}
                    color="primary"
                    disableDimension
                  />
                  {translate("delete")}
                </MenuItem>
              </>
            }
          />
        </>
      </TableCell>
    </TableRow>
  );
};

const EditValue = ({
  value,
  row,
  dispatch,
  i,
  expectedValuesOption,
  setFieldValue,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const { translate } = useLocales();
  const [selectOption, setSelectOption] = useState({
    label:
      expectedValuesOption?.find((item) => item.value === value)?.description ??
      "",
    value: value,
  });
  const handleBlur = () => {
    setFieldValue(`${i}.value`, inputValue);
    dispatch({
      type: "ADD_FIELD",
      payload: [
        {
          name: row.name,
          value: inputValue,
          pageNumber: row.pageNumber,
          confidence: 0.9,
          isRemoved: false,
          isNew: false,
          itemsFields: null,
          boundingPolygon: row.boundingPolygon,
        },
      ],
    });
  };

  if (Array.isArray(expectedValuesOption) && expectedValuesOption.length > 0) {
    return (
      <MenuAutocomplete
        value={selectOption}
        disableClearable
        dense={"dense"}
        variant="secondary"
        onBlur={handleBlur}
        onChange={(event, newValue) => {
          if (newValue != null && typeof newValue !== "string") {
            setInputValue(newValue.value);
            setSelectOption({
              label: newValue.label,
              value: newValue.value,
            });
          } else if (newValue === null) {
            setInputValue("");
            setSelectOption({
              label: "",
              value: "",
            });
          }
          setFieldValue(`${i}.value`, newValue.value ?? "");
          dispatch({
            type: "ADD_FIELD",
            payload: [
              {
                name: row.name,
                value: newValue.value ?? "",
                pageNumber: row.pageNumber,
                confidence: 0.9,
                isRemoved: false,
                isNew: false,
                itemsFields: null,
                boundingPolygon: row.boundingPolygon,
              },
            ],
          });
        }}
        renderOption={(props: any, option: any, state: object) => (
          <MyMenuItem {...{ option, displayKey: "label", ...props }} />
        )}
        options={
          expectedValuesOption?.map((item: ExpectedValues) => {
            return {
              label: translate(item.description),
              value: item.value,
            };
          }) ?? []
        }
      />
    );
  }
  return (
    <NewTextFormField
      value={inputValue}
      multiline
      sx={{
        padding: "5px 10px 5px 10px",
      }}
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      onBlur={handleBlur}
    />
  );
};
