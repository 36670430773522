import { useTheme } from "@mui/material";
import { SvgIconProps } from "@mui/material";
import { type } from "os";
import React from "react";
type Props = SvgIconProps & { customColor?: string };

export const DocumentProcessingIcon = ({
  customColor,
  color = "primary",
  height = 24,
  width = 24,
}: Props) => {
  const theme = useTheme();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12V6C22 4.89543 21.1046 4 20 4L4 4C2.89543 4 2 4.89543 2 6L2 18C2 19.1046 2.89543 20 4 20H15.5M6.16797 8H13.668M6.51758 11.977H10.167M6.51758 15.9979H11.167M21.9996 18.9999L19.3805 16.3809M20.7372 13.7761C20.7372 15.1842 19.9839 16.4854 18.7611 17.1895C17.5382 17.8936 16.0317 17.8936 14.8088 17.1895C13.586 16.4854 12.8327 15.1843 12.8327 13.7761C12.8327 12.368 13.586 11.0668 14.8088 10.3627C16.0316 9.65862 17.5382 9.65862 18.7611 10.3627C19.9839 11.0668 20.7372 12.368 20.7372 13.7761Z"
        stroke={customColor ?? theme.palette?.[color]?.main}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
