import { Box, Chip, Typography } from "@mui/material";
import { getYear } from "date-fns";
import React from "react";
import useLocales from "src/hooks/useLocales";

const Footer = () => {
  const version = localStorage.getItem("version");
  const { translate } = useLocales();
  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: 0.5,
      }}
    >
      <Typography variant="body1">
        &copy; {getYear(new Date())} Dost. {translate("copyright_message")}
      </Typography>
      <Box sx={{ mx: 0.4 }}>-</Box>
      <Typography variant="body2">{version}</Typography>
    </Box>
  );
};

export default Footer;
