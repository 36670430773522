import { Box, useTheme } from "@mui/material";

const EuroIcon = () => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="12"
          cy="12"
          r="10"
          stroke={theme.palette.text.greyLight}
          strokeWidth="1.5"
        />
        <path
          d="M15 6.80269C14.1175 6.29218 13.0929 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18C13.0929 18 14.1175 17.7078 15 17.1973"
          stroke={theme.palette.text.greyLight}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M5 10.5H10"
          stroke={theme.palette.text.greyLight}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M5 13.5H10"
          stroke={theme.palette.text.greyLight}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </Box>
  );
};

export default EuroIcon;
