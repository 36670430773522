import { createTheme } from "@mui/system";

export const textStyle = createTheme({
  palette: {
    inputLabel: {
      light: "#A5B0BA",
      dark: "#ffffff",
    },
    color: {
      light: "#2E435E",
      dark: "#0d6efd",
    },
    textColor: {
      ight: "#2E435E",
      dark: "#ffffff",
    },
  },
});
