import React from "react";

const AlertIllustration = () => {
  return (
    <>
      <svg
        width="112"
        height="112"
        viewBox="0 0 112 112"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M93.4026 36.2085L74.0333 26.8083L46.3628 13.3796L34.9592 7.84024C32.109 6.44256 28.9407 5.82282 25.7745 6.04366C22.6084 6.2645 19.5566 7.3181 16.9276 9.09795C14.2987 10.8778 12.1857 13.3208 10.8024 16.1801C9.4191 19.0393 8.81443 22.2134 9.04953 25.3815L11.4812 59.457L13.7451 90.0073C13.9986 93.1729 15.0747 96.2171 16.8665 98.8379C18.6583 101.459 21.1036 103.565 23.9597 104.948C26.8159 106.33 29.9836 106.942 33.1486 106.721C36.3136 106.5 39.3659 105.454 42.0026 103.688L72.0209 83.9644C72.704 83.3925 73.3483 82.7756 73.9494 82.118L95.6666 67.5142C98.295 65.7276 100.408 63.2804 101.792 60.4182C103.177 57.556 103.786 54.3796 103.556 51.2077C103.327 48.0358 102.268 44.9801 100.486 42.3474C98.7035 39.7146 96.2607 37.5975 93.4026 36.2085Z"
          fill="#EEF5FD"
        />
        <path
          d="M0.207088 74.8184V74.6797C0.170295 74.6797 0.135009 74.6943 0.108992 74.7203C0.0829754 74.7463 0.0683594 74.7816 0.0683594 74.8184H0.207088Z"
          fill="#528ACC"
        />
        <path
          d="M0.208092 70.3828H0C0 70.5215 0 70.6603 0.138728 70.6603C0.180872 70.5736 0.204496 70.4791 0.208092 70.3828Z"
          fill="#528ACC"
        />
        <path
          d="M0.208093 66.1495C0.21128 66.1119 0.206773 66.0741 0.194847 66.0383C0.182921 66.0025 0.163826 65.9696 0.138728 65.9414C0 65.9414 0 66.0108 0 66.1495H0.208093Z"
          fill="#528ACC"
        />
        <path
          d="M0.208093 61.7109H0C0 61.8497 0 61.8497 0.138728 61.8497C0.277457 61.8497 0.208093 61.8497 0.208093 61.7109Z"
          fill="#528ACC"
        />
        <path
          d="M0.208093 57.3398H0C0 57.4786 0 57.5479 0.138728 57.5479C0.163826 57.5198 0.182921 57.4868 0.194847 57.451C0.206773 57.4153 0.21128 57.3774 0.208093 57.3398Z"
          fill="#528ACC"
        />
        <path
          d="M0.208092 53.043H0.138728C0 53.043 0 53.1123 0 53.1123C0 53.1491 0.014616 53.1844 0.0406326 53.2104C0.0666492 53.2364 0.101935 53.2511 0.138728 53.2511L0.208092 53.043Z"
          fill="#528ACC"
        />
        <path
          d="M0.138728 48.8087V48.5312C0 48.5312 0 48.67 0 48.8087H0.138728Z"
          fill="#528ACC"
        />
        <path
          d="M4.50787 90.0117H4.36914V90.1504C4.50787 90.1504 4.50787 90.1504 4.50787 90.0117Z"
          fill="#528ACC"
        />
        <path
          d="M4.6466 85.7121C4.63087 85.6511 4.59482 85.5972 4.54439 85.5594C4.49396 85.5216 4.43215 85.5021 4.36914 85.504V85.8509C4.47431 85.8362 4.57177 85.7875 4.6466 85.7121Z"
          fill="#528ACC"
        />
        <path
          d="M4.64736 81.3419C4.64736 81.2031 4.50864 81.2031 4.36991 81.2031C4.23118 81.2031 4.30054 81.2031 4.30054 81.3419C4.30054 81.4806 4.36991 81.4806 4.36991 81.4806C4.36991 81.4806 4.64736 81.4806 4.64736 81.3419Z"
          fill="#528ACC"
        />
        <path
          d="M4.64736 76.9689C4.64736 76.9689 4.50864 76.6914 4.36991 76.6914C4.23118 76.6914 4.30054 76.9689 4.30054 76.9689C4.30054 76.9689 4.30054 77.177 4.36991 77.177C4.43927 77.177 4.64736 77.177 4.64736 76.9689Z"
          fill="#528ACC"
        />
        <path
          d="M4.71692 72.6729C4.71692 72.4648 4.64755 72.4648 4.3701 72.4648C4.09264 72.4648 4.162 72.4648 4.162 72.6729C4.162 72.7281 4.18393 72.7811 4.22295 72.8201C4.26198 72.8591 4.31491 72.881 4.3701 72.881C4.64755 72.881 4.71692 72.8117 4.71692 72.6729Z"
          fill="#528ACC"
        />
        <path
          d="M4.71716 68.3009C4.71716 68.1622 4.6478 68.0234 4.37034 68.0234C4.30929 68.0392 4.25542 68.0752 4.2176 68.1256C4.17978 68.1761 4.16025 68.2379 4.16225 68.3009C4.16225 68.3703 4.30098 68.5784 4.37034 68.5784C4.45148 68.58 4.53062 68.5531 4.59399 68.5024C4.65736 68.4517 4.70095 68.3804 4.71716 68.3009Z"
          fill="#528ACC"
        />
        <path
          d="M4.71702 63.8616C4.68824 63.795 4.6393 63.7391 4.57709 63.7018C4.51487 63.6644 4.44251 63.6476 4.3702 63.6535L4.16211 63.8616C4.16211 64.1391 4.30084 64.2084 4.3702 64.2084C4.43957 64.2084 4.71702 64.1391 4.71702 63.8616Z"
          fill="#528ACC"
        />
        <path
          d="M4.71692 59.629C4.71692 59.3516 4.64755 59.3516 4.3701 59.3516C4.09264 59.3516 4.162 59.3516 4.162 59.629L4.3701 59.8371C4.64755 59.8371 4.71692 59.6984 4.71692 59.629Z"
          fill="#528ACC"
        />
        <path
          d="M4.71702 55.1925C4.71702 55.1231 4.64766 54.9844 4.3702 54.9844C4.32238 55.0017 4.27895 55.0293 4.24299 55.0653C4.20703 55.1012 4.17942 55.1446 4.16211 55.1925C4.16211 55.2477 4.18403 55.3006 4.22306 55.3396C4.26208 55.3786 4.31501 55.4006 4.3702 55.4006C4.64766 55.4006 4.71702 55.3312 4.71702 55.1925Z"
          fill="#528ACC"
        />
        <path
          d="M4.6476 50.8184C4.57277 50.7431 4.47531 50.6943 4.37015 50.6797C4.30078 50.6797 4.30078 50.8184 4.30078 50.8184C4.30078 50.8184 4.30078 51.0265 4.37015 51.0265C4.43951 51.0265 4.6476 51.0265 4.6476 50.8184Z"
          fill="#528ACC"
        />
        <path
          d="M4.64664 46.5206C4.64664 46.3819 4.50791 46.3125 4.36919 46.3125C4.34409 46.3407 4.32499 46.3736 4.31307 46.4094C4.30114 46.4452 4.29663 46.483 4.29982 46.5206V46.6593L4.64664 46.5206Z"
          fill="#528ACC"
        />
        <path
          d="M4.50787 42.2198C4.50787 42.1504 4.50787 42.0117 4.36914 42.0117V42.2198H4.50787Z"
          fill="#528ACC"
        />
        <path
          d="M4.50787 37.7109H4.36914V37.8497C4.40593 37.8497 4.44122 37.835 4.46724 37.809C4.49325 37.783 4.50787 37.7477 4.50787 37.7109Z"
          fill="#528ACC"
        />
        <path
          d="M8.87801 92.2989L8.80865 92.1602L8.66992 92.2989H8.80865H8.87801Z"
          fill="#528ACC"
        />
        <path
          d="M9.01674 87.8623C9.01674 87.793 8.87801 87.793 8.80865 87.793C8.73929 87.793 8.66992 87.793 8.66992 87.8623C8.66992 87.9317 8.66992 88.0011 8.80865 88.0011C8.94738 88.0011 9.01674 88.0011 9.01674 87.8623Z"
          fill="#528ACC"
        />
        <path
          d="M9.0168 83.4893C9.0168 83.4893 8.87807 83.2812 8.80871 83.2812C8.73934 83.2812 8.53125 83.4893 8.53125 83.4893C8.53125 83.4893 8.66998 83.6974 8.80871 83.6974C8.94743 83.6974 9.0168 83.6974 9.0168 83.4893Z"
          fill="#528ACC"
        />
        <path
          d="M9.1556 79.1906C9.1556 79.0986 9.11906 79.0104 9.05402 78.9453C8.98898 78.8803 8.90076 78.8438 8.80878 78.8438C8.72926 78.86 8.65795 78.9036 8.60725 78.9669C8.55655 79.0303 8.52968 79.1094 8.53132 79.1906C8.53132 79.3293 8.67005 79.3987 8.80878 79.3987C8.94751 79.3987 9.1556 79.3293 9.1556 79.1906Z"
          fill="#528ACC"
        />
        <path
          d="M9.15653 74.8195C9.15653 74.7275 9.11999 74.6393 9.05495 74.5742C8.98991 74.5092 8.90169 74.4727 8.80971 74.4727C8.71773 74.4727 8.62951 74.5092 8.56447 74.5742C8.49943 74.6393 8.46289 74.7275 8.46289 74.8195C8.46289 75.0276 8.53225 75.1663 8.80971 75.1663C8.90169 75.1663 8.98991 75.1298 9.05495 75.0647C9.11999 74.9997 9.15653 74.9115 9.15653 74.8195Z"
          fill="#528ACC"
        />
        <path
          d="M9.15653 70.3839C9.15653 70.1758 9.0178 70.1758 8.80971 70.1758C8.60162 70.1758 8.46289 70.1758 8.46289 70.3839C8.46289 70.592 8.53225 70.8694 8.80971 70.8694C9.08717 70.8694 9.15653 70.6613 9.15653 70.3839Z"
          fill="#528ACC"
        />
        <path
          d="M9.2259 66.1496C9.2259 65.9415 9.0178 65.6641 8.80971 65.6641C8.60162 65.6641 8.46289 65.9415 8.46289 66.1496C8.46289 66.3577 8.53225 66.5658 8.80971 66.5658C8.92009 66.5658 9.02595 66.522 9.104 66.4439C9.18205 66.3659 9.2259 66.26 9.2259 66.1496Z"
          fill="#528ACC"
        />
        <path
          d="M9.2259 61.7101C9.19195 61.6215 9.13586 61.5432 9.063 61.4825C8.99014 61.4217 8.90294 61.3807 8.80971 61.3633C8.71773 61.3633 8.62951 61.3998 8.56447 61.4649C8.49943 61.5299 8.46289 61.6181 8.46289 61.7101C8.46289 61.9876 8.53225 62.0569 8.80971 62.0569C9.08717 62.0569 9.2259 61.9876 9.2259 61.7101Z"
          fill="#528ACC"
        />
        <path
          d="M9.2259 57.343C9.20935 57.245 9.15829 57.1562 9.08196 57.0926C9.00563 57.0289 8.90906 56.9947 8.80971 56.9961C8.53225 56.9961 8.46289 57.1349 8.46289 57.343C8.46289 57.551 8.53225 57.8285 8.80971 57.8285C9.08717 57.8285 9.2259 57.551 9.2259 57.343Z"
          fill="#528ACC"
        />
        <path
          d="M9.15653 53.0382C9.15653 52.9462 9.11999 52.858 9.05495 52.793C8.98991 52.7279 8.90169 52.6914 8.80971 52.6914C8.53225 52.6914 8.46289 52.8301 8.46289 53.0382C8.46289 53.2463 8.53225 53.385 8.80971 53.385C9.08717 53.385 9.15653 53.3157 9.15653 53.0382Z"
          fill="#528ACC"
        />
        <path
          d="M9.15695 48.8098C9.15695 48.5323 9.01822 48.3242 8.81013 48.3242C8.70652 48.355 8.61615 48.4195 8.55333 48.5074C8.49051 48.5954 8.45883 48.7018 8.46331 48.8098C8.46331 48.8098 8.6714 49.0179 8.81013 49.0179C8.8795 49.0101 8.94639 48.9876 9.00624 48.9516C9.06609 48.9157 9.11749 48.8673 9.15695 48.8098Z"
          fill="#528ACC"
        />
        <path
          d="M9.0168 44.3009C9.0168 44.3009 8.87807 44.0234 8.80871 44.0234C8.73934 44.0234 8.53125 44.3009 8.53125 44.3009C8.53125 44.3745 8.56048 44.4451 8.61251 44.4971C8.66455 44.5491 8.73512 44.5784 8.80871 44.5784C8.87807 44.5784 9.0168 44.509 9.0168 44.3009Z"
          fill="#528ACC"
        />
        <path
          d="M9.0168 40.0011C9.0168 39.9459 8.99488 39.8929 8.95585 39.8539C8.91683 39.8149 8.8639 39.793 8.80871 39.793C8.66998 39.793 8.53125 39.8623 8.53125 40.0011C8.54698 40.0621 8.58303 40.116 8.63346 40.1538C8.68389 40.1916 8.7457 40.2111 8.80871 40.2092C8.85653 40.1918 8.89996 40.1642 8.93592 40.1283C8.97188 40.0923 8.99949 40.0489 9.0168 40.0011Z"
          fill="#528ACC"
        />
        <path
          d="M8.87801 35.6964C8.87801 35.4883 8.87801 35.4883 8.80865 35.4883C8.73929 35.4883 8.66992 35.4883 8.66992 35.6964H8.87801Z"
          fill="#528ACC"
        />
        <path
          d="M13.3165 98.8223L13.1778 98.6836C13.141 98.6836 13.1057 98.6982 13.0797 98.7242C13.0537 98.7502 13.0391 98.7855 13.0391 98.8223H13.3165Z"
          fill="#528ACC"
        />
        <path
          d="M13.3175 94.3828H12.9707C12.9707 94.5215 13.0401 94.6603 13.1788 94.6603C13.3175 94.6603 13.3175 94.5215 13.3175 94.3828Z"
          fill="#528ACC"
        />
        <path
          d="M13.3889 90.0128C13.3889 89.9855 13.3835 89.9584 13.3731 89.9331C13.3626 89.9079 13.3473 89.885 13.3279 89.8656C13.3086 89.8463 13.2857 89.831 13.2604 89.8205C13.2352 89.8101 13.2081 89.8047 13.1808 89.8047C12.9727 89.8047 12.834 89.9434 12.834 90.0128C12.834 90.1048 12.8705 90.193 12.9356 90.258C13.0006 90.3231 13.0888 90.3596 13.1808 90.3596C13.3195 90.3596 13.3889 90.2209 13.3889 90.0128Z"
          fill="#528ACC"
        />
        <path
          d="M13.5276 85.7101C13.5276 85.6181 13.4911 85.5299 13.426 85.4649C13.361 85.3998 13.2728 85.3633 13.1808 85.3633C13.0888 85.3633 13.0006 85.3998 12.9356 85.4649C12.8705 85.5299 12.834 85.6181 12.834 85.7101C12.834 85.9876 12.9727 86.0569 13.1808 86.0569C13.2728 86.0569 13.361 86.0204 13.426 85.9553C13.4911 85.8903 13.5276 85.8021 13.5276 85.7101Z"
          fill="#528ACC"
        />
        <path
          d="M13.5267 81.343C13.5267 81.251 13.4901 81.1628 13.4251 81.0977C13.3601 81.0327 13.2718 80.9961 13.1799 80.9961C13.0805 80.9947 12.9839 81.0289 12.9076 81.0926C12.8313 81.1562 12.7802 81.245 12.7637 81.343C12.7637 81.551 12.9718 81.8285 13.1799 81.8285C13.3879 81.8285 13.5267 81.551 13.5267 81.343Z"
          fill="#528ACC"
        />
        <path
          d="M13.6646 76.9699C13.6499 76.8462 13.594 76.7311 13.5059 76.643C13.4179 76.555 13.3027 76.4991 13.1791 76.4844C13.0626 76.5012 12.9563 76.5597 12.8797 76.649C12.8032 76.7383 12.7617 76.8523 12.7629 76.9699C12.7496 77.0273 12.7511 77.0871 12.7673 77.1437C12.7835 77.2003 12.8139 77.2518 12.8555 77.2935C12.8971 77.3351 12.9487 77.3655 13.0053 77.3817C13.0619 77.3979 13.1217 77.3994 13.1791 77.3861C13.3871 77.3861 13.6646 77.3167 13.6646 76.9699Z"
          fill="#528ACC"
        />
        <path
          d="M13.6655 72.6701C13.6655 72.462 13.388 72.2539 13.1799 72.2539C12.9718 72.2539 12.625 72.462 12.625 72.6701C12.642 72.8049 12.7079 72.9289 12.8101 73.0184C12.9124 73.1079 13.044 73.1567 13.1799 73.1556C13.3036 73.1409 13.4187 73.0851 13.5068 72.997C13.5949 72.9089 13.6508 72.7938 13.6655 72.6701Z"
          fill="#528ACC"
        />
        <path
          d="M13.664 68.3001C13.6676 68.156 13.6212 68.015 13.5326 67.9012C13.4441 67.7874 13.319 67.7078 13.1784 67.6758C13.0993 67.6752 13.021 67.6915 12.9487 67.7236C12.8764 67.7557 12.8119 67.8029 12.7593 67.862C12.7068 67.9211 12.6675 67.9908 12.644 68.0664C12.6206 68.1419 12.6136 68.2216 12.6235 68.3001C12.6235 68.4472 12.682 68.5884 12.786 68.6925C12.8901 68.7965 13.0312 68.855 13.1784 68.855C13.3078 68.826 13.4246 68.7566 13.5119 68.6569C13.5992 68.5571 13.6524 68.4321 13.664 68.3001Z"
          fill="#528ACC"
        />
        <path
          d="M13.6635 63.8615C13.6635 63.6534 13.3861 63.4453 13.178 63.4453C12.9699 63.4453 12.6231 63.6534 12.6231 63.8615C12.6112 63.9371 12.6174 64.0144 12.6411 64.0871C12.6648 64.1599 12.7053 64.226 12.7594 64.2801C12.8135 64.3342 12.8796 64.3747 12.9523 64.3984C13.0251 64.4221 13.1024 64.4283 13.178 64.4164C13.3128 64.3994 13.4367 64.3335 13.5262 64.2313C13.6157 64.129 13.6646 63.9974 13.6635 63.8615Z"
          fill="#528ACC"
        />
        <path
          d="M13.6655 59.6273C13.6508 59.5036 13.5949 59.3884 13.5068 59.3004C13.4188 59.2123 13.3036 59.1564 13.1799 59.1417C13.1106 59.1317 13.0399 59.1368 12.9728 59.1567C12.9056 59.1766 12.8435 59.2107 12.7908 59.2569C12.7381 59.303 12.696 59.36 12.6674 59.4239C12.6388 59.4878 12.6243 59.5572 12.625 59.6273C12.625 59.8353 12.8331 60.0434 13.1799 60.0434C13.5268 60.0434 13.6655 59.8353 13.6655 59.6273Z"
          fill="#528ACC"
        />
        <path
          d="M13.6635 55.1935C13.6635 54.9854 13.3861 54.7773 13.178 54.7773C12.9699 54.7773 12.6231 54.9854 12.6231 55.1935C12.6112 55.2691 12.6174 55.3464 12.6411 55.4191C12.6648 55.4919 12.7053 55.558 12.7594 55.6121C12.8135 55.6662 12.8796 55.7067 12.9523 55.7304C13.0251 55.7541 13.1024 55.7603 13.178 55.7484C13.3128 55.7315 13.4367 55.6656 13.5262 55.5633C13.6157 55.461 13.6646 55.3294 13.6635 55.1935Z"
          fill="#528ACC"
        />
        <path
          d="M13.5267 50.8195C13.5267 50.7275 13.4901 50.6393 13.4251 50.5743C13.3601 50.5092 13.2718 50.4727 13.1799 50.4727C13.0805 50.4713 12.9839 50.5055 12.9076 50.5691C12.8313 50.6327 12.7802 50.7215 12.7637 50.8195C12.7637 50.8742 12.7744 50.9283 12.7954 50.9788C12.8163 51.0293 12.8469 51.0752 12.8856 51.1138C12.9242 51.1525 12.9701 51.1831 13.0206 51.204C13.0711 51.2249 13.1252 51.2357 13.1799 51.2357C13.2778 51.2192 13.3666 51.1681 13.4303 51.0918C13.4939 51.0154 13.5281 50.9189 13.5267 50.8195Z"
          fill="#528ACC"
        />
        <path
          d="M13.5267 46.5217C13.5281 46.4223 13.4939 46.3257 13.4303 46.2494C13.3666 46.1731 13.2778 46.122 13.1799 46.1055C13.1252 46.1055 13.0711 46.1162 13.0206 46.1371C12.9701 46.1581 12.9242 46.1887 12.8856 46.2274C12.8469 46.266 12.8163 46.3119 12.7954 46.3624C12.7744 46.4129 12.7637 46.467 12.7637 46.5217C12.7802 46.6196 12.8313 46.7084 12.9076 46.7721C12.9839 46.8357 13.0805 46.8699 13.1799 46.8685C13.2718 46.8685 13.3601 46.8319 13.4251 46.7669C13.4901 46.7019 13.5267 46.6136 13.5267 46.5217Z"
          fill="#528ACC"
        />
        <path
          d="M13.5277 42.2209C13.5291 42.1215 13.4949 42.025 13.4312 41.9486C13.3676 41.8723 13.2788 41.8212 13.1808 41.8047C13.0829 41.8212 12.9941 41.8723 12.9304 41.9486C12.8668 42.025 12.8326 42.1215 12.834 42.2209C12.834 42.2209 12.9728 42.4983 13.1808 42.4983C13.3889 42.4983 13.5277 42.2209 13.5277 42.2209Z"
          fill="#528ACC"
        />
        <path
          d="M13.3869 37.712C13.3869 37.712 13.3175 37.5039 13.1788 37.5039C13.0401 37.5039 12.9707 37.712 12.9707 37.712C12.9707 37.712 13.0401 37.9895 13.1788 37.9895C13.3175 37.9895 13.3869 37.9895 13.3869 37.712Z"
          fill="#528ACC"
        />
        <path
          d="M13.3165 33.4806L13.1778 33.2031C13.0391 33.2031 13.0391 33.4806 13.0391 33.4806H13.3165Z"
          fill="#528ACC"
        />
        <path
          d="M13.1778 29.043H13.0391C13.0391 29.1817 13.0391 29.1817 13.1778 29.1817V29.043Z"
          fill="#528ACC"
        />
        <path
          d="M17.6172 100.971C17.6172 100.832 17.6172 100.832 17.4785 100.832V100.971C17.6172 101.04 17.6172 101.04 17.6172 100.971Z"
          fill="#528ACC"
        />
        <path
          d="M17.6867 96.5312H17.4786C17.3398 96.5312 17.3398 96.6006 17.3398 96.6006C17.3398 96.6006 17.3398 96.8781 17.4786 96.8781C17.6173 96.8781 17.6867 96.8087 17.6867 96.5312Z"
          fill="#528ACC"
        />
        <path
          d="M17.8284 92.3009C17.8284 92.0234 17.6896 92.0234 17.4815 92.0234C17.2734 92.0234 17.2734 92.0234 17.2734 92.3009C17.2734 92.3561 17.2954 92.409 17.3344 92.448C17.3734 92.4871 17.4263 92.509 17.4815 92.509C17.6896 92.509 17.8284 92.3703 17.8284 92.3009Z"
          fill="#528ACC"
        />
        <path
          d="M17.8978 87.8624C17.8638 87.7739 17.8077 87.6955 17.7349 87.6348C17.662 87.5741 17.5748 87.5331 17.4816 87.5156C17.3429 87.5156 17.1348 87.7931 17.1348 87.8624C17.1348 87.9544 17.1713 88.0426 17.2363 88.1077C17.3014 88.1727 17.3896 88.2093 17.4816 88.2093C17.6897 88.2093 17.8978 88.1399 17.8978 87.8624Z"
          fill="#528ACC"
        />
        <path
          d="M17.4806 83.9066C17.7105 83.9066 17.8968 83.7203 17.8968 83.4904C17.8968 83.2606 17.7105 83.0742 17.4806 83.0742C17.2508 83.0742 17.0645 83.2606 17.0645 83.4904C17.0645 83.7203 17.2508 83.9066 17.4806 83.9066Z"
          fill="#528ACC"
        />
        <path
          d="M18.0366 79.1897C18.0484 79.1141 18.0423 79.0368 18.0186 78.9641C17.9949 78.8913 17.9543 78.8252 17.9002 78.7711C17.8461 78.717 17.78 78.6765 17.7073 78.6528C17.6345 78.6291 17.5572 78.6229 17.4817 78.6348C17.3468 78.6518 17.2229 78.7176 17.1334 78.8199C17.0439 78.9222 16.995 79.0538 16.9961 79.1897C17.0108 79.3134 17.0667 79.4285 17.1548 79.5166C17.2428 79.6046 17.358 79.6605 17.4817 79.6752C17.6176 79.6763 17.7491 79.6274 17.8514 79.538C17.9537 79.4485 18.0196 79.3245 18.0366 79.1897Z"
          fill="#528ACC"
        />
        <path
          d="M17.4807 75.3755C17.7872 75.3755 18.0356 75.127 18.0356 74.8205C18.0356 74.5141 17.7872 74.2656 17.4807 74.2656C17.1742 74.2656 16.9258 74.5141 16.9258 74.8205C16.9258 75.127 17.1742 75.3755 17.4807 75.3755Z"
          fill="#528ACC"
        />
        <path
          d="M18.1743 70.3791C18.1311 70.2258 18.041 70.0898 17.9166 69.9903C17.7922 69.8908 17.6398 69.8327 17.4807 69.8242C17.3387 69.8393 17.2061 69.9026 17.1051 70.0036C17.0042 70.1046 16.9409 70.2371 16.9258 70.3791C16.9258 70.8647 17.1339 71.0728 17.4807 71.0728C17.5745 71.0839 17.6696 71.0737 17.7588 71.0427C17.848 71.0118 17.9291 70.9611 17.9959 70.8943C18.0626 70.8275 18.1134 70.7465 18.1443 70.6572C18.1752 70.568 18.1855 70.4729 18.1743 70.3791Z"
          fill="#528ACC"
        />
        <path
          d="M18.1727 66.1511C18.1839 66.0574 18.1736 65.9623 18.1427 65.873C18.1118 65.7838 18.061 65.7027 17.9943 65.636C17.9275 65.5692 17.8464 65.5184 17.7572 65.4875C17.668 65.4566 17.5729 65.4463 17.4791 65.4575C17.1323 65.4575 16.9242 65.6656 16.9242 66.1511C16.9145 66.2332 16.9213 66.3164 16.9441 66.3958C16.9668 66.4752 17.0051 66.5494 17.0567 66.6139C17.1083 66.6784 17.1723 66.732 17.2448 66.7717C17.3173 66.8113 17.3969 66.8362 17.4791 66.8448C17.6578 66.8292 17.8253 66.7511 17.9522 66.6242C18.079 66.4973 18.1571 66.3299 18.1727 66.1511Z"
          fill="#528ACC"
        />
        <path
          d="M18.1724 61.7131C18.1724 61.3663 17.8949 61.1582 17.4787 61.1582C17.4032 61.1464 17.3259 61.1525 17.2531 61.1762C17.1804 61.1999 17.1143 61.2405 17.0602 61.2946C17.0061 61.3487 16.9655 61.4148 16.9418 61.4875C16.9182 61.5602 16.912 61.6375 16.9238 61.7131C16.9142 61.7952 16.921 61.8783 16.9437 61.9578C16.9665 62.0372 17.0048 62.1114 17.0564 62.1759C17.108 62.2404 17.1719 62.294 17.2444 62.3337C17.3169 62.3733 17.3966 62.3981 17.4787 62.4068C17.6627 62.4068 17.8391 62.3337 17.9692 62.2036C18.0993 62.0735 18.1724 61.8971 18.1724 61.7131Z"
          fill="#528ACC"
        />
        <path
          d="M18.1743 57.3401C18.1311 57.1867 18.041 57.0508 17.9166 56.9513C17.7922 56.8517 17.6398 56.7937 17.4807 56.7852C17.3387 56.8002 17.2061 56.8635 17.1051 56.9645C17.0042 57.0655 16.9409 57.1981 16.9258 57.3401C16.9258 57.8256 17.1339 58.0337 17.4807 58.0337C17.5745 58.0449 17.6696 58.0346 17.7588 58.0037C17.848 57.9728 17.9291 57.922 17.9959 57.8552C18.0626 57.7885 18.1134 57.7074 18.1443 57.6182C18.1752 57.5289 18.1855 57.4338 18.1743 57.3401Z"
          fill="#528ACC"
        />
        <path
          d="M18.1724 53.0384C18.1551 52.8667 18.0745 52.7076 17.9462 52.5921C17.818 52.4767 17.6513 52.4132 17.4787 52.4141C17.3997 52.4135 17.3213 52.4298 17.2491 52.4619C17.1768 52.494 17.1122 52.5412 17.0596 52.6003C17.0071 52.6594 16.9678 52.7291 16.9444 52.8046C16.9209 52.8802 16.9139 52.9599 16.9238 53.0384C16.912 53.1139 16.9182 53.1912 16.9418 53.264C16.9655 53.3367 17.0061 53.4028 17.0602 53.4569C17.1143 53.511 17.1804 53.5516 17.2531 53.5753C17.3259 53.599 17.4032 53.6051 17.4787 53.5933C17.5608 53.6029 17.644 53.5961 17.7234 53.5734C17.8028 53.5506 17.877 53.5123 17.9415 53.4607C18.006 53.4091 18.0597 53.3452 18.0993 53.2727C18.1389 53.2002 18.1638 53.1205 18.1724 53.0384Z"
          fill="#528ACC"
        />
        <path
          d="M18.034 48.8108C18.034 48.3253 17.8259 48.1172 17.4791 48.1172C17.3969 48.1258 17.3173 48.1506 17.2448 48.1903C17.1723 48.2299 17.1083 48.2836 17.0567 48.3481C17.0051 48.4126 16.9668 48.4867 16.9441 48.5662C16.9213 48.6456 16.9145 48.7288 16.9242 48.8108C16.9242 49.0189 17.271 49.227 17.4791 49.227C17.6872 49.227 18.034 49.0189 18.034 48.8108Z"
          fill="#528ACC"
        />
        <path
          d="M18.0366 44.2991C18.0373 44.2291 18.0228 44.1597 17.9942 44.0958C17.9656 44.0318 17.9235 43.9749 17.8708 43.9287C17.8181 43.8826 17.756 43.8484 17.6888 43.8286C17.6216 43.8087 17.551 43.8036 17.4816 43.8136C17.358 43.8283 17.2428 43.8842 17.1547 43.9722C17.0667 44.0603 17.0108 44.1754 16.9961 44.2991C17.0076 44.4312 17.0609 44.5562 17.1482 44.6559C17.2355 44.7557 17.3523 44.8251 17.4816 44.854C17.6288 44.854 17.77 44.7956 17.874 44.6915C17.9781 44.5874 18.0366 44.4463 18.0366 44.2991Z"
          fill="#528ACC"
        />
        <path
          d="M17.8978 40.0012C17.8978 39.7931 17.6897 39.5156 17.4816 39.5156C17.2735 39.5156 17.1348 39.7931 17.1348 40.0012C17.1334 40.1005 17.1676 40.1971 17.2312 40.2734C17.2948 40.3498 17.3837 40.4008 17.4816 40.4174C17.592 40.4174 17.6979 40.3735 17.7759 40.2955C17.854 40.2174 17.8978 40.1116 17.8978 40.0012Z"
          fill="#528ACC"
        />
        <path
          d="M17.8284 35.6974C17.8298 35.5981 17.7956 35.5015 17.732 35.4252C17.6684 35.3489 17.5796 35.2978 17.4816 35.2812C17.3837 35.2978 17.2948 35.3489 17.2312 35.4252C17.1676 35.5015 17.1334 35.5981 17.1348 35.6974C17.1348 35.8362 17.3429 35.9055 17.4816 35.9055C17.6204 35.9055 17.8284 35.8362 17.8284 35.6974Z"
          fill="#528ACC"
        />
        <path
          d="M17.6896 31.1925C17.6896 31.1373 17.6677 31.0843 17.6287 31.0453C17.5896 31.0063 17.5367 30.9844 17.4815 30.9844C17.3428 30.9844 17.2734 31.1925 17.2734 31.1925C17.2734 31.1925 17.3428 31.4006 17.4815 31.4006C17.6203 31.4006 17.6896 31.4006 17.6896 31.1925Z"
          fill="#528ACC"
        />
        <path
          d="M17.6867 26.8867H17.3398L17.4786 27.0254C17.5217 27.0202 17.563 27.005 17.5991 26.9809C17.6353 26.9568 17.6653 26.9245 17.6867 26.8867Z"
          fill="#528ACC"
        />
        <path
          d="M17.6172 22.5195H17.4785V22.6583C17.6172 22.6583 17.6172 22.6583 17.6172 22.5195Z"
          fill="#528ACC"
        />
        <path
          d="M21.9883 107.49L21.8496 107.352V107.629C21.8864 107.629 21.9217 107.614 21.9477 107.588C21.9737 107.562 21.9883 107.527 21.9883 107.49Z"
          fill="#528ACC"
        />
        <path
          d="M22.0568 103.051H21.7793C21.7793 103.19 21.7793 103.328 21.8487 103.328C21.918 103.328 22.0568 103.19 22.0568 103.051Z"
          fill="#528ACC"
        />
        <path
          d="M22.0568 98.8214C22.0568 98.6133 21.9874 98.6133 21.9874 98.6133C21.9874 98.6133 21.6406 98.6133 21.6406 98.8214L21.8487 99.0295L22.0568 98.8214Z"
          fill="#528ACC"
        />
        <path
          d="M22.1975 94.3829C22.1975 94.1748 22.0588 94.1055 21.8507 94.1055C21.6426 94.1055 21.5039 94.1748 21.5039 94.3829C21.5039 94.591 21.6426 94.7297 21.8507 94.7297C22.0588 94.7297 22.1975 94.6604 22.1975 94.3829Z"
          fill="#528ACC"
        />
        <path
          d="M22.3353 90.0138C22.3353 89.8058 22.0579 89.5977 21.8498 89.5977C21.7394 89.5977 21.6335 89.6415 21.5555 89.7196C21.4774 89.7976 21.4336 89.9035 21.4336 90.0138C21.4336 90.3607 21.6417 90.4994 21.8498 90.4994C21.9786 90.4994 22.1021 90.4482 22.1931 90.3572C22.2842 90.2661 22.3353 90.1426 22.3353 90.0138Z"
          fill="#528ACC"
        />
        <path
          d="M22.4047 85.7073C22.4047 85.5601 22.3463 85.4189 22.2422 85.3149C22.1382 85.2108 21.997 85.1523 21.8498 85.1523C21.7027 85.1523 21.5615 85.2108 21.4575 85.3149C21.3534 85.4189 21.2949 85.5601 21.2949 85.7073C21.2949 85.8544 21.3534 85.9956 21.4575 86.0996C21.5615 86.2037 21.7027 86.2622 21.8498 86.2622C21.997 86.2622 22.1382 86.2037 22.2422 86.0996C22.3463 85.9956 22.4047 85.8544 22.4047 85.7073Z"
          fill="#528ACC"
        />
        <path
          d="M22.5435 81.3421C22.5435 81.1581 22.4705 80.9817 22.3404 80.8516C22.2103 80.7215 22.0339 80.6484 21.8499 80.6484C21.6659 80.6484 21.4895 80.7215 21.3594 80.8516C21.2293 80.9817 21.1562 81.1581 21.1562 81.3421C21.1562 81.526 21.2293 81.7025 21.3594 81.8326C21.4895 81.9626 21.6659 82.0357 21.8499 82.0357C22.0339 82.0357 22.2103 81.9626 22.3404 81.8326C22.4705 81.7025 22.5435 81.526 22.5435 81.3421Z"
          fill="#528ACC"
        />
        <path
          d="M22.5435 76.9675C22.5547 76.8738 22.5444 76.7787 22.5135 76.6894C22.4826 76.6002 22.4318 76.5192 22.3651 76.4524C22.2983 76.3856 22.2172 76.3348 22.128 76.3039C22.0388 76.273 21.9437 76.2627 21.8499 76.2739C21.6712 76.2895 21.5037 76.3676 21.3768 76.4945C21.25 76.6213 21.1719 76.7888 21.1563 76.9675C21.1555 77.1584 21.2263 77.3427 21.3547 77.484C21.4831 77.6252 21.6598 77.7132 21.8499 77.7305C21.9471 77.731 22.0434 77.7111 22.1324 77.672C22.2214 77.6329 22.3012 77.5755 22.3666 77.5035C22.432 77.4316 22.4816 77.3467 22.5121 77.2543C22.5425 77.162 22.5533 77.0643 22.5435 76.9675Z"
          fill="#528ACC"
        />
        <path
          d="M22.6813 72.6724C22.6818 72.5661 22.66 72.4609 22.6174 72.3635C22.5748 72.2661 22.5123 72.1786 22.4339 72.1068C22.3555 72.035 22.263 71.9803 22.1623 71.9463C22.0616 71.9123 21.9548 71.8997 21.849 71.9094C21.6466 71.9094 21.4525 71.9898 21.3094 72.1329C21.1663 72.276 21.0859 72.4701 21.0859 72.6724C21.0855 72.7697 21.1054 72.8659 21.1445 72.9549C21.1836 73.0439 21.241 73.1237 21.313 73.1891C21.3849 73.2546 21.4698 73.3041 21.5622 73.3346C21.6545 73.3651 21.7522 73.3758 21.849 73.3661C22.3345 73.3661 22.6813 73.158 22.6813 72.6724Z"
          fill="#528ACC"
        />
        <path
          d="M22.6828 68.3006C22.7198 68.1764 22.7273 68.0452 22.7047 67.9176C22.6821 67.7899 22.6299 67.6693 22.5525 67.5654C22.475 67.4614 22.3743 67.377 22.2584 67.3189C22.1426 67.2607 22.0147 67.2305 21.8851 67.2305C21.7555 67.2305 21.6276 67.2607 21.5118 67.3189C21.3959 67.377 21.2952 67.4614 21.2177 67.5654C21.1403 67.6693 21.0881 67.7899 21.0655 67.9176C21.0429 68.0452 21.0504 68.1764 21.0874 68.3006C21.0874 68.503 21.1678 68.697 21.3109 68.8401C21.454 68.9832 21.6481 69.0636 21.8504 69.0636C21.9563 69.0733 22.063 69.0607 22.1638 69.0267C22.2645 68.9927 22.357 68.9381 22.4354 68.8662C22.5137 68.7944 22.5762 68.707 22.6188 68.6096C22.6615 68.5122 22.6832 68.4069 22.6828 68.3006Z"
          fill="#528ACC"
        />
        <path
          d="M22.6828 63.8612C22.6828 63.445 22.336 63.1675 21.8504 63.1675C21.7537 63.1578 21.656 63.1685 21.5636 63.199C21.4713 63.2295 21.3864 63.279 21.3145 63.3445C21.2425 63.4099 21.1851 63.4897 21.146 63.5787C21.1069 63.6677 21.0869 63.7639 21.0874 63.8612C21.0504 63.9854 21.0429 64.1166 21.0655 64.2442C21.0881 64.3719 21.1403 64.4925 21.2177 64.5964C21.2952 64.7003 21.3959 64.7848 21.5118 64.8429C21.6276 64.901 21.7555 64.9313 21.8851 64.9313C22.0147 64.9313 22.1426 64.901 22.2584 64.8429C22.3743 64.7848 22.475 64.7003 22.5525 64.5964C22.6299 64.4925 22.6821 64.3719 22.7047 64.2442C22.7273 64.1166 22.7198 63.9854 22.6828 63.8612Z"
          fill="#528ACC"
        />
        <path
          d="M22.6845 59.6292C22.6845 59.4085 22.5968 59.1968 22.4407 59.0407C22.2846 58.8846 22.0729 58.7969 21.8521 58.7969C21.7458 58.7964 21.6406 58.8182 21.5432 58.8608C21.4458 58.9034 21.3583 58.9659 21.2865 59.0443C21.2147 59.1227 21.16 59.2152 21.126 59.3159C21.092 59.4166 21.0794 59.5234 21.0891 59.6292C21.0891 60.0454 21.4359 60.2535 21.8521 60.2535C22.2683 60.2535 22.6845 60.0454 22.6845 59.6292Z"
          fill="#528ACC"
        />
        <path
          d="M22.5431 55.1902C22.5543 55.0964 22.544 55.0013 22.5131 54.9121C22.4822 54.8229 22.4314 54.7418 22.3646 54.675C22.2978 54.6083 22.2168 54.5575 22.1276 54.5266C22.0383 54.4957 21.9432 54.4854 21.8495 54.4966C21.6655 54.4966 21.4891 54.5696 21.359 54.6997C21.2289 54.8298 21.1558 55.0062 21.1558 55.1902C21.1461 55.2869 21.1568 55.3847 21.1873 55.477C21.2178 55.5693 21.2673 55.6542 21.3327 55.7262C21.3981 55.7981 21.4779 55.8555 21.567 55.8946C21.656 55.9337 21.7522 55.9537 21.8495 55.9532C22.335 55.9532 22.5431 55.6757 22.5431 55.1902Z"
          fill="#528ACC"
        />
        <path
          d="M22.5435 50.8225C22.5435 50.6145 22.1967 50.1289 21.8499 50.1289C21.5031 50.1289 21.1562 50.6145 21.1562 50.8225C21.1562 51.0065 21.2293 51.1829 21.3594 51.313C21.4895 51.4431 21.6659 51.5162 21.8499 51.5162C22.0339 51.5162 22.2103 51.4431 22.3404 51.313C22.4705 51.1829 22.5435 51.0065 22.5435 50.8225Z"
          fill="#528ACC"
        />
        <path
          d="M22.4047 46.5198C22.4047 46.3726 22.3463 46.2314 22.2422 46.1274C22.1382 46.0233 21.997 45.9648 21.8498 45.9648C21.7027 45.9648 21.5615 46.0233 21.4575 46.1274C21.3534 46.2314 21.2949 46.3726 21.2949 46.5198C21.2949 46.6669 21.3534 46.8081 21.4575 46.9121C21.5615 47.0162 21.7027 47.0747 21.8498 47.0747C21.997 47.0747 22.1382 47.0162 22.2422 46.9121C22.3463 46.8081 22.4047 46.6669 22.4047 46.5198Z"
          fill="#528ACC"
        />
        <path
          d="M22.4047 42.2219C22.4057 42.0677 22.3495 41.9186 22.247 41.8033C22.1446 41.6881 22.0031 41.6148 21.8498 41.5977C21.6417 41.5977 21.4336 41.9445 21.4336 42.2219C21.4324 42.3395 21.4739 42.4536 21.5505 42.5429C21.627 42.6322 21.7334 42.6907 21.8498 42.7075C21.9191 42.7175 21.9898 42.7124 22.057 42.6925C22.1241 42.6726 22.1862 42.6385 22.2389 42.5923C22.2916 42.5462 22.3338 42.4892 22.3624 42.4253C22.391 42.3613 22.4054 42.292 22.4047 42.2219Z"
          fill="#528ACC"
        />
        <path
          d="M22.3354 37.7101C22.3354 37.5021 22.0579 37.3633 21.8498 37.3633C21.7505 37.3619 21.6539 37.3961 21.5776 37.4597C21.5012 37.5234 21.4502 37.6122 21.4336 37.7101C21.4324 37.8278 21.4739 37.9418 21.5505 38.0311C21.627 38.1204 21.7334 38.1789 21.8498 38.1957C21.9735 38.181 22.0886 38.1251 22.1767 38.037C22.2648 37.949 22.3207 37.8338 22.3354 37.7101Z"
          fill="#528ACC"
        />
        <path
          d="M22.1975 33.4796C22.1975 33.2022 22.0588 33.1328 21.8507 33.1328C21.6426 33.1328 21.5039 33.2022 21.5039 33.4796C21.5039 33.5716 21.5404 33.6598 21.6055 33.7249C21.6705 33.7899 21.7587 33.8265 21.8507 33.8265C21.9427 33.8265 22.0309 33.7899 22.096 33.7249C22.161 33.6598 22.1975 33.5716 22.1975 33.4796Z"
          fill="#528ACC"
        />
        <path
          d="M22.0569 29.043H21.6408C21.6388 29.106 21.6583 29.1678 21.6961 29.2182C21.7339 29.2686 21.7878 29.3047 21.8489 29.3204C21.8489 29.3204 22.0569 29.1817 22.0569 29.043Z"
          fill="#528ACC"
        />
        <path
          d="M21.9884 24.668H21.7803C21.7771 24.7055 21.7816 24.7434 21.7935 24.7792C21.8055 24.8149 21.8246 24.8479 21.8497 24.8761C21.8875 24.8547 21.9197 24.8247 21.9438 24.7885C21.9679 24.7524 21.9832 24.7111 21.9884 24.668Z"
          fill="#528ACC"
        />
        <path
          d="M26.3585 105.342C26.3585 105.203 26.2891 105.203 26.2891 105.203C26.2891 105.203 26.1504 105.203 26.1504 105.342C26.1504 105.379 26.165 105.414 26.191 105.44C26.217 105.466 26.2523 105.481 26.2891 105.481L26.3585 105.342Z"
          fill="#528ACC"
        />
        <path
          d="M26.4953 100.969C26.4973 100.906 26.4778 100.844 26.44 100.794C26.4021 100.743 26.3483 100.707 26.2872 100.691C26.2136 100.691 26.1431 100.721 26.091 100.773C26.039 100.825 26.0098 100.895 26.0098 100.969C26.0338 101.012 26.0689 101.048 26.1116 101.074C26.1543 101.099 26.203 101.112 26.2525 101.112C26.3021 101.112 26.3508 101.099 26.3935 101.074C26.4361 101.048 26.4713 101.012 26.4953 100.969Z"
          fill="#528ACC"
        />
        <path
          d="M26.5657 96.5323C26.5657 96.4629 26.3576 96.3242 26.2882 96.3242C26.2189 96.3242 25.9414 96.4629 25.9414 96.5323C25.9414 96.6017 26.0108 97.0179 26.2882 97.0179C26.3733 96.9687 26.4438 96.8979 26.4926 96.8126C26.5413 96.7273 26.5666 96.6306 26.5657 96.5323Z"
          fill="#528ACC"
        />
        <path
          d="M26.7057 92.3012C26.7069 92.1836 26.6654 92.0696 26.5888 91.9803C26.5123 91.891 26.4059 91.8325 26.2895 91.8157C26.223 91.8033 26.1545 91.8072 26.0899 91.8272C26.0253 91.8472 25.9666 91.8827 25.9188 91.9305C25.871 91.9783 25.8355 92.0371 25.8155 92.1017C25.7955 92.1663 25.7915 92.2347 25.804 92.3012C25.804 92.5093 25.9427 92.7174 26.2895 92.7174C26.3442 92.7174 26.3983 92.7066 26.4488 92.6857C26.4993 92.6648 26.5451 92.6342 26.5838 92.5955C26.6224 92.5569 26.6531 92.511 26.674 92.4605C26.6949 92.41 26.7057 92.3559 26.7057 92.3012Z"
          fill="#528ACC"
        />
        <path
          d="M26.8442 87.8635C26.8291 87.7215 26.7658 87.5889 26.6648 87.488C26.5639 87.387 26.4313 87.3237 26.2893 87.3086C26.1421 87.3086 26.001 87.3671 25.8969 87.4711C25.7928 87.5752 25.7344 87.7163 25.7344 87.8635C25.7344 88.0107 25.7928 88.1518 25.8969 88.2559C26.001 88.36 26.1421 88.4184 26.2893 88.4184C26.4313 88.4033 26.5639 88.34 26.6648 88.2391C26.7658 88.1381 26.8291 88.0055 26.8442 87.8635Z"
          fill="#528ACC"
        />
        <path
          d="M26.8443 83.4905C26.8443 83.2824 26.5668 82.7969 26.2893 82.7969C26.0119 82.7969 25.5957 83.2824 25.5957 83.4905C25.5957 83.6745 25.6688 83.8509 25.7989 83.981C25.9289 84.1111 26.1054 84.1842 26.2893 84.1842C26.3715 84.1755 26.4512 84.1507 26.5237 84.1111C26.5962 84.0714 26.6601 84.0178 26.7117 83.9533C26.7633 83.8887 26.8016 83.8146 26.8244 83.7352C26.8471 83.6557 26.8539 83.5726 26.8443 83.4905Z"
          fill="#528ACC"
        />
        <path
          d="M26.9137 79.1919C26.9167 79.0115 26.8556 78.8358 26.7413 78.6961C26.627 78.5564 26.4669 78.4617 26.2894 78.4289C26.1835 78.4193 26.0768 78.4318 25.9761 78.4658C25.8753 78.4998 25.7828 78.5545 25.7045 78.6263C25.6261 78.6982 25.5636 78.7856 25.521 78.883C25.4784 78.9804 25.4566 79.0856 25.457 79.1919C25.457 79.6775 25.8039 79.8856 26.2894 79.8856C26.3776 79.8861 26.4648 79.868 26.5455 79.8324C26.6261 79.7968 26.6983 79.7445 26.7573 79.6789C26.8163 79.6134 26.8607 79.5361 26.8877 79.4522C26.9146 79.3683 26.9235 79.2796 26.9137 79.1919Z"
          fill="#528ACC"
        />
        <path
          d="M27.0524 74.8207C27.0621 74.7148 27.0495 74.6081 27.0155 74.5073C26.9815 74.4066 26.9269 74.3141 26.855 74.2357C26.7832 74.1573 26.6958 74.0948 26.5984 74.0522C26.501 74.0096 26.3957 73.9878 26.2894 73.9883C26.1801 73.9883 26.0719 74.0098 25.9709 74.0516C25.8699 74.0935 25.7781 74.1548 25.7008 74.2321C25.6235 74.3094 25.5622 74.4011 25.5204 74.5021C25.4786 74.6031 25.457 74.7114 25.457 74.8207C25.4566 74.927 25.4784 75.0322 25.521 75.1296C25.5636 75.227 25.6261 75.3144 25.7045 75.3863C25.7828 75.4581 25.8753 75.5128 25.9761 75.5468C26.0768 75.5808 26.1835 75.5933 26.2894 75.5837C26.4918 75.5837 26.6858 75.5033 26.8289 75.3602C26.972 75.2171 27.0524 75.023 27.0524 74.8207Z"
          fill="#528ACC"
        />
        <path
          d="M27.0534 70.3802C27.0534 70.1778 26.973 69.9838 26.8299 69.8407C26.6868 69.6976 26.4928 69.6172 26.2904 69.6172C25.6661 69.6172 25.3193 69.964 25.3193 70.3802C25.3088 70.5104 25.3268 70.6413 25.3719 70.7639C25.417 70.8864 25.4882 70.9977 25.5805 71.0901C25.6729 71.1824 25.7842 71.2536 25.9067 71.2987C26.0293 71.3438 26.1602 71.3618 26.2904 71.3513C26.7066 71.3513 27.0534 71.0045 27.0534 70.3802Z"
          fill="#528ACC"
        />
        <path
          d="M27.1912 66.1505C27.175 65.9167 27.0748 65.6966 26.9091 65.5309C26.7434 65.3651 26.5233 65.2649 26.2895 65.2488C26.1653 65.2392 26.0406 65.2554 25.923 65.2964C25.8055 65.3374 25.6977 65.4023 25.6065 65.487C25.5153 65.5718 25.4426 65.6744 25.393 65.7886C25.3434 65.9028 25.318 66.026 25.3184 66.1505C25.318 66.275 25.3434 66.3982 25.393 66.5124C25.4426 66.6266 25.5153 66.7292 25.6065 66.814C25.6977 66.8987 25.8055 66.9636 25.923 67.0046C26.0406 67.0456 26.1653 67.0618 26.2895 67.0522C26.5185 67.0233 26.7314 66.919 26.8947 66.7557C27.0579 66.5925 27.1622 66.3795 27.1912 66.1505Z"
          fill="#528ACC"
        />
        <path
          d="M27.1913 61.7109C27.1913 61.4718 27.0963 61.2424 26.9272 61.0733C26.7581 60.9042 26.5287 60.8092 26.2896 60.8092C26.1632 60.7872 26.0334 60.7947 25.9104 60.8312C25.7874 60.8676 25.6745 60.932 25.5804 61.0193C25.4864 61.1066 25.4139 61.2144 25.3684 61.3344C25.323 61.4544 25.3059 61.5833 25.3185 61.7109C25.3089 61.8442 25.327 61.978 25.3715 62.1039C25.416 62.2298 25.486 62.3453 25.5772 62.4429C25.6683 62.5406 25.7786 62.6184 25.9012 62.6714C26.0238 62.7245 26.156 62.7517 26.2896 62.7514C26.5408 62.7176 26.7711 62.5932 26.9371 62.4017C27.1032 62.2101 27.1936 61.9645 27.1913 61.7109Z"
          fill="#528ACC"
        />
        <path
          d="M27.1913 57.3419C27.1623 57.1129 27.058 56.8999 26.8948 56.7367C26.7315 56.5734 26.5186 56.4691 26.2896 56.4402C26.1655 56.4306 26.0407 56.4468 25.9232 56.4878C25.8056 56.5288 25.6978 56.5937 25.6066 56.6784C25.5154 56.7632 25.4427 56.8658 25.3931 56.98C25.3435 57.0942 25.3181 57.2174 25.3185 57.3419C25.3059 57.4696 25.323 57.5984 25.3684 57.7184C25.4139 57.8384 25.4864 57.9463 25.5804 58.0336C25.6745 58.1209 25.7874 58.1852 25.9104 58.2217C26.0334 58.2581 26.1632 58.2656 26.2896 58.2436C26.408 58.2436 26.5252 58.2203 26.6346 58.175C26.7441 58.1297 26.8435 58.0633 26.9272 57.9795C27.0109 57.8958 27.0773 57.7964 27.1227 57.687C27.168 57.5776 27.1913 57.4603 27.1913 57.3419Z"
          fill="#528ACC"
        />
        <path
          d="M27.0525 53.0424C27.055 52.8254 26.9793 52.6148 26.8392 52.4492C26.699 52.2835 26.5038 52.174 26.2895 52.1406C26.048 52.1546 25.819 52.2523 25.6418 52.4169C25.4646 52.5815 25.3502 52.8026 25.3184 53.0424C25.3359 53.2877 25.4459 53.5172 25.6262 53.6846C25.8064 53.852 26.0435 53.9447 26.2895 53.9441C26.3989 53.9354 26.5055 53.905 26.6032 53.8549C26.7009 53.8048 26.7877 53.7358 26.8586 53.652C26.9296 53.5682 26.9832 53.4712 27.0165 53.3666C27.0497 53.2619 27.062 53.1517 27.0525 53.0424Z"
          fill="#528ACC"
        />
        <path
          d="M27.0514 48.8119C27.041 48.5986 26.9607 48.3947 26.8228 48.2317C26.6848 48.0687 26.497 47.9557 26.2884 47.9102C26.173 47.9098 26.0588 47.9334 25.953 47.9795C25.8472 48.0256 25.7521 48.0932 25.6739 48.1779C25.5956 48.2627 25.5358 48.3629 25.4983 48.472C25.4608 48.5812 25.4464 48.6969 25.456 48.8119C25.456 49.1587 25.8028 49.4362 26.2884 49.4362C26.7739 49.4362 27.0514 49.1587 27.0514 48.8119Z"
          fill="#528ACC"
        />
        <path
          d="M26.9135 44.3025C26.9009 44.1341 26.8328 43.9747 26.7198 43.8492C26.6068 43.7236 26.4554 43.6391 26.2893 43.6089C26.175 43.5732 26.054 43.5649 25.936 43.5847C25.8179 43.6044 25.7062 43.6516 25.6098 43.7225C25.5134 43.7934 25.435 43.886 25.381 43.9928C25.327 44.0996 25.2988 44.2176 25.2988 44.3372C25.2988 44.4569 25.327 44.5749 25.381 44.6817C25.435 44.7884 25.5134 44.881 25.6098 44.9519C25.7062 45.0228 25.8179 45.07 25.936 45.0898C26.054 45.1095 26.175 45.1012 26.2893 45.0655C26.4668 45.0328 26.6269 44.9381 26.7412 44.7984C26.8555 44.6587 26.9165 44.483 26.9135 44.3025Z"
          fill="#528ACC"
        />
        <path
          d="M26.8443 40.0022C26.8475 39.84 26.7938 39.6817 26.6924 39.5549C26.591 39.4282 26.4484 39.341 26.2893 39.3086C26.1054 39.3086 25.9289 39.3817 25.7989 39.5118C25.6688 39.6418 25.5957 39.8183 25.5957 40.0022C25.5957 40.1862 25.6688 40.3626 25.7989 40.4927C25.9289 40.6228 26.1054 40.6959 26.2893 40.6959C26.4484 40.6634 26.591 40.5763 26.6924 40.4495C26.7938 40.3228 26.8475 40.1645 26.8443 40.0022Z"
          fill="#528ACC"
        />
        <path
          d="M26.7065 35.696C26.7065 35.3492 26.4984 35.1411 26.2903 35.1411C26.2118 35.1312 26.1321 35.1382 26.0566 35.1616C25.9811 35.185 25.9114 35.2243 25.8523 35.2769C25.7931 35.3294 25.7459 35.394 25.7138 35.4663C25.6817 35.5386 25.6654 35.6169 25.666 35.696C25.698 35.8366 25.7777 35.9617 25.8915 36.0502C26.0053 36.1387 26.1462 36.1851 26.2903 36.1815C26.4984 36.1815 26.7065 35.9041 26.7065 35.696Z"
          fill="#528ACC"
        />
        <path
          d="M26.5677 31.1906C26.5677 30.9825 26.4983 30.8438 26.2902 30.8438C26.0821 30.8438 25.8047 30.9825 25.8047 31.1906C25.8194 31.3143 25.8753 31.4294 25.9633 31.5175C26.0514 31.6055 26.1666 31.6614 26.2902 31.6761C26.4983 31.6761 26.5677 31.3987 26.5677 31.1906Z"
          fill="#528ACC"
        />
        <path
          d="M26.4963 26.8878C26.4963 26.6797 26.3576 26.6797 26.2882 26.6797C26.2189 26.6797 25.9414 26.6797 25.9414 26.8878C25.9414 27.0959 26.0108 27.1652 26.2882 27.1652C26.5657 27.1652 26.4963 27.1652 26.4963 26.8878Z"
          fill="#528ACC"
        />
        <path
          d="M26.3566 22.5215C26.3566 22.3828 26.3566 22.3828 26.2872 22.3828C26.2179 22.3828 26.0098 22.3828 26.0098 22.5215C26.0098 22.6603 26.1485 22.7296 26.2872 22.7296C26.426 22.7296 26.3566 22.6603 26.3566 22.5215Z"
          fill="#528ACC"
        />
        <path
          d="M30.7284 107.49C30.7284 107.352 30.659 107.352 30.5203 107.352C30.3816 107.352 30.4509 107.352 30.4509 107.49C30.4509 107.629 30.4509 107.629 30.5203 107.629C30.5897 107.629 30.7284 107.629 30.7284 107.49Z"
          fill="#528ACC"
        />
        <path
          d="M30.8655 103.052C30.8655 102.982 30.7267 102.844 30.5186 102.844L30.3105 103.052C30.3105 103.329 30.4493 103.399 30.5186 103.399C30.588 103.399 30.8655 103.329 30.8655 103.052Z"
          fill="#528ACC"
        />
        <path
          d="M31.0062 98.8215C30.9915 98.6978 30.9357 98.5827 30.8476 98.4946C30.7595 98.4065 30.6444 98.3506 30.5207 98.3359C30.3126 98.3359 30.1739 98.6134 30.1739 98.8215C30.1725 98.9208 30.2067 99.0174 30.2703 99.0937C30.3339 99.1701 30.4227 99.2211 30.5207 99.2377C30.7288 99.2377 31.0062 99.0296 31.0062 98.8215Z"
          fill="#528ACC"
        />
        <path
          d="M31.0746 94.3791C31.0746 94.232 31.0161 94.0908 30.9121 93.9867C30.808 93.8827 30.6669 93.8242 30.5197 93.8242C30.3116 93.8242 30.1035 94.1017 30.1035 94.3791C30.1035 94.6566 30.3116 95.0034 30.5197 95.0034C30.5988 95.004 30.6771 94.9877 30.7494 94.9556C30.8217 94.9235 30.8863 94.8763 30.9388 94.8172C30.9914 94.7581 31.0307 94.6884 31.0541 94.6128C31.0775 94.5373 31.0845 94.4576 31.0746 94.3791Z"
          fill="#528ACC"
        />
        <path
          d="M31.2134 90.0123C31.2048 89.9301 31.1799 89.8505 31.1403 89.778C31.1007 89.7055 31.047 89.6415 30.9825 89.5899C30.918 89.5383 30.8439 89.5 30.7644 89.4773C30.685 89.4545 30.6018 89.4477 30.5198 89.4574C30.3777 89.4725 30.2452 89.5358 30.1442 89.6367C30.0432 89.7377 29.9799 89.8703 29.9648 90.0123C29.9648 90.4978 30.1729 90.7059 30.5198 90.7059C30.8666 90.7059 31.2134 90.4978 31.2134 90.0123Z"
          fill="#528ACC"
        />
        <path
          d="M31.3507 85.7115C31.3511 85.6052 31.3294 85.4999 31.2867 85.4025C31.2441 85.3051 31.1816 85.2177 31.1033 85.1459C31.0249 85.074 30.9324 85.0194 30.8317 84.9854C30.7309 84.9514 30.6242 84.9388 30.5183 84.9485C30.3282 84.9658 30.1515 85.0538 30.0231 85.1951C29.8947 85.3363 29.8239 85.5206 29.8247 85.7115C29.8135 85.8053 29.8238 85.9003 29.8547 85.9896C29.8856 86.0788 29.9364 86.1599 30.0032 86.2266C30.0699 86.2934 30.151 86.3442 30.2402 86.3751C30.3295 86.406 30.4245 86.4163 30.5183 86.4051C31.0039 86.4051 31.3507 86.197 31.3507 85.7115Z"
          fill="#528ACC"
        />
        <path
          d="M31.4225 81.3403C31.4138 81.2309 31.3835 81.1243 31.3334 81.0266C31.2832 80.9289 31.2143 80.8421 31.1305 80.7712C31.0466 80.7003 30.9496 80.6466 30.845 80.6133C30.7404 80.5801 30.6302 80.5678 30.5208 80.5773C30.3184 80.5773 30.1244 80.6577 29.9813 80.8008C29.8382 80.9439 29.7578 81.138 29.7578 81.3403C29.7469 81.4432 29.7591 81.5472 29.7936 81.6448C29.8281 81.7423 29.8839 81.8309 29.9571 81.9041C30.0302 81.9772 30.1188 82.0331 30.2164 82.0676C30.3139 82.1021 30.4179 82.1143 30.5208 82.1034C31.0063 82.1034 31.4225 81.8953 31.4225 81.3403Z"
          fill="#528ACC"
        />
        <path
          d="M31.5613 76.9708C31.5525 76.8431 31.5186 76.7185 31.4615 76.604C31.4044 76.4895 31.3252 76.3875 31.2285 76.3037C31.1318 76.2199 31.0196 76.156 30.8981 76.1157C30.7767 76.0755 30.6485 76.0596 30.5209 76.0691C30.2871 76.0852 30.067 76.1854 29.9013 76.3512C29.7355 76.5169 29.6353 76.737 29.6191 76.9708C29.6191 77.2099 29.7141 77.4393 29.8833 77.6084C30.0524 77.7775 30.2817 77.8725 30.5209 77.8725C30.6485 77.882 30.7767 77.8661 30.8981 77.8258C31.0196 77.7856 31.1318 77.7217 31.2285 77.6379C31.3252 77.5541 31.4044 77.4521 31.4615 77.3376C31.5186 77.2231 31.5525 77.0984 31.5613 76.9708Z"
          fill="#528ACC"
        />
        <path
          d="M31.562 72.6694C31.562 72.3934 31.4524 72.1288 31.2573 71.9336C31.0622 71.7385 30.7975 71.6289 30.5216 71.6289C30.3939 71.6377 30.2693 71.6716 30.1548 71.7287C30.0403 71.7858 29.9382 71.865 29.8544 71.9617C29.7706 72.0584 29.7068 72.1707 29.6665 72.2921C29.6263 72.4136 29.6104 72.5418 29.6198 72.6694C29.6198 72.9085 29.7148 73.1379 29.8839 73.307C30.0531 73.4761 30.2824 73.5711 30.5216 73.5711C30.6492 73.5805 30.7774 73.5647 30.8988 73.5244C31.0203 73.4842 31.1325 73.4203 31.2292 73.3365C31.3259 73.2527 31.4051 73.1506 31.4622 73.0361C31.5193 72.9217 31.5532 72.797 31.562 72.6694Z"
          fill="#528ACC"
        />
        <path
          d="M30.519 69.3426C31.0936 69.3426 31.5594 68.8768 31.5594 68.3022C31.5594 67.7275 31.0936 67.2617 30.519 67.2617C29.9443 67.2617 29.4785 67.7275 29.4785 68.3022C29.4785 68.8768 29.9443 69.3426 30.519 69.3426Z"
          fill="#528ACC"
        />
        <path
          d="M31.6295 63.8589C31.5805 63.6019 31.4422 63.3705 31.2392 63.2055C31.0361 63.0405 30.7813 62.9526 30.5197 62.9572C30.2737 62.9566 30.0367 63.0493 29.8564 63.2167C29.6762 63.3841 29.5662 63.6136 29.5486 63.8589C29.5292 64.0011 29.5395 64.1457 29.5787 64.2837C29.6179 64.4217 29.6852 64.5501 29.7764 64.6608C29.8676 64.7716 29.9807 64.8622 30.1086 64.9272C30.2365 64.9921 30.3765 65.0299 30.5197 65.0381C30.8202 65.0204 31.1027 64.8884 31.309 64.6692C31.5154 64.4499 31.63 64.16 31.6295 63.8589Z"
          fill="#528ACC"
        />
        <path
          d="M30.519 60.6708C31.0936 60.6708 31.5594 60.2049 31.5594 59.6303C31.5594 59.0557 31.0936 58.5898 30.519 58.5898C29.9443 58.5898 29.4785 59.0557 29.4785 59.6303C29.4785 60.2049 29.9443 60.6708 30.519 60.6708Z"
          fill="#528ACC"
        />
        <path
          d="M31.5614 55.1898C31.5438 54.9261 31.4264 54.6789 31.2332 54.4986C31.0399 54.3182 30.7853 54.2182 30.5209 54.2188C30.2756 54.2363 30.046 54.3463 29.8787 54.5266C29.7113 54.7068 29.6186 54.9439 29.6192 55.1898C29.6169 55.4434 29.7073 55.689 29.8733 55.8806C30.0394 56.0721 30.2697 56.1965 30.5209 56.2303C30.7969 56.2303 31.0615 56.1207 31.2566 55.9256C31.4518 55.7304 31.5614 55.4658 31.5614 55.1898Z"
          fill="#528ACC"
        />
        <path
          d="M31.4218 50.8196C31.4222 50.7042 31.3986 50.59 31.3525 50.4842C31.3064 50.3784 31.2388 50.2834 31.154 50.2051C31.0692 50.1268 30.9691 50.0671 30.8599 50.0296C30.7508 49.9921 30.6351 49.9777 30.5201 49.9873C30.4138 49.9868 30.3085 50.0086 30.2111 50.0512C30.1137 50.0938 30.0263 50.1563 29.9545 50.2347C29.8826 50.3131 29.828 50.4056 29.794 50.5063C29.76 50.607 29.7474 50.7138 29.7571 50.8196C29.7476 50.929 29.7598 51.0392 29.7931 51.1438C29.8263 51.2485 29.88 51.3455 29.9509 51.4293C30.0218 51.5131 30.1086 51.5821 30.2063 51.6322C30.304 51.6823 30.4106 51.7126 30.5201 51.7214C30.6412 51.732 30.7631 51.7159 30.8773 51.6743C30.9915 51.6328 31.0952 51.5667 31.1812 51.4807C31.2671 51.3948 31.3332 51.2911 31.3748 51.1769C31.4163 51.0627 31.4324 50.9407 31.4218 50.8196Z"
          fill="#528ACC"
        />
        <path
          d="M31.3532 46.5201C31.3536 46.4138 31.3318 46.3085 31.2892 46.2111C31.2466 46.1137 31.1841 46.0263 31.1058 45.9545C31.0274 45.8826 30.9349 45.828 30.8341 45.794C30.7334 45.76 30.6267 45.7474 30.5208 45.7571C30.3237 45.7729 30.1387 45.8584 29.9989 45.9982C29.8591 46.138 29.7737 46.323 29.7578 46.5201C29.7578 46.7224 29.8382 46.9165 29.9813 47.0596C30.1244 47.2027 30.3185 47.2831 30.5208 47.2831C30.6267 47.2927 30.7334 47.2802 30.8341 47.2462C30.9349 47.2122 31.0274 47.1575 31.1058 47.0857C31.1841 47.0138 31.2466 46.9264 31.2892 46.829C31.3318 46.7316 31.3536 46.6264 31.3532 46.5201Z"
          fill="#528ACC"
        />
        <path
          d="M31.2135 42.22C31.2232 42.1233 31.2125 42.0256 31.182 41.9333C31.1515 41.8409 31.102 41.756 31.0366 41.6841C30.9711 41.6121 30.8913 41.5547 30.8023 41.5156C30.7133 41.4765 30.6171 41.4566 30.5198 41.457C30.3297 41.4744 30.153 41.5624 30.0246 41.7036C29.8962 41.8449 29.8254 42.0291 29.8262 42.22C29.8534 42.3942 29.9353 42.5552 30.06 42.6799C30.1846 42.8046 30.3456 42.8864 30.5198 42.9137C30.7038 42.9137 30.8802 42.8406 31.0103 42.7105C31.1404 42.5804 31.2135 42.404 31.2135 42.22Z"
          fill="#528ACC"
        />
        <path
          d="M31.0731 37.7092C31.0731 37.5011 30.865 37.293 30.5182 37.293C30.1713 37.293 29.9632 37.5011 29.9632 37.7092C29.9536 37.7912 29.9604 37.8744 29.9831 37.9538C30.0059 38.0333 30.0442 38.1074 30.0958 38.1719C30.1474 38.2364 30.2113 38.2901 30.2838 38.3297C30.3563 38.3693 30.436 38.3942 30.5182 38.4028C30.865 38.4028 31.0731 38.1947 31.0731 37.7092Z"
          fill="#528ACC"
        />
        <path
          d="M30.5197 33.8988C30.7496 33.8988 30.9359 33.7124 30.9359 33.4826C30.9359 33.2527 30.7496 33.0664 30.5197 33.0664C30.2898 33.0664 30.1035 33.2527 30.1035 33.4826C30.1035 33.7124 30.2898 33.8988 30.5197 33.8988Z"
          fill="#528ACC"
        />
        <path
          d="M30.8675 29.0382C30.8675 28.9689 30.7287 28.6914 30.5206 28.6914C30.3728 28.7696 30.252 28.8904 30.1738 29.0382C30.1738 29.1302 30.2104 29.2184 30.2754 29.2835C30.3405 29.3485 30.4287 29.385 30.5206 29.385C30.7287 29.385 30.8675 29.3157 30.8675 29.0382Z"
          fill="#528ACC"
        />
        <path
          d="M30.7267 24.6729C30.7267 24.6729 30.6574 24.4648 30.5186 24.4648C30.3799 24.4648 30.3105 24.6729 30.3105 24.6729C30.3105 24.6729 30.4493 24.881 30.5186 24.881C30.588 24.881 30.7267 24.881 30.7267 24.6729Z"
          fill="#528ACC"
        />
        <path
          d="M30.6593 20.3711H30.4512V20.5098C30.4737 20.5231 30.4994 20.5298 30.5256 20.5292C30.5517 20.5285 30.5771 20.5204 30.5988 20.5059C30.6206 20.4914 30.6378 20.4711 30.6485 20.4472C30.6592 20.4234 30.6629 20.397 30.6593 20.3711Z"
          fill="#528ACC"
        />
        <path
          d="M35.1672 105.341C35.1672 105.286 35.1453 105.233 35.1062 105.194C35.0672 105.155 35.0143 105.133 34.9591 105.133C34.8204 105.133 34.6816 105.202 34.6816 105.341C34.6816 105.48 34.8204 105.549 34.9591 105.549C34.9864 105.549 35.0135 105.544 35.0387 105.533C35.064 105.523 35.0869 105.507 35.1062 105.488C35.1256 105.469 35.1409 105.446 35.1513 105.421C35.1618 105.395 35.1672 105.368 35.1672 105.341Z"
          fill="#528ACC"
        />
        <path
          d="M35.305 100.968C35.305 100.876 35.2684 100.788 35.2034 100.723C35.1383 100.658 35.0501 100.621 34.9581 100.621C34.8662 100.621 34.778 100.658 34.7129 100.723C34.6479 100.788 34.6113 100.876 34.6113 100.968C34.6113 101.176 34.6807 101.315 34.9581 101.315C35.0501 101.315 35.1383 101.278 35.2034 101.213C35.2684 101.148 35.305 101.06 35.305 100.968Z"
          fill="#528ACC"
        />
        <path
          d="M35.3763 96.5334C35.3763 96.423 35.3325 96.3172 35.2544 96.2391C35.1764 96.1611 35.0705 96.1172 34.9602 96.1172C34.8425 96.116 34.7285 96.1575 34.6392 96.2341C34.5499 96.3106 34.4914 96.417 34.4746 96.5334C34.4746 96.6622 34.5258 96.7857 34.6168 96.8767C34.7079 96.9678 34.8314 97.0189 34.9602 97.0189C35.0766 97.0022 35.1829 96.9437 35.2595 96.8544C35.336 96.7651 35.3775 96.651 35.3763 96.5334Z"
          fill="#528ACC"
        />
        <path
          d="M35.5141 92.301C35.5141 92.1538 35.4557 92.0127 35.3516 91.9086C35.2475 91.8046 35.1064 91.7461 34.9592 91.7461C34.812 91.7461 34.6709 91.8046 34.5668 91.9086C34.4628 92.0127 34.4043 92.1538 34.4043 92.301C34.4043 92.4482 34.4628 92.5893 34.5668 92.6934C34.6709 92.7975 34.812 92.8559 34.9592 92.8559C35.1064 92.8559 35.2475 92.7975 35.3516 92.6934C35.4557 92.5893 35.5141 92.4482 35.5141 92.301Z"
          fill="#528ACC"
        />
        <path
          d="M35.5835 87.8616C35.5933 87.774 35.5845 87.6853 35.5575 87.6014C35.5306 87.5174 35.4861 87.4402 35.4272 87.3746C35.3682 87.3091 35.296 87.2568 35.2153 87.2212C35.1347 87.1856 35.0474 87.1674 34.9593 87.168C34.7806 87.1836 34.6131 87.2617 34.4862 87.3886C34.3594 87.5154 34.2813 87.6829 34.2656 87.8616C34.2656 88.2084 34.6124 88.694 34.9593 88.694C35.3061 88.694 35.5835 88.2084 35.5835 87.8616Z"
          fill="#528ACC"
        />
        <path
          d="M35.7219 83.4895C35.7328 83.3867 35.7206 83.2826 35.6861 83.1851C35.6516 83.0876 35.5957 82.999 35.5226 82.9258C35.4494 82.8527 35.3608 82.7968 35.2633 82.7623C35.1657 82.7278 35.0617 82.7156 34.9588 82.7265C34.853 82.7169 34.7463 82.7294 34.6455 82.7634C34.5448 82.7974 34.4523 82.8521 34.3739 82.9239C34.2955 82.9958 34.233 83.0832 34.1904 83.1806C34.1478 83.278 34.126 83.3832 34.1265 83.4895C34.0894 83.6138 34.0819 83.7449 34.1046 83.8726C34.1272 84.0002 34.1793 84.1208 34.2568 84.2248C34.3343 84.3287 34.435 84.4131 34.5508 84.4712C34.6667 84.5294 34.7945 84.5597 34.9242 84.5597C35.0538 84.5597 35.1816 84.5294 35.2975 84.4712C35.4134 84.4131 35.5141 84.3287 35.5915 84.2248C35.669 84.1208 35.7211 84.0002 35.7438 83.8726C35.7664 83.7449 35.7589 83.6138 35.7219 83.4895Z"
          fill="#528ACC"
        />
        <path
          d="M35.8621 79.1901C35.8727 79.069 35.8566 78.947 35.815 78.8328C35.7735 78.7186 35.7074 78.6149 35.6214 78.529C35.5355 78.443 35.4318 78.3769 35.3176 78.3354C35.2033 78.2938 35.0814 78.2777 34.9603 78.2883C34.7212 78.2883 34.4918 78.3833 34.3227 78.5524C34.1536 78.7215 34.0586 78.9509 34.0586 79.1901C34.0586 79.4292 34.1536 79.6586 34.3227 79.8277C34.4918 79.9968 34.7212 80.0918 34.9603 80.0918C35.1995 80.0918 35.4288 79.9968 35.598 79.8277C35.7671 79.6586 35.8621 79.4292 35.8621 79.1901Z"
          fill="#528ACC"
        />
        <path
          d="M35.9315 74.8217C35.9321 74.5574 35.832 74.3027 35.6517 74.1095C35.4713 73.9162 35.2241 73.7989 34.9604 73.7812C34.6844 73.7812 34.4198 73.8909 34.2247 74.086C34.0295 74.2811 33.9199 74.5458 33.9199 74.8217C33.9375 75.0855 34.0549 75.3326 34.2481 75.513C34.4414 75.6933 34.696 75.7934 34.9604 75.7928C35.2179 75.7928 35.4649 75.6905 35.6471 75.5084C35.8292 75.3263 35.9315 75.0793 35.9315 74.8217Z"
          fill="#528ACC"
        />
        <path
          d="M36.0683 70.3794C36.0683 70.085 35.9514 69.8027 35.7433 69.5946C35.5351 69.3865 35.2528 69.2695 34.9585 69.2695C34.6621 69.2842 34.3807 69.404 34.1646 69.6074C33.9485 69.8107 33.8119 70.0844 33.7793 70.3794C33.7959 70.6867 33.9255 70.977 34.1432 71.1947C34.3608 71.4123 34.6512 71.5419 34.9585 71.5585C35.1103 71.5588 35.2605 71.528 35.3999 71.468C35.5392 71.4079 35.6648 71.3199 35.7688 71.2094C35.8729 71.0988 35.9531 70.9682 36.0046 70.8254C36.0561 70.6826 36.0778 70.5308 36.0683 70.3794Z"
          fill="#528ACC"
        />
        <path
          d="M36.0693 66.1514C36.0795 66.003 36.0579 65.8541 36.0057 65.7148C35.9536 65.5755 35.8722 65.449 35.767 65.3438C35.6618 65.2386 35.5353 65.1572 35.396 65.1051C35.2567 65.0529 35.1078 65.0313 34.9594 65.0415C34.6651 65.0415 34.3828 65.1585 34.1747 65.3666C33.9665 65.5747 33.8496 65.857 33.8496 66.1514C33.8496 66.4457 33.9665 66.728 34.1747 66.9361C34.3828 67.1443 34.6651 67.2612 34.9594 67.2612C35.1078 67.2715 35.2567 67.2498 35.396 67.1977C35.5353 67.1455 35.6618 67.0641 35.767 66.9589C35.8722 66.8538 35.9536 66.7272 36.0057 66.5879C36.0579 66.4486 36.0795 66.2998 36.0693 66.1514Z"
          fill="#528ACC"
        />
        <path
          d="M36.0698 61.7114C36.0698 61.417 35.9529 61.1348 35.7447 60.9266C35.5366 60.7185 35.2543 60.6016 34.96 60.6016C34.6589 60.601 34.369 60.7157 34.1498 60.9221C33.9305 61.1284 33.7985 61.4108 33.7808 61.7114C33.7377 61.8851 33.7347 62.0664 33.7721 62.2415C33.8095 62.4166 33.8862 62.5808 33.9965 62.7218C34.1068 62.8628 34.2478 62.9769 34.4087 63.0553C34.5696 63.1337 34.7463 63.1745 34.9253 63.1745C35.1043 63.1745 35.281 63.1337 35.4419 63.0553C35.6028 62.9769 35.7438 62.8628 35.8541 62.7218C35.9644 62.5808 36.0411 62.4166 36.0785 62.2415C36.1159 62.0664 36.1129 61.8851 36.0698 61.7114Z"
          fill="#528ACC"
        />
        <path
          d="M36.0683 57.3403C36.0683 57.046 35.9514 56.7637 35.7433 56.5555C35.5351 56.3474 35.2528 56.2305 34.9585 56.2305C34.6574 56.2299 34.3675 56.3446 34.1483 56.551C33.929 56.7573 33.797 57.0397 33.7793 57.3403C33.779 57.4921 33.8098 57.6423 33.8699 57.7817C33.93 57.921 34.018 58.0466 34.1285 58.1506C34.239 58.2547 34.3697 58.3349 34.5124 58.3864C34.6552 58.4379 34.807 58.4596 34.9585 58.4501C35.1069 58.4604 35.2557 58.4387 35.3951 58.3866C35.5344 58.3344 35.6609 58.253 35.7661 58.1479C35.8712 58.0427 35.9526 57.9162 36.0048 57.7769C36.0569 57.6376 36.0786 57.4887 36.0683 57.3403Z"
          fill="#528ACC"
        />
        <path
          d="M36.0702 53.0395C36.0702 52.7452 35.9533 52.4629 35.7452 52.2547C35.537 52.0466 35.2548 51.9297 34.9604 51.9297C34.6783 51.9474 34.4135 52.072 34.2201 52.2783C34.0268 52.4845 33.9194 52.7568 33.92 53.0395C33.9179 53.3296 34.023 53.6103 34.2149 53.8279C34.4069 54.0454 34.6723 54.1846 34.9604 54.2187C35.2554 54.1861 35.529 54.0495 35.7324 53.8334C35.9358 53.6173 36.0555 53.3359 36.0702 53.0395Z"
          fill="#528ACC"
        />
        <path
          d="M35.9318 48.8081C35.9512 48.666 35.941 48.5213 35.9018 48.3833C35.8626 48.2454 35.7952 48.117 35.7041 48.0062C35.6129 47.8955 35.4997 47.8048 35.3718 47.7399C35.2439 47.6749 35.1039 47.6372 34.9607 47.6289C34.8149 47.6377 34.6722 47.6753 34.5409 47.7394C34.4096 47.8035 34.2923 47.8929 34.1956 48.0024C34.0989 48.112 34.0249 48.2395 33.9776 48.3778C33.9304 48.5161 33.9109 48.6623 33.9203 48.8081C33.9541 49.0594 34.0784 49.2896 34.27 49.4557C34.4616 49.6217 34.7072 49.7121 34.9607 49.7098C35.2067 49.7105 35.4437 49.6177 35.624 49.4504C35.8042 49.283 35.9143 49.0534 35.9318 48.8081Z"
          fill="#528ACC"
        />
        <path
          d="M35.8621 44.3001C35.8446 44.0732 35.7419 43.8613 35.5746 43.7069C35.4074 43.5525 35.1879 43.4671 34.9603 43.4677C34.8453 43.4581 34.7296 43.4725 34.6205 43.51C34.5113 43.5475 34.4112 43.6073 34.3264 43.6856C34.2416 43.7639 34.174 43.8589 34.1279 43.9647C34.0818 44.0705 34.0582 44.1847 34.0586 44.3001C34.0586 44.5393 34.1536 44.7686 34.3227 44.9377C34.4918 45.1068 34.7212 45.2018 34.9603 45.2018C35.1995 45.2018 35.4288 45.1068 35.598 44.9377C35.7671 44.7686 35.8621 44.5393 35.8621 44.3001Z"
          fill="#528ACC"
        />
        <path
          d="M35.7243 40.0003C35.7339 39.8945 35.7214 39.7877 35.6874 39.687C35.6534 39.5863 35.5987 39.4938 35.5269 39.4154C35.455 39.337 35.3676 39.2745 35.2702 39.2319C35.1728 39.1893 35.0676 39.1675 34.9613 39.168C34.7405 39.168 34.5288 39.2557 34.3727 39.4118C34.2166 39.5679 34.1289 39.7796 34.1289 40.0003C34.1289 40.4165 34.4757 40.694 34.9613 40.694C35.058 40.7037 35.1557 40.693 35.2481 40.6625C35.3404 40.632 35.4253 40.5825 35.4972 40.5171C35.5692 40.4517 35.6266 40.3719 35.6657 40.2828C35.7048 40.1938 35.7248 40.0976 35.7243 40.0003Z"
          fill="#528ACC"
        />
        <path
          d="M35.5835 35.6976C35.5933 35.6099 35.5845 35.5212 35.5575 35.4373C35.5306 35.3534 35.4861 35.2761 35.4272 35.2106C35.3682 35.145 35.296 35.0927 35.2153 35.0571C35.1347 35.0215 35.0474 35.0034 34.9593 35.0039C34.7753 35.0039 34.5989 35.077 34.4688 35.2071C34.3387 35.3372 34.2656 35.5136 34.2656 35.6976C34.3088 35.8509 34.399 35.9868 34.5234 36.0864C34.6478 36.1859 34.8002 36.244 34.9593 36.2525C35.1095 36.2403 35.2517 36.1796 35.3643 36.0795C35.4769 35.9794 35.5539 35.8453 35.5835 35.6976Z"
          fill="#528ACC"
        />
        <path
          d="M35.5142 31.1936C35.5142 30.9855 35.1674 30.7774 34.9593 30.7774C34.8417 30.7762 34.7276 30.8177 34.6383 30.8942C34.549 30.9708 34.4905 31.0771 34.4737 31.1936C34.4637 31.2629 34.4688 31.3336 34.4887 31.4007C34.5086 31.4679 34.5428 31.53 34.5889 31.5827C34.635 31.6354 34.692 31.6775 34.7559 31.7061C34.8199 31.7347 34.8892 31.7492 34.9593 31.7485C35.1013 31.7334 35.2339 31.6701 35.3348 31.5691C35.4358 31.4681 35.4991 31.3356 35.5142 31.1936Z"
          fill="#528ACC"
        />
        <path
          d="M35.3743 26.8888C35.3743 26.7785 35.3305 26.6726 35.2524 26.5946C35.1744 26.5165 35.0685 26.4727 34.9581 26.4727C34.6807 26.4727 34.6113 26.6807 34.6113 26.8888C34.6113 27.0969 34.6807 27.3744 34.9581 27.3744C35.0746 27.3576 35.1809 27.2991 35.2575 27.2098C35.334 27.1205 35.3755 27.0065 35.3743 26.8888Z"
          fill="#528ACC"
        />
        <path
          d="M35.1672 22.5206C35.1672 22.4654 35.1453 22.4125 35.1062 22.3734C35.0672 22.3344 35.0143 22.3125 34.9591 22.3125C34.8204 22.3125 34.6816 22.3819 34.6816 22.5206C34.6816 22.6593 34.8204 22.8674 34.9591 22.8674C35.0978 22.8674 35.1672 22.6593 35.1672 22.5206Z"
          fill="#528ACC"
        />
        <path
          d="M35.0304 18.1484H34.8223C34.8223 18.3565 34.8223 18.3565 34.961 18.3565C35.0997 18.3565 35.0304 18.3565 35.0304 18.1484Z"
          fill="#528ACC"
        />
        <path
          d="M39.4689 111.86C39.4689 111.791 39.3301 111.652 39.3301 111.652C39.3301 111.652 39.1914 111.791 39.1914 111.86C39.1914 111.897 39.206 111.933 39.232 111.959C39.2581 111.985 39.2933 111.999 39.3301 111.999L39.4689 111.86Z"
          fill="#528ACC"
        />
        <path
          d="M39.5392 107.489C39.5392 107.448 39.527 107.408 39.5042 107.374C39.4813 107.34 39.4488 107.313 39.4108 107.297C39.3727 107.281 39.3309 107.277 39.2905 107.285C39.2502 107.293 39.2131 107.313 39.184 107.342C39.1549 107.371 39.1351 107.408 39.127 107.449C39.119 107.489 39.1231 107.531 39.1389 107.569C39.1546 107.607 39.1813 107.639 39.2155 107.662C39.2497 107.685 39.29 107.697 39.3311 107.697C39.3863 107.697 39.4393 107.676 39.4783 107.636C39.5173 107.597 39.5392 107.545 39.5392 107.489Z"
          fill="#528ACC"
        />
        <path
          d="M39.6761 103.055C39.6761 102.847 39.468 102.777 39.3293 102.777C39.1906 102.777 38.8438 102.847 38.8438 103.055C38.8584 103.178 38.9143 103.294 39.0024 103.382C39.0905 103.47 39.2056 103.526 39.3293 103.54C39.4329 103.51 39.5233 103.445 39.5861 103.357C39.6489 103.269 39.6806 103.163 39.6761 103.055Z"
          fill="#528ACC"
        />
        <path
          d="M39.7445 98.8215C39.7445 98.6134 39.5364 98.336 39.3284 98.336C39.1924 98.3349 39.0609 98.3837 38.9586 98.4732C38.8563 98.5627 38.7904 98.6867 38.7734 98.8215C38.7734 99.0296 38.9815 99.2377 39.3284 99.2377C39.4387 99.2377 39.5446 99.1938 39.6226 99.1158C39.7007 99.0377 39.7445 98.9319 39.7445 98.8215Z"
          fill="#528ACC"
        />
        <path
          d="M39.8844 94.3826C39.8844 94.2354 39.8259 94.0943 39.7218 93.9902C39.6178 93.8861 39.4766 93.8277 39.3295 93.8277C39.2354 93.7949 39.1348 93.7852 39.0362 93.7992C38.9376 93.8133 38.8438 93.8507 38.7626 93.9084C38.6814 93.9661 38.6152 94.0424 38.5695 94.131C38.5238 94.2195 38.5 94.3176 38.5 94.4172C38.5 94.5169 38.5238 94.615 38.5695 94.7035C38.6152 94.7921 38.6814 94.8684 38.7626 94.9261C38.8438 94.9838 38.9376 95.0212 39.0362 95.0353C39.1348 95.0493 39.2354 95.0396 39.3295 95.0068C39.4086 95.0075 39.4869 94.9912 39.5591 94.9591C39.6314 94.9269 39.696 94.8797 39.7486 94.8206C39.8011 94.7615 39.8404 94.6918 39.8638 94.6163C39.8873 94.5407 39.8943 94.461 39.8844 94.3826Z"
          fill="#528ACC"
        />
        <path
          d="M40.0243 90.0135C40.0086 89.8348 39.9305 89.6673 39.8037 89.5405C39.6768 89.4136 39.5093 89.3355 39.3306 89.3199C39.2339 89.3102 39.1362 89.3209 39.0438 89.3514C38.9515 89.3818 38.8666 89.4314 38.7947 89.4968C38.7227 89.5622 38.6653 89.642 38.6262 89.731C38.5871 89.82 38.5671 89.9163 38.5676 90.0135C38.5579 90.1194 38.5705 90.2261 38.6045 90.3269C38.6385 90.4276 38.6932 90.5201 38.765 90.5985C38.8368 90.6768 38.9243 90.7393 39.0217 90.7819C39.1191 90.8246 39.2243 90.8463 39.3306 90.8459C39.431 90.8372 39.5286 90.8087 39.6179 90.7621C39.7072 90.7154 39.7864 90.6516 39.8508 90.5742C39.9153 90.4968 39.9639 90.4074 39.9936 90.3112C40.0234 90.215 40.0338 90.1138 40.0243 90.0135Z"
          fill="#528ACC"
        />
        <path
          d="M40.0925 85.7103C40.0951 85.4934 40.0193 85.2828 39.8792 85.1171C39.739 84.9515 39.5439 84.842 39.3295 84.8086C39.0903 84.8086 38.861 84.9036 38.6918 85.0727C38.5227 85.2418 38.4277 85.4712 38.4277 85.7103C38.4277 85.9495 38.5227 86.1788 38.6918 86.3479C38.861 86.5171 39.0903 86.6121 39.3295 86.6121C39.4389 86.6033 39.5455 86.573 39.6432 86.5229C39.7409 86.4728 39.8277 86.4038 39.8986 86.32C39.9696 86.2362 40.0232 86.1392 40.0565 86.0345C40.0897 85.9299 40.102 85.8197 40.0925 85.7103Z"
          fill="#528ACC"
        />
        <path
          d="M39.3315 82.3817C39.9061 82.3817 40.3719 81.9159 40.3719 81.3412C40.3719 80.7666 39.9061 80.3008 39.3315 80.3008C38.7568 80.3008 38.291 80.7666 38.291 81.3412C38.291 81.9159 38.7568 82.3817 39.3315 82.3817Z"
          fill="#528ACC"
        />
        <path
          d="M40.3696 76.9722C40.3716 76.682 40.2666 76.4013 40.0746 76.1838C39.8827 75.9662 39.6172 75.8271 39.3291 75.793C39.0285 75.8107 38.7461 75.9427 38.5398 76.1619C38.3334 76.3812 38.2188 76.6711 38.2193 76.9722C38.209 77.1205 38.2307 77.2694 38.2828 77.4087C38.335 77.548 38.4164 77.6745 38.5215 77.7797C38.6267 77.8849 38.7532 77.9663 38.8925 78.0184C39.0318 78.0706 39.1807 78.0923 39.3291 78.082C39.6113 78.0643 39.876 77.9396 40.0694 77.7334C40.2628 77.5271 40.3701 77.2549 40.3696 76.9722Z"
          fill="#528ACC"
        />
        <path
          d="M40.4385 72.6705C40.4583 72.5135 40.4443 72.3541 40.3974 72.203C40.3506 72.0519 40.272 71.9126 40.1669 71.7944C40.0618 71.6761 39.9326 71.5817 39.788 71.5175C39.6435 71.4533 39.4869 71.4207 39.3287 71.4219C38.9975 71.4219 38.68 71.5535 38.4458 71.7876C38.2117 72.0218 38.0801 72.3393 38.0801 72.6705C38.0789 72.8287 38.1115 72.9853 38.1757 73.1298C38.2399 73.2744 38.3343 73.4036 38.4526 73.5087C38.5708 73.6138 38.7101 73.6924 38.8612 73.7392C39.0123 73.7861 39.1717 73.8001 39.3287 73.7803C39.4771 73.7906 39.6259 73.7689 39.7652 73.7168C39.9045 73.6646 40.0311 73.5832 40.1362 73.478C40.2414 73.3729 40.3228 73.2463 40.375 73.107C40.4271 72.9677 40.4488 72.8189 40.4385 72.6705Z"
          fill="#528ACC"
        />
        <path
          d="M40.5753 68.3013C40.5753 67.9701 40.4437 67.6525 40.2096 67.4184C39.9754 67.1842 39.6579 67.0527 39.3267 67.0527C39.1536 67.0433 38.9803 67.0682 38.8169 67.126C38.6534 67.1837 38.5029 67.2731 38.374 67.3891C38.2451 67.5051 38.1404 67.6454 38.0658 67.8019C37.9913 67.9584 37.9483 68.1281 37.9395 68.3013C37.9479 68.4717 37.9911 68.6385 38.0665 68.7916C38.1419 68.9446 38.2479 69.0806 38.3779 69.1911C38.5079 69.3016 38.6591 69.3843 38.8223 69.4341C38.9855 69.4838 39.1572 69.4996 39.3267 69.4804C39.4873 69.4899 39.6482 69.4664 39.7993 69.4114C39.9505 69.3564 40.0888 69.271 40.2058 69.1606C40.3227 69.0501 40.4158 68.9169 40.4794 68.7691C40.5429 68.6213 40.5756 68.4621 40.5753 68.3013Z"
          fill="#528ACC"
        />
        <path
          d="M40.5772 63.8598C40.5772 63.5287 40.4457 63.2111 40.2115 62.977C39.9774 62.7428 39.6598 62.6113 39.3287 62.6113C39.1555 62.6019 38.9823 62.6268 38.8188 62.6846C38.6553 62.7423 38.5048 62.8317 38.3759 62.9477C38.2471 63.0637 38.1423 63.2039 38.0678 63.3605C37.9932 63.517 37.9503 63.6867 37.9414 63.8598C37.9314 64.0447 37.9604 64.2295 38.0266 64.4024C38.0927 64.5752 38.1946 64.7322 38.3255 64.8631C38.4563 64.9939 38.6133 65.0958 38.7862 65.1619C38.959 65.2281 39.1439 65.2571 39.3287 65.2471C39.505 65.2482 39.6795 65.212 39.8408 65.1407C40.0021 65.0695 40.1465 64.9649 40.2644 64.8338C40.3824 64.7028 40.4713 64.5482 40.5252 64.3803C40.5791 64.2125 40.5968 64.0351 40.5772 63.8598Z"
          fill="#528ACC"
        />
        <path
          d="M40.5772 59.6314C40.5772 59.4674 40.5449 59.3051 40.4821 59.1536C40.4194 59.0021 40.3274 58.8644 40.2115 58.7485C40.0956 58.6326 39.9579 58.5406 39.8064 58.4779C39.655 58.4151 39.4926 58.3828 39.3286 58.3828C39.1647 58.3828 39.0023 58.4151 38.8508 58.4779C38.6993 58.5406 38.5617 58.6326 38.4458 58.7485C38.3298 58.8644 38.2379 59.0021 38.1751 59.1536C38.1124 59.3051 38.0801 59.4674 38.0801 59.6314C38.0801 59.9625 38.2116 60.2801 38.4458 60.5142C38.6799 60.7484 38.9975 60.8799 39.3286 60.8799C39.6598 60.8799 39.9773 60.7484 40.2115 60.5142C40.4456 60.2801 40.5772 59.9625 40.5772 59.6314Z"
          fill="#528ACC"
        />
        <path
          d="M40.4385 55.188C40.4219 54.899 40.2997 54.6263 40.095 54.4216C39.8904 54.2169 39.6176 54.0947 39.3286 54.0781C39.0202 54.0762 38.7219 54.1886 38.4914 54.3935C38.2609 54.5984 38.1143 54.8814 38.0801 55.188C38.0801 55.5191 38.2116 55.8367 38.4458 56.0708C38.6799 56.305 38.9975 56.4365 39.3286 56.4365C39.6352 56.4023 39.9182 56.2557 40.1231 56.0252C40.328 55.7947 40.4404 55.4964 40.4385 55.188Z"
          fill="#528ACC"
        />
        <path
          d="M40.371 50.8227C40.3622 50.695 40.3283 50.5704 40.2712 50.4559C40.2141 50.3414 40.1349 50.2393 40.0382 50.1555C39.9415 50.0717 39.8292 50.0079 39.7078 49.9676C39.5863 49.9273 39.4581 49.9115 39.3305 49.9209C39.0653 49.9009 38.8026 49.9832 38.5962 50.1509C38.3898 50.3186 38.2554 50.559 38.2207 50.8227C38.2207 51.117 38.3376 51.3993 38.5458 51.6074C38.7539 51.8156 39.0362 51.9325 39.3305 51.9325C39.4732 51.9328 39.6144 51.9038 39.7454 51.8472C39.8764 51.7906 39.9943 51.7077 40.0919 51.6036C40.1895 51.4995 40.2646 51.3765 40.3126 51.2422C40.3606 51.1078 40.3805 50.965 40.371 50.8227Z"
          fill="#528ACC"
        />
        <path
          d="M40.2332 46.519C40.2043 46.2899 40.0999 46.077 39.9367 45.9138C39.7734 45.7505 39.5605 45.6462 39.3315 45.6172C39.078 45.615 38.8323 45.7053 38.6408 45.8714C38.4492 46.0374 38.3248 46.2677 38.291 46.519C38.2998 46.6466 38.3337 46.7713 38.3908 46.8857C38.448 47.0002 38.5271 47.1023 38.6238 47.1861C38.7205 47.2699 38.8328 47.3338 38.9542 47.374C39.0757 47.4143 39.2039 47.4301 39.3315 47.4207C39.5653 47.4045 39.7854 47.3043 39.9511 47.1386C40.1168 46.9729 40.217 46.7528 40.2332 46.519Z"
          fill="#528ACC"
        />
        <path
          d="M40.0925 42.222C40.0951 42.0051 40.0193 41.7945 39.8792 41.6289C39.739 41.4632 39.5439 41.3537 39.3295 41.3203C39.0957 41.3365 38.8756 41.4367 38.7098 41.6024C38.5441 41.7682 38.4439 41.9882 38.4277 42.222C38.462 42.4305 38.5742 42.6182 38.7416 42.747C38.909 42.8758 39.1192 42.9361 39.3295 42.9157C39.5204 42.9165 39.7046 42.8457 39.8459 42.7173C39.9871 42.5889 40.0751 42.4122 40.0925 42.222Z"
          fill="#528ACC"
        />
        <path
          d="M40.022 37.7102C39.982 37.5488 39.8946 37.403 39.7709 37.2917C39.6473 37.1805 39.4932 37.1088 39.3284 37.0859C39.1559 37.0851 38.9892 37.1486 38.8609 37.264C38.7326 37.3794 38.652 37.5385 38.6348 37.7102C38.6348 38.1958 38.8429 38.4039 39.3284 38.4039C39.4222 38.415 39.5173 38.4048 39.6065 38.3738C39.6957 38.3429 39.7768 38.2922 39.8436 38.2254C39.9103 38.1586 39.9611 38.0776 39.992 37.9883C40.0229 37.8991 40.0332 37.804 40.022 37.7102Z"
          fill="#528ACC"
        />
        <path
          d="M39.8833 33.4797C39.8615 33.333 39.7974 33.1958 39.6988 33.0849C39.6003 32.9741 39.4715 32.8943 39.3284 32.8555C39.1751 32.8726 39.0336 32.9459 38.9311 33.0611C38.8287 33.1764 38.7725 33.3255 38.7734 33.4797C38.7885 33.6218 38.8518 33.7543 38.9528 33.8553C39.0538 33.9563 39.1863 34.0196 39.3284 34.0347C39.4624 34.0011 39.5849 33.9317 39.6826 33.834C39.7804 33.7363 39.8497 33.6138 39.8833 33.4797Z"
          fill="#528ACC"
        />
        <path
          d="M39.6761 29.0382C39.6761 28.9689 39.5374 28.6914 39.3293 28.6914C39.2277 28.7094 39.1313 28.7499 39.0473 28.8099C38.9633 28.8699 38.8938 28.9479 38.8438 29.0382C38.8437 29.167 38.8949 29.2905 38.986 29.3816C39.077 29.4726 39.2005 29.5238 39.3293 29.5238C39.5374 29.5238 39.6761 29.385 39.6761 29.0382Z"
          fill="#528ACC"
        />
        <path
          d="M39.5373 24.6729C39.5373 24.6177 39.5154 24.5648 39.4764 24.5258C39.4374 24.4868 39.3844 24.4648 39.3292 24.4648C39.1212 24.4648 38.9824 24.5342 38.9824 24.6729C38.9824 24.8117 39.1212 25.1585 39.3292 25.1585C39.5373 25.1585 39.5373 24.881 39.5373 24.6729Z"
          fill="#528ACC"
        />
        <path
          d="M39.4699 20.3682L39.3311 20.1602C39.2759 20.1602 39.223 20.1821 39.184 20.2211C39.145 20.2601 39.123 20.3131 39.123 20.3682C39.123 20.4234 39.145 20.4764 39.184 20.5154C39.223 20.5544 39.2759 20.5763 39.3311 20.5763C39.3689 20.5549 39.4012 20.525 39.4253 20.4888C39.4494 20.4527 39.4646 20.4114 39.4699 20.3682Z"
          fill="#528ACC"
        />
        <path
          d="M39.3301 16.0703C39.1914 16.0703 39.1914 16.0703 39.1914 16.1397L39.3301 16.0703Z"
          fill="#528ACC"
        />
        <path
          d="M43.7003 109.643C43.7003 109.643 43.7003 109.504 43.6309 109.504L43.4922 109.643L43.6309 109.851C43.7003 109.851 43.7003 109.712 43.7003 109.643Z"
          fill="#528ACC"
        />
        <path
          d="M43.9778 105.341C43.9778 105.133 43.8391 105.133 43.631 105.133C43.4229 105.133 43.3535 105.133 43.3535 105.341C43.3535 105.549 43.4922 105.549 43.631 105.549C43.7697 105.549 43.9778 105.48 43.9778 105.341Z"
          fill="#528ACC"
        />
        <path
          d="M44.0462 100.968C44.0462 100.69 43.8381 100.621 43.63 100.621C43.4219 100.621 43.2832 100.69 43.2832 100.968C43.2832 101.245 43.3526 101.315 43.63 101.315C43.7294 101.316 43.8259 101.282 43.9023 101.218C43.9786 101.155 44.0297 101.066 44.0462 100.968Z"
          fill="#528ACC"
        />
        <path
          d="M44.1861 96.5334C44.1861 96.3253 43.978 96.1172 43.6312 96.1172C43.5135 96.116 43.3995 96.1575 43.3102 96.2341C43.2209 96.3106 43.1624 96.417 43.1456 96.5334C43.1356 96.6027 43.1407 96.6734 43.1606 96.7406C43.1805 96.8077 43.2146 96.8698 43.2608 96.9225C43.3069 96.9752 43.3639 97.0174 43.4278 97.046C43.4917 97.0746 43.5611 97.089 43.6312 97.0883C43.7067 97.1002 43.784 97.094 43.8568 97.0703C43.9295 97.0466 43.9956 97.0061 44.0497 96.952C44.1038 96.8979 44.1444 96.8318 44.1681 96.759C44.1917 96.6863 44.1979 96.609 44.1861 96.5334Z"
          fill="#528ACC"
        />
        <path
          d="M44.2534 92.2991C44.2632 92.2115 44.2543 92.1228 44.2274 92.0389C44.2004 91.9549 44.156 91.8777 44.097 91.8121C44.0381 91.7466 43.9659 91.6943 43.8852 91.6587C43.8046 91.6231 43.7173 91.6049 43.6291 91.6055C43.2823 91.6055 43.0742 91.8136 43.0742 92.2991C43.0733 92.4533 43.1294 92.6025 43.2319 92.7177C43.3344 92.833 43.4759 92.9063 43.6291 92.9234C43.7895 92.908 43.9395 92.8373 44.0534 92.7234C44.1673 92.6095 44.238 92.4595 44.2534 92.2991Z"
          fill="#528ACC"
        />
        <path
          d="M44.3923 87.8607C44.3764 87.6636 44.2909 87.4786 44.1511 87.3388C44.0113 87.199 43.8263 87.1135 43.6292 87.0977C43.4283 87.1222 43.24 87.2089 43.0908 87.3457C42.9415 87.4826 42.8388 87.6626 42.7969 87.8607C42.8129 88.0761 42.9057 88.2786 43.0585 88.4314C43.2113 88.5842 43.4138 88.677 43.6292 88.693C43.7356 88.6935 43.8408 88.6717 43.9382 88.6291C44.0356 88.5865 44.123 88.524 44.1949 88.4456C44.2667 88.3672 44.3214 88.2747 44.3554 88.174C44.3893 88.0733 44.4019 87.9665 44.3923 87.8607Z"
          fill="#528ACC"
        />
        <path
          d="M44.532 83.4916C44.532 83.2524 44.437 83.0231 44.2679 82.854C44.0988 82.6848 43.8694 82.5898 43.6303 82.5898C43.3911 82.5898 43.1617 82.6848 42.9926 82.854C42.8235 83.0231 42.7285 83.2524 42.7285 83.4916C42.7285 83.7307 42.8235 83.9601 42.9926 84.1292C43.1617 84.2983 43.3911 84.3933 43.6303 84.3933C43.7513 84.4039 43.8733 84.3879 43.9875 84.3463C44.1017 84.3047 44.2054 84.2386 44.2914 84.1527C44.3773 84.0667 44.4434 83.963 44.485 83.8488C44.5265 83.7346 44.5426 83.6127 44.532 83.4916Z"
          fill="#528ACC"
        />
        <path
          d="M44.6034 79.1919C44.6128 79.0552 44.5951 78.9179 44.5513 78.7881C44.5076 78.6582 44.4386 78.5383 44.3484 78.4352C44.2582 78.3321 44.1484 78.2478 44.0255 78.1872C43.9027 78.1266 43.769 78.0908 43.6323 78.082C43.3501 78.0997 43.0853 78.2244 42.892 78.4306C42.6986 78.6369 42.5912 78.9091 42.5918 79.1919C42.5918 79.4678 42.7014 79.7325 42.8965 79.9276C43.0917 80.1227 43.3563 80.2323 43.6323 80.2323C43.896 80.2147 44.1432 80.0973 44.3235 79.9041C44.5039 79.7109 44.6039 79.4562 44.6034 79.1919Z"
          fill="#528ACC"
        />
        <path
          d="M44.8784 74.8208C44.8442 74.5142 44.6977 74.2312 44.4671 74.0263C44.2366 73.8214 43.9383 73.7091 43.6299 73.711C43.3335 73.7256 43.0521 73.8454 42.836 74.0488C42.6199 74.2522 42.4833 74.5258 42.4507 74.8208C42.4065 75.0044 42.4045 75.1956 42.4449 75.38C42.4853 75.5645 42.567 75.7374 42.6838 75.8857C42.8007 76.034 42.9496 76.1539 43.1195 76.2363C43.2894 76.3187 43.4758 76.3616 43.6646 76.3616C43.8534 76.3616 44.0398 76.3187 44.2096 76.2363C44.3795 76.1539 44.5285 76.034 44.6453 75.8857C44.7622 75.7374 44.8439 75.5645 44.8842 75.38C44.9246 75.1956 44.9226 75.0044 44.8784 74.8208Z"
          fill="#528ACC"
        />
        <path
          d="M44.9473 70.3804C44.9384 70.2164 44.8972 70.0557 44.8262 69.9076C44.7551 69.7595 44.6555 69.6268 44.533 69.5173C44.4106 69.4078 44.2678 69.3234 44.1127 69.2692C43.9576 69.215 43.7934 69.1919 43.6293 69.2012C43.3099 69.2008 43.0024 69.3227 42.7701 69.5421C42.5379 69.7614 42.3985 70.0615 42.3808 70.3804C42.3612 70.5557 42.3789 70.7331 42.4328 70.9009C42.4867 71.0688 42.5756 71.2234 42.6936 71.3544C42.8115 71.4855 42.9559 71.5901 43.1172 71.6613C43.2785 71.7325 43.453 71.7688 43.6293 71.7677C43.8084 71.768 43.9856 71.7317 44.1502 71.6612C44.3148 71.5907 44.4633 71.4873 44.5867 71.3575C44.71 71.2277 44.8056 71.0741 44.8676 70.9061C44.9296 70.7381 44.9567 70.5593 44.9473 70.3804Z"
          fill="#528ACC"
        />
        <path
          d="M45.0861 66.1509C45.0863 65.9628 45.0483 65.7765 44.9743 65.6035C44.9003 65.4305 44.7918 65.2744 44.6556 65.1446C44.5193 65.0148 44.3581 64.9141 44.1817 64.8487C44.0053 64.7832 43.8174 64.7542 43.6294 64.7636C43.4446 64.7536 43.2598 64.7827 43.0869 64.8488C42.9141 64.915 42.7571 65.0169 42.6262 65.1477C42.4954 65.2786 42.3935 65.4356 42.3274 65.6084C42.2612 65.7813 42.2322 65.9661 42.2422 66.1509C42.2419 66.33 42.2782 66.5072 42.3487 66.6718C42.4192 66.8364 42.5225 66.9849 42.6524 67.1083C42.7822 67.2316 42.9358 67.3272 43.1038 67.3892C43.2718 67.4512 43.4506 67.4783 43.6294 67.4689C43.8117 67.4782 43.994 67.4515 44.1659 67.3903C44.3378 67.3291 44.4959 67.2345 44.6312 67.1121C44.7665 66.9897 44.8763 66.8418 44.9544 66.6768C45.0325 66.5119 45.0772 66.3332 45.0861 66.1509Z"
          fill="#528ACC"
        />
        <path
          d="M45.0859 61.7106C45.0689 61.3297 44.91 60.9689 44.6405 60.6994C44.3709 60.4298 44.0101 60.2709 43.6293 60.2539C43.4411 60.2537 43.2548 60.2917 43.0818 60.3657C42.9088 60.4397 42.7527 60.5482 42.6229 60.6844C42.4932 60.8207 42.3925 60.9819 42.327 61.1583C42.2615 61.3347 42.2326 61.5226 42.242 61.7106C42.2326 61.8985 42.2615 62.0864 42.327 62.2628C42.3925 62.4392 42.4932 62.6004 42.6229 62.7367C42.7527 62.8729 42.9088 62.9814 43.0818 63.0554C43.2548 63.1294 43.4411 63.1674 43.6293 63.1672C44.0156 63.1672 44.3861 63.0137 44.6593 62.7406C44.9324 62.4674 45.0859 62.0969 45.0859 61.7106Z"
          fill="#528ACC"
        />
        <path
          d="M44.9474 57.3404C44.9478 56.9842 44.8112 56.6414 44.5659 56.3832C44.3205 56.1249 43.9852 55.9709 43.6295 55.9531C43.272 55.9844 42.9371 56.1406 42.6833 56.3943C42.4296 56.648 42.2734 56.983 42.2422 57.3404C42.2591 57.7029 42.4107 58.046 42.6673 58.3026C42.9238 58.5592 43.267 58.7108 43.6295 58.7277C43.8085 58.7279 43.9858 58.6917 44.1504 58.6212C44.315 58.5506 44.4635 58.4473 44.5868 58.3175C44.7101 58.1877 44.8057 58.0341 44.8677 57.8661C44.9297 57.6981 44.9568 57.5192 44.9474 57.3404Z"
          fill="#528ACC"
        />
        <path
          d="M44.878 53.0415C44.8612 52.7158 44.7243 52.4079 44.4937 52.1772C44.2631 51.9466 43.9551 51.8097 43.6294 51.793C43.3037 51.8097 42.9958 51.9466 42.7651 52.1772C42.5345 52.4079 42.3976 52.7158 42.3809 53.0415C42.3806 53.2024 42.4132 53.3616 42.4768 53.5094C42.5403 53.6572 42.6334 53.7904 42.7504 53.9009C42.8673 54.0113 43.0057 54.0967 43.1568 54.1517C43.308 54.2067 43.4688 54.2302 43.6294 54.2207C43.9489 54.2212 44.2564 54.0992 44.4886 53.8799C44.7209 53.6605 44.8602 53.3605 44.878 53.0415Z"
          fill="#528ACC"
        />
        <path
          d="M44.7383 48.8071C44.7664 48.5838 44.726 48.3573 44.6224 48.1575C44.5188 47.9577 44.3569 47.7941 44.1581 47.6885C43.9594 47.5828 43.7333 47.5401 43.5097 47.5659C43.2861 47.5918 43.0757 47.685 42.9063 47.8332C42.737 47.9814 42.6167 48.1776 42.5614 48.3957C42.5061 48.6139 42.5184 48.8437 42.5967 49.0547C42.6751 49.2657 42.8157 49.4479 43 49.5771C43.1842 49.7063 43.4034 49.7764 43.6285 49.7782C43.9003 49.7803 44.1634 49.6826 44.368 49.5037C44.5726 49.3247 44.7043 49.0768 44.7383 48.8071Z"
          fill="#528ACC"
        />
        <path
          d="M44.6021 44.2989C44.6024 44.1745 44.577 44.0512 44.5274 43.9371C44.4778 43.8229 44.4051 43.7202 44.3139 43.6355C44.2227 43.5508 44.1149 43.4859 43.9974 43.4449C43.8798 43.4039 43.7551 43.3876 43.631 43.3972C43.3918 43.3972 43.1625 43.4922 42.9933 43.6613C42.8242 43.8304 42.7292 44.0598 42.7292 44.2989C42.7197 44.4231 42.7359 44.5478 42.7769 44.6654C42.8179 44.7829 42.8828 44.8907 42.9675 44.9819C43.0522 45.0731 43.1549 45.1458 43.2691 45.1954C43.3833 45.245 43.5065 45.2704 43.631 45.27C43.7612 45.2805 43.8921 45.2626 44.0146 45.2175C44.1372 45.1724 44.2485 45.1012 44.3409 45.0088C44.4332 44.9165 44.5044 44.8052 44.5495 44.6826C44.5946 44.56 44.6125 44.4291 44.6021 44.2989Z"
          fill="#528ACC"
        />
        <path
          d="M44.5319 39.9994C44.5157 39.7656 44.4155 39.5455 44.2498 39.3798C44.0841 39.214 43.864 39.1138 43.6302 39.0977C43.5148 39.0973 43.4006 39.1209 43.2948 39.167C43.189 39.2131 43.0939 39.2806 43.0157 39.3654C42.9374 39.4502 42.8776 39.5504 42.8401 39.6595C42.8026 39.7687 42.7882 39.8844 42.7978 39.9994C42.7971 40.227 42.8826 40.4464 43.037 40.6137C43.1913 40.7809 43.4033 40.8836 43.6302 40.9011C43.8592 40.8722 44.0721 40.7679 44.2354 40.6046C44.3986 40.4414 44.503 40.2284 44.5319 39.9994Z"
          fill="#528ACC"
        />
        <path
          d="M44.3922 35.6966C44.3922 35.5964 44.3725 35.4972 44.3341 35.4046C44.2958 35.312 44.2396 35.2279 44.1687 35.1571C44.0979 35.0862 44.0138 35.03 43.9212 34.9917C43.8286 34.9533 43.7294 34.9336 43.6292 34.9336C43.4391 34.9509 43.2624 35.0389 43.134 35.1802C43.0056 35.3214 42.9348 35.5057 42.9356 35.6966C42.9512 35.8753 43.0293 36.0428 43.1561 36.1697C43.283 36.2965 43.4505 36.3746 43.6292 36.3902C43.8201 36.391 44.0044 36.3202 44.1456 36.1918C44.2869 36.0634 44.3748 35.8867 44.3922 35.6966Z"
          fill="#528ACC"
        />
        <path
          d="M44.184 31.1935C44.184 30.9854 43.976 30.7773 43.6291 30.7773C43.2823 30.7773 43.0742 30.9854 43.0742 31.1935C43.0742 31.4016 43.2823 31.8872 43.6291 31.8872C43.976 31.8872 44.184 31.6791 44.184 31.1935Z"
          fill="#528ACC"
        />
        <path
          d="M43.6291 27.305C43.8589 27.305 44.0453 27.1187 44.0453 26.8888C44.0453 26.659 43.8589 26.4727 43.6291 26.4727C43.3992 26.4727 43.2129 26.659 43.2129 26.8888C43.2129 27.1187 43.3992 27.305 43.6291 27.305Z"
          fill="#528ACC"
        />
        <path
          d="M43.9779 22.5226C43.9779 22.4306 43.9413 22.3424 43.8763 22.2774C43.8112 22.2123 43.723 22.1758 43.631 22.1758C43.4923 22.1758 43.3536 22.3839 43.3536 22.5226C43.3519 22.6037 43.3788 22.6829 43.4295 22.7463C43.4802 22.8096 43.5515 22.8532 43.631 22.8694C43.723 22.8694 43.8112 22.8329 43.8763 22.7678C43.9413 22.7028 43.9779 22.6146 43.9779 22.5226Z"
          fill="#528ACC"
        />
        <path
          d="M43.839 18.1504L43.6309 18.0117C43.5941 18.0117 43.5588 18.0263 43.5328 18.0524C43.5068 18.0784 43.4922 18.1137 43.4922 18.1504C43.4922 18.3585 43.4922 18.3585 43.6309 18.3585C43.7696 18.3585 43.839 18.3585 43.839 18.1504Z"
          fill="#528ACC"
        />
        <path
          d="M48.1388 111.86C48.1388 111.791 48.1388 111.652 48.0001 111.652C47.8613 111.652 47.8613 111.791 47.8613 111.86C47.8613 111.897 47.8759 111.933 47.902 111.959C47.928 111.985 47.9633 111.999 48.0001 111.999C48.0368 111.999 48.0721 111.985 48.0982 111.959C48.1242 111.933 48.1388 111.897 48.1388 111.86Z"
          fill="#528ACC"
        />
        <path
          d="M48.2092 107.489C48.2092 107.434 48.1872 107.381 48.1482 107.342C48.1092 107.303 48.0562 107.281 48.0011 107.281C47.9459 107.281 47.8929 107.303 47.8539 107.342C47.8149 107.381 47.793 107.434 47.793 107.489C47.793 107.628 47.8623 107.836 48.0011 107.836C48.1398 107.836 48.2092 107.628 48.2092 107.489Z"
          fill="#528ACC"
        />
        <path
          d="M48.346 103.055C48.346 102.847 48.2073 102.777 47.9992 102.777C47.7911 102.777 47.5137 102.847 47.5137 103.055C47.5284 103.178 47.5843 103.294 47.6723 103.382C47.7604 103.47 47.8755 103.526 47.9992 103.54C48.2073 103.54 48.346 103.332 48.346 103.055Z"
          fill="#528ACC"
        />
        <path
          d="M48.5551 98.8186C48.5401 98.6766 48.4768 98.544 48.3758 98.443C48.2748 98.3421 48.1422 98.2787 48.0002 98.2637C47.9247 98.2518 47.8473 98.258 47.7746 98.2817C47.7019 98.3054 47.6358 98.3459 47.5817 98.4C47.5276 98.4541 47.487 98.5202 47.4633 98.593C47.4396 98.6657 47.4335 98.743 47.4453 98.8186C47.4453 99.0267 47.6534 99.2348 48.0002 99.2348C48.347 99.2348 48.5551 99.0267 48.5551 98.8186Z"
          fill="#528ACC"
        />
        <path
          d="M48.692 94.3811C48.692 94.1972 48.6189 94.0207 48.4888 93.8907C48.3587 93.7606 48.1823 93.6875 47.9983 93.6875C47.8144 93.6875 47.6379 93.7606 47.5078 93.8907C47.3778 94.0207 47.3047 94.1972 47.3047 94.3811C47.3047 94.5651 47.3778 94.7415 47.5078 94.8716C47.6379 95.0017 47.8144 95.0748 47.9983 95.0748C48.1823 95.0748 48.3587 95.0017 48.4888 94.8716C48.6189 94.7415 48.692 94.5651 48.692 94.3811Z"
          fill="#528ACC"
        />
        <path
          d="M48.7635 90.0135C48.764 89.9163 48.744 89.82 48.7049 89.731C48.6658 89.642 48.6084 89.5622 48.5365 89.4968C48.4645 89.4314 48.3796 89.3818 48.2873 89.3514C48.195 89.3209 48.0972 89.3102 48.0005 89.3199C47.9038 89.3102 47.806 89.3209 47.7137 89.3514C47.6214 89.3818 47.5365 89.4314 47.4645 89.4968C47.3926 89.5622 47.3352 89.642 47.2961 89.731C47.257 89.82 47.237 89.9163 47.2375 90.0135C47.228 90.1229 47.2402 90.2331 47.2735 90.3377C47.3068 90.4424 47.3604 90.5394 47.4313 90.6232C47.5022 90.707 47.589 90.776 47.6867 90.8261C47.7844 90.8762 47.8911 90.9065 48.0005 90.9153C48.1099 90.9065 48.2166 90.8762 48.3143 90.8261C48.412 90.776 48.4988 90.707 48.5697 90.6232C48.6406 90.5394 48.6942 90.4424 48.7275 90.3377C48.7608 90.2331 48.773 90.1229 48.7635 90.0135Z"
          fill="#528ACC"
        />
        <path
          d="M48.9011 85.7103C48.8722 85.4813 48.7678 85.2684 48.6046 85.1051C48.4413 84.9419 48.2284 84.8376 47.9994 84.8086C47.7656 84.8248 47.5455 84.925 47.3798 85.0907C47.214 85.2564 47.1138 85.4765 47.0977 85.7103C47.0977 85.9495 47.1927 86.1788 47.3618 86.3479C47.5309 86.5171 47.7602 86.6121 47.9994 86.6121C48.2332 86.5959 48.4533 86.4957 48.619 86.33C48.7847 86.1642 48.8849 85.9441 48.9011 85.7103Z"
          fill="#528ACC"
        />
        <path
          d="M49.0419 81.3412C49.0419 81.1355 48.9808 80.9343 48.8665 80.7632C48.7522 80.5921 48.5897 80.4587 48.3996 80.38C48.2094 80.3012 48.0002 80.2806 47.7984 80.3208C47.5966 80.3609 47.4112 80.46 47.2657 80.6055C47.1202 80.751 47.0211 80.9364 46.9809 81.1383C46.9408 81.3401 46.9614 81.5493 47.0401 81.7394C47.1189 81.9295 47.2522 82.092 47.4234 82.2064C47.5945 82.3207 47.7956 82.3817 48.0014 82.3817C48.2773 82.3817 48.542 82.2721 48.7371 82.077C48.9322 81.8818 49.0419 81.6172 49.0419 81.3412Z"
          fill="#528ACC"
        />
        <path
          d="M49.1103 76.9731C49.1103 76.6788 48.9933 76.3965 48.7852 76.1883C48.5771 75.9802 48.2948 75.8633 48.0005 75.8633C47.7061 75.8633 47.4238 75.9802 47.2157 76.1883C47.0076 76.3965 46.8906 76.6788 46.8906 76.9731C46.8906 77.2675 47.0076 77.5497 47.2157 77.7579C47.4238 77.966 47.7061 78.0829 48.0005 78.0829C48.2948 78.0829 48.5771 77.966 48.7852 77.7579C48.9933 77.5497 49.1103 77.2675 49.1103 76.9731Z"
          fill="#528ACC"
        />
        <path
          d="M49.3175 72.6695C49.3175 72.4088 49.2402 72.154 49.0954 71.9373C48.9506 71.7206 48.7447 71.5516 48.5039 71.4519C48.2631 71.3521 47.9981 71.326 47.7424 71.3769C47.4868 71.4277 47.252 71.5533 47.0677 71.7376C46.8833 71.9219 46.7578 72.1567 46.707 72.4124C46.6561 72.668 46.6822 72.933 46.782 73.1738C46.8817 73.4146 47.0506 73.6205 47.2674 73.7653C47.4841 73.9101 47.7389 73.9874 47.9996 73.9874C48.3437 73.9706 48.6692 73.8263 48.9128 73.5827C49.1564 73.3391 49.3007 73.0136 49.3175 72.6695Z"
          fill="#528ACC"
        />
        <path
          d="M47.9996 69.757C48.8041 69.757 49.4563 69.1049 49.4563 68.3004C49.4563 67.4959 48.8041 66.8438 47.9996 66.8438C47.1951 66.8438 46.543 67.4959 46.543 68.3004C46.543 69.1049 47.1951 69.757 47.9996 69.757Z"
          fill="#528ACC"
        />
        <path
          d="M49.4563 63.8629C49.4563 63.4766 49.3028 63.1061 49.0296 62.8329C48.7564 62.5597 48.3859 62.4062 47.9996 62.4062C47.6133 62.4062 47.2428 62.5597 46.9696 62.8329C46.6964 63.1061 46.543 63.4766 46.543 63.8629C46.543 64.2492 46.6964 64.6197 46.9696 64.8929C47.2428 65.1661 47.6133 65.3195 47.9996 65.3195C48.3859 65.3195 48.7564 65.1661 49.0296 64.8929C49.3028 64.6197 49.4563 64.2492 49.4563 63.8629Z"
          fill="#528ACC"
        />
        <path
          d="M47.9996 60.9483C48.7274 60.9483 49.3175 60.3583 49.3175 59.6304C49.3175 58.9026 48.7274 58.3125 47.9996 58.3125C47.2717 58.3125 46.6816 58.9026 46.6816 59.6304C46.6816 60.3583 47.2717 60.9483 47.9996 60.9483Z"
          fill="#528ACC"
        />
        <path
          d="M49.2491 55.19C49.2491 54.943 49.1758 54.7016 49.0386 54.4963C48.9015 54.291 48.7065 54.1309 48.4783 54.0364C48.2502 53.9419 47.9991 53.9172 47.7569 53.9654C47.5147 54.0136 47.2923 54.1325 47.1176 54.3071C46.943 54.4817 46.8241 54.7042 46.7759 54.9464C46.7278 55.1886 46.7525 55.4396 46.847 55.6678C46.9415 55.8959 47.1015 56.0909 47.3068 56.2281C47.5122 56.3653 47.7536 56.4385 48.0005 56.4385C48.3316 56.4385 48.6492 56.307 48.8834 56.0728C49.1175 55.8387 49.2491 55.5211 49.2491 55.19Z"
          fill="#528ACC"
        />
        <path
          d="M49.1103 50.8208C49.1103 50.5264 48.9933 50.2441 48.7852 50.036C48.5771 49.8279 48.2948 49.7109 48.0005 49.7109C47.7061 49.7109 47.4238 49.8279 47.2157 50.036C47.0076 50.2441 46.8906 50.5264 46.8906 50.8208C46.8906 51.1151 47.0076 51.3974 47.2157 51.6055C47.4238 51.8137 47.7061 51.9306 48.0005 51.9306C48.2948 51.9306 48.5771 51.8137 48.7852 51.6055C48.9933 51.3974 49.1103 51.1151 49.1103 50.8208Z"
          fill="#528ACC"
        />
        <path
          d="M49.0419 46.517C49.0419 46.2411 48.9322 45.9764 48.7371 45.7813C48.542 45.5862 48.2773 45.4766 48.0014 45.4766C47.7255 45.4766 47.4608 45.5862 47.2657 45.7813C47.0706 45.9764 46.9609 46.2411 46.9609 46.517C46.9609 46.793 47.0706 47.0576 47.2657 47.2527C47.4608 47.4479 47.7255 47.5575 48.0014 47.5575C48.2773 47.5575 48.542 47.4479 48.7371 47.2527C48.9322 47.0576 49.0419 46.793 49.0419 46.517Z"
          fill="#528ACC"
        />
        <path
          d="M48.9011 42.222C48.9011 41.9829 48.8061 41.7535 48.637 41.5844C48.4679 41.4153 48.2385 41.3203 47.9993 41.3203C47.5138 41.3203 47.2363 41.6671 47.2363 42.222C47.2363 42.777 47.5138 42.9157 47.9993 42.9157C48.4849 42.9157 48.9011 42.7076 48.9011 42.222Z"
          fill="#528ACC"
        />
        <path
          d="M48.692 37.7102C48.6747 37.5385 48.5941 37.3794 48.4658 37.264C48.3376 37.1485 48.1709 37.085 47.9983 37.0859C47.9107 37.0761 47.822 37.085 47.7381 37.1119C47.6542 37.1389 47.5769 37.1833 47.5113 37.2423C47.4458 37.3013 47.3935 37.3735 47.3579 37.4541C47.3223 37.5347 47.3042 37.622 47.3047 37.7102C47.3047 38.1957 47.5128 38.5425 47.9983 38.5425C48.0987 38.5338 48.1963 38.5054 48.2856 38.4587C48.3749 38.4121 48.4541 38.3482 48.5186 38.2709C48.583 38.1935 48.6316 38.1041 48.6613 38.0079C48.6911 37.9116 48.7015 37.8105 48.692 37.7102Z"
          fill="#528ACC"
        />
        <path
          d="M48.0002 34.0356C48.3067 34.0356 48.5551 33.7872 48.5551 33.4807C48.5551 33.1742 48.3067 32.9258 48.0002 32.9258C47.6938 32.9258 47.4453 33.1742 47.4453 33.4807C47.4453 33.7872 47.6938 34.0356 48.0002 34.0356Z"
          fill="#528ACC"
        />
        <path
          d="M48.4154 29.0383C48.3989 28.9403 48.3478 28.8515 48.2715 28.7879C48.1952 28.7243 48.0986 28.6901 47.9992 28.6914C47.7911 28.6914 47.5137 28.8302 47.5137 29.0383C47.5126 29.1742 47.5615 29.3057 47.651 29.408C47.7405 29.5103 47.8644 29.5762 47.9992 29.5932C48.2073 29.5932 48.4154 29.3851 48.4154 29.0383Z"
          fill="#528ACC"
        />
        <path
          d="M48.3479 24.6729C48.3479 24.5342 48.2092 24.4648 48.0011 24.4648C47.793 24.4648 47.6543 24.5342 47.6543 24.6729C47.6543 24.8117 47.793 25.1585 48.0011 25.1585C48.2092 25.1585 48.3479 24.881 48.3479 24.6729Z"
          fill="#528ACC"
        />
        <path
          d="M48.2092 20.3682C48.2092 20.3131 48.1872 20.2601 48.1482 20.2211C48.1092 20.1821 48.0562 20.1602 48.0011 20.1602C47.9459 20.1602 47.8929 20.1821 47.8539 20.2211C47.8149 20.2601 47.793 20.3131 47.793 20.3682C47.793 20.4234 47.8149 20.4764 47.8539 20.5154C47.8929 20.5544 47.9459 20.5763 48.0011 20.5763C48.0562 20.5763 48.1092 20.5544 48.1482 20.5154C48.1872 20.4764 48.2092 20.4234 48.2092 20.3682Z"
          fill="#528ACC"
        />
        <path
          d="M52.5089 109.645H52.3008C52.3008 109.714 52.3008 109.853 52.3701 109.853L52.5089 109.645Z"
          fill="#528ACC"
        />
        <path
          d="M52.6467 105.341C52.6467 105.133 52.508 105.133 52.3693 105.133C52.2305 105.133 52.0918 105.133 52.0918 105.341C52.0918 105.549 52.1612 105.549 52.3693 105.549C52.5773 105.549 52.6467 105.48 52.6467 105.341Z"
          fill="#528ACC"
        />
        <path
          d="M52.7161 100.969C52.6838 100.894 52.6304 100.831 52.5626 100.787C52.4949 100.742 52.4156 100.719 52.3346 100.719C52.2536 100.719 52.1744 100.742 52.1066 100.787C52.0388 100.831 51.9855 100.894 51.9531 100.969C51.9697 101.067 52.0207 101.155 52.0971 101.219C52.1734 101.283 52.27 101.317 52.3693 101.315C52.4613 101.315 52.5495 101.279 52.6145 101.214C52.6796 101.149 52.7161 101.061 52.7161 100.969Z"
          fill="#528ACC"
        />
        <path
          d="M52.8569 96.5334C52.8401 96.417 52.7816 96.3106 52.6923 96.2341C52.603 96.1575 52.4889 96.116 52.3713 96.1172C52.0939 96.1172 51.8164 96.3253 51.8164 96.5334C51.8164 96.6806 51.8749 96.8217 51.9789 96.9258C52.083 97.0298 52.2241 97.0883 52.3713 97.0883C52.4414 97.089 52.5107 97.0746 52.5747 97.046C52.6386 97.0174 52.6956 96.9752 52.7417 96.9225C52.7878 96.8698 52.822 96.8077 52.8419 96.7406C52.8618 96.6734 52.8669 96.6027 52.8569 96.5334Z"
          fill="#528ACC"
        />
        <path
          d="M52.9946 92.2991C53.0044 92.2115 52.9955 92.1228 52.9686 92.0389C52.9416 91.9549 52.8972 91.8777 52.8382 91.8121C52.7792 91.7466 52.7071 91.6943 52.6264 91.6587C52.5458 91.6231 52.4585 91.6049 52.3703 91.6055C52.2822 91.6049 52.1949 91.6231 52.1143 91.6587C52.0336 91.6943 51.9614 91.7466 51.9024 91.8121C51.8435 91.8777 51.799 91.9549 51.7721 92.0389C51.7451 92.1228 51.7363 92.2115 51.7461 92.2991C51.7726 92.455 51.8469 92.5989 51.9587 92.7107C52.0706 92.8226 52.2144 92.8969 52.3703 92.9234C52.5307 92.908 52.6807 92.8373 52.7946 92.7234C52.9085 92.6095 52.9792 92.4595 52.9946 92.2991Z"
          fill="#528ACC"
        />
        <path
          d="M53.0616 87.8615C53.0973 87.7473 53.1056 87.6263 53.0859 87.5082C53.0661 87.3902 53.0189 87.2785 52.948 87.1821C52.8771 87.0857 52.7845 87.0073 52.6778 86.9533C52.571 86.8992 52.453 86.8711 52.3333 86.8711C52.2136 86.8711 52.0957 86.8992 51.9889 86.9533C51.8821 87.0073 51.7895 87.0857 51.7186 87.1821C51.6477 87.2785 51.6005 87.3902 51.5808 87.5082C51.561 87.6263 51.5693 87.7473 51.605 87.8615C51.5693 87.9758 51.561 88.0968 51.5808 88.2148C51.6005 88.3328 51.6477 88.4446 51.7186 88.541C51.7895 88.6374 51.8821 88.7158 51.9889 88.7698C52.0957 88.8238 52.2136 88.852 52.3333 88.852C52.453 88.852 52.571 88.8238 52.6778 88.7698C52.7845 88.7158 52.8771 88.6374 52.948 88.541C53.0189 88.4446 53.0661 88.3328 53.0859 88.2148C53.1056 88.0968 53.0973 87.9758 53.0616 87.8615Z"
          fill="#528ACC"
        />
        <path
          d="M53.2021 83.4916C53.2117 83.3766 53.1973 83.2608 53.1598 83.1517C53.1223 83.0426 53.0625 82.9424 52.9843 82.8576C52.906 82.7728 52.8109 82.7052 52.7052 82.6591C52.5994 82.613 52.4851 82.5894 52.3697 82.5898C52.1306 82.5898 51.9012 82.6849 51.7321 82.854C51.563 83.0231 51.468 83.2524 51.468 83.4916C51.4574 83.6127 51.4735 83.7346 51.515 83.8488C51.5566 83.963 51.6227 84.0667 51.7086 84.1527C51.7946 84.2386 51.8983 84.3047 52.0125 84.3463C52.1267 84.3879 52.2487 84.4039 52.3697 84.3933C52.4851 84.3937 52.5994 84.3701 52.7052 84.324C52.8109 84.2779 52.906 84.2103 52.9843 84.1255C53.0625 84.0407 53.1223 83.9406 53.1598 83.8315C53.1973 83.7223 53.2117 83.6066 53.2021 83.4916Z"
          fill="#528ACC"
        />
        <path
          d="M52.3686 80.2294C52.9432 80.2294 53.409 79.7635 53.409 79.1889C53.409 78.6143 52.9432 78.1484 52.3686 78.1484C51.794 78.1484 51.3281 78.6143 51.3281 79.1889C51.3281 79.7635 51.794 80.2294 52.3686 80.2294Z"
          fill="#528ACC"
        />
        <path
          d="M53.5498 74.8237C53.5498 74.511 53.4255 74.2111 53.2044 73.9899C52.9833 73.7688 52.6833 73.6445 52.3706 73.6445C52.0579 73.6445 51.7579 73.7688 51.5368 73.9899C51.3156 74.2111 51.1914 74.511 51.1914 74.8237C51.1914 75.1365 51.3156 75.4364 51.5368 75.6575C51.7579 75.8787 52.0579 76.0029 52.3706 76.0029C52.6833 76.0029 52.9833 75.8787 53.2044 75.6575C53.4255 75.4364 53.5498 75.1365 53.5498 74.8237Z"
          fill="#528ACC"
        />
        <path
          d="M53.6187 70.3815C53.6098 70.2266 53.5705 70.0749 53.5029 69.9352C53.4353 69.7955 53.3408 69.6705 53.2248 69.5674C53.1088 69.4643 52.9736 69.3851 52.8269 69.3343C52.6803 69.2836 52.525 69.2623 52.3701 69.2717C52.069 69.2712 51.7792 69.3858 51.5599 69.5922C51.3407 69.7985 51.2086 70.0809 51.1909 70.3815C51.1467 70.5651 51.1448 70.7563 51.1851 70.9407C51.2255 71.1252 51.3072 71.2981 51.424 71.4464C51.5409 71.5947 51.6899 71.7146 51.8597 71.7971C52.0296 71.8795 52.216 71.9223 52.4048 71.9223C52.5936 71.9223 52.78 71.8795 52.9499 71.7971C53.1197 71.7146 53.2687 71.5947 53.3856 71.4464C53.5024 71.2981 53.5841 71.1252 53.6245 70.9407C53.6649 70.7563 53.6629 70.5651 53.6187 70.3815Z"
          fill="#528ACC"
        />
        <path
          d="M53.7579 66.149C53.7267 65.7916 53.5705 65.4566 53.3168 65.2029C53.0631 64.9492 52.7281 64.793 52.3707 64.7617C52.0149 64.7795 51.6796 64.9335 51.4342 65.1918C51.1889 65.45 51.0523 65.7928 51.0527 66.149C51.0527 66.3221 51.0868 66.4934 51.1531 66.6533C51.2193 66.8132 51.3164 66.9585 51.4387 67.0809C51.5611 67.2033 51.7064 67.3004 51.8663 67.3666C52.0262 67.4328 52.1976 67.4669 52.3707 67.4669C52.722 67.4517 53.0561 67.3099 53.3111 67.0677C53.566 66.8255 53.7248 66.4991 53.7579 66.149Z"
          fill="#528ACC"
        />
        <path
          d="M53.758 61.7106C53.7402 61.3548 53.5862 61.0195 53.328 60.7742C53.0697 60.5288 52.727 60.3922 52.3707 60.3927C52.195 60.3826 52.0192 60.4098 51.8547 60.4725C51.6903 60.5351 51.5409 60.6319 51.4165 60.7563C51.292 60.8808 51.1953 61.0301 51.1326 61.1946C51.07 61.359 51.0428 61.5349 51.0528 61.7106C51.0434 61.8894 51.0705 62.0683 51.1325 62.2363C51.1945 62.4043 51.2901 62.5578 51.4134 62.6877C51.5368 62.8175 51.6853 62.9208 51.8498 62.9913C52.0144 63.0619 52.1917 63.0981 52.3707 63.0979C52.7387 63.0979 53.0915 62.9517 53.3517 62.6915C53.6119 62.4314 53.758 62.0785 53.758 61.7106Z"
          fill="#528ACC"
        />
        <path
          d="M53.6187 57.3403C53.6187 57.0092 53.4872 56.6916 53.253 56.4575C53.0189 56.2233 52.7013 56.0918 52.3702 56.0918C52.2005 56.0823 52.0306 56.1076 51.871 56.1661C51.7115 56.2246 51.5655 56.3151 51.4421 56.432C51.3187 56.5489 51.2205 56.6898 51.1535 56.8459C51.0864 57.0021 51.052 57.1704 51.0523 57.3403C51.007 57.5337 51.006 57.7348 51.0494 57.9286C51.0928 58.1224 51.1794 58.3039 51.3028 58.4595C51.4262 58.6152 51.5832 58.7409 51.762 58.8273C51.9409 58.9137 52.1369 58.9586 52.3355 58.9586C52.5341 58.9586 52.7301 58.9137 52.909 58.8273C53.0878 58.7409 53.2448 58.6152 53.3682 58.4595C53.4916 58.3039 53.5782 58.1224 53.6216 57.9286C53.665 57.7348 53.664 57.5337 53.6187 57.3403Z"
          fill="#528ACC"
        />
        <path
          d="M53.5498 53.0425C53.5498 52.7297 53.4255 52.4298 53.2044 52.2087C52.9833 51.9875 52.6833 51.8633 52.3706 51.8633C52.0579 51.8633 51.7579 51.9875 51.5368 52.2087C51.3156 52.4298 51.1914 52.7297 51.1914 53.0425C51.1914 53.3552 51.3156 53.6551 51.5368 53.8763C51.7579 54.0974 52.0579 54.2217 52.3706 54.2217C52.6833 54.2217 52.9833 54.0974 53.2044 53.8763C53.4255 53.6551 53.5498 53.3552 53.5498 53.0425Z"
          fill="#528ACC"
        />
        <path
          d="M53.4122 48.8081C53.4321 48.6603 53.42 48.51 53.3767 48.3673C53.3334 48.2245 53.2599 48.0928 53.1612 47.981C53.0626 47.8691 52.941 47.7798 52.8048 47.7191C52.6686 47.6584 52.5209 47.6276 52.3718 47.6289C52.22 47.6287 52.0698 47.6595 51.9304 47.7195C51.791 47.7796 51.6654 47.8676 51.5614 47.9781C51.4574 48.0887 51.3772 48.2193 51.3256 48.3621C51.2741 48.5049 51.2524 48.6567 51.2619 48.8081C51.311 49.0652 51.4492 49.2966 51.6523 49.4616C51.8553 49.6266 52.1102 49.7145 52.3718 49.7099C52.4994 49.7193 52.6276 49.7034 52.749 49.6632C52.8704 49.6229 52.9827 49.5591 53.0794 49.4753C53.1761 49.3915 53.2553 49.2894 53.3124 49.1749C53.3695 49.0604 53.4034 48.9358 53.4122 48.8081Z"
          fill="#528ACC"
        />
        <path
          d="M53.2713 44.2989C53.2713 44.0598 53.1763 43.8304 53.0072 43.6613C52.8381 43.4922 52.6087 43.3972 52.3695 43.3972C52.2454 43.3876 52.1207 43.4039 52.0031 43.4449C51.8856 43.4859 51.7778 43.5508 51.6866 43.6355C51.5954 43.7202 51.5227 43.8229 51.4731 43.9371C51.4235 44.0512 51.3981 44.1745 51.3984 44.2989C51.3984 44.5565 51.5008 44.8035 51.6829 44.9856C51.865 45.1677 52.112 45.27 52.3695 45.27C52.494 45.2704 52.6172 45.245 52.7314 45.1954C52.8456 45.1458 52.9483 45.0731 53.033 44.9819C53.1177 44.8907 53.1826 44.7829 53.2236 44.6654C53.2646 44.5478 53.2809 44.4231 53.2713 44.2989Z"
          fill="#528ACC"
        />
        <path
          d="M53.2009 39.9994C53.2105 39.8844 53.1961 39.7687 53.1586 39.6595C53.1211 39.5504 53.0613 39.4502 52.9831 39.3654C52.9048 39.2806 52.8097 39.2131 52.7039 39.167C52.5982 39.1209 52.4839 39.0973 52.3685 39.0977C52.1347 39.1138 51.9146 39.214 51.7489 39.3798C51.5832 39.5455 51.483 39.7656 51.4668 39.9994C51.5002 40.2138 51.6097 40.409 51.7753 40.5491C51.941 40.6892 52.1516 40.765 52.3685 40.7624C52.4744 40.7721 52.5811 40.7595 52.6819 40.7255C52.7826 40.6915 52.8751 40.6368 52.9535 40.565C53.0319 40.4932 53.0943 40.4057 53.137 40.3083C53.1796 40.2109 53.2013 40.1057 53.2009 39.9994Z"
          fill="#528ACC"
        />
        <path
          d="M53.064 35.6966C53.0648 35.5057 52.994 35.3214 52.8656 35.1802C52.7372 35.0389 52.5605 34.9509 52.3704 34.9336C51.9542 34.9336 51.7461 35.2804 51.7461 35.6966C51.7461 36.1128 51.9542 36.3902 52.3704 36.3902C52.7866 36.3902 53.064 35.9047 53.064 35.6966Z"
          fill="#528ACC"
        />
        <path
          d="M52.9956 31.1935C52.9956 30.9854 52.6488 30.7773 52.3713 30.7773C52.0939 30.7773 51.8164 30.9854 51.8164 31.1935C51.8164 31.4016 52.0939 31.8872 52.3713 31.8872C52.4595 31.8877 52.5468 31.8696 52.6274 31.834C52.708 31.7983 52.7802 31.7461 52.8392 31.6805C52.8982 31.615 52.9426 31.5377 52.9696 31.4538C52.9965 31.3698 53.0054 31.2811 52.9956 31.1935Z"
          fill="#528ACC"
        />
        <path
          d="M52.8549 26.8889C52.8381 26.7725 52.7796 26.6661 52.6903 26.5895C52.601 26.513 52.4869 26.4715 52.3693 26.4727C52.2589 26.4727 52.1531 26.5165 52.075 26.5946C51.997 26.6726 51.9531 26.7785 51.9531 26.8889C51.9531 27.1663 52.0919 27.3744 52.3693 27.3744C52.4981 27.3744 52.6216 27.3233 52.7126 27.2322C52.8037 27.1411 52.8549 27.0176 52.8549 26.8889Z"
          fill="#528ACC"
        />
        <path
          d="M52.6467 22.5226C52.6467 22.3839 52.508 22.1758 52.3693 22.1758C52.2305 22.1758 52.0918 22.3839 52.0918 22.5226C52.0918 22.6613 52.1612 22.8694 52.3693 22.8694C52.4488 22.8532 52.5201 22.8096 52.5708 22.7463C52.6215 22.6829 52.6483 22.6037 52.6467 22.5226Z"
          fill="#528ACC"
        />
        <path
          d="M52.5089 18.1504C52.5089 18.1137 52.4943 18.0784 52.4683 18.0524C52.4423 18.0263 52.407 18.0117 52.3702 18.0117L52.1621 18.1504C52.1621 18.3585 52.3008 18.3585 52.3702 18.3585C52.4396 18.3585 52.5089 18.3585 52.5089 18.1504Z"
          fill="#528ACC"
        />
        <path
          d="M56.8087 111.862C56.8087 111.793 56.8087 111.793 56.67 111.793C56.5312 111.793 56.5313 111.793 56.5313 111.862H56.8087Z"
          fill="#528ACC"
        />
        <path
          d="M56.8791 107.489C56.8791 107.448 56.8669 107.408 56.844 107.374C56.8211 107.34 56.7886 107.313 56.7506 107.297C56.7126 107.281 56.6708 107.277 56.6304 107.285C56.59 107.293 56.5529 107.313 56.5238 107.342C56.4947 107.371 56.4749 107.408 56.4669 107.449C56.4589 107.489 56.463 107.531 56.4787 107.569C56.4945 107.607 56.5212 107.639 56.5554 107.662C56.5896 107.685 56.6298 107.697 56.671 107.697C56.7262 107.697 56.7791 107.676 56.8181 107.636C56.8572 107.597 56.8791 107.545 56.8791 107.489Z"
          fill="#528ACC"
        />
        <path
          d="M57.0183 103.055C57.0183 102.847 56.8795 102.777 56.6715 102.777C56.4634 102.777 56.3246 102.847 56.3246 103.055C56.3202 103.163 56.3518 103.269 56.4147 103.357C56.4775 103.445 56.5679 103.51 56.6715 103.54C56.8795 103.54 57.0183 103.332 57.0183 103.055Z"
          fill="#528ACC"
        />
        <path
          d="M57.225 98.8215C57.208 98.6867 57.1421 98.5627 57.0399 98.4732C56.9376 98.3837 56.806 98.3349 56.6701 98.336C56.462 98.336 56.2539 98.6134 56.2539 98.8215C56.2539 98.9319 56.2978 99.0377 56.3758 99.1158C56.4539 99.1938 56.5597 99.2377 56.6701 99.2377C57.0169 99.2377 57.225 99.0296 57.225 98.8215Z"
          fill="#528ACC"
        />
        <path
          d="M57.3642 94.3793C57.3318 94.2202 57.2446 94.0776 57.1179 93.9762C56.9912 93.8748 56.8329 93.8211 56.6706 93.8244C56.5234 93.8244 56.3823 93.8828 56.2782 93.9869C56.1741 94.091 56.1157 94.2321 56.1157 94.3793C56.1058 94.4577 56.1128 94.5374 56.1362 94.613C56.1596 94.6885 56.1989 94.7582 56.2515 94.8173C56.304 94.8764 56.3686 94.9236 56.4409 94.9558C56.5132 94.9879 56.5915 95.0042 56.6706 95.0035C56.8431 95.0044 57.0098 94.9409 57.1381 94.8255C57.2664 94.7101 57.347 94.551 57.3642 94.3793Z"
          fill="#528ACC"
        />
        <path
          d="M57.5021 90.0135C57.4934 89.9131 57.4649 89.8155 57.4183 89.7262C57.3716 89.6369 57.3078 89.5577 57.2304 89.4933C57.153 89.4288 57.0636 89.3802 56.9674 89.3505C56.8712 89.3207 56.77 89.3103 56.6697 89.3198C56.491 89.3355 56.3235 89.4136 56.1967 89.5404C56.0698 89.6673 55.9917 89.8348 55.9761 90.0135C55.9665 90.1138 55.977 90.2149 56.0067 90.3112C56.0365 90.4074 56.085 90.4968 56.1495 90.5742C56.214 90.6515 56.2932 90.7154 56.3824 90.762C56.4717 90.8086 56.5694 90.8371 56.6697 90.8458C56.8905 90.8458 57.1022 90.7581 57.2583 90.602C57.4144 90.4459 57.5021 90.2342 57.5021 90.0135Z"
          fill="#528ACC"
        />
        <path
          d="M57.5722 85.7103C57.556 85.4765 57.4558 85.2564 57.29 85.0907C57.1243 84.925 56.9042 84.8248 56.6704 84.8086C56.456 84.842 56.2609 84.9515 56.1207 85.1171C55.9806 85.2828 55.9048 85.4934 55.9074 85.7103C55.8979 85.8197 55.9102 85.9299 55.9434 86.0345C55.9767 86.1392 56.0303 86.2362 56.1012 86.32C56.1722 86.4038 56.259 86.4728 56.3567 86.5229C56.4543 86.573 56.561 86.6033 56.6704 86.6121C56.9096 86.6121 57.1389 86.5171 57.308 86.3479C57.4772 86.1788 57.5722 85.9495 57.5722 85.7103Z"
          fill="#528ACC"
        />
        <path
          d="M57.7118 81.3412C57.7118 81.0653 57.6022 80.8006 57.407 80.6055C57.2119 80.4104 56.9473 80.3008 56.6713 80.3008C56.3954 80.3008 56.1307 80.4104 55.9356 80.6055C55.7405 80.8006 55.6309 81.0653 55.6309 81.3412C55.6309 81.6172 55.7405 81.8818 55.9356 82.077C56.1307 82.2721 56.3954 82.3817 56.6713 82.3817C56.9473 82.3817 57.2119 82.2721 57.407 82.077C57.6022 81.8818 57.7118 81.6172 57.7118 81.3412Z"
          fill="#528ACC"
        />
        <path
          d="M57.7812 76.9702C57.7635 76.688 57.6388 76.4232 57.4326 76.2299C57.2263 76.0365 56.954 75.9291 56.6713 75.9297C56.4008 75.9461 56.1456 76.061 55.9539 76.2527C55.7622 76.4444 55.6473 76.6996 55.6309 76.9702C55.6303 77.2529 55.7377 77.5251 55.931 77.7314C56.1244 77.9376 56.3892 78.0623 56.6713 78.08C56.8197 78.0902 56.9686 78.0686 57.1079 78.0164C57.2472 77.9643 57.3737 77.8829 57.4789 77.7777C57.5841 77.6725 57.6655 77.546 57.7176 77.4067C57.7698 77.2674 57.7914 77.1185 57.7812 76.9702Z"
          fill="#528ACC"
        />
        <path
          d="M57.9189 72.6693C57.9202 72.5111 57.8876 72.3545 57.8233 72.2099C57.7591 72.0654 57.6647 71.9362 57.5465 71.8311C57.4282 71.726 57.2889 71.6474 57.1378 71.6006C56.9867 71.5537 56.8273 71.5397 56.6704 71.5595C56.376 71.5595 56.0937 71.6764 55.8856 71.8846C55.6775 72.0927 55.5605 72.375 55.5605 72.6693C55.5605 72.9637 55.6775 73.246 55.8856 73.4541C56.0937 73.6622 56.376 73.7791 56.6704 73.7791C56.8273 73.7989 56.9867 73.7849 57.1378 73.7381C57.2889 73.6912 57.4282 73.6126 57.5465 73.5075C57.6647 73.4024 57.7591 73.2733 57.8233 73.1287C57.8876 72.9841 57.9202 72.8275 57.9189 72.6693Z"
          fill="#528ACC"
        />
        <path
          d="M57.919 68.2993C57.919 67.9682 57.7874 67.6506 57.5533 67.4165C57.3191 67.1823 57.0016 67.0508 56.6704 67.0508C56.3393 67.0508 56.0217 67.1823 55.7876 67.4165C55.5534 67.6506 55.4219 67.9682 55.4219 68.2993C55.4219 68.6305 55.5534 68.948 55.7876 69.1822C56.0217 69.4163 56.3393 69.5479 56.6704 69.5479C57.0016 69.5479 57.3191 69.4163 57.5533 69.1822C57.7874 68.948 57.919 68.6305 57.919 68.2993Z"
          fill="#528ACC"
        />
        <path
          d="M57.919 63.8579C57.919 63.5268 57.7874 63.2092 57.5533 62.9751C57.3191 62.7409 57.0016 62.6094 56.6704 62.6094C56.3393 62.6094 56.0217 62.7409 55.7876 62.9751C55.5534 63.2092 55.4219 63.5268 55.4219 63.8579C55.4219 64.1891 55.5534 64.5066 55.7876 64.7408C56.0217 64.9749 56.3393 65.1065 56.6704 65.1065C57.0016 65.1065 57.3191 64.9749 57.5533 64.7408C57.7874 64.5066 57.919 64.1891 57.919 63.8579Z"
          fill="#528ACC"
        />
        <path
          d="M57.9189 59.6304C57.9192 59.4696 57.8866 59.3104 57.823 59.1626C57.7595 59.0148 57.6664 58.8816 57.5494 58.7711C57.4324 58.6607 57.2941 58.5753 57.143 58.5203C56.9918 58.4653 56.831 58.4418 56.6704 58.4513C56.3698 58.469 56.0874 58.601 55.8811 58.8202C55.6747 59.0395 55.56 59.3294 55.5605 59.6304C55.5605 59.9248 55.6775 60.2071 55.8856 60.4152C56.0937 60.6233 56.376 60.7403 56.6704 60.7403C56.8253 60.7497 56.9805 60.7284 57.1272 60.6776C57.2739 60.6269 57.4091 60.5477 57.5251 60.4446C57.6411 60.3415 57.7356 60.2165 57.8032 60.0768C57.8707 59.9371 57.9101 59.7854 57.9189 59.6304Z"
          fill="#528ACC"
        />
        <path
          d="M57.7802 55.192C57.7816 55.0519 57.7527 54.9132 57.6955 54.7853C57.6383 54.6574 57.5541 54.5435 57.4486 54.4512C57.3432 54.359 57.2191 54.2906 57.0847 54.2509C56.9504 54.2111 56.809 54.2009 56.6704 54.2209C56.5337 54.2115 56.3965 54.2292 56.2666 54.2729C56.1368 54.3167 56.0168 54.3856 55.9137 54.4759C55.8106 54.5661 55.7263 54.6758 55.6657 54.7987C55.6051 54.9216 55.5694 55.0553 55.5605 55.192C55.5771 55.481 55.6993 55.7537 55.904 55.9584C56.1087 56.163 56.3814 56.2853 56.6704 56.3018C56.9647 56.3018 57.247 56.1849 57.4551 55.9768C57.6633 55.7686 57.7802 55.4863 57.7802 55.192Z"
          fill="#528ACC"
        />
        <path
          d="M57.7118 50.8217C57.7118 50.5458 57.6022 50.2811 57.407 50.086C57.2119 49.8909 56.9473 49.7812 56.6713 49.7812C56.3954 49.7812 56.1307 49.8909 55.9356 50.086C55.7405 50.2811 55.6309 50.5458 55.6309 50.8217C55.6309 51.0977 55.7405 51.3623 55.9356 51.5574C56.1307 51.7526 56.3954 51.8622 56.6713 51.8622C56.9473 51.8622 57.2119 51.7526 57.407 51.5574C57.6022 51.3623 57.7118 51.0977 57.7118 50.8217Z"
          fill="#528ACC"
        />
        <path
          d="M57.573 46.5189C57.573 46.2798 57.478 46.0504 57.3089 45.8813C57.1398 45.7122 56.9104 45.6172 56.6713 45.6172C56.4422 45.6461 56.2293 45.7505 56.0661 45.9137C55.9028 46.077 55.7985 46.2899 55.7695 46.5189C55.7857 46.7527 55.8859 46.9728 56.0516 47.1385C56.2174 47.3043 56.4374 47.4045 56.6713 47.4207C56.9104 47.4207 57.1398 47.3257 57.3089 47.1565C57.478 46.9874 57.573 46.7581 57.573 46.5189Z"
          fill="#528ACC"
        />
        <path
          d="M57.5036 42.222C57.5043 41.9944 57.4188 41.775 57.2644 41.6077C57.1101 41.4405 56.8981 41.3378 56.6712 41.3203C56.4626 41.3658 56.2748 41.4788 56.1368 41.6418C55.9989 41.8048 55.9186 42.0088 55.9082 42.222C55.9389 42.4066 56.0309 42.5754 56.1694 42.7013C56.3078 42.8271 56.4846 42.9026 56.6712 42.9157C56.7715 42.9252 56.8727 42.9148 56.9689 42.885C57.0651 42.8553 57.1545 42.8068 57.2319 42.7423C57.3093 42.6778 57.3731 42.5986 57.4198 42.5093C57.4664 42.42 57.4949 42.3224 57.5036 42.222Z"
          fill="#528ACC"
        />
        <path
          d="M57.3638 37.7073C57.3206 37.5539 57.2304 37.418 57.106 37.3185C56.9817 37.2189 56.8292 37.1609 56.6701 37.1523C56.5281 37.1674 56.3956 37.2307 56.2946 37.3317C56.1936 37.4327 56.1303 37.5652 56.1152 37.7073C56.1152 38.1928 56.3233 38.4009 56.6701 38.4009C56.7639 38.4121 56.859 38.4018 56.9482 38.3709C57.0375 38.34 57.1185 38.2892 57.1853 38.2224C57.2521 38.1556 57.3028 38.0746 57.3338 37.9854C57.3647 37.8961 57.3749 37.801 57.3638 37.7073Z"
          fill="#528ACC"
        />
        <path
          d="M57.225 33.4797C57.226 33.3255 57.1698 33.1764 57.0673 33.0611C56.9649 32.9459 56.8234 32.8726 56.6701 32.8555C56.462 32.8555 56.2539 33.2023 56.2539 33.4797C56.2539 33.7572 56.462 34.0347 56.6701 34.0347C56.8121 34.0196 56.9447 33.9563 57.0456 33.8553C57.1466 33.7543 57.2099 33.6218 57.225 33.4797Z"
          fill="#528ACC"
        />
        <path
          d="M57.1566 29.0382C57.1066 28.9479 57.037 28.8699 56.953 28.8099C56.869 28.7499 56.7727 28.7094 56.671 28.6914C56.4629 28.6914 56.3242 28.9689 56.3242 29.0382C56.3242 29.1076 56.4629 29.5238 56.671 29.5238C56.7998 29.5238 56.9233 29.4726 57.0144 29.3816C57.1054 29.2905 57.1566 29.167 57.1566 29.0382Z"
          fill="#528ACC"
        />
        <path
          d="M57.0178 24.6729C57.0178 24.5342 56.8791 24.4648 56.671 24.4648C56.6158 24.4648 56.5629 24.4868 56.5238 24.5258C56.4848 24.5648 56.4629 24.6177 56.4629 24.6729C56.4629 24.881 56.5323 25.0198 56.671 25.0198C56.7165 25.0198 56.7616 25.0108 56.8037 24.9934C56.8458 24.9759 56.884 24.9504 56.9162 24.9182C56.9484 24.886 56.974 24.8477 56.9914 24.8057C57.0088 24.7636 57.0178 24.7185 57.0178 24.6729Z"
          fill="#528ACC"
        />
        <path
          d="M56.8781 20.3682C56.8781 20.3131 56.8561 20.2601 56.8171 20.2211C56.7781 20.1821 56.7252 20.1602 56.67 20.1602C56.5312 20.1602 56.5313 20.2989 56.5313 20.3682C56.5313 20.4376 56.5312 20.5763 56.67 20.5763C56.8087 20.5763 56.8781 20.3682 56.8781 20.3682Z"
          fill="#528ACC"
        />
        <path
          d="M56.8087 16.0694C56.8087 16 56.6699 16 56.6699 16H56.8087V16.0694Z"
          fill="#528ACC"
        />
        <path
          d="M61.3176 105.341C61.3176 105.202 61.1789 105.133 61.0401 105.133C60.9014 105.133 60.832 105.202 60.832 105.341L61.0401 105.549C61.1789 105.549 61.3176 105.341 61.3176 105.341Z"
          fill="#528ACC"
        />
        <path
          d="M61.388 100.968C61.388 100.876 61.3514 100.788 61.2864 100.723C61.2213 100.658 61.1331 100.621 61.0411 100.621C60.833 100.621 60.7637 100.829 60.7637 100.968C60.7637 101.107 60.833 101.315 61.0411 101.315C61.1331 101.315 61.2213 101.278 61.2864 101.213C61.3514 101.148 61.388 101.06 61.388 100.968Z"
          fill="#528ACC"
        />
        <path
          d="M61.5248 96.5334C61.508 96.417 61.4495 96.3106 61.3602 96.2341C61.2709 96.1575 61.1569 96.116 61.0393 96.1172C60.9289 96.1172 60.823 96.1611 60.745 96.2391C60.6669 96.3172 60.6231 96.423 60.6231 96.5334C60.6219 96.651 60.6634 96.7651 60.7399 96.8544C60.8165 96.9437 60.9228 97.0022 61.0393 97.0189C61.168 97.0189 61.2915 96.9678 61.3826 96.8767C61.4736 96.7857 61.5248 96.6622 61.5248 96.5334Z"
          fill="#528ACC"
        />
        <path
          d="M61.6636 92.3001C61.6636 92.2181 61.6474 92.1369 61.6161 92.0612C61.5847 91.9854 61.5387 91.9166 61.4807 91.8586C61.4228 91.8007 61.3539 91.7547 61.2782 91.7233C61.2025 91.6919 61.1213 91.6758 61.0393 91.6758C60.886 91.6929 60.7445 91.7662 60.6421 91.8814C60.5396 91.9967 60.4834 92.1458 60.4844 92.3001C60.51 92.4378 60.5768 92.5645 60.6758 92.6635C60.7749 92.7626 60.9016 92.8293 61.0393 92.855C61.1895 92.8429 61.3317 92.7821 61.4443 92.682C61.557 92.5819 61.6339 92.4478 61.6636 92.3001Z"
          fill="#528ACC"
        />
        <path
          d="M61.7349 87.8616C61.7349 87.7244 61.6943 87.5903 61.618 87.4762C61.5418 87.3622 61.4335 87.2733 61.3067 87.2208C61.18 87.1683 61.0405 87.1545 60.906 87.1813C60.7714 87.2081 60.6478 87.2741 60.5508 87.3711C60.4538 87.4681 60.3878 87.5917 60.361 87.7263C60.3342 87.8608 60.348 88.0003 60.4005 88.1271C60.453 88.2538 60.5419 88.3621 60.6559 88.4384C60.77 88.5146 60.9041 88.5552 61.0413 88.5552C61.22 88.5396 61.3875 88.4615 61.5144 88.3347C61.6412 88.2078 61.7193 88.0403 61.7349 87.8616Z"
          fill="#528ACC"
        />
        <path
          d="M61.8703 83.4901C61.8703 83.0045 61.5235 82.7964 61.0379 82.7964C60.9412 82.7867 60.8435 82.7974 60.7511 82.8279C60.6588 82.8584 60.5739 82.9079 60.502 82.9734C60.43 83.0388 60.3726 83.1186 60.3335 83.2076C60.2944 83.2966 60.2744 83.3929 60.2749 83.4901C60.2379 83.6143 60.2304 83.7455 60.253 83.8731C60.2756 84.0008 60.3278 84.1214 60.4052 84.2253C60.4827 84.3293 60.5834 84.4137 60.6993 84.4718C60.8151 84.5299 60.943 84.5602 61.0726 84.5602C61.2022 84.5602 61.3301 84.5299 61.4459 84.4718C61.5618 84.4137 61.6625 84.3293 61.74 84.2253C61.8174 84.1214 61.8696 84.0008 61.8922 83.8731C61.9148 83.7455 61.9073 83.6143 61.8703 83.4901Z"
          fill="#528ACC"
        />
        <path
          d="M61.0404 80.0925C61.5384 80.0925 61.9421 79.6888 61.9421 79.1908C61.9421 78.6928 61.5384 78.2891 61.0404 78.2891C60.5424 78.2891 60.1387 78.6928 60.1387 79.1908C60.1387 79.6888 60.5424 80.0925 61.0404 80.0925Z"
          fill="#528ACC"
        />
        <path
          d="M62.0819 74.8217C62.0819 74.5458 61.9723 74.2811 61.7771 74.086C61.582 73.8909 61.3174 73.7812 61.0414 73.7812C60.7777 73.7989 60.5305 73.9162 60.3501 74.1095C60.1698 74.3027 60.0697 74.5574 60.0703 74.8217C60.0703 75.0793 60.1726 75.3263 60.3547 75.5084C60.5369 75.6905 60.7839 75.7928 61.0414 75.7928C61.1747 75.8024 61.3084 75.7843 61.4344 75.7398C61.5603 75.6953 61.6757 75.6253 61.7734 75.5341C61.871 75.443 61.9488 75.3327 62.0019 75.2101C62.055 75.0875 62.0822 74.9553 62.0819 74.8217Z"
          fill="#528ACC"
        />
        <path
          d="M62.0814 70.3809C62.0726 70.2533 62.0387 70.1287 61.9816 70.0142C61.9245 69.8997 61.8453 69.7976 61.7486 69.7138C61.6519 69.63 61.5396 69.5662 61.4182 69.5259C61.2967 69.4856 61.1685 69.4698 61.0409 69.4792C60.795 69.4786 60.5579 69.5713 60.3777 69.7387C60.1974 69.9061 60.0874 70.1356 60.0698 70.3809C60.029 70.535 60.0242 70.6963 60.0556 70.8525C60.087 71.0087 60.1539 71.1557 60.2511 71.2819C60.3483 71.4082 60.4731 71.5105 60.6161 71.5808C60.7591 71.6512 60.9163 71.6878 61.0756 71.6878C61.235 71.6878 61.3922 71.6512 61.5351 71.5808C61.6781 71.5105 61.803 71.4082 61.9002 71.2819C61.9973 71.1557 62.0642 71.0087 62.0956 70.8525C62.1271 70.6963 62.1222 70.535 62.0814 70.3809Z"
          fill="#528ACC"
        />
        <path
          d="M62.2207 66.1511C62.221 65.9993 62.1901 65.8491 62.1301 65.7097C62.07 65.5703 61.982 65.4447 61.8715 65.3407C61.7609 65.2367 61.6303 65.1564 61.4875 65.1049C61.3447 65.0534 61.1929 65.0317 61.0415 65.0412C60.7471 65.0412 60.4648 65.1582 60.2567 65.3663C60.0486 65.5744 59.9316 65.8567 59.9316 66.1511C59.9482 66.44 60.0704 66.7128 60.2751 66.9174C60.4797 67.1221 60.7525 67.2443 61.0415 67.2609C61.3426 67.2614 61.6324 67.1467 61.8517 66.9404C62.0709 66.734 62.2029 66.4516 62.2207 66.1511Z"
          fill="#528ACC"
        />
        <path
          d="M62.2207 61.7082C62.1858 61.4261 62.0441 61.1682 61.8247 60.9875C61.6053 60.8068 61.325 60.7172 61.0415 60.7371C60.9048 60.7277 60.7676 60.7454 60.6377 60.7891C60.5079 60.8329 60.3879 60.9018 60.2848 60.9921C60.1817 61.0823 60.0974 61.192 60.0368 61.3149C59.9762 61.4378 59.9405 61.5715 59.9316 61.7082C59.9311 62.0093 60.0458 62.2992 60.2521 62.5184C60.4585 62.7377 60.7409 62.8697 61.0415 62.8874C61.3488 62.8707 61.6392 62.7412 61.8568 62.5235C62.0744 62.3059 62.204 62.0155 62.2207 61.7082Z"
          fill="#528ACC"
        />
        <path
          d="M62.0819 57.3412C62.0655 57.0707 61.9506 56.8155 61.7589 56.6238C61.5672 56.4321 61.312 56.3172 61.0415 56.3008C60.7677 56.3277 60.5105 56.4443 60.3098 56.6325C60.1091 56.8206 59.9762 57.0698 59.9316 57.3412C59.9316 57.6356 60.0486 57.9179 60.2567 58.126C60.4648 58.3341 60.7471 58.4511 61.0415 58.4511C61.1872 58.4631 61.3338 58.442 61.4703 58.3894C61.6068 58.3369 61.7297 58.2541 61.8297 58.1474C61.9298 58.0407 62.0044 57.9128 62.0481 57.7732C62.0917 57.6336 62.1033 57.4859 62.0819 57.3412Z"
          fill="#528ACC"
        />
        <path
          d="M62.0819 53.04C62.0822 52.9064 62.055 52.7742 62.0019 52.6516C61.9488 52.529 61.871 52.4187 61.7734 52.3276C61.6757 52.2364 61.5603 52.1664 61.4344 52.1219C61.3084 52.0774 61.1747 52.0593 61.0414 52.0689C60.7839 52.0689 60.5369 52.1712 60.3547 52.3533C60.1726 52.5354 60.0703 52.7824 60.0703 53.04C60.0697 53.3043 60.1698 53.559 60.3501 53.7522C60.5305 53.9455 60.7777 54.0628 61.0414 54.0804C61.3174 54.0804 61.582 53.9708 61.7771 53.7757C61.9723 53.5806 62.0819 53.3159 62.0819 53.04Z"
          fill="#528ACC"
        />
        <path
          d="M61.9409 48.81C61.9503 48.6824 61.9345 48.5542 61.8942 48.4328C61.8539 48.3113 61.7901 48.199 61.7063 48.1023C61.6225 48.0056 61.5204 47.9265 61.4059 47.8694C61.2914 47.8122 61.1668 47.7783 61.0391 47.7695C60.9115 47.7783 60.7869 47.8122 60.6724 47.8694C60.5579 47.9265 60.4558 48.0056 60.372 48.1023C60.2882 48.199 60.2244 48.3113 60.1841 48.4328C60.1438 48.5542 60.128 48.6824 60.1374 48.81C60.1374 49.0491 60.2324 49.2785 60.4015 49.4476C60.5706 49.6167 60.8 49.7117 61.0391 49.7117C61.273 49.6955 61.493 49.5953 61.6588 49.4296C61.8245 49.2639 61.9247 49.0438 61.9409 48.81Z"
          fill="#528ACC"
        />
        <path
          d="M61.8703 44.3011C61.8703 44.0804 61.7826 43.8687 61.6265 43.7126C61.4704 43.5565 61.2587 43.4688 61.0379 43.4688C60.9316 43.4683 60.8264 43.4901 60.729 43.5327C60.6316 43.5753 60.5442 43.6378 60.4723 43.7162C60.4005 43.7945 60.3458 43.8871 60.3118 43.9878C60.2778 44.0885 60.2653 44.1953 60.2749 44.3011C60.2379 44.4254 60.2304 44.5565 60.253 44.6842C60.2756 44.8118 60.3278 44.9324 60.4052 45.0364C60.4827 45.1403 60.5834 45.2247 60.6993 45.2828C60.8151 45.341 60.943 45.3713 61.0726 45.3713C61.2022 45.3713 61.3301 45.341 61.4459 45.2828C61.5618 45.2247 61.6625 45.1403 61.74 45.0364C61.8174 44.9324 61.8696 44.8118 61.8922 44.6842C61.9148 44.5565 61.9073 44.4254 61.8703 44.3011Z"
          fill="#528ACC"
        />
        <path
          d="M61.7339 40.0022C61.7339 39.5167 61.5258 39.3086 61.0403 39.3086C60.5547 39.3086 60.416 39.5167 60.416 40.0022C60.416 40.4878 60.6241 40.6959 61.0403 40.6959C61.1341 40.707 61.2292 40.6968 61.3184 40.6658C61.4076 40.6349 61.4887 40.5842 61.5555 40.5174C61.6222 40.4506 61.673 40.3696 61.7039 40.2803C61.7348 40.1911 61.7451 40.096 61.7339 40.0022Z"
          fill="#528ACC"
        />
        <path
          d="M61.6646 35.6976C61.6744 35.6099 61.6655 35.5212 61.6385 35.4373C61.6116 35.3534 61.5672 35.2761 61.5082 35.2106C61.4492 35.145 61.377 35.0927 61.2964 35.0571C61.2157 35.0215 61.1285 35.0034 61.0403 35.0039C60.8686 35.0212 60.7095 35.1018 60.5941 35.2301C60.4786 35.3583 60.4152 35.525 60.416 35.6976C60.416 35.9057 60.7628 36.1831 61.0403 36.1831C61.1844 36.1867 61.3253 36.1403 61.4391 36.0518C61.5529 35.9633 61.6326 35.8381 61.6646 35.6976Z"
          fill="#528ACC"
        />
        <path
          d="M61.5248 31.1936C61.508 31.0771 61.4495 30.9708 61.3602 30.8942C61.2709 30.8177 61.1568 30.7762 61.0392 30.7774C60.9289 30.7774 60.823 30.8212 60.7449 30.8993C60.6669 30.9773 60.623 31.0832 60.623 31.1936C60.623 31.5404 60.8311 31.7485 61.0392 31.7485C61.1093 31.7492 61.1786 31.7347 61.2426 31.7061C61.3065 31.6775 61.3635 31.6354 61.4096 31.5827C61.4558 31.53 61.4899 31.4679 61.5098 31.4007C61.5297 31.3336 61.5348 31.2629 61.5248 31.1936Z"
          fill="#528ACC"
        />
        <path
          d="M61.388 26.8868C61.388 26.6787 61.3186 26.6094 61.0411 26.6094C60.7637 26.6094 60.7637 26.6787 60.7637 26.8868C60.7637 27.0949 60.833 27.2337 61.0411 27.2337C61.2492 27.2337 61.388 27.1643 61.388 26.8868Z"
          fill="#528ACC"
        />
        <path
          d="M61.3176 22.5206C61.3176 22.3819 61.1789 22.3125 61.0401 22.3125C60.9014 22.3125 60.832 22.3819 60.832 22.5206C60.8514 22.6452 60.8988 22.7638 60.9708 22.8674C61.0627 22.8674 61.151 22.8309 61.216 22.7658C61.281 22.7008 61.3176 22.6126 61.3176 22.5206Z"
          fill="#528ACC"
        />
        <path
          d="M61.1807 18.1484H60.9727V18.3565C61.1807 18.3565 61.1807 18.2178 61.1807 18.1484Z"
          fill="#528ACC"
        />
        <path
          d="M65.5509 107.49C65.5509 107.352 65.4815 107.352 65.4815 107.352C65.4815 107.352 65.2734 107.352 65.2734 107.49C65.2734 107.629 65.3428 107.629 65.4815 107.629C65.6203 107.629 65.5509 107.629 65.5509 107.49Z"
          fill="#528ACC"
        />
        <path
          d="M65.6897 103.052L65.4816 102.844C65.2735 102.844 65.1348 102.982 65.1348 103.052C65.1348 103.121 65.2735 103.399 65.4816 103.399C65.6897 103.399 65.6897 103.329 65.6897 103.052Z"
          fill="#528ACC"
        />
        <path
          d="M65.8285 98.8215C65.8104 98.7198 65.7699 98.6235 65.7099 98.5395C65.6499 98.4555 65.5719 98.386 65.4816 98.3359C65.358 98.3506 65.2428 98.4065 65.1547 98.4946C65.0667 98.5827 65.0108 98.6978 64.9961 98.8215C64.9961 99.0296 65.2736 99.1683 65.4816 99.1683C65.6897 99.1683 65.8285 99.0296 65.8285 98.8215Z"
          fill="#528ACC"
        />
        <path
          d="M65.8954 94.3791C65.8954 94.1017 65.6873 93.8242 65.4792 93.8242C65.332 93.8242 65.1909 93.8827 65.0868 93.9867C64.9827 94.0908 64.9243 94.232 64.9243 94.3791C64.9144 94.4576 64.9214 94.5373 64.9448 94.6128C64.9682 94.6884 65.0075 94.7581 65.0601 94.8172C65.1126 94.8763 65.1772 94.9235 65.2495 94.9556C65.3218 94.9877 65.4001 95.004 65.4792 95.0034C65.6873 95.0034 65.8954 94.7259 65.8954 94.3791Z"
          fill="#528ACC"
        />
        <path
          d="M66.0342 90.0119C66.0191 89.8699 65.9558 89.7374 65.8548 89.6364C65.7538 89.5354 65.6213 89.4721 65.4793 89.457C65.3202 89.4655 65.1678 89.5236 65.0434 89.6231C64.919 89.7227 64.8288 89.8586 64.7856 90.0119C64.7745 90.1057 64.7847 90.2008 64.8156 90.29C64.8466 90.3793 64.8973 90.4603 64.9641 90.5271C65.0309 90.5939 65.1119 90.6446 65.2012 90.6756C65.2904 90.7065 65.3855 90.7167 65.4793 90.7056C65.8261 90.7056 66.0342 90.4975 66.0342 90.0119Z"
          fill="#528ACC"
        />
        <path
          d="M66.1735 85.7102C66.174 85.622 66.1559 85.5347 66.1202 85.4541C66.0846 85.3735 66.0323 85.3013 65.9668 85.2423C65.9013 85.1833 65.824 85.1389 65.7401 85.1119C65.6561 85.085 65.5674 85.0761 65.4798 85.0859C64.9943 85.0859 64.7168 85.294 64.7168 85.7102C64.7163 85.8074 64.7363 85.9037 64.7754 85.9927C64.8145 86.0817 64.8719 86.1615 64.9438 86.2269C65.0158 86.2923 65.1007 86.3419 65.193 86.3723C65.2854 86.4028 65.3831 86.4135 65.4798 86.4038C65.6585 86.3882 65.826 86.3101 65.9529 86.1832C66.0797 86.0564 66.1578 85.8889 66.1735 85.7102Z"
          fill="#528ACC"
        />
        <path
          d="M66.2447 81.3411C66.2168 81.1487 66.1275 80.9704 65.9899 80.8329C65.8524 80.6954 65.6742 80.606 65.4817 80.5781C65.2794 80.5781 65.0853 80.6585 64.9422 80.8016C64.7991 80.9447 64.7187 81.1388 64.7187 81.3411C64.7078 81.444 64.72 81.548 64.7545 81.6456C64.789 81.7431 64.8449 81.8317 64.918 81.9049C64.9912 81.978 65.0797 82.0339 65.1773 82.0684C65.2748 82.1028 65.3788 82.1151 65.4817 82.1041C65.6841 82.1041 65.8782 82.0238 66.0213 81.8807C66.1643 81.7376 66.2447 81.5435 66.2447 81.3411Z"
          fill="#528ACC"
        />
        <path
          d="M66.2441 76.972C66.2536 76.8627 66.2413 76.7525 66.2081 76.6479C66.1748 76.5432 66.1212 76.4462 66.0502 76.3624C65.9793 76.2786 65.8925 76.2096 65.7948 76.1595C65.6972 76.1094 65.5905 76.0791 65.4811 76.0703C65.2419 76.0703 65.0126 76.1653 64.8434 76.3344C64.6743 76.5035 64.5793 76.7329 64.5793 76.972C64.5687 77.0931 64.5848 77.2151 64.6264 77.3293C64.6679 77.4435 64.734 77.5472 64.82 77.6332C64.9059 77.7191 65.0096 77.7852 65.1238 77.8267C65.2381 77.8683 65.36 77.8844 65.4811 77.8738C65.5905 77.865 65.6972 77.8347 65.7948 77.7846C65.8925 77.7345 65.9793 77.6655 66.0502 77.5817C66.1212 77.4979 66.1748 77.4009 66.2081 77.2962C66.2413 77.1916 66.2536 77.0814 66.2441 76.972Z"
          fill="#528ACC"
        />
        <path
          d="M66.3816 72.6713C66.3527 72.4423 66.2484 72.2293 66.0851 72.0661C65.9219 71.9028 65.709 71.7985 65.4799 71.7696C65.2264 71.7673 64.9808 71.8577 64.7892 72.0237C64.5976 72.1898 64.4732 72.42 64.4395 72.6713C64.4482 72.799 64.4822 72.9236 64.5393 73.0381C64.5964 73.1526 64.6756 73.2546 64.7723 73.3384C64.8689 73.4222 64.9812 73.4861 65.1027 73.5264C65.2241 73.5666 65.3523 73.5825 65.4799 73.573C65.7137 73.5569 65.9338 73.4567 66.0995 73.2909C66.2653 73.1252 66.3655 72.9051 66.3816 72.6713Z"
          fill="#528ACC"
        />
        <path
          d="M66.3816 68.3022C66.3911 68.1746 66.3752 68.0464 66.335 67.9249C66.2947 67.8035 66.2308 67.6912 66.147 67.5945C66.0633 67.4978 65.9612 67.4187 65.8467 67.3615C65.7322 67.3044 65.6076 67.2705 65.4799 67.2617C65.204 67.2617 64.9393 67.3713 64.7442 67.5665C64.5491 67.7616 64.4395 68.0262 64.4395 68.3022C64.4571 68.5659 64.5744 68.8131 64.7677 68.9935C64.9609 69.1738 65.2156 69.2739 65.4799 69.2733C65.7253 69.2557 65.9548 69.1457 66.1222 68.9654C66.2895 68.7852 66.3823 68.5482 66.3816 68.3022Z"
          fill="#528ACC"
        />
        <path
          d="M66.3804 63.8588C66.3515 63.6298 66.2471 63.4168 66.0839 63.2536C65.9206 63.0903 65.7077 62.986 65.4787 62.9571C65.2252 62.9548 64.9796 63.0452 64.788 63.2112C64.5964 63.3773 64.472 63.6075 64.4382 63.8588C64.4279 63.9981 64.4477 64.138 64.4963 64.2689C64.5449 64.3998 64.6212 64.5188 64.72 64.6175C64.8187 64.7163 64.9376 64.7926 65.0686 64.8412C65.1995 64.8898 65.3394 64.9096 65.4787 64.8993C65.6063 64.8905 65.731 64.8565 65.8455 64.7994C65.9599 64.7423 66.062 64.6631 66.1458 64.5665C66.2296 64.4698 66.2935 64.3575 66.3337 64.236C66.374 64.1146 66.3899 63.9864 66.3804 63.8588Z"
          fill="#528ACC"
        />
        <path
          d="M66.3816 59.6283C66.3816 59.3891 66.2866 59.1598 66.1175 58.9907C65.9484 58.8216 65.7191 58.7266 65.4799 58.7266C64.925 58.7266 64.4395 59.004 64.4395 59.6283C64.4732 59.8796 64.5976 60.1098 64.7892 60.2759C64.9808 60.4419 65.2264 60.5323 65.4799 60.53C65.709 60.5011 65.9219 60.3968 66.0851 60.2335C66.2484 60.0703 66.3527 59.8573 66.3816 59.6283Z"
          fill="#528ACC"
        />
        <path
          d="M66.3828 55.1919C66.3494 54.9775 66.2399 54.7824 66.0743 54.6422C65.9086 54.5021 65.698 54.4263 65.4811 54.4289C65.3717 54.4194 65.2615 54.4316 65.1569 54.4649C65.0522 54.4982 64.9552 54.5518 64.8714 54.6227C64.7876 54.6937 64.7186 54.7805 64.6685 54.8781C64.6184 54.9758 64.5881 55.0825 64.5793 55.1919C64.5687 55.313 64.5848 55.4349 64.6264 55.5491C64.6679 55.6633 64.734 55.7671 64.82 55.853C64.9059 55.939 65.0096 56.005 65.1238 56.0466C65.2381 56.0882 65.36 56.1042 65.4811 56.0936C65.7202 56.0936 65.9496 55.9986 66.1187 55.8295C66.2878 55.6604 66.3828 55.4311 66.3828 55.1919Z"
          fill="#528ACC"
        />
        <path
          d="M66.2429 50.8196C66.2525 50.7138 66.24 50.607 66.206 50.5063C66.172 50.4056 66.1173 50.3131 66.0455 50.2347C65.9736 50.1563 65.8862 50.0938 65.7888 50.0512C65.6914 50.0086 65.5862 49.9868 65.4799 49.9873C65.3649 49.9777 65.2491 49.9921 65.14 50.0296C65.0309 50.0671 64.9307 50.1268 64.8459 50.2051C64.7611 50.2834 64.6935 50.3784 64.6474 50.4842C64.6013 50.59 64.5777 50.7042 64.5781 50.8196C64.5943 51.0535 64.6945 51.2735 64.8602 51.4393C65.026 51.605 65.246 51.7052 65.4799 51.7214C65.6943 51.688 65.8894 51.5785 66.0296 51.4128C66.1697 51.2472 66.2455 51.0366 66.2429 50.8196Z"
          fill="#528ACC"
        />
        <path
          d="M66.1735 46.5213C66.1735 46.3374 66.1004 46.1609 65.9703 46.0309C65.8402 45.9008 65.6638 45.8277 65.4798 45.8277C65.3831 45.818 65.2854 45.8287 65.193 45.8592C65.1007 45.8897 65.0158 45.9392 64.9438 46.0046C64.8719 46.07 64.8145 46.1498 64.7754 46.2388C64.7363 46.3279 64.7163 46.4241 64.7168 46.5213C64.7168 47.0069 64.9943 47.215 65.4798 47.215C65.5736 47.2261 65.6687 47.2159 65.7579 47.185C65.8471 47.154 65.9282 47.1033 65.995 47.0365C66.0617 46.9697 66.1125 46.8887 66.1434 46.7994C66.1743 46.7102 66.1846 46.6151 66.1735 46.5213Z"
          fill="#528ACC"
        />
        <path
          d="M66.0337 42.2219C66.0337 41.8057 65.8256 41.5977 65.4788 41.5977C65.132 41.5977 64.7852 41.8057 64.7852 42.2219C64.7846 42.3101 64.8028 42.3974 64.8384 42.478C64.874 42.5587 64.9263 42.6308 64.9918 42.6898C65.0573 42.7488 65.1346 42.7932 65.2186 42.8202C65.3025 42.8471 65.3912 42.856 65.4788 42.8462C65.6321 42.8291 65.7736 42.7558 65.876 42.6405C65.9785 42.5253 66.0347 42.3762 66.0337 42.2219Z"
          fill="#528ACC"
        />
        <path
          d="M66.034 37.7092C66.034 37.5011 65.6872 37.293 65.4791 37.293C65.271 37.293 64.9242 37.5011 64.9242 37.7092C64.9145 37.7912 64.9213 37.8744 64.9441 37.9538C64.9668 38.0333 65.0051 38.1074 65.0567 38.1719C65.1083 38.2364 65.1723 38.2901 65.2448 38.3297C65.3173 38.3693 65.3969 38.3942 65.4791 38.4028C65.6324 38.3596 65.7684 38.2694 65.8679 38.1451C65.9674 38.0207 66.0255 37.8682 66.034 37.7092Z"
          fill="#528ACC"
        />
        <path
          d="M65.8285 33.4816C65.8285 33.2042 65.6897 32.9961 65.4816 32.9961C65.358 33.0108 65.2428 33.0667 65.1547 33.1547C65.0667 33.2428 65.0108 33.358 64.9961 33.4816C64.9961 33.6897 65.2736 33.8978 65.4816 33.8978C65.5796 33.8813 65.6684 33.8302 65.732 33.7539C65.7957 33.6776 65.8299 33.581 65.8285 33.4816Z"
          fill="#528ACC"
        />
        <path
          d="M65.6897 29.0382C65.6897 28.9689 65.551 28.6914 65.4816 28.6914C65.4122 28.6914 65.1348 28.9689 65.1348 29.0382C65.1348 29.1076 65.2735 29.385 65.4816 29.385C65.6897 29.385 65.6897 29.3157 65.6897 29.0382Z"
          fill="#528ACC"
        />
        <path
          d="M65.5509 24.67C65.5509 24.67 65.5509 24.5312 65.4815 24.5312C65.4384 24.5365 65.3971 24.5517 65.361 24.5758C65.3248 24.5999 65.2948 24.6322 65.2734 24.67C65.2734 24.8781 65.3428 24.8781 65.4815 24.8781C65.6203 24.8781 65.5509 24.8781 65.5509 24.67Z"
          fill="#528ACC"
        />
        <path
          d="M69.8487 105.342C69.8487 105.342 69.8487 105.203 69.7793 105.203V105.342H69.8487Z"
          fill="#528ACC"
        />
        <path
          d="M69.9874 100.971C69.9874 100.832 69.8487 100.832 69.7794 100.832C69.71 100.832 69.6406 100.832 69.6406 100.971C69.6406 101.109 69.6406 101.179 69.7794 101.179C69.8272 101.162 69.8706 101.134 69.9066 101.098C69.9425 101.062 69.9701 101.019 69.9874 100.971Z"
          fill="#528ACC"
        />
        <path
          d="M70.0588 96.5324C70.0588 96.4631 69.9895 96.3244 69.7814 96.3244C69.7184 96.3224 69.6565 96.3419 69.6061 96.3797C69.5557 96.4175 69.5196 96.4714 69.5039 96.5324C69.5039 96.8099 69.6426 96.8793 69.7814 96.8793C69.9201 96.8793 70.0588 96.8099 70.0588 96.5324Z"
          fill="#528ACC"
        />
        <path
          d="M70.1966 92.298C70.1978 92.1804 70.1563 92.0664 70.0797 91.9771C70.0032 91.8878 69.8968 91.8293 69.7804 91.8125C69.503 91.8125 69.4336 92.0206 69.4336 92.298C69.4336 92.5755 69.503 92.7142 69.7804 92.7142C69.8908 92.7142 69.9967 92.6704 70.0747 92.5923C70.1528 92.5143 70.1966 92.4084 70.1966 92.298Z"
          fill="#528ACC"
        />
        <path
          d="M70.3364 87.8635C70.3364 87.7163 70.2779 87.5752 70.1739 87.4711C70.0698 87.3671 69.9286 87.3086 69.7815 87.3086C69.6343 87.3086 69.4932 87.3671 69.3891 87.4711C69.285 87.5752 69.2266 87.7163 69.2266 87.8635C69.2266 88.0107 69.285 88.1518 69.3891 88.2559C69.4932 88.36 69.6343 88.4184 69.7815 88.4184C69.9286 88.4184 70.0698 88.36 70.1739 88.2559C70.2779 88.1518 70.3364 88.0107 70.3364 87.8635Z"
          fill="#528ACC"
        />
        <path
          d="M70.4048 83.4924C70.3659 83.3492 70.2862 83.2205 70.1753 83.1219C70.0644 83.0234 69.9272 82.9593 69.7805 82.9375C69.6303 82.9496 69.4881 83.0103 69.3755 83.1105C69.2628 83.2106 69.1859 83.3447 69.1562 83.4924C69.1464 83.58 69.1553 83.6687 69.1822 83.7527C69.2092 83.8366 69.2536 83.9139 69.3126 83.9794C69.3716 84.0449 69.4438 84.0972 69.5244 84.1329C69.6051 84.1685 69.6923 84.1866 69.7805 84.1861C69.9522 84.1688 70.1113 84.0882 70.2267 83.9599C70.3422 83.8317 70.4056 83.665 70.4048 83.4924Z"
          fill="#528ACC"
        />
        <path
          d="M70.4058 79.1918C70.4173 79.1071 70.409 79.0209 70.3817 78.94C70.3544 78.859 70.3087 78.7855 70.2483 78.725C70.1879 78.6646 70.1143 78.619 70.0334 78.5916C69.9524 78.5643 69.8662 78.5561 69.7815 78.5675C69.6939 78.5577 69.6052 78.5666 69.5213 78.5935C69.4374 78.6205 69.3601 78.6649 69.2945 78.7239C69.229 78.7829 69.1767 78.8551 69.1411 78.9357C69.1055 79.0163 69.0874 79.1036 69.0879 79.1918C69.1035 79.3705 69.1816 79.538 69.3085 79.6648C69.4353 79.7917 69.6028 79.8698 69.7815 79.8854C69.8697 79.886 69.957 79.8678 70.0376 79.8322C70.1183 79.7966 70.1905 79.7443 70.2494 79.6788C70.3084 79.6132 70.3528 79.536 70.3798 79.452C70.4068 79.3681 70.4156 79.2794 70.4058 79.1918Z"
          fill="#528ACC"
        />
        <path
          d="M70.5426 74.8207C70.5292 74.6158 70.4463 74.4216 70.3075 74.2703C70.1688 74.1189 69.9826 74.0194 69.7796 73.9883C69.5731 74.0268 69.383 74.1269 69.2344 74.2754C69.0858 74.424 68.9857 74.6141 68.9473 74.8207C68.9784 75.0236 69.0779 75.2098 69.2292 75.3486C69.3806 75.4873 69.5748 75.5702 69.7796 75.5837C69.982 75.5837 70.1761 75.5033 70.3192 75.3602C70.4623 75.2171 70.5426 75.023 70.5426 74.8207Z"
          fill="#528ACC"
        />
        <path
          d="M70.5455 70.3782C70.5455 69.962 70.1987 69.7539 69.7825 69.7539C69.3663 69.7539 68.9501 69.962 68.9501 70.3782C68.9407 70.4967 68.9547 70.6159 68.9915 70.7289C69.0282 70.8419 69.087 70.9466 69.1644 71.0368C69.2417 71.1271 69.3361 71.2012 69.4422 71.2548C69.5483 71.3084 69.664 71.3405 69.7825 71.3493C70.1987 71.3493 70.5455 70.8637 70.5455 70.3782Z"
          fill="#528ACC"
        />
        <path
          d="M70.6843 66.1517C70.6681 65.9179 70.5679 65.6978 70.4022 65.5321C70.2364 65.3664 70.0163 65.2662 69.7825 65.25C69.6671 65.2496 69.5529 65.2732 69.4471 65.3193C69.3413 65.3654 69.2463 65.433 69.168 65.5178C69.0897 65.6026 69.0299 65.7027 68.9924 65.8119C68.9549 65.921 68.9405 66.0367 68.9502 66.1517C68.9497 66.2581 68.9715 66.3633 69.0141 66.4607C69.0567 66.5581 69.1192 66.6455 69.1976 66.7174C69.2759 66.7892 69.3685 66.8439 69.4692 66.8779C69.5699 66.9118 69.6767 66.9244 69.7825 66.9147C69.9995 66.9173 70.2101 66.8416 70.3757 66.7014C70.5414 66.5613 70.6509 66.3661 70.6843 66.1517Z"
          fill="#528ACC"
        />
        <path
          d="M70.6829 61.7115C70.6495 61.4971 70.54 61.3019 70.3744 61.1618C70.2087 61.0216 69.9981 60.9459 69.7812 60.9485C69.6753 60.9388 69.5686 60.9514 69.4678 60.9854C69.3671 61.0194 69.2746 61.074 69.1962 61.1459C69.1178 61.2177 69.0554 61.3051 69.0127 61.4025C68.9701 61.4999 68.9484 61.6052 68.9488 61.7115C68.9381 61.8235 68.9522 61.9364 68.9902 62.0423C69.0283 62.1482 69.0892 62.2443 69.1688 62.3239C69.2483 62.4034 69.3445 62.4644 69.4504 62.5024C69.5562 62.5404 69.6692 62.5546 69.7812 62.5438C69.8962 62.5535 70.0119 62.5391 70.121 62.5016C70.2302 62.4641 70.3303 62.4043 70.4151 62.326C70.4999 62.2477 70.5675 62.1527 70.6136 62.0469C70.6597 61.9411 70.6833 61.8269 70.6829 61.7115Z"
          fill="#528ACC"
        />
        <path
          d="M70.6843 57.3421C70.6843 56.9259 70.1987 56.6484 69.7825 56.6484C69.3663 56.6484 68.9502 56.9259 68.9502 57.3421C68.9405 57.4571 68.9549 57.5728 68.9924 57.682C69.0299 57.7911 69.0897 57.8912 69.168 57.976C69.2463 58.0608 69.3413 58.1284 69.4471 58.1745C69.5529 58.2206 69.6671 58.2442 69.7825 58.2438C70.0163 58.2276 70.2364 58.1274 70.4022 57.9617C70.5679 57.796 70.6681 57.5759 70.6843 57.3421Z"
          fill="#528ACC"
        />
        <path
          d="M70.5426 53.0396C70.5426 52.8372 70.4623 52.6432 70.3192 52.5001C70.1761 52.357 69.982 52.2766 69.7796 52.2766C69.6738 52.2669 69.567 52.2795 69.4663 52.3135C69.3656 52.3475 69.2731 52.4022 69.1947 52.474C69.1163 52.5458 69.0538 52.6333 69.0112 52.7307C68.9686 52.8281 68.9468 52.9333 68.9473 53.0396C68.9473 53.2604 69.035 53.4721 69.1911 53.6282C69.3472 53.7843 69.5589 53.872 69.7796 53.872C69.886 53.8724 69.9912 53.8506 70.0886 53.808C70.186 53.7654 70.2734 53.7029 70.3453 53.6246C70.4171 53.5462 70.4718 53.4537 70.5058 53.3529C70.5397 53.2522 70.5523 53.1455 70.5426 53.0396Z"
          fill="#528ACC"
        />
        <path
          d="M70.5441 48.8068C70.5797 48.6926 70.588 48.5716 70.5683 48.4536C70.5486 48.3355 70.5013 48.2238 70.4304 48.1274C70.3595 48.031 70.267 47.9526 70.1602 47.8986C70.0534 47.8446 69.9354 47.8164 69.8157 47.8164C69.6961 47.8164 69.5781 47.8446 69.4713 47.8986C69.3645 47.9526 69.2719 48.031 69.201 48.1274C69.1301 48.2238 69.0829 48.3355 69.0632 48.4536C69.0434 48.5716 69.0517 48.6926 69.0874 48.8068C69.1047 48.9785 69.1853 49.1376 69.3136 49.2531C69.4418 49.3685 69.6085 49.432 69.7811 49.4311C69.8722 49.4407 69.9644 49.4321 70.0522 49.4058C70.1401 49.3796 70.2218 49.3362 70.2928 49.2781C70.3637 49.2201 70.4225 49.1485 70.4656 49.0676C70.5087 48.9867 70.5354 48.8981 70.5441 48.8068Z"
          fill="#528ACC"
        />
        <path
          d="M70.404 44.3001C70.3774 44.1441 70.3031 44.0003 70.1913 43.8885C70.0794 43.7766 69.9356 43.7023 69.7797 43.6758C69.6193 43.6912 69.4693 43.7619 69.3554 43.8758C69.2415 43.9897 69.1708 44.1397 69.1554 44.3001C69.1439 44.3847 69.1522 44.4709 69.1795 44.5519C69.2069 44.6328 69.2525 44.7064 69.3129 44.7668C69.3733 44.8272 69.4469 44.8729 69.5279 44.9002C69.6088 44.9275 69.695 44.9358 69.7797 44.9243C69.9452 44.9243 70.104 44.8586 70.2211 44.7415C70.3382 44.6244 70.404 44.4656 70.404 44.3001Z"
          fill="#528ACC"
        />
        <path
          d="M69.7815 40.5551C70.0879 40.5551 70.3364 40.3067 70.3364 40.0002C70.3364 39.6938 70.0879 39.4453 69.7815 39.4453C69.475 39.4453 69.2266 39.6938 69.2266 40.0002C69.2266 40.3067 69.475 40.5551 69.7815 40.5551Z"
          fill="#528ACC"
        />
        <path
          d="M69.7814 36.1136C70.0113 36.1136 70.1976 35.9273 70.1976 35.6974C70.1976 35.4676 70.0113 35.2812 69.7814 35.2812C69.5516 35.2812 69.3652 35.4676 69.3652 35.6974C69.3652 35.9273 69.5516 36.1136 69.7814 36.1136Z"
          fill="#528ACC"
        />
        <path
          d="M70.0579 31.1925C70.0579 31.1231 69.9885 30.9844 69.7804 30.9844C69.5723 30.9844 69.4336 31.1231 69.4336 31.1925C69.4336 31.2618 69.6417 31.678 69.7804 31.678C69.9191 31.678 70.0579 31.4006 70.0579 31.1925Z"
          fill="#528ACC"
        />
        <path
          d="M69.9895 26.8892C69.9655 26.8459 69.9303 26.8097 69.8876 26.7845C69.8449 26.7594 69.7962 26.7461 69.7467 26.7461C69.6971 26.7461 69.6485 26.7594 69.6058 26.7845C69.5631 26.8097 69.5279 26.8459 69.5039 26.8892C69.5039 26.9257 69.5111 26.9617 69.525 26.9954C69.539 27.0291 69.5594 27.0597 69.5852 27.0854C69.6109 27.1112 69.6415 27.1316 69.6752 27.1456C69.7089 27.1595 69.7449 27.1667 69.7814 27.1667C69.8424 27.151 69.8963 27.1149 69.9341 27.0645C69.9719 27.014 69.9915 26.9522 69.9895 26.8892Z"
          fill="#528ACC"
        />
        <path
          d="M69.8487 22.5215C69.8487 22.3828 69.8487 22.3828 69.7794 22.3828C69.7426 22.3828 69.7073 22.3974 69.6813 22.4234C69.6552 22.4495 69.6406 22.4847 69.6406 22.5215C69.6406 22.5583 69.6552 22.5936 69.6813 22.6196C69.7073 22.6457 69.7426 22.6603 69.7794 22.6603C69.8487 22.6603 69.8487 22.6603 69.8487 22.5215Z"
          fill="#528ACC"
        />
        <path
          d="M74.2872 103.051H74.0098C74.0098 103.19 74.0098 103.328 74.1485 103.328C74.2872 103.328 74.2872 103.19 74.2872 103.051Z"
          fill="#528ACC"
        />
        <path
          d="M74.3576 98.8214C74.3576 98.6133 74.2882 98.6133 74.1495 98.6133C74.0108 98.6133 73.9414 98.6133 73.9414 98.8214C73.9414 99.0295 74.0108 99.0295 74.1495 99.0295C74.2047 99.0295 74.2576 99.0075 74.2966 98.9685C74.3357 98.9295 74.3576 98.8766 74.3576 98.8214Z"
          fill="#528ACC"
        />
        <path
          d="M74.4983 94.382C74.4983 94.29 74.4618 94.2018 74.3967 94.1367C74.3317 94.0717 74.2435 94.0352 74.1515 94.0352C74.0595 94.0352 73.9713 94.0717 73.9063 94.1367C73.8412 94.2018 73.8047 94.29 73.8047 94.382C73.8047 94.6594 73.9434 94.7288 74.1515 94.7288C74.3596 94.7288 74.4983 94.6594 74.4983 94.382Z"
          fill="#528ACC"
        />
        <path
          d="M74.5678 90.0109C74.5512 89.913 74.5001 89.8241 74.4238 89.7605C74.3475 89.6969 74.2509 89.6627 74.1516 89.6641C73.9435 89.6641 73.666 89.8028 73.666 90.0109C73.6807 90.1346 73.7366 90.2497 73.8247 90.3378C73.9127 90.4259 74.0279 90.4818 74.1516 90.4965C74.3597 90.4965 74.5678 90.219 74.5678 90.0109Z"
          fill="#528ACC"
        />
        <path
          d="M74.5668 85.7092C74.5668 85.5988 74.523 85.4929 74.4449 85.4149C74.3669 85.3368 74.261 85.293 74.1506 85.293C73.8038 85.293 73.5957 85.5011 73.5957 85.7092C73.595 85.7792 73.6095 85.8486 73.6381 85.9125C73.6667 85.9764 73.7088 86.0334 73.7615 86.0795C73.8142 86.1257 73.8763 86.1599 73.9435 86.1797C74.0106 86.1996 74.0813 86.2047 74.1506 86.1947C74.2671 86.1779 74.3734 86.1194 74.45 86.0301C74.5265 85.9408 74.568 85.8268 74.5668 85.7092Z"
          fill="#528ACC"
        />
        <path
          d="M74.7055 81.3401C74.7055 81.2303 74.673 81.123 74.612 81.0318C74.551 80.9405 74.4644 80.8694 74.363 80.8274C74.2616 80.7854 74.15 80.7744 74.0424 80.7958C73.9347 80.8172 73.8358 80.8701 73.7582 80.9477C73.6806 81.0253 73.6278 81.1242 73.6064 81.2318C73.585 81.3395 73.5959 81.451 73.6379 81.5524C73.6799 81.6538 73.7511 81.7405 73.8423 81.8015C73.9336 81.8624 74.0409 81.895 74.1506 81.895C74.2262 81.9068 74.3035 81.9007 74.3762 81.877C74.449 81.8533 74.5151 81.8127 74.5692 81.7586C74.6233 81.7045 74.6638 81.6384 74.6875 81.5657C74.7112 81.4929 74.7174 81.4156 74.7055 81.3401Z"
          fill="#528ACC"
        />
        <path
          d="M74.8443 76.971C74.8443 76.787 74.7712 76.6106 74.6412 76.4805C74.5111 76.3504 74.3346 76.2773 74.1507 76.2773C73.9667 76.2773 73.7903 76.3504 73.6602 76.4805C73.5301 76.6106 73.457 76.787 73.457 76.971C73.457 77.155 73.5301 77.3314 73.6602 77.4615C73.7903 77.5915 73.9667 77.6646 74.1507 77.6646C74.3346 77.6646 74.5111 77.5915 74.6412 77.4615C74.7712 77.3314 74.8443 77.155 74.8443 76.971Z"
          fill="#528ACC"
        />
        <path
          d="M74.8443 72.6702C74.8443 72.4862 74.7712 72.3098 74.6412 72.1797C74.5111 72.0496 74.3346 71.9766 74.1507 71.9766C73.9667 71.9766 73.7903 72.0496 73.6602 72.1797C73.5301 72.3098 73.457 72.4862 73.457 72.6702C73.457 72.8542 73.5301 73.0306 73.6602 73.1607C73.7903 73.2908 73.9667 73.3638 74.1507 73.3638C74.3346 73.3638 74.5111 73.2908 74.6412 73.1607C74.7712 73.0306 74.8443 72.8542 74.8443 72.6702Z"
          fill="#528ACC"
        />
        <path
          d="M74.8428 68.2996C74.8272 68.1209 74.7491 67.9534 74.6222 67.8265C74.4954 67.6997 74.3279 67.6216 74.1492 67.6059C74.0554 67.5948 73.9603 67.605 73.8711 67.636C73.7819 67.6669 73.7008 67.7176 73.634 67.7844C73.5672 67.8512 73.5165 67.9322 73.4856 68.0215C73.4547 68.1107 73.4444 68.2058 73.4555 68.2996C73.4548 68.4905 73.5255 68.6747 73.654 68.816C73.7824 68.9572 73.9591 69.0452 74.1492 69.0626C74.496 69.0626 74.8428 68.577 74.8428 68.2996Z"
          fill="#528ACC"
        />
        <path
          d="M74.8428 63.8621C74.8272 63.6834 74.7491 63.5159 74.6222 63.389C74.4954 63.2622 74.3279 63.1841 74.1492 63.1684C74.0554 63.1573 73.9603 63.1675 73.8711 63.1985C73.7819 63.2294 73.7008 63.2801 73.634 63.3469C73.5672 63.4137 73.5165 63.4947 73.4856 63.584C73.4547 63.6732 73.4444 63.7683 73.4555 63.8621C73.4555 64.3476 73.6636 64.6944 74.1492 64.6944C74.2495 64.6857 74.3472 64.6573 74.4365 64.6106C74.5257 64.564 74.6049 64.5001 74.6694 64.4228C74.7339 64.3454 74.7824 64.256 74.8122 64.1598C74.8419 64.0635 74.8524 63.9624 74.8428 63.8621Z"
          fill="#528ACC"
        />
        <path
          d="M74.8443 59.6277C74.8555 59.5339 74.8452 59.4388 74.8143 59.3496C74.7834 59.2604 74.7326 59.1793 74.6658 59.1125C74.5991 59.0458 74.518 58.995 74.4288 58.9641C74.3395 58.9332 74.2445 58.9229 74.1507 58.9341C73.6651 58.9341 73.457 59.1421 73.457 59.6277C73.4565 59.7159 73.4746 59.8031 73.5102 59.8838C73.5459 59.9644 73.5982 60.0366 73.6637 60.0956C73.7292 60.1546 73.8065 60.199 73.8904 60.2259C73.9744 60.2529 74.0631 60.2618 74.1507 60.252C74.3191 60.2394 74.4785 60.1712 74.6041 60.0582C74.7296 59.9453 74.8141 59.7938 74.8443 59.6277Z"
          fill="#528ACC"
        />
        <path
          d="M74.8443 55.1897C74.8443 55.0058 74.7712 54.8293 74.6412 54.6993C74.5111 54.5692 74.3346 54.4961 74.1507 54.4961C73.9667 54.4961 73.7903 54.5692 73.6602 54.6993C73.5301 54.8293 73.457 55.0058 73.457 55.1897C73.457 55.3737 73.5301 55.5501 73.6602 55.6802C73.7903 55.8103 73.9667 55.8834 74.1507 55.8834C74.3346 55.8834 74.5111 55.8103 74.6412 55.6802C74.7712 55.5501 74.8443 55.3737 74.8443 55.1897Z"
          fill="#528ACC"
        />
        <path
          d="M74.7056 50.8205C74.6905 50.6785 74.6272 50.546 74.5262 50.445C74.4252 50.344 74.2927 50.2807 74.1507 50.2656C73.9916 50.2741 73.8392 50.3322 73.7148 50.4317C73.5904 50.5313 73.5003 50.6672 73.457 50.8205C73.457 51.0045 73.5301 51.1809 73.6602 51.311C73.7903 51.4411 73.9667 51.5142 74.1507 51.5142C74.4975 51.5142 74.7056 51.2367 74.7056 50.8205Z"
          fill="#528ACC"
        />
        <path
          d="M74.7055 46.5217C74.6905 46.3797 74.6272 46.2471 74.5262 46.1462C74.4252 46.0452 74.2926 45.9819 74.1506 45.9668C74.075 45.9549 73.9977 45.9611 73.925 45.9848C73.8523 46.0085 73.7862 46.0491 73.7321 46.1032C73.678 46.1572 73.6374 46.2234 73.6137 46.2961C73.59 46.3688 73.5839 46.4461 73.5957 46.5217C73.6127 46.6566 73.6786 46.7805 73.7809 46.87C73.8831 46.9595 74.0147 47.0083 74.1506 47.0073C74.3587 47.0073 74.7055 46.7298 74.7055 46.5217Z"
          fill="#528ACC"
        />
        <path
          d="M74.5678 42.2209C74.5678 42.1105 74.5239 42.0047 74.4459 41.9266C74.3678 41.8486 74.2619 41.8047 74.1516 41.8047C74.034 41.8035 73.9199 41.845 73.8306 41.9216C73.7413 41.9981 73.6828 42.1045 73.666 42.2209C73.666 42.4984 73.9435 42.5677 74.1516 42.5677C74.3597 42.5677 74.5678 42.4984 74.5678 42.2209Z"
          fill="#528ACC"
        />
        <path
          d="M74.4983 37.712C74.4983 37.6426 74.3596 37.5039 74.1515 37.5039C73.9434 37.5039 73.8047 37.6426 73.8047 37.712C73.8047 37.7814 73.9434 38.1975 74.1515 38.1975C74.3596 38.1975 74.4983 37.9895 74.4983 37.712Z"
          fill="#528ACC"
        />
        <path
          d="M74.3596 33.4806C74.3596 33.2031 74.2902 33.2031 74.1515 33.2031C74.0128 33.2031 73.8047 33.2031 73.8047 33.4806C73.8047 33.758 73.9434 33.6887 74.1515 33.6887C74.3596 33.6887 74.3596 33.6887 74.3596 33.4806Z"
          fill="#528ACC"
        />
        <path
          d="M74.2882 29.043H73.9414C73.9414 29.1817 74.0108 29.3204 74.1495 29.3204C74.2882 29.3204 74.2882 29.1817 74.2882 29.043Z"
          fill="#528ACC"
        />
        <path
          d="M74.2872 24.668H74.0098C74.0098 24.7048 74.0244 24.74 74.0504 24.7661C74.0764 24.7921 74.1117 24.8067 74.1485 24.8067C74.1853 24.8067 74.2206 24.7921 74.2466 24.7661C74.2726 24.74 74.2872 24.7048 74.2872 24.668Z"
          fill="#528ACC"
        />
        <path
          d="M78.5205 100.971V100.832C78.4512 100.832 78.4512 100.971 78.4512 100.971C78.4512 100.971 78.4512 101.04 78.5205 101.04V100.971Z"
          fill="#528ACC"
        />
        <path
          d="M78.6595 96.5312H78.3126C78.3106 96.5943 78.3302 96.6561 78.368 96.7065C78.4058 96.7569 78.4597 96.793 78.5207 96.8087C78.5961 96.7339 78.6448 96.6364 78.6595 96.5312Z"
          fill="#528ACC"
        />
        <path
          d="M78.7268 92.3009C78.7268 92.0234 78.6574 92.0234 78.5187 92.0234C78.38 92.0234 78.1719 92.0234 78.1719 92.3009C78.1719 92.5784 78.3106 92.509 78.5187 92.509C78.5739 92.509 78.6268 92.4871 78.6658 92.448C78.7049 92.409 78.7268 92.3561 78.7268 92.3009Z"
          fill="#528ACC"
        />
        <path
          d="M78.7268 87.8624C78.7268 87.7931 78.6574 87.5156 78.5187 87.5156C78.38 87.5156 78.1719 87.7931 78.1719 87.8624C78.1719 87.9318 78.3106 88.2093 78.5187 88.2093C78.7268 88.2093 78.7268 88.1399 78.7268 87.8624Z"
          fill="#528ACC"
        />
        <path
          d="M78.868 83.4914C78.868 83.3994 78.8315 83.3112 78.7664 83.2462C78.7014 83.1811 78.6132 83.1446 78.5212 83.1446C78.4218 83.1432 78.3253 83.1774 78.2489 83.241C78.1726 83.3046 78.1215 83.3934 78.105 83.4914C78.0774 83.5547 78.066 83.6239 78.0718 83.6928C78.0776 83.7616 78.1005 83.8279 78.1383 83.8857C78.1762 83.9435 78.2278 83.991 78.2886 84.0239C78.3494 84.0567 78.4174 84.0739 78.4865 84.0739C78.5556 84.0739 78.6236 84.0567 78.6844 84.0239C78.7452 83.991 78.7968 83.9435 78.8347 83.8857C78.8725 83.8279 78.8954 83.7616 78.9012 83.6928C78.907 83.6239 78.8956 83.5547 78.868 83.4914Z"
          fill="#528ACC"
        />
        <path
          d="M79.0053 79.1935C79.0053 78.9854 78.7278 78.7773 78.5198 78.7773C78.3117 78.7773 77.9648 78.9854 77.9648 79.1935C77.9818 79.3284 78.0477 79.4523 78.15 79.5418C78.2523 79.6313 78.3838 79.6801 78.5198 79.6791C78.6434 79.6644 78.7586 79.6085 78.8467 79.5204C78.9347 79.4324 78.9906 79.3172 79.0053 79.1935Z"
          fill="#528ACC"
        />
        <path
          d="M79.0053 74.8205C79.0064 74.6846 78.9575 74.5531 78.868 74.4508C78.7785 74.3485 78.6546 74.2826 78.5198 74.2656C78.3726 74.2656 78.2314 74.3241 78.1274 74.4282C78.0233 74.5322 77.9648 74.6734 77.9648 74.8205C77.9648 74.9677 78.0233 75.1089 78.1274 75.2129C78.2314 75.317 78.3726 75.3755 78.5198 75.3755C78.6546 75.3585 78.7785 75.2926 78.868 75.1903C78.9575 75.088 79.0064 74.9564 79.0053 74.8205Z"
          fill="#528ACC"
        />
        <path
          d="M79.0037 70.381C79.0037 70.1729 78.7262 69.9648 78.5182 69.9648C78.3101 69.9648 77.9632 70.1729 77.9632 70.381C77.9536 70.4631 77.9604 70.5463 77.9831 70.6257C78.0059 70.7051 78.0442 70.7793 78.0958 70.8438C78.1474 70.9083 78.2113 70.9619 78.2838 71.0016C78.3563 71.0412 78.436 71.0661 78.5182 71.0747C78.7262 71.0747 79.0037 70.7278 79.0037 70.381Z"
          fill="#528ACC"
        />
        <path
          d="M79.0747 66.1526C79.0596 66.0106 78.9963 65.878 78.8953 65.777C78.7943 65.676 78.6618 65.6127 78.5198 65.5977C78.4469 65.5977 78.3747 65.612 78.3074 65.6399C78.2401 65.6678 78.1789 65.7087 78.1274 65.7602C78.0758 65.8117 78.035 65.8729 78.0071 65.9402C77.9792 66.0075 77.9648 66.0797 77.9648 66.1526C77.9648 66.2254 77.9792 66.2976 78.0071 66.3649C78.035 66.4323 78.0758 66.4934 78.1274 66.545C78.1789 66.5965 78.2401 66.6374 78.3074 66.6652C78.3747 66.6931 78.4469 66.7075 78.5198 66.7075C78.6618 66.6924 78.7943 66.6291 78.8953 66.5281C78.9963 66.4271 79.0596 66.2946 79.0747 66.1526Z"
          fill="#528ACC"
        />
        <path
          d="M79.0747 61.7112C79.0596 61.5692 78.9963 61.4366 78.8953 61.3356C78.7943 61.2346 78.6618 61.1713 78.5198 61.1562C78.3726 61.1562 78.2314 61.2147 78.1274 61.3188C78.0233 61.4228 77.9648 61.564 77.9648 61.7112C77.9648 61.8583 78.0233 61.9995 78.1274 62.1036C78.2314 62.2076 78.3726 62.2661 78.5198 62.2661C78.6618 62.251 78.7943 62.1877 78.8953 62.0867C78.9963 61.9857 79.0596 61.8532 79.0747 61.7112Z"
          fill="#528ACC"
        />
        <path
          d="M79.0747 57.342C79.0747 57.1339 78.7278 56.9258 78.5198 56.9258C78.3117 56.9258 77.9648 57.1339 77.9648 57.342C77.9648 57.5501 78.1729 58.0356 78.5198 58.0356C78.6019 58.027 78.6816 58.0021 78.7541 57.9625C78.8266 57.9229 78.8905 57.8692 78.9421 57.8047C78.9937 57.7402 79.032 57.6661 79.0548 57.5866C79.0775 57.5072 79.0843 57.424 79.0747 57.342Z"
          fill="#528ACC"
        />
        <path
          d="M79.0053 53.0412C79.0064 52.9053 78.9575 52.7738 78.868 52.6715C78.7785 52.5692 78.6546 52.5033 78.5198 52.4863C78.4442 52.4745 78.3669 52.4807 78.2941 52.5043C78.2214 52.528 78.1553 52.5686 78.1012 52.6227C78.0471 52.6768 78.0066 52.7429 77.9829 52.8156C77.9592 52.8884 77.953 52.9657 77.9648 53.0412C77.9641 53.1113 77.9786 53.1807 78.0072 53.2446C78.0358 53.3085 78.0779 53.3655 78.1306 53.4116C78.1833 53.4578 78.2454 53.4919 78.3126 53.5118C78.3798 53.5317 78.4504 53.5368 78.5198 53.5268C78.6434 53.5121 78.7586 53.4562 78.8467 53.3681C78.9347 53.2801 78.9906 53.1649 79.0053 53.0412Z"
          fill="#528ACC"
        />
        <path
          d="M79.0053 48.8108C79.0064 48.6749 78.9575 48.5433 78.868 48.441C78.7785 48.3387 78.6546 48.2728 78.5198 48.2559C78.4442 48.244 78.3669 48.2502 78.2941 48.2739C78.2214 48.2976 78.1553 48.3381 78.1012 48.3922C78.0471 48.4463 78.0066 48.5124 77.9829 48.5852C77.9592 48.6579 77.953 48.7352 77.9648 48.8108C77.9648 49.0189 78.1729 49.227 78.5198 49.227C78.8666 49.227 79.0053 49.0189 79.0053 48.8108Z"
          fill="#528ACC"
        />
        <path
          d="M78.8677 44.2999C78.8677 44.0225 78.7289 43.9531 78.5208 43.9531C78.3128 43.9531 78.1047 44.0225 78.1047 44.2999C78.0914 44.3573 78.0929 44.4171 78.1091 44.4737C78.1253 44.5303 78.1556 44.5819 78.1973 44.6235C78.2389 44.6651 78.2905 44.6955 78.3471 44.7117C78.4037 44.7279 78.4635 44.7294 78.5208 44.7161C78.6188 44.6996 78.7076 44.6485 78.7712 44.5722C78.8349 44.4959 78.8691 44.3993 78.8677 44.2999Z"
          fill="#528ACC"
        />
        <path
          d="M78.8655 39.9992C78.8655 39.9072 78.829 39.819 78.7639 39.7539C78.6989 39.6889 78.6107 39.6523 78.5187 39.6523C78.4267 39.6523 78.3385 39.6889 78.2735 39.7539C78.2084 39.819 78.1719 39.9072 78.1719 39.9992C78.1719 40.0911 78.2084 40.1794 78.2735 40.2444C78.3385 40.3094 78.4267 40.346 78.5187 40.346C78.6107 40.346 78.6989 40.3094 78.7639 40.2444C78.829 40.1794 78.8655 40.0911 78.8655 39.9992Z"
          fill="#528ACC"
        />
        <path
          d="M78.7268 35.6984C78.7268 35.4903 78.6574 35.3516 78.5187 35.3516C78.4267 35.3516 78.3385 35.3881 78.2735 35.4531C78.2084 35.5182 78.1719 35.6064 78.1719 35.6984C78.2113 35.756 78.2627 35.8044 78.3226 35.8403C78.3824 35.8762 78.4493 35.8988 78.5187 35.9065C78.6574 35.9065 78.7268 35.6984 78.7268 35.6984Z"
          fill="#528ACC"
        />
        <path
          d="M78.6593 31.1905C78.6593 31.1905 78.6593 31.1211 78.5206 31.1211H78.3125C78.3125 31.3292 78.4512 31.3292 78.5206 31.3292C78.59 31.3292 78.6593 31.3986 78.6593 31.1905Z"
          fill="#528ACC"
        />
        <path
          d="M78.5205 26.8867C78.5114 26.8867 78.5024 26.8885 78.494 26.892C78.4856 26.8955 78.4779 26.9006 78.4715 26.907C78.465 26.9135 78.4599 26.9211 78.4565 26.9295C78.453 26.938 78.4512 26.947 78.4512 26.9561C78.4512 26.9561 78.4512 27.0948 78.5205 27.0948V26.8867Z"
          fill="#528ACC"
        />
        <path
          d="M82.9591 94.3828H82.6816C82.6816 94.4196 82.6963 94.4549 82.7223 94.4809C82.7483 94.5069 82.7836 94.5215 82.8204 94.5215C82.9591 94.5215 82.9591 94.5215 82.9591 94.3828Z"
          fill="#528ACC"
        />
        <path
          d="M83.0295 90.0128C83.0295 90.0128 82.9601 89.8047 82.8214 89.8047C82.6826 89.8047 82.6133 90.0128 82.6133 90.0128C82.6133 90.0128 82.6826 90.2209 82.8214 90.2209C82.9601 90.2209 83.0295 90.2209 83.0295 90.0128Z"
          fill="#528ACC"
        />
        <path
          d="M83.1682 85.712C83.1682 85.5039 83.0295 85.5039 82.8214 85.5039C82.6133 85.5039 82.6133 85.5039 82.6133 85.712C82.6133 85.9201 82.6826 85.9895 82.8214 85.9895C82.9025 85.9911 82.9817 85.9642 83.045 85.9135C83.1084 85.8628 83.152 85.7915 83.1682 85.712Z"
          fill="#528ACC"
        />
        <path
          d="M83.1683 81.3429C83.1683 81.2509 83.1317 81.1627 83.0667 81.0977C83.0016 81.0326 82.9134 80.9961 82.8214 80.9961C82.7343 81.0097 82.6538 81.0507 82.5915 81.113C82.5292 81.1753 82.4882 81.2558 82.4746 81.3429C82.4746 81.4349 82.5111 81.5231 82.5762 81.5882C82.6412 81.6532 82.7294 81.6897 82.8214 81.6897C82.9134 81.6897 83.0016 81.6532 83.0667 81.5882C83.1317 81.5231 83.1683 81.4349 83.1683 81.3429Z"
          fill="#528ACC"
        />
        <path
          d="M83.2377 76.9679C83.2377 76.6905 83.0296 76.6211 82.8215 76.6211C82.6134 76.6211 82.4747 76.6905 82.4747 76.9679C82.4733 77.0673 82.5075 77.1638 82.5711 77.2402C82.6347 77.3165 82.7235 77.3675 82.8215 77.3841C82.9318 77.3841 83.0377 77.3403 83.1158 77.2622C83.1938 77.1842 83.2377 77.0783 83.2377 76.9679Z"
          fill="#528ACC"
        />
        <path
          d="M82.8205 73.0863C83.0503 73.0863 83.2367 72.8999 83.2367 72.6701C83.2367 72.4402 83.0503 72.2539 82.8205 72.2539C82.5906 72.2539 82.4043 72.4402 82.4043 72.6701C82.4043 72.8999 82.5906 73.0863 82.8205 73.0863Z"
          fill="#528ACC"
        />
        <path
          d="M82.8205 68.7152C83.0503 68.7152 83.2367 68.5288 83.2367 68.299C83.2367 68.0691 83.0503 67.8828 82.8205 67.8828C82.5906 67.8828 82.4043 68.0691 82.4043 68.299C82.4043 68.5288 82.5906 68.7152 82.8205 68.7152Z"
          fill="#528ACC"
        />
        <path
          d="M82.8205 64.2777C83.0503 64.2777 83.2367 64.0913 83.2367 63.8615C83.2367 63.6316 83.0503 63.4453 82.8205 63.4453C82.5906 63.4453 82.4043 63.6316 82.4043 63.8615C82.4043 64.0913 82.5906 64.2777 82.8205 64.2777Z"
          fill="#528ACC"
        />
        <path
          d="M82.8205 60.0433C83.0503 60.0433 83.2367 59.857 83.2367 59.6271C83.2367 59.3973 83.0503 59.2109 82.8205 59.2109C82.5906 59.2109 82.4043 59.3973 82.4043 59.6271C82.4043 59.857 82.5906 60.0433 82.8205 60.0433Z"
          fill="#528ACC"
        />
        <path
          d="M83.2376 55.1906C83.2211 55.0926 83.17 55.0038 83.0937 54.9402C83.0174 54.8766 82.9208 54.8424 82.8214 54.8438C82.7294 54.8438 82.6412 54.8803 82.5762 54.9454C82.5111 55.0104 82.4746 55.0986 82.4746 55.1906C82.4746 55.3987 82.6133 55.6762 82.8214 55.6762C83.0295 55.6762 83.2376 55.3987 83.2376 55.1906Z"
          fill="#528ACC"
        />
        <path
          d="M83.1683 50.8214C83.1288 50.7638 83.0774 50.7154 83.0175 50.6795C82.9577 50.6436 82.8908 50.621 82.8214 50.6133H82.4746C82.4746 50.9601 82.6133 51.1682 82.8214 51.1682C82.9085 51.1546 82.989 51.1136 83.0514 51.0513C83.1137 50.989 83.1546 50.9085 83.1683 50.8214Z"
          fill="#528ACC"
        />
        <path
          d="M83.1683 46.5226C83.1683 46.4306 83.1317 46.3424 83.0667 46.2774C83.0016 46.2123 82.9134 46.1758 82.8214 46.1758C82.7294 46.1758 82.6412 46.2123 82.5762 46.2774C82.5111 46.3424 82.4746 46.4306 82.4746 46.5226C82.4746 46.7307 82.6827 46.7307 82.8214 46.7307C82.9602 46.7307 83.1683 46.7307 83.1683 46.5226Z"
          fill="#528ACC"
        />
        <path
          d="M83.0295 42.2189C83.0295 42.0108 82.9601 41.9414 82.8214 41.9414C82.6826 41.9414 82.6133 42.0108 82.6133 42.2189C82.6133 42.427 82.6826 42.4963 82.8214 42.4963C82.9601 42.4963 83.0295 42.2189 83.0295 42.2189Z"
          fill="#528ACC"
        />
        <path
          d="M83.0285 37.7109H82.8204C82.6816 37.7109 82.6816 37.7803 82.6816 37.7803C82.6816 37.7803 82.6816 38.0578 82.8204 38.0578C82.9591 38.0578 83.0285 37.9884 83.0285 37.7109Z"
          fill="#528ACC"
        />
        <path
          d="M82.9591 33.4786C82.9591 33.4418 82.9445 33.4065 82.9185 33.3805C82.8924 33.3545 82.8572 33.3398 82.8204 33.3398L82.6816 33.4786H82.9591Z"
          fill="#528ACC"
        />
        <path
          d="M87.3311 87.8623C87.3311 87.793 87.1924 87.793 87.1924 87.793C87.1924 87.793 87.123 87.793 87.123 87.8623C87.123 87.9317 87.123 88.0011 87.1924 88.0011C87.2292 88.0011 87.2645 87.9864 87.2905 87.9604C87.3165 87.9344 87.3311 87.8991 87.3311 87.8623Z"
          fill="#528ACC"
        />
        <path
          d="M87.3292 83.4903C87.3292 83.4535 87.3146 83.4182 87.2886 83.3922C87.2626 83.3662 87.2273 83.3516 87.1905 83.3516L86.9824 83.4903C86.9824 83.6984 87.1211 83.6984 87.1905 83.6984C87.2599 83.6984 87.3292 83.6984 87.3292 83.4903Z"
          fill="#528ACC"
        />
        <path
          d="M87.468 79.19C87.444 79.1466 87.4088 79.1105 87.3661 79.0853C87.3234 79.0602 87.2748 79.0469 87.2252 79.0469C87.1756 79.0469 87.127 79.0602 87.0843 79.0853C87.0416 79.1105 87.0064 79.1466 86.9824 79.19C86.9824 79.2452 87.0043 79.2981 87.0434 79.3372C87.0824 79.3762 87.1353 79.3981 87.1905 79.3981C87.3292 79.3981 87.468 79.3287 87.468 79.19Z"
          fill="#528ACC"
        />
        <path
          d="M87.468 74.8175C87.468 74.6787 87.3292 74.6094 87.1905 74.6094C87.1353 74.6094 87.0824 74.6313 87.0434 74.6703C87.0043 74.7093 86.9824 74.7623 86.9824 74.8175C86.9824 74.8175 87.1211 75.1643 87.1905 75.1643C87.2599 75.1643 87.468 74.8868 87.468 74.8175Z"
          fill="#528ACC"
        />
        <path
          d="M87.468 70.384C87.4523 70.323 87.4163 70.2691 87.3658 70.2313C87.3154 70.1935 87.2536 70.1739 87.1906 70.1759C86.9825 70.1759 86.8438 70.3147 86.8438 70.384C86.8438 70.4534 86.9825 70.7308 87.1906 70.7308C87.3987 70.7308 87.468 70.6615 87.468 70.384Z"
          fill="#528ACC"
        />
        <path
          d="M87.5374 66.1515C87.5374 66.0595 87.5009 65.9713 87.4358 65.9063C87.3708 65.8412 87.2826 65.8047 87.1906 65.8047C87.0986 65.8047 87.0104 65.8412 86.9453 65.9063C86.8803 65.9713 86.8438 66.0595 86.8438 66.1515C86.8438 66.3596 86.9825 66.3596 87.1906 66.3596C87.3987 66.3596 87.5374 66.3596 87.5374 66.1515Z"
          fill="#528ACC"
        />
        <path
          d="M87.5374 61.7101C87.5374 61.6407 87.3293 61.3633 87.1906 61.3633C87.0518 61.3633 86.8438 61.6407 86.8438 61.7101C86.8438 61.7795 86.9825 62.0569 87.1906 62.0569C87.2826 62.0569 87.3708 62.0204 87.4358 61.9553C87.5009 61.8903 87.5374 61.8021 87.5374 61.7101Z"
          fill="#528ACC"
        />
        <path
          d="M87.468 57.3409C87.468 57.2022 87.3293 57.1328 87.1906 57.1328C87.0518 57.1328 86.8438 57.2022 86.8438 57.3409C86.8438 57.4796 86.9825 57.8265 87.1906 57.8265C87.3987 57.8265 87.468 57.549 87.468 57.3409Z"
          fill="#528ACC"
        />
        <path
          d="M87.468 53.0401C87.468 52.832 87.3293 52.832 87.1906 52.832C87.0518 52.832 86.8438 52.832 86.8438 53.0401C86.8438 53.2482 86.9825 53.3176 87.1906 53.3176C87.3987 53.3176 87.468 53.3176 87.468 53.0401Z"
          fill="#528ACC"
        />
        <path
          d="M87.468 48.8117C87.468 48.5342 87.3292 48.4648 87.1905 48.4648C87.0518 48.4648 86.9824 48.5342 86.9824 48.8117C86.9824 49.0891 87.1211 49.0198 87.1905 49.0198C87.2599 49.0198 87.468 48.8117 87.468 48.8117Z"
          fill="#528ACC"
        />
        <path
          d="M87.468 44.2989C87.3931 44.2235 87.2957 44.1748 87.1905 44.1602L86.9824 44.2989C86.9824 44.507 87.1211 44.507 87.1905 44.507C87.2599 44.507 87.468 44.507 87.468 44.2989Z"
          fill="#528ACC"
        />
        <path
          d="M87.3311 40.0011L87.1924 39.793C87.123 39.793 87.123 40.0011 87.123 40.0011H87.3311Z"
          fill="#528ACC"
        />
        <path
          d="M87.1917 35.6992C87.1735 35.6992 87.156 35.7064 87.1431 35.7193C87.1303 35.7322 87.123 35.7496 87.123 35.7678L87.1917 35.6992Z"
          fill="#528ACC"
        />
        <path
          d="M91.6309 81.3419V81.2031C91.4922 81.2031 91.4922 81.2031 91.4922 81.3419C91.4922 81.4806 91.4922 81.4806 91.6309 81.4806V81.3419Z"
          fill="#528ACC"
        />
        <path
          d="M91.7013 76.9708V76.832L91.4238 76.9708C91.4396 77.0318 91.4756 77.0857 91.526 77.1235C91.5765 77.1613 91.6383 77.1808 91.7013 77.1789V76.9708Z"
          fill="#528ACC"
        />
        <path
          d="M91.7003 72.6729C91.7035 72.6354 91.699 72.5975 91.6871 72.5617C91.6752 72.526 91.6561 72.493 91.631 72.4648C91.4923 72.4648 91.3535 72.5342 91.3535 72.6729C91.3535 72.8117 91.4923 72.881 91.631 72.881L91.7003 72.6729Z"
          fill="#528ACC"
        />
        <path
          d="M91.7003 68.2989C91.7003 68.1602 91.631 68.1602 91.631 68.1602C91.631 68.1602 91.3535 68.1602 91.3535 68.2989C91.3692 68.3599 91.4053 68.4138 91.4557 68.4516C91.5062 68.4894 91.568 68.509 91.631 68.507L91.7003 68.2989Z"
          fill="#528ACC"
        />
        <path
          d="M91.7003 63.8633H91.3535C91.3535 64.1407 91.4923 64.1407 91.631 64.1407C91.7697 64.1407 91.7003 64.1407 91.7003 63.8633Z"
          fill="#528ACC"
        />
        <path
          d="M91.7003 59.629C91.6967 59.5327 91.6731 59.4382 91.631 59.3516C91.5574 59.3516 91.4868 59.3808 91.4348 59.4328C91.3827 59.4849 91.3535 59.5554 91.3535 59.629C91.3692 59.6901 91.4053 59.7439 91.4557 59.7818C91.5062 59.8196 91.568 59.8391 91.631 59.8371L91.7003 59.629Z"
          fill="#528ACC"
        />
        <path
          d="M91.7003 55.1905C91.7003 55.1721 91.693 55.1544 91.68 55.1414C91.667 55.1284 91.6494 55.1211 91.631 55.1211C91.4923 55.1211 91.3535 55.1211 91.3535 55.1905C91.3535 55.2598 91.4923 55.3292 91.631 55.3292C91.7697 55.3292 91.7003 55.3292 91.7003 55.1905Z"
          fill="#528ACC"
        />
        <path
          d="M91.7003 50.8203H91.3535C91.3692 50.8814 91.4053 50.9352 91.4557 50.9731C91.5062 51.0109 91.568 51.0304 91.631 51.0284C91.665 50.9632 91.6884 50.8929 91.7003 50.8203Z"
          fill="#528ACC"
        />
        <path
          d="M91.6309 46.5215V46.3828C91.5941 46.3828 91.5588 46.3974 91.5328 46.4234C91.5068 46.4495 91.4922 46.4847 91.4922 46.5215H91.6309Z"
          fill="#528ACC"
        />
        <path
          d="M91.6309 42.2188C91.4922 42.2188 91.4922 42.2187 91.4922 42.2881L91.6309 42.2188Z"
          fill="#528ACC"
        />
        <path
          d="M96.0001 70.3828H95.8613V70.5215L96.0001 70.3828Z"
          fill="#528ACC"
        />
        <path
          d="M96.0001 61.7109H95.8613V61.8497C95.8981 61.8497 95.9334 61.835 95.9594 61.809C95.9854 61.783 96.0001 61.7477 96.0001 61.7109Z"
          fill="#528ACC"
        />
        <path
          d="M96.0001 57.3398H95.8613V57.4786C95.8981 57.4786 95.9334 57.464 95.9594 57.4379C95.9854 57.4119 96.0001 57.3766 96.0001 57.3398Z"
          fill="#528ACC"
        />
        <circle cx="56" cy="56" r="29.5" fill="white" stroke="#0B427A" />
        <rect
          x="53"
          y="34"
          width="6"
          height="32"
          rx="3"
          fill="#EEF5FD"
          stroke="#0B427A"
        />
        <circle cx="56" cy="74" r="3.5" fill="#EEF5FD" stroke="#0B427A" />
      </svg>
    </>
  );
};

export default AlertIllustration;
