import { Xwrapper } from "react-xarrows";
import { createRef, useContext, useEffect, useRef, useState } from "react";
import { regenerateKeys } from "../../hooks/useLabels";
import DeleteDialog from "../table/DeleteDialog";
import PreProcessedTable from "../table/preprocessing/preProcessedTable";
import { ReviewContext } from "../../context/ReviewContext";
import useLocales from "src/hooks/useLocales";

const PreProcessed = ({
  fields,
  values,
  handleMouseScroll,
  removeArrowAxis,
  setFieldValue,
  title,
  handleItemOpen,
  tableRefs,
  generateMap,
  handleItemOpenGeneral,
}) => {
  const [fieldsLabelMap, setFieldsLabelMap] = useState<any>({});
  const [fieldsMap, setFieldsMap] = useState<any>([]);
  const [item, setItem] = useState<any>(null);

  useEffect(() => {
    createFieldsMap(fields);
  }, [fields]);

  const renderCounter = useRef(0);
  function getFieldValue(dotNotationPath, sourceObject = values) {
    let returnData = sourceObject;
    dotNotationPath.split(".").forEach((subPath) => {
      returnData = returnData[subPath] || `Property ${subPath} not found`;
    });
    return returnData;
  }
  const { translate } = useLocales();
  const getTranslatedFields = (title) => {
    let result = translate(`review_screen.review_fields.${title}`);
    if (result.indexOf("review_screen") == -1) {
      return result;
    }
    return title;
  };
  useEffect(() => {
    createFieldsLabelMap(fields);
  }, [fields]);

  const getSortedFields = (a: any, b: any): number => {
    const orderA = a?.order ?? null;
    const orderB = b?.order ?? null;

    if (orderA !== null && orderB !== null) {
      // If both orders are present, sort by order in ascending order
      return orderA - orderB;
    }

    if (orderA === null && orderB === null) {
      // If both orders are null, sort by name in ascending alphabetical order
      const nameA = getTranslatedFields(a?.name);
      const nameB = getTranslatedFields(b?.name);
      return nameA.localeCompare(nameB);
    }

    // If one order is present and the other is not, the present one should come first
    if (orderA !== null) return -1;
    if (orderB !== null) return 1;

    return 0; // In case of an unexpected situation
  };
  const getAlphabaticallySortedFields = (a: any, b: any) => {
    if (
      getTranslatedFields(a?.name).toLowerCase() >
      getTranslatedFields(b?.name).toLowerCase()
    ) {
      return 1;
    }
    if (
      getTranslatedFields(a?.name).toLowerCase() <
      getTranslatedFields(b?.name).toLowerCase()
    ) {
      return -1;
    }
    return 0;
  };

  const createFieldsLabelMap = (fields) => {
    let fieldsLabelMap_ = {};
    fields
      ?.filter((f) => f.itemsFields != null)
      ?.forEach((fieldList) => {
        fieldsLabelMap_[
          fieldList?.itemsFields?.[0]?.item?.[0]?.category ?? fieldList?.name
        ] = fieldList.key;
      });

    setFieldsLabelMap(fieldsLabelMap_);
    return fieldsLabelMap_;
  };

  const createFieldsMap = (fields) => {
    renderCounter.current = renderCounter.current + 1;
    let fieldsMap = {};
    fieldsMap["general"] = fields?.filter((f) => f.itemsFields == null);
    let filteredFields = fields?.filter((f) => f.itemsFields != null);
    filteredFields?.map((fieldList) => {
      fieldsMap[
        fieldList?.itemsFields?.[0]?.item?.[0]?.category ?? fieldList?.name
      ] = [
        ...fieldList.itemsFields?.map((field) => {
          return field.item?.map((item) => {
            return {
              ...item,
              itemsFieldIndex: field.index,
            };
          });
        }),
      ];
    });
    let fieldsMap_ = Object.entries(fieldsMap)?.map(([key, item]) => {
      if (key == "general") {
        return [key, (item as any).sort(getSortedFields)];
      }
      return [
        key,
        (item as any)?.map((i) => i.sort(getAlphabaticallySortedFields)),
      ];
    });

    setFieldsMap(fieldsMap_);
    Object.keys(fieldsMap)
      ?.filter?.((field) => field != "general")
      ?.forEach((key, index) => {
        tableRefs.current[key] = tableRefs.current[key] ?? createRef();
      });
    Object.keys(tableRefs.current).forEach((ref, index) => {
      tableRefs.current[ref] = tableRefs.current[ref] ?? createRef();
    });
    return fieldsMap;
  };

  const reviewContext = useContext(ReviewContext);
  const { reviewResponse } = reviewContext;
  const { dispatch } = reviewResponse;

  const handleDeleteRow = (key: string) => {
    let keys = key.split(".");
    let idx: string | null = null;
    if (keys.length == 2) {
      idx = keys[1];
    }
    let temp = values.fields;
    let tempField_ = temp?.splice(idx, 1);

    let tempField = tempField_[0];
    if (tempField.category === "general") {
      dispatch({
        type: "REMOVE_FIELD",
        payload: [
          {
            name: tempField.name,
            value: tempField.value,
            pageNumber: tempField.pageNumber,
            confidence: 0.9,
            isRemoved: true,
            isNew: false,
            itemsFields: null,
            boundingPolygon: tempField.boundingPolygon,
          },
        ],
      });
    } else {
      dispatch({
        type: "REMOVE_FIELD",
        payload: [
          {
            name: tempField.category,
            value: null,
            pageNumber: tempField.pageNumber,
            confidence: null,
            isRemoved: false,
            isNew: false,
            itemsFields: [
              {
                index: tempField.itemsFieldIndex,
                item: [
                  {
                    name: tempField.name,
                    value: tempField.value,
                    pageNumber: tempField.pageNumber,
                    confidence: 0.9,
                    isRemoved: true,
                    isNew: false,
                    boundingPolygon: tempField.boundingPolygon,
                  },
                ],
              },
            ],
          },
        ],
      });
    }
    let temp_ = regenerateKeys(structuredClone(temp));
    setFieldValue?.("fields", temp_);
    createFieldsMap(temp_);
    createFieldsLabelMap(temp_);
    generateMap();
  };

  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleDeleteDialog = (item) => {
    setItem(item);
    setOpenDialog(true);
  };

  const handleDelete = (key: string, isEditable = true) => {
    let keys = key.split(".");
    let idx: string | null = null;
    if (keys.length == 2) {
      idx = keys[1];
    }
    if (isEditable) {
      let temp = values.fields;
      let tempField = temp?.splice(idx, 1);
      let temp_ = regenerateKeys(structuredClone(temp));
      setFieldValue?.("fields", temp_);
      createFieldsMap(temp_);
      createFieldsLabelMap(temp_);
      dispatch({
        type: "REMOVE_TABLE",
        payload: {
          name: tempField[0].name,
        },
      });
    }
  };

  return (
    <Xwrapper>
      <DeleteDialog
        {...{
          openDialog,
          handleCloseDialog,
          handleDeleteRow,
          item,
        }}
      />
      {fieldsMap?.map(([k, item]: any[]) => {
        return (
          <PreProcessedTable
            key={k + "-table"}
            {...{
              k,
              handleItemOpen,
              handleItemOpenGeneral,
              item,
              fieldsLabelMap,
              handleDelete,
              handleMouseScroll,
              removeArrowAxis,
              setFieldValue,
              handleDeleteDialog,
              title,
              getFieldValue,
              ref: tableRefs.current[k],
            }}
          />
        );
      })}
    </Xwrapper>
  );
};

export default PreProcessed;
