import { useEffect } from "react";
import Page from "src/components/Page";
import { useSearchParams } from "react-router-dom";
import { PostAsync } from "src/common/httpRequests";
import { API_GATEWAY } from "src/constants/settings";
import LoadingScreen from "src/components/LoadingScreen";

function RedirectionPage() {
  const [params, setSearchParams] = useSearchParams();

  const redirection = () => {
    let configurations = Array.from(params.entries()).map((entry) => {
      if (entry[0] != "connectorId")
        return {
          key: entry[0],
          value: entry[1],
        };
    });
    configurations = configurations.filter((item) => item);

    PostAsync(`${API_GATEWAY}/api/connectorservice/userconnectors`, {
      connectorId: window.localStorage.getItem("connectorId"),
      isActive: true,
      configurations: configurations,
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          setTimeout(() => {
            setSearchParams({
              connected: "true",
            });
            /* eslint-disable-next-line no-restricted-globals */
            self.close();
          }, 5000);
        } else {
          setSearchParams({
            connected: "false",
            error: res.data.Detail,
          });
          /* eslint-disable-next-line no-restricted-globals */
          self.close();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      redirection();
    }, 2000);
  }, []);

  return (
    <Page title="Register">
      <LoadingScreen />
    </Page>
  );
}

export default RedirectionPage;
