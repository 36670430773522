import { LoadingButton } from "@mui/lab";
import { Alert, Button, DialogContent, DialogTitle } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { Dialog } from "@mui/material";
import { DialogActions } from "@mui/material";
import { useState } from "react";
import NewTextField from "src/components/form/TextField";
import useLocales from "src/hooks/useLocales";

type SelfTrainingTagDialogProps = {
  content: string;
  open: boolean;
  isPolygonUpdated: boolean;
  setOpenTagDialog: (open: boolean) => void;
  onSave: (tagName: string) => void;
  loading?: boolean;
};
const SelfTrainingTagDialog = (props: SelfTrainingTagDialogProps) => {
  const { content, open, setOpenTagDialog, onSave, loading, isPolygonUpdated } =
    props;
  const [tagName, setTagName] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { translate } = useLocales();

  const validate = () => {
    if (
      !content.toLowerCase().includes(tagName.toLowerCase()) ||
      tagName == ""
    ) {
      setError(translate("Tag name not found in document"));
      return true;
    } else if (!isPolygonUpdated) {
      setError(translate("Atleast one polygon should be updated"));
      return true;
    } else {
      setError("");
      return false;
    }
  };

  const isRequired = () => {
    if (tagName === "") {
      setError(translate("Tag name is required"));
      return true;
    }
    return false;
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setError("");
          setOpenTagDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translate("Add tag for self-training")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              fontSize: "12px",
            }}
          >
            {translate(
              "There is no tag attached with this document, if you wish to make this part of self-training please enter tag name."
            )}
          </DialogContentText>
          <NewTextField
            label={translate("Tag name")}
            placeholder={translate("Enter tag name")}
            value={tagName}
            error={error}
            helperText={error}
            onBlur={validate}
            onChange={(e) => setTagName(e.target.value)}
          />
          <Alert
            severity="info"
            variant="outlined"
            sx={{
              mt: 2,
              fontSize: "11px",
              fontWeight: "normal",
              fontStyle: "italic",
            }}
          >
            <p>
              {translate(
                "Note: Tag name should be unique like vendor id, vendor name, customer id, customer name and mentioned in document’s content."
              )}
            </p>
            <p>
              {translate(
                "To enable self-training, atleast one polygon should be updated."
              )}
            </p>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => {
              setError("");
              setOpenTagDialog(false);
            }}
            variant="outlined"
            size="small"
          >
            {translate("cancel")}
          </Button>
          <LoadingButton
            loading={loading}
            onClick={() => {
              onSave("");
            }}
            variant="contained"
            size="small"
          >
            {translate("Ignore & save")}
          </LoadingButton>
          <LoadingButton
            loading={loading}
            onClick={() => {
              if (isRequired()) return;
              if (validate()) return;
              onSave(tagName);
            }}
            variant="contained"
            size="small"
          >
            {translate("save")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SelfTrainingTagDialog;
