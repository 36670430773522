import { IconButton, Tooltip } from "@mui/material";
import MenuPopover from "../MenuPopover";

// ----------------------------------------------------------------------

type Props = {
  actions: React.ReactNode;
  open?: HTMLElement | null;
  onClose?: VoidFunction;
  onOpen?: (event: React.MouseEvent<HTMLElement>) => void;
  icon?: React.ReactNode;
  label?: string;
  width?: number;
  disabled?: boolean;
};

export default function TableMoreMenu({
  actions,
  open,
  onClose,
  onOpen,
  icon,
  label,
  width,
  disabled,
}: Props) {
  return (
    <>
      <Tooltip title={label ?? ""} placement="top">
        <IconButton size="small" onClick={onOpen} disabled={disabled}>
          {icon ? (
            icon
          ) : (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00977 2H8.01977M2 2H2.01M14.0195 2H14.0295"
                stroke="#657482"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </IconButton>
      </Tooltip>

      <MenuPopover
        disabledArrow
        open={Boolean(open)}
        anchorEl={open}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: width ?? 230,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
            "& svg": { mr: 2, width: 20, height: 20 },
            fontWeight: "bold",
          },
        }}
      >
        {actions}
      </MenuPopover>
    </>
  );
}
