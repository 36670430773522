import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
import { dashboard } from './apiData';
import { CompletedStep, ProgressBarPer } from './bumper';
import { CheckTooltipToggle } from './modal';
import { logoutOrganizationAction } from './organization';

// ----------------------------------------------------------------------

type CredState = {
    userCred: Array<string>;
    userTokenDecode: Array<string>;
    isLoading: boolean;
    error: Error | string | null;
};

const initialState: CredState = {
    isLoading: false,
    error: null,
    userCred: [],
    userTokenDecode: []
};

const slice = createSlice({
    name: 'Credential',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        loginAction(state, action) {
            state.userCred = action.payload;
        },
        loginTokenDecodeAction(state, action) {
            state.userTokenDecode = action.payload;
        },
        logoutAction(state, action) {
            state.userCred = action.payload;
            state.userTokenDecode = action.payload;
        },
    },
});


// Reducer
export default slice.reducer;

// Actions
export const { loginAction, logoutAction, loginTokenDecodeAction } = slice.actions;

export function LoginCred(userCred: any, email: any) {
    const array = { ...userCred, email }
    return async () => {
        dispatch(loginAction(array));
    };
}

export function tokenCred(token: any,) {
    return async () => {
        dispatch(loginTokenDecodeAction(token));
    };
}

export function LogoutCred() {
    const array: never[] = []
    return async () => {
        dispatch(logoutAction(array));
        dispatch(dashboard({}))
        dispatch(ProgressBarPer(0))
        dispatch(CompletedStep(0))
        dispatch(CheckTooltipToggle(true))
        dispatch(logoutOrganizationAction())
    };
}

