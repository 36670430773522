import { Stack, Typography, Modal, Box, Grid, Button } from "@mui/material";

import { createTheme } from "@mui/system";

import { LoadingButton } from "@mui/lab";

// hooks
import useLocales from "src/hooks/useLocales";

import DeleteIcon from "@mui/icons-material/Delete";

//theme
import { useTheme } from "@mui/material/styles";
import { PatchAsyncNew } from "src/common/httpRequests";
import { API_GATEWAY } from "src/constants/settings";
import { useSnackbar } from "notistack";
import { useState } from "react";

interface DeleteWorkflowModalProps {
  isShowModal: boolean;
  showModalMethod: VoidFunction;
  id: string;
  handleGetWorkflowAfterDelete: VoidFunction;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 550,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
  mt: 1,
  overflow: "auto",
};

const customStyle = createTheme({
  palette: {
    cardBackground: {
      light: "#FFFFFF",
      dark: "#212B36",
    },
    cardHeader: {
      light: "#2E435E",
      dark: "#848A90",
    },
    modalHeader: {
      light: "#000000",
      dark: "#848A90",
    },
    modalTitle: {
      light: "#2E435E",
      dark: "#848A90",
    },
  },
});

export default function DeleteWorkflowModal(props: DeleteWorkflowModalProps) {
  const { isShowModal, showModalMethod, id, handleGetWorkflowAfterDelete } =
    props;
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const { mode } = theme?.palette;

  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const deleteWorkflow = async () => {
    setLoading(true);
    await PatchAsyncNew(
      `${API_GATEWAY}/api/workflowservice/workflows/activate`,
      {
        id,
        activate: false,
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          enqueueSnackbar(
            translate("workflows_screen.alertDeactivateMessage"),
            {
              variant: "success",
            }
          );

          handleGetWorkflowAfterDelete();
          showModalMethod();
          setLoading(false);
        } else {
          enqueueSnackbar(translate(res?.data?.Detail), { variant: "error" });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error", err);
        setLoading(false);
      });
  };

  return (
    <Modal
      open={isShowModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack spacing={2}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            sx={{ color: customStyle.palette.modalHeader[mode] }}
            align="center"
          >
            {`${translate("delete_workflow")}?`}
          </Typography>

          <Typography
            sx={{ color: customStyle.palette.modalHeader[mode] }}
            align="center"
          >
            {translate("delete_workflow_approval")}
          </Typography>
          <Typography
            sx={{ color: customStyle.palette.modalHeader[mode] }}
            align="center"
          >
            {translate("delete_workflow_warning")}
          </Typography>
        </Stack>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid
            item
            md={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button onClick={() => showModalMethod()} variant="outlined">
              {translate("cancel_button")}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              onClick={() => deleteWorkflow()}
              loadingPosition="start"
              startIcon={<DeleteIcon />}
              sx={{ ml: 2 }}
            >
              {translate("delete")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
